import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { pullContactState } from "./store";

const AutoCompleteHeader = observer(props => {
  const {
    optionList = [],
    showDropdown = false,
    setShowDropdown = () => {},
    setUpdatedOptions = () => {},
    searchValue = "",
    setSearchValue
  } = props || {};
  const dropdownRef = useRef(null);

  const filterBySearchText = (value = "") => {
    let filteredData =
      optionList?.length > 0 &&
      optionList?.filter(item => {
        return item?.name?.toString()?.toLowerCase()?.includes(value);
      });
    setUpdatedOptions(filteredData);
  };

  const handleInputChange = event => {
    const value = event?.currentTarget?.value || "";
    setSearchValue(value);
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      setUpdatedOptions(optionList);
    } else {
      filterBySearchText(lowercasedValue);
    }
  };

  const scrollIntoDropdownView = () => {
    setTimeout(() => {
      dropdownRef &&
        dropdownRef.current &&
        dropdownRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
        });
    }, 150);
  };

  const handleHeaderClick = () => {
    if (!showDropdown) {
      setShowDropdown(true);
      scrollIntoDropdownView();
    }
  };

  const focusInputBox = () => {
    dropdownRef && dropdownRef.current && dropdownRef.current.focus();
  };

  const clearSearchInput = event => {
    Utils.preventDefaultFn(event);
    setSearchValue("");
    setUpdatedOptions(optionList);
    focusInputBox();
    pullContactState.setCreateListError("");
  };

  return (
    <div className="dropdownHeader" onClick={handleHeaderClick}>
      <div className="dropdownInputHeader">
        <input
          className="dropdownTitle"
          type="text"
          placeholder="Select Sequence"
          onChange={handleInputChange}
          value={searchValue}
          ref={dropdownRef}
        />
        {searchValue?.length > 0 && (
          <i
            className="material-icons clearIpIcon"
            onClick={e => clearSearchInput(e)}
          >
            close
          </i>
        )}
        <i
          className={`material-icons arrowIcon ${showDropdown ? "rotate" : ""}`}
        >
          keyboard_arrow_down
        </i>
      </div>
    </div>
  );
});

const AutoCompleteBody = observer(props => {
  const {
    updatedOptions = [],
    dropDownValue = "",
    cbk = () => {},
    setShowDropdown = () => {},
    setSearchValue = () => {}
  } = props || {};

  const handleDropdownChange = (item, index) => {
    setSearchValue(item?.name);
    cbk(item, index);
    setShowDropdown(false);
  };

  return (
    <>
      {updatedOptions.length > 0 ? (
        updatedOptions?.map((item, index) => (
          <li
            key={`limit-${item?.name}-${Date.now}-${index}`}
            className={`dropdownOptions ${
              dropDownValue === item?.name && !item?.disabled ? "active" : ""
            } ${item?.disabled ? "disabled" : ""}`}
            onClick={
              item?.disabled ? () => {} : e => handleDropdownChange(item, index)
            }
          >
            <>
              <span
                className={`updatedName ${
                  item?.id?.toLowerCase() === "loading" ? "loading" : ""
                } `}
              >
                {item?.name}
              </span>
            </>
          </li>
        ))
      ) : (
        <li className="dropdownOptions noResultsFound">No results found</li>
      )}
    </>
  );
});

const SelectSequenceWithSearch = observer(props => {
  const {
    cbk = () => {},
    optionList = [],
    defaultValue = "",
    disabled = false
  } = props || {};

  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [dropDownValue, setDropdownValue] = useState(defaultValue);

  useEffect(() => {
    setSearchValue(pullContactState.selectedSequence.name);
  }, [pullContactState.selectedSequence]);

  useEffect(() => {
    optionList && optionList?.length > 0 && setUpdatedOptions(optionList);
  }, [optionList]);

  return (
    <>
      <div
        className={`customAutoCompleteDropdownWrapper ${
          !pullContactState.allowSequence ? "restrictAllowSequence" : ""
        } ${disabled ? "disableDropdown" : ""}`}
      >
        <AutoCompleteHeader
          optionList={optionList}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          setUpdatedOptions={setUpdatedOptions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {showDropdown && (
          <div className="dropdownPanel">
            <ul className="dropdownList" ref={dropdownRef}>
              <AutoCompleteBody
                optionList={optionList}
                updatedOptions={updatedOptions}
                setUpdatedOptions={setUpdatedOptions}
                dropDownValue={dropDownValue}
                setDropdownValue={setDropdownValue}
                cbk={cbk}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setShowDropdown={setShowDropdown}
              />
            </ul>
          </div>
        )}
      </div>
      {showDropdown && (
        <div
          className="autoCompleteDropdownOverlay"
          onClick={() => {
            setShowDropdown(false);
          }}
        />
      )}
    </>
  );
});

export { SelectSequenceWithSearch };
export default SelectSequenceWithSearch;
