/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import CustomTooltip from "Components/common/CustomTooltip";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { ReactComponent as ExportIcon } from "Assets/svg/exportCsv.svg";
import DropdownMenu from "Components/common/DropDownMenu";
import { ReactComponent as CvsExportIcon } from "Assets/svg/csvExport.svg";
import { ReactComponent as HubspotExportIcon } from "Assets/svg/hubspotExport.svg";
import { ReactComponent as SalesforceExportIcon } from "Assets/svg/salesforceExport.svg";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const ExportBtn = props => {
  const {
    selectedEntityCount = 0,
    cbk = () => {},
    loading = false,
    checked = false,
    isExportEnabled = false,
    pageType = "",
    tooltipText = "contacts",
    showExportTypeDropdown = false,
    setShowExportTypeDropdown = () => {}
  } = props || {};

  const {
    inFreePlan = false,
    planName = "",
    salesforceIntegrationEnabled = false,
    hubspotIntegrationEnabled = false
  } = userDetail.userFeatureFlag || {};

  const title =
    selectedEntityCount > 0
      ? "Export Selected"
      : `Select one or more ${tooltipText} to export.`;

  const [showDescriptionTp, setShowDescriptionTp] = useState(false);

  const exportAction = async ({}, item = {}) => {
    setShowExportTypeDropdown(false);
    const { type = "" } = item || {};
    if (["hubspot", "salesforce"].includes(type?.toLowerCase())) {
      !loading && cbk(type);
    } else {
      !isExportEnabled ||
        (checked &&
          !loading &&
          (await checkTeamSubscriptionActive()) &&
          cbk(type));
    }
  };

  const handleExportHoverAction = (mouseEnter = false) => {
    if (selectedEntityCount > 0) {
      setShowExportTypeDropdown(mouseEnter);
    }
    setShowDescriptionTp(mouseEnter);
  };

  const pageTypeObj = {
    calls: "callsExport",
    contacts: "contactsExport",
    sequences: "sequenceExport"
  };

  const dropdownOptions = [
    {
      icon: {
        ele: <CvsExportIcon className="csvIcon" />
      },
      value: "Export as CSV",
      key: "exportAsCsv",
      method: exportAction,
      type: "csv"
    },
    {
      icon: {
        ele: <HubspotExportIcon className="hubspotIcon" />
      },
      value: "Export to Hubspot",
      key: "exportToHubspot",
      method: exportAction,
      type: "hubspot",
      showUpgrade: !hubspotIntegrationEnabled,
      plan: "basicPlan"
    },
    {
      icon: {
        ele: <SalesforceExportIcon className="salesforceIcon" />
      },
      value: "Export to Salesforce",
      key: "exportToSalesforce",
      method: exportAction,
      type: "salesforce",
      showUpgrade: !salesforceIntegrationEnabled,
      plan: "basicPlan"
    }
  ];

  return (
    <div
      className={`exportBtnWrapper ${
        selectedEntityCount <= 0 ? "exportSelectedDisabled" : ""
      } ${selectedEntityCount && isExportEnabled ? "enabled" : ""}`}
      onClick={e => exportAction(e, "exportIcon")}
      onMouseEnter={() => handleExportHoverAction(true)}
      onMouseLeave={() => handleExportHoverAction(false)}
    >
      <ExportIcon className="exportSvg" />
      {showDescriptionTp && (
        <CustomTooltip
          text={title}
          className={
            selectedEntityCount > 0
              ? "enrichContactsTooltip"
              : "bulkAddtoSequence"
          }
        />
      )}
      {!isExportEnabled && (
        <span className="wrapLockIcon">
          <UpgradeIconTooltip
            plan={PaywallUtils.getPlanName(planName, inFreePlan)}
            type={pageTypeObj[pageType]}
            enableTooltip={true}
            alignTooltip="topRight"
          />
        </span>
      )}
      {showExportTypeDropdown && <DropdownMenu options={dropdownOptions} />}
    </div>
  );
};

export { ExportBtn };
export default observer(ExportBtn);
