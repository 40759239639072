/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { tagsStoreData } from "Stores/TagsData";
import PersonalInformation from "Components/common/contact/PersonalInformation";
import CompanyInformation from "Components/common/contact/CompanyInformation";
import ContactNotes from "Components/common/contact/ContactNotes";
import LocationInformation from "Components/common/contact/LocationInformation";
import ContactTags from "Components/common/contact/ContactTags";
import SocialLinks from "Components/common/contact/SocialLinks";
import ContactCustomFields from "Components/common/contact/ContactCustomFields";
import { contactStoreData } from "Stores/contactData";
import { SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS } from "Static/constant";

class EditContactPopupState {
  @observable showPopup = false;
  @observable contact = false;
  @observable callback = null;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setContact = val => {
    this.contact = val;
  };

  @action
  setCallback = val => {
    this.callback = val;
  };
}

const editContactPopupState = new EditContactPopupState();

const EditContactPopup = props => {
  const defaultInputState = editContactPopupState.contact;
  const [inputVal, setInputVal] = useState(defaultInputState);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showTagDropDown, setShowTagDropDown] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState(inputVal.contactTags);

  const changeInputVal = (id, data, flag) => {
    if (flag === "customFields") {
      const stateObj = { ...inputVal };
      const customFields = stateObj["customFields"];
      if (customFields) {
        customFields[id] = data;
        setInputVal(stateObj);
      } else {
        const tempObj = {};
        tempObj[id] = data;
        stateObj.customFields = tempObj;
        setInputVal(stateObj);
      }
    } else {
      const stateObj = { ...inputVal };
      stateObj[id] = data;
      setInputVal(stateObj);
    }
  };

  const submitAction = async ev => {
    if (ev?.target?.checkValidity()) {
      ev.preventDefault(); // Necessary to prevent submiting form in url
      try {
        setButtonLoader(true);
        const payload = getEditContactPayload();
        const response = await saveContact(payload);
        if (response && response.data) {
          handleUpdateContactResponse(response.data);
        } else {
          Utils.showApiFailureResponse(response);
          setButtonLoader(false);
          closeAction();
        }
      } catch (e) {
        handleUpdateContactFailure();
      }
    } else {
      ev?.target?.reportValidity();
    }
  };

  const getEditContactPayload = () => {
    if (inputVal && Object.keys(inputVal).length) {
      const payload = {};
      const contact = {};
      if (selectedTags && selectedTags.length) {
        const extractedTagsArray = Utils.extractTagNameFromList(selectedTags);
        payload.contactTags = extractedTagsArray;
      }
      contact.firstName = inputVal.firstName || null;
      contact.lastName = inputVal.lastName || null;
      contact.email = inputVal.email || null;
      contact.linkedinId = Utils.removeHttpFromString(
        inputVal.linkedinId || null
      );
      if (inputVal?.mobilePhone?.length) {
        contact.mobilePhone = [inputVal.mobilePhone];
      }
      if (inputVal?.otherPhone?.length) {
        contact.otherPhone = [inputVal.otherPhone];
      }
      if (inputVal?.phone?.length) {
        contact.phone = [inputVal.phone];
      }
      contact.company = inputVal.company || null;
      contact.jobTitle = inputVal.jobTitle || null;
      contact.industry = inputVal.industry || null;
      contact.street = inputVal.street || null;
      contact.city = inputVal.city || null;
      contact.state = inputVal.state || null;
      contact.country = inputVal.country || null;
      contact.zipCode = inputVal.zipCode || null;
      contact.website = inputVal.website || null;
      contact.facebook = inputVal.facebook || null;
      contact.twitter = inputVal.twitter || null;
      contact.contactNotes = inputVal.contactNotes || null;
      contact.customFields =
        Utils.addNullForEmptyObject(inputVal.customFields) || null;
      contact.id = inputVal.id || null;
      payload.contacts = [contact];
      return payload;
    }
  };

  const saveContact = payload => {
    const config = {
      url: URLS.updateContactsToDirectory,
      method: "POST",
      data: payload
    };
    return makeApi(config);
  };

  const handleUpdateContactResponse = response => {
    const responseCode = response.responseCode ? response.responseCode : "";
    if (responseCode && responseCode === 200) {
      const extractedTagsArray = Utils.extractTagNameFromList(selectedTags);
      tagsStoreData.getAllTags();
      let message = "Contact has been updated successfully";
      toasterState.setToastMsg(message, "success");
      const contactdata = response.contact;
      setButtonLoader(false);
      closeAction();
      editContactPopupState.setContact(contactdata);
      editContactPopupState.callback(contactdata, extractedTagsArray);
    } else if (responseCode === 400) {
      setButtonLoader(false);
      closeAction();
      let message = "Email Address or LinkedIn url is mandatory for a contact.";
      toasterState.setToastMsg(message, "failure");
    } else if (responseCode === 409) {
      setButtonLoader(false);
      closeAction();
      let message =
        "A contact already exists with the same email address or LinkedIn profile. Please check and try again.";
      toasterState.setToastMsg(message, "failure");
    } else if (responseCode === 403) {
      setButtonLoader(false);
      closeAction();
      let message = "You don't have editing access to this contact.";
      toasterState.setToastMsg(message, "failure");
    } else {
      handleUpdateContactFailure();
    }
  };

  const handleUpdateContactFailure = () => {
    setButtonLoader(false);
    toasterState.setToastMsg("Failed to updated contact", "failure");
  };

  const closeAction = () => {
    editContactPopupState.setShowPopup(false);
    contactStoreData.setUpdateContactEmail("");
    setSelectedTags({});
  };

  const CloseIcon = () => {
    return (
      <div
        className="popup-close"
        title="Click here to close"
        onClick={closeAction}
      >
        <i className="material-icons">close</i>
      </div>
    );
  };

  const addSelectedTagsInTagList = (tagList, existingTags) => {
    if (existingTags && existingTags.length) {
      tagList &&
        tagList.map(obj => {
          const { value } = obj;
          if (value && existingTags.includes(value)) {
            obj.checked = true;
          }
        });
    }
    setTagList([...tagList]);
  };

  useEffect(() => {
    if (defaultInputState && Object.keys(defaultInputState).length) {
      setInputVal(defaultInputState);
      setSelectedTags(defaultInputState.contactTags);
      addSelectedTagsInTagList(tagList, defaultInputState.contactTags);
    }
  }, [defaultInputState]);

  useEffect(() => {
    if (tagsStoreData.tagsData) {
      addSelectedTagsInTagList(
        Utils.createObjWithoutRef(tagsStoreData.tagsData),
        null
      );
    }
  }, [tagsStoreData.tagsData]);

  return editContactPopupState.showPopup && inputVal ? (
    <div className="popupWrapper editContactPopup">
      <div className="confirmation-popup common-popup">
        <form
          className="popup-wrap animated customzoomIn "
          onSubmit={e => submitAction(e)}
          autoComplete="off"
        >
          {showTagDropDown && (
            <div
              className="transparentOverlay"
              onClick={e => setShowTagDropDown(false)}
            ></div>
          )}
          <div className="title">
            <div className="titleName">Edit Contact</div>
          </div>
          <CloseIcon />
          <div className="popContainer popup-content-wrap">
            <PersonalInformation
              changeInputVal={changeInputVal}
              inputVal={inputVal}
              tokenList={SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS}
              isEditContact={true}
            />
            <div className="companyAndNotes">
              <CompanyInformation
                changeInputVal={changeInputVal}
                tokenList={SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS}
                inputVal={inputVal}
              />
              <ContactNotes
                changeInputVal={changeInputVal}
                inputVal={inputVal}
              />
            </div>
            <LocationInformation
              changeInputVal={changeInputVal}
              inputVal={inputVal}
            />
            <SocialLinks changeInputVal={changeInputVal} inputVal={inputVal} />
            <ContactTags
              tagsList={tagList}
              setTagList={setTagList}
              showTagDropDown={showTagDropDown}
              setShowTagDropDown={setShowTagDropDown}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
            <ContactCustomFields
              changeInputVal={changeInputVal}
              customFieldsValue={inputVal.customFields || {}}
            />
          </div>
          <div className="popup-action-wrap">
            <Button
              type="submit"
              btnText="Update Contact"
              className={`positive-btn ${buttonLoader ? "loading" : ""}`}
              //click={e => submitAction(e)}
              loader={buttonLoader}
            />
          </div>
        </form>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
};

export { EditContactPopup, editContactPopupState };
export default observer(EditContactPopup);
