import { action, observable } from "mobx";

const onBoardingFlagsObj = {
  isSequenceCreated: null,
  isContactsAdded: null,
  isEmailAccountConnected: null,
  firstSequenceUrl: null,
  isOnboardingCompleted: "",
  firstSequence: null,
  isTeamMemberAdded: null
};

class Onboarding {
  @observable popup = false;
  @observable remainingFeatureCount = 0;
  @observable onBoardingFlagValues = onBoardingFlagsObj;

  @action
  setPopup = (value = false) => {
    this.popup = value;
  };

  @action
  setRemainingFeatureCount = (value = 0) => {
    this.remainingFeatureCount = value;
  };

  @action
  setOnBoardingFlagValues = (value = {}) => {
    this.onBoardingFlagValues = value;
  };
}

const onboarding = new Onboarding();
export default onboarding;
export { onboarding };
