/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { sequenceStoreData } from "Stores/sequenceData";
import contactStoreData from "Stores/contactData";
import NoSequenceImg from "Assets/Sequence/noSequence.png";
import { ReactComponent as FolderIcon } from "Assets/svg/folder.svg";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrow.svg";
import RadioButton from "Components/common/radioBtn";
import Utils from "Utils/utils";
import { settingsStoreData } from "Stores/settingsData";
import Button from "Components/common/Button";
import SequenceUtils from "Utils/SequenceUtils";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { onboardingStoreData } from "Stores/onBoardingData";
import { scheduleDataStore } from "Stores/ScheduleData";
import PaywallUtils from "Utils/PaywallUtils";

class AddSequenceInContact {
  @observable showPopup = false;
  @observable submitCbk = () => {};
  @observable resetAllCheckedContacts = false;
  @observable pageType = "";

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.pageType = "";
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPageType = (value = "") => {
    this.pageType = value;
  };

  @action
  setCallback = cbkFn => {
    this.submitCbk = cbkFn;
  };

  @action
  setResetAllCheckedContacts = value => {
    this.resetAllCheckedContacts = value;
  };
}

const addSequenceInContactPopupState = new AddSequenceInContact();

const AddSequenceInContactPopup = props => {
  let settingsData = settingsStoreData.settingsData;
  const {
    taskEnabled = "",
    planName = "",
    inFreePlan = false
  } = userDetail?.userFeatureFlag || {};
  const [folderData, setFolderData] = useState(
    sequenceStoreData.allFolderResponse
  );
  const [sequenceData, setSequenceData] = useState([]);
  const [sequenceSectionLoader, setSequenceSectionLoader] = useState(true);
  const [folderSelectedClass, setFolderSelected] = useState("mySequence");
  const [isNxtBtnDisabled, setNxtBtnDisable] = useState(true);
  const [nextBtnLoader, setNextBtnLoader] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [selectedSequenceData, setSelectedSequenceData] = useState({});

  const addRadioStatusObj = seqData => {
    const tempSequenceData = [];
    if (seqData?.length) {
      seqData.map((seqObj, index) => {
        if (seqObj.name) {
          seqObj.isChecked = index === 0;
          tempSequenceData.push(seqObj);
        }
      });
    }
    if (tempSequenceData.length) {
      setNxtBtnDisable(false);
    } else {
      setNxtBtnDisable(true);
    }
    setSequenceData(tempSequenceData);
    setSequenceSectionLoader(false);
  };

  const updateSelectedClass = (folderId, seqName) => {
    if (folderId) {
      setFolderSelected(folderId);
    } else setFolderSelected(seqName);
  };

  const submitAction = async event => {
    Utils.preventDefaultFn(event);
    setNextBtnLoader(true);
    if (sequenceData?.length) {
      const selectedSeq = getSelectedSeq();
      setSelectedSequenceData(selectedSeq);
      const cbk = async () => {
        const tempCallObj = {};
        if (selectedSeq) {
          const selectedSeqId = selectedSeq?.id || "";
          const selectedSteps = selectedSeq?.steps || {};
          // check once
          if (
            selectedSteps &&
            Object.keys(selectedSteps)?.length &&
            SequenceUtils.hasTask(selectedSteps) &&
            !taskEnabled
          ) {
            setNextBtnLoader(false);
            addSequenceInContactPopupState.setResetAllCheckedContacts(true);
            addSequenceInContactPopupState.setShowPopup(false);

            PaywallUtils.upgradeUtilsFunction(
              "addContactsToSequence",
              false,
              planName,
              inFreePlan
            );
          } else {
            if (
              SequenceUtils.checkAndAllowContactUpload(
                props,
                selectedSeq,
                selectedSeqId,
                "contacts"
              )
            ) {
              if (SequenceUtils.inboxConnected(selectedSeq)) {
                // await sequenceStoreData.getSenderToken(selectedSeqId);
                setNextBtnLoader(false);
                // if (sequenceStoreData?.missingSenderTokens?.length === 0) {
                const seqName = selectedSeq?.name || "Sequence";
                tempCallObj.title = seqName;
                tempCallObj.steps = selectedSteps;
                addSequenceInContactPopupState.submitCbk(
                  tempCallObj,
                  selectedSeqId,
                  selectedSeq
                );
                addSequenceInContactPopupState.setShowPopup(false);
                // } else {
                //   addSequenceInContactPopupState.setResetAllCheckedContacts(
                //     true
                //   );
                //   addSequenceInContactPopupState.setShowPopup(false);
                //   SequenceUtils.showMissingSenderTokenPopup(props);
                // }
              } else {
                addSequenceInContactPopupState.setResetAllCheckedContacts(true);
                addSequenceInContactPopupState.setShowPopup(false);
                SequenceUtils.showNoInboxconnectedPopup(
                  props,
                  SequenceUtils.getSequencePath(selectedSeq, folderId)
                );
                setNextBtnLoader(false);
              }
            } else {
              setNextBtnLoader(false);
              addSequenceInContactPopupState.setResetAllCheckedContacts(true);
              addSequenceInContactPopupState.setShowPopup(false);
            }
          }
        }
      };
      !onboardingStoreData?.onBoardingFlags?.isContactsAdded &&
        setNextBtnLoader(false);
      cbk();
    }
  };

  const getSelectedSeq = () => {
    return sequenceData.find(seqObj => seqObj.isChecked);
  };

  const RenderFolderData = () => {
    return (
      <div className="folderListCont">
        <DefaultSequenceFolder />
        <RenderDynamicFolder />
      </div>
    );
  };

  const RightArrowIcon = () => {
    return (
      <span className="rightArrowIcon">
        <RightArrow />
      </span>
    );
  };

  const DefaultSequenceFolder = () => {
    const mySeqClickAction = async () => {
      setSequenceSectionLoader(true);
      updateSelectedClass("mySequence"); // Active Sequence folder menu
      const res = await contactStoreData.getContactSequenceData("all");
      res && addRadioStatusObj(res || []);
    };

    const sharedSequenceAction = async () => {
      setSequenceSectionLoader(true);
      updateSelectedClass("sharedSequence"); // Active Sequence folder menu
      const res = await contactStoreData.getContactSequenceData("shared");
      res && addRadioStatusObj(res || []);
    };

    return (
      <>
        <div
          className={`folder ${
            folderSelectedClass === "mySequence" ? " selected" : ""
          }`}
          onClick={mySeqClickAction}
        >
          <span className="folderIcon">
            <FolderIcon />
          </span>
          <span className="folderName">My Sequences</span>
          <RightArrowIcon />
        </div>
        <div
          className={`folder ${
            folderSelectedClass === "sharedSequence" ? " selected" : ""
          }`}
          onClick={sharedSequenceAction}
        >
          <span className="folderIcon">
            <FolderIcon />
          </span>
          <span className="folderName">Shared Sequences</span>
          <RightArrowIcon />
        </div>
      </>
    );
  };

  const RenderDynamicFolder = observer(() => {
    const folderClickAction = async (event, folderId) => {
      event.preventDefault();
      setSequenceSectionLoader(true);
      updateSelectedClass(folderId);
      setFolderId(folderId);
      const res = await contactStoreData.getContactSequenceData(folderId);
      res && addRadioStatusObj(res || []);
    };

    return (
      <div className="dynamicFoldersWrapper">
        {folderData &&
          folderData.length > 0 &&
          folderData.map((folderVal, index) => {
            const { id, folderName } = folderVal;
            return (
              <div
                className={`folder ${
                  folderSelectedClass === id ? " selected" : ""
                }`}
                key={index}
                onClick={e => folderClickAction(e, id)}
              >
                <span className="folderIcon">
                  {" "}
                  <FolderIcon />
                </span>
                <span className="folderName">{folderName}</span>
                <RightArrowIcon />
              </div>
            );
          })}
      </div>
    );
  });

  const hasSequences = () => {
    if (sequenceData && sequenceData.length > 0) return true;
    else return false;
  };

  const LoadingComponent = () => {
    return Array.from({ length: 10 }, (value, index) => (
      <div
        className="sequenceDetails"
        key={`sequenceDetails ${index}`}
        style={{ height: "45px" }}
      >
        <span className="d-block h-14p b-r-20 w-100 linear-background"></span>
      </div>
    ));
  };

  const redirectToCreateSequence = () => {
    addSequenceInContactPopupState.setShowPopup(false);
    let pathUrl = `/sequences/${
      (folderSelectedClass === "mySequence" && "all") || folderSelectedClass
    }`;
    folderSelectedClass !== "sharedSequence" &&
      props.history.push(pathUrl.trim());
  };

  const RenderSequence = observer(() => {
    const radioOnchangeAction = (e, id = "") => {
      sequenceData.map(seqObj => {
        if (seqObj.name) {
          if (id === seqObj.id) {
            seqObj.isChecked = true;
          } else seqObj.isChecked = false;
        }
      });
      setSequenceData(sequenceData);
    };

    return (
      <div className="sequenceInnerCont">
        {!sequenceSectionLoader ? (
          hasSequences() ? (
            sequenceData.map((seqObj, index) => {
              const { id, name, isChecked, steps = "" } = seqObj;
              if (name) {
                return (
                  <div
                    className="sequenceDetails"
                    key={index}
                    data-name={name}
                    data-id={id}
                  >
                    <RadioButton
                      id={id}
                      value={name}
                      name="sequence"
                      defaultChecked={isChecked}
                      radioOnchange={radioOnchangeAction}
                    />
                    {steps && SequenceUtils.hasTask(steps) && !taskEnabled && (
                      <UpgradeIconTooltip
                        plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                        type="taskPage"
                        showIcon={true}
                      />
                    )}
                  </div>
                );
              }
            })
          ) : (
            <div className="noSequence">
              <img src={NoSequenceImg} alt="" />
              <p>{`No ${
                folderSelectedClass === "sharedSequence" ? "Shared" : ""
              } Sequence added in this folder`}</p>
              {folderSelectedClass !== "sharedSequence" && (
                <Button
                  id="redirectToCreateSequence"
                  name="redirectToCreateSequence"
                  btnText="Create a Sequence"
                  type="button"
                  className="redirectToCreateSequence"
                  click={e => {
                    e.preventDefault();
                    redirectToCreateSequence();
                  }}
                />
              )}
            </div>
          )
        ) : (
          <LoadingComponent />
        )}
      </div>
    );
  });

  const popupCloseAction = () => {
    addSequenceInContactPopupState.setShowPopup(false);
  };

  const getAllSequenceData = async () => {
    if (addSequenceInContactPopupState.showPopup) {
      setFolderSelected("mySequence");
      setSequenceSectionLoader(true);
      let res = await contactStoreData.getContactSequenceData("all");
      res && addRadioStatusObj(res || []);
    }
  };

  useEffect(() => {
    setFolderData(sequenceStoreData.allFolderResponse);
  }, [sequenceStoreData.allFolderResponse]);

  useEffect(() => {
    settingsData = settingsStoreData.settingsData;
  }, [settingsStoreData.settingsData]);

  useEffect(() => {
    getAllSequenceData();
    return () => {
      setSequenceSectionLoader(false);
      setFolderSelected("mySequence");
    };
  }, [addSequenceInContactPopupState.showPopup]);

  useEffect(() => {
    scheduleDataStore.getDefaultScheduleData(
      selectedSequenceData?.scheduleWindowId
    );
  }, [scheduleDataStore.scheduleData, selectedSequenceData]);

  useEffect(() => {
    scheduleDataStore.getScheduleData();
    const selectedSeq = getSelectedSeq();
    setSelectedSequenceData(selectedSeq);
  }, []);

  return (
    (addSequenceInContactPopupState.showPopup && (
      <div className="popupWrapper addSeqinContactsPage">
        <div className="confirmation-popup common-popup">
          <form
            className="popup-wrap animated customzoomIn"
            onSubmit={e => submitAction(e)}
            autoComplete="off"
          >
            <div className="title">
              Add to Sequence
              <div
                className="popup-close"
                title="Click here to close"
                onClick={() => popupCloseAction()}
              >
                <i className="material-icons">close</i>
              </div>
              {addSequenceInContactPopupState?.pageType?.toLowerCase() ===
                "discover" && (
                <p className="descText">
                  Please note, You will be charged 1 email credit per purchased
                  contact. For previously owned contacts, no credits will be
                  deducted.
                </p>
              )}
            </div>
            <div
              className={`popup-content-wrap ${
                addSequenceInContactPopupState?.pageType?.toLowerCase() || ""
              }`}
            >
              <div className="leftSection">
                <div className="folderCont">
                  <RenderFolderData />
                </div>
              </div>
              <div className="rightSection">
                <div className="sequenceCont">
                  <RenderSequence />
                </div>
              </div>
            </div>
            <div className="popup-action-wrap">
              <Button
                id="nextBtnInAddContact"
                name="nextBtn"
                btnText="Next"
                type="button"
                loader={nextBtnLoader}
                className={`nextBtn btn ${nextBtnLoader ? "loading" : ""} ${
                  sequenceSectionLoader || isNxtBtnDisabled ? "disabled" : ""
                }`}
                click={e => {
                  e.preventDefault();
                  submitAction(e);
                }}
              />
            </div>
          </form>
        </div>
        <div className="common-popup-mask" />
      </div>
    )) ||
    ""
  );
};

export { AddSequenceInContactPopup, addSequenceInContactPopupState };
export default observer(AddSequenceInContactPopup);
