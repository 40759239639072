import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import onboarding from "../Components/common/Onboarding";

const onBoardingFlagsObj = {
  isSequenceCreated: null,
  isContactsAdded: null,
  isEmailAccountConnected: null,
  firstSequenceUrl: null,
  isOnboardingCompleted: "",
  firstSequence: null,
  isTeamMemberAdded: null
};

class OnboardingData {
  @observable onBoardingFlags = onBoardingFlagsObj;
  @observable OnBoardingEnabled = "";
  @observable showStepsPopup = false;
  @observable isGettingStartVideoShown = this.fetchGettingStartFlag();
  @observable activeOnBoardingData = "";
  @observable disableContactOnboarding = false;
  @observable disableTeamInvite = false;

  @action
  setOnBoardingFlags = value => {
    this.onBoardingFlags = value;
  };

  @action
  setActiveOnBoardingData = value => {
    this.activeOnBoardingData = value;
  };

  @action
  setDisableTeamInvite = value => {
    this.disableTeamInvite = value;
  };

  @action
  setDisableContactOnboarding = value => {
    this.disableContactOnboarding = value;
  };

  @action
  setShowStepsPopup = value => {
    this.showStepsPopup = value;
  };

  @action
  setOnboardingEnabled = value => {
    this.OnBoardingEnabled = value;
  };

  @action
  setGettingStartVideoShown = value => {
    this.isGettingStartVideoShown = value;
  };

  @action
  fetchGettingStartFlag = () => {
    if (localStorage && localStorage.getItem("isGettingStartedVideoShown")) {
      return localStorage.getItem("isGettingStartedVideoShown");
    } else {
      return false;
    }
  };

  @action
  getOnBoardingData = async () => {
    try {
      let config = {
        url: URLS.getonBoardingData,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data) {
        this.constructOnboardingFlags(response.data);
        const { isOnboardingCompleted = "", emailConnected = "" } =
          response.data;
        return isOnboardingCompleted && emailConnected;
      } else Utils.showApiFailureResponse(response);
      return false;
    } catch (err) {
      console.error("OnBoarding Data error ", err);
    }
  };

  @action
  constructOnboardingFlags = data => {
    if (data) {
      const tempFlagObj = JSON.parse(JSON.stringify(onBoardingFlagsObj));
      const {
        emailConnected,
        firstSequence,
        scheduledSequenceCount,
        isOnboardingCompleted,
        schedulingInProgress
      } = data;
      tempFlagObj.firstSequence = firstSequence;
      tempFlagObj.isEmailAccountConnected = emailConnected;
      tempFlagObj.isContactsAdded =
        scheduledSequenceCount > 0 || schedulingInProgress ? true : false;
      // isSequenceCreated => if user has previously scheduled sequence don't show create sequence
      tempFlagObj.isSequenceCreated =
        tempFlagObj.isContactsAdded || findIsSequenceCreated(firstSequence);
      tempFlagObj.isOnboardingCompleted = isOnboardingCompleted;
      const sequenceUrl = constructFirstSequnceUrl(firstSequence);
      tempFlagObj.firstSequenceUrl = sequenceUrl;
      this.setOnBoardingFlags(tempFlagObj);
      onboarding.setOnBoardingFlagValues(tempFlagObj);
    }
  };
}

const constructFirstSequnceUrl = firstSequence => {
  if (firstSequence && firstSequence.id) {
    const folderId = firstSequence.folderId;
    const sequenceId = firstSequence.id;
    let sequenceUrl = "/sequences";
    if (folderId) {
      sequenceUrl += `/${folderId}`;
      if (sequenceId) {
        sequenceUrl += `/${sequenceId}`;
      }
    } else if (sequenceId) {
      sequenceUrl += `/all/${sequenceId}`;
    }
    return sequenceUrl;
  } else {
    return null;
  }
};

const findIsSequenceCreated = firstSequence => {
  if (firstSequence?.id && firstSequence?.steps) {
    return Object.keys(firstSequence.steps)?.length;
  } else {
    return false;
  }
};

const onboardingStoreData = new OnboardingData();
export { onboardingStoreData, OnboardingData };
export default onboardingStoreData;
