import React, {
  useState,
  useRef,
  createContext,
  useContext,
  useEffect
} from "react";
import SearchInputBox from "Components/common/SearchInputBox";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

const AutoCompleteContext = createContext({});

const AutoCompleteHeader = observer(props => {
  const {
    searchBoxRef = null,
    showDropdown = false,
    setShowDropdown = () => {},
    dropDownValue = "",
    hasArrow = true,
    showIcon = false,
    HeaderChildren = "",
    validateOnHeaderClick = "",
    hasAddNewList = "",
    icon = <></>,
    setDropdownBottom = () => {},
    setShowDropdownMenu = () => {},
    crm = false
  } = useContext(AutoCompleteContext);

  const {
    discoverPage = false,
    handleGetSearch = () => {},
    customTitle = "Select"
  } = props || {};

  const toggleDropdown = event => {
    let positionY = event?.clientY || event?.pageY || 0;
    let domPosition =
      36 - event?.nativeEvent?.layerY || event?.nativeEvent?.offsetY || 0;
    let innerHeight = window?.innerHeight;
    let diffHeight = innerHeight - positionY;
    setDropdownBottom(diffHeight - (domPosition + 70) >= 235);
  };

  const headerClick = e => {
    Utils.mixpanelTrack(MXP_EVENT.ADD_TO_LIST_CLICKED, {
      pageType: PAGE_TYPE[hasAddNewList ? "discover" : "contacts"]
    });
    if (typeof validateOnHeaderClick === "function") {
      validateOnHeaderClick() && showUpdatedDropDown(e);
    } else {
      showUpdatedDropDown(e);
    }
  };

  const showUpdatedDropDown = e => {
    crm && toggleDropdown(e);
    setShowDropdownMenu(true);
    setShowDropdown(true);
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const styleObj = discoverPage
    ? {
        border: "0px",
        padding: "0px",
        color: "#3953FB",
        height: "0px",
        width: "32px",
        position: "relative",
        top: "9px"
      }
    : {};

  return (
    <span className="dropdownHeader" style={styleObj} onClick={headerClick}>
      {React.isValidElement(HeaderChildren) ? (
        HeaderChildren
      ) : (
        <>
          <div className="titleWrapper">
            {showIcon && icon}
            <div className="title">{dropDownValue || customTitle}</div>
          </div>
          {hasArrow && (
            <span className={`arrow ${showDropdown ? "activeArrow" : ""}`}>
              <i className="material-icons">expand_more</i>
            </span>
          )}
        </>
      )}
    </span>
  );
});

const AutoCompleteBody = observer(props => {
  const {
    cbk = () => {},
    index = "",
    setDropdownValue = () => {},
    setShowDropdown = () => {},
    dropDownValue = "",
    updatedOptions = [],
    setUpdatedOptions = () => {},
    updateListBody = false,
    Body = <></>,
    setShowDropdownMenu = () => {}
  } = useContext(AutoCompleteContext);
  const {
    optionList = [],
    discoverPage = false,
    setSelectedValueCbk = () => {}
  } = props;

  const handleDropdownChange = (item, index) => {
    if (discoverPage) {
      setDropdownValue(item?.searchName);
      setSelectedValueCbk(item?.id);
    } else {
      setDropdownValue(item?.name);
    }
    cbk(item, index);
    setUpdatedOptions(optionList);
    setShowDropdownMenu(false);
    setShowDropdown(false);
  };

  return updatedOptions?.map(item => (
    <li
      key={`limit${discoverPage ? item?.searchName : item?.name}`}
      className={`dropdownOptions ${
        dropDownValue === item?.name && !discoverPage && !item?.disabled
          ? "active"
          : ""
      } ${item?.disabled ? "disabled" : ""}`}
      onClick={
        item?.disabled ? () => {} : e => handleDropdownChange(item, index)
      }
    >
      {updateListBody ? (
        <Body {...item} />
      ) : (
        <>
          <span
            className={`updatedName ${
              item?.id?.toLowerCase() === "loading" ? "loading" : ""
            } `}
          >
            {item?.name}
          </span>
          {item?.default && (
            <span className="defaultValue">
              {item?.default ? "Default" : ""}
            </span>
          )}
        </>
      )}
    </li>
  ));
});

const AutoCompleteBodyLoader = () => {
  return Array.from({ length: 10 }, (value, index) => (
    <li className="dropdownOptions optionLoader" key={`${index}`}>
      <div className="updatedName linear-background" />
    </li>
  ));
};

const AutoCompleteDropdown = observer(props => {
  const {
    defaultValue = "",
    optionList = [],
    showSearchPanel = true,
    children = <></>,
    loader = false,
    loaderComponent = <></>,
    showDropdown: showDropdownMenu = false,
    setShowDropdown: setShowDropdownMenu = () => {},
    discoverPage = false,
    setSelectedValueCbk = () => {},
    handleGetSearch = () => {},
    setScrollEnd = () => {},
    loaderEnabled = false,
    customTitle = "Select",
    disabled = false,
    ...remainingProps
  } = props || {};

  const [showDropdown, setShowDropdown] = useState(showDropdownMenu);
  const [dropDownValue, setDropdownValue] = useState(defaultValue);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [dropdownBottom, setDropdownBottom] = useState("");
  const dropdownRef = useRef(null);
  const searchBoxRef = useRef(null);

  const searchTimeZone = value => {
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      setUpdatedOptions(optionList);
    } else {
      const filteredData =
        optionList?.length > 0 &&
        optionList?.filter(item => {
          return discoverPage
            ? item?.searchName
                ?.toString()
                ?.toLowerCase()
                ?.includes(lowercasedValue)
            : item?.name?.toString()?.toLowerCase()?.includes(lowercasedValue);
        });
      setUpdatedOptions(filteredData);
    }
  };

  useEffect(() => {
    setShowDropdown(showDropdownMenu);
  }, [showDropdownMenu]);

  useEffect(() => {
    setDropdownValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (discoverPage) {
      optionList && optionList?.length >= 0 && setUpdatedOptions(optionList);
    } else {
      optionList && optionList?.length > 0 && setUpdatedOptions(optionList);
    }
  }, [optionList]);

  const handleScroll = () => {
    const dropdownElement = dropdownRef?.current;
    if (
      dropdownElement?.scrollHeight - dropdownElement?.scrollTop <=
        dropdownElement?.clientHeight + 2 &&
      loaderEnabled
    ) {
      setScrollEnd(true);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      const dropdownElement = dropdownRef?.current;
      dropdownElement.addEventListener("scroll", handleScroll);
      return () => {
        dropdownElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [showDropdown]);

  return (
    <AutoCompleteContext.Provider
      value={{
        searchBoxRef,
        setShowDropdown,
        showDropdown,
        dropDownValue,
        setDropdownValue,
        updatedOptions,
        setUpdatedOptions,
        setShowDropdownMenu,
        dropdownBottom,
        setDropdownBottom,
        ...remainingProps
      }}
    >
      <div
        className={`autoCompleteDropdown ${disabled ? "disableDropdown" : ""} ${
          dropdownBottom === false ? "positionTop" : ""
        }`}
        style={{ width: discoverPage ? "32px" : "100%" }}
      >
        <AutoCompleteHeader
          discoverPage={discoverPage}
          handleGetSearch={handleGetSearch}
          customTitle={customTitle}
        />
        {showDropdown && (
          <div className="dropdownPanel">
            {showSearchPanel &&
              ((discoverPage && optionList && optionList?.length > 0) ||
                !discoverPage) && (
                <SearchInputBox
                  valueCbk={searchTimeZone}
                  type="text"
                  placeholder={discoverPage ? "Search saved filters" : "Search"}
                  searchBoxRef={searchBoxRef}
                  clearSearchInput={clearSearchInput}
                  discoverPage={discoverPage}
                />
              )}
            <ul className="dropdownList" ref={dropdownRef}>
              {loader ? (
                loaderComponent || <AutoCompleteBodyLoader />
              ) : updatedOptions?.length > 0 ? (
                <AutoCompleteBody
                  optionList={optionList}
                  discoverPage={discoverPage}
                  setSelectedValueCbk={setSelectedValueCbk}
                />
              ) : (
                <div
                  className={`${
                    discoverPage ? "noResult displayCenter" : "noResult"
                  }`}
                >
                  No results found
                </div>
              )}
            </ul>
            {children}
          </div>
        )}
      </div>
      {showDropdown && (
        <div
          className="autoCompleteDropdownOverlay"
          onClick={() => {
            setShowDropdown(false);
            setShowDropdownMenu(true);
            setClearSearchInput(true);
          }}
        />
      )}
    </AutoCompleteContext.Provider>
  );
});

export { AutoCompleteDropdown, AutoCompleteContext };
export default AutoCompleteDropdown;
