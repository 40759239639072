import React, { useState } from "react";
import { pullContactState } from "./store";
import { observer } from "mobx-react";

const PasteInput = observer(() => {
  const handlePaste = event => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text/plain");
    pullContactState.setPasteInputValue(pastedText);
  };

  return (
    <input
      type="text"
      value={pullContactState.pasteInputValue}
      onChange={e => pullContactState.setPasteInputValue(e.currentTarget.value)}
      onPaste={handlePaste}
      className={`pasteInputWrapper ${
        pullContactState.extractBtn ? "disableInput" : ""
      }`}
      placeholder="Paste sales navigator Search URL"
    />
  );
});

export default PasteInput;
