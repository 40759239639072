import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";
import PaginationComponent from "Components/common/PaginationComponent";
import { MP_EVENT, IMPORT_CSV_DATE } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import CustomTooltip from "Components/common/CustomTooltip";
import { toasterState } from "Components/common/toaster";
import SequenceUtils from "Utils/SequenceUtils";

const CsvImports = props => {
  const { email: loggedinUserEmail = "" } = userDetail?.userInfo || {};
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [showTooltip, setShowTooltip] = useState({});

  const defaultPayload = {
    limit,
    pageNo
  };

  const statusField = {
    TO_BE_PROCESSED: "Pending",
    INPROGRESS: "In Progress",
    PROCESSED: "Completed",
    FAILED: "Failed"
  };

  const headerList = [
    { name: "File Name", key: "fileName" },
    { name: "Sequence Name", key: "sequenceName" },
    { name: "Status", key: "status" },
    { name: "Total", key: "totalCount" },
    { name: "Success", key: "successCount" },
    { name: "Failed", key: "failedCount" },
    { name: "Created", key: "createdDate" },
    { name: "Owner", key: "ownerName" }
  ];

  const getCsvImportsData = async data => {
    data.date = IMPORT_CSV_DATE;
    try {
      const config = {
        url: URLS.csvImportsList,
        method: "POST",
        data
      };
      const res = await makeApi(config);
      const {
        importList = [],
        currentPage,
        totalPages,
        pageLimit,
        totalImports
      } = res?.data || {};
      if (res?.data) {
        if (totalImports && importList?.length) {
          setData(importList);
          setPageNo(currentPage + 1);
          setPages(totalPages);
          setLimit(pageLimit);
        } else if (res?.response?.status === 401) {
          Utils.showLoggedOutPopup();
        }
      } else {
        Utils.showApiFailureResponse(res);
      }
      setLoader(false);
    } catch (err) {
      console.error("Fetching csv imports error", err);
    }
  };

  const redirectToSequence = (seqId, folderId, sharedType, email) => {
    loggedinUserEmail === email || sharedType
      ? props.history.push(
          `/sequences/${
            sharedType && loggedinUserEmail !== email
              ? "shared"
              : folderId || "all"
          }/${seqId}`
        )
      : toasterState.setToastMsg(
          "Sorry, you cannot access this private sequence",
          "fail"
        );
  };

  const paginate = pageNoObj => {
    if (!loader) {
      setLoader(true);
      const payload = {
        limit,
        pageNo: pageNoObj.selected || 0
      };
      setPageNo(pageNoObj?.selected + 1 || 1);
      getCsvImportsData(payload);
    }
  };

  useEffect(() => {
    setLoader(true);
    Utils.mixpanelTrack(MP_EVENT.CSV_IMPORTS_VIEWED);
    getCsvImportsData(defaultPayload);
    return () => {
      setLoader(true);
      setData([]);
      setLimit(10);
      setPageNo(0);
      setPages(0);
    };
  }, []);

  return (
    <div className="csvImportsSection">
      <div className="tableList">
        <div className="tableHeader">
          {headerList?.map((item, index) => {
            const { name = "", key = "" } = item || {};
            return (
              <div className={`titleRow ${key}`} key={`csvImports${index}`}>
                {name}
              </div>
            );
          })}
        </div>
        {!loader ? (
          <>
            {(data?.length && (
              <div className="tableRecordHeightAlign">
                {data?.map((item, index) => {
                  const {
                    id,
                    csvFileName,
                    sequenceName,
                    result,
                    status,
                    createTs,
                    owner,
                    sequenceShareType,
                    sequenceId,
                    sequenceFolderId
                  } = item || {};
                  let sharedType = sequenceShareType === "SHARED";
                  const { email } = owner || {};
                  const {
                    bouncedCnt = 0,
                    dupCount = 0,
                    emailsFailed = 0,
                    emailsSent = 0,
                    excludedCount = 0,
                    optedOutCnt = 0,
                    donotContact = 0,
                    limitReached = 0,
                    invalidInbox = 0
                  } = result || {};
                  let totalEmailsCount =
                    bouncedCnt +
                    dupCount +
                    emailsSent +
                    emailsFailed +
                    excludedCount +
                    optedOutCnt +
                    donotContact +
                    limitReached +
                    invalidInbox;
                  let inProgress = ["TO_BE_PROCESSED", "INPROGRESS"].includes(
                    status
                  );
                  let totalFailedCount = totalEmailsCount - emailsSent;
                  return (
                    <div
                      className={`tableBody ${id}`}
                      key={`csvTableBody${index}`}
                    >
                      <div
                        className={`fileName titleRow`}
                        title={csvFileName || ""}
                      >
                        {csvFileName || "--"}
                      </div>
                      <div
                        className={`sequenceName titleRow`}
                        title={sequenceName || ""}
                      >
                        {(sequenceName && (
                          <>
                            <span
                              className="name"
                              title={sequenceName}
                              onClick={() =>
                                redirectToSequence(
                                  sequenceId,
                                  sequenceFolderId,
                                  sharedType,
                                  email
                                )
                              }
                            >
                              {sequenceName || "--"}
                            </span>
                            {sharedType && (
                              <span className="shared">shared</span>
                            )}
                            {SequenceUtils.getOwnerName(owner)}
                          </>
                        )) ||
                          "--"}
                      </div>
                      <div className={`status titleRow`}>
                        {statusField[status] || "--"}
                      </div>
                      <div className={`totalCount titleRow`}>
                        {inProgress ? "--" : totalEmailsCount}
                      </div>
                      <div className={`successCount titleRow`}>
                        {inProgress ? "--" : emailsSent}
                      </div>
                      <div className={`failedCount titleRow`}>
                        {inProgress
                          ? "--"
                          : (totalFailedCount > 0 && (
                              <FailedCsvContactDetailedInfo
                                {...result}
                                total={totalFailedCount}
                              />
                            )) ||
                            0}
                      </div>
                      <div className={`createdDate titleRow`}>
                        {Utils.getDateFromString(createTs || "--")}
                      </div>
                      <div className={`ownerName`}>
                        {(owner && (
                          <div
                            className={`contactOwner`}
                            onMouseEnter={() =>
                              setShowTooltip({ key: id, value: true })
                            }
                            onMouseLeave={() =>
                              setShowTooltip({ key: id, value: false })
                            }
                          >
                            <span className="circleOwner">
                              {Utils.getOwnerInitial(owner)}
                            </span>
                            {showTooltip?.key === id && showTooltip?.value && (
                              <CustomTooltip
                                text={`Added by ${Utils.getOwnerName(owner)}`}
                              />
                            )}
                          </div>
                        )) ||
                          "--"}
                      </div>
                    </div>
                  );
                })}
              </div>
            )) || (
              <div className="noDataFound">
                <i className="material-icons-outlined">list_alt</i>
                <span className="text">No data found</span>
              </div>
            )}
          </>
        ) : (
          <div className="adjustHt">
            {Array.from({ length: 8 }, (value, index) => (
              <div
                className="tableBodyLoader"
                key={`tableBodyLoader${index + 1}`}
              >
                {headerList?.map((item, idx) => {
                  const { key = "" } = item || {};
                  return (
                    <div className={`${key}`} key={`${key}${index}${idx}`}>
                      <span className={`colLoader linear-background`} />
                    </div>
                  );
                })}
              </div>
            ))}{" "}
          </div>
        )}
      </div>
      <PaginationComponent
        callback={paginate}
        loading={loader}
        totalPages={pages}
        currentPage={pageNo || 1}
      />
    </div>
  );
};

const FailedCsvContactDetailedInfo = props => {
  const {
    bouncedCnt = 0,
    dupCount = 0,
    emailsFailed = 0,
    excludedCount = 0,
    optedOutCnt = 0,
    total = 0,
    donotContact = 0,
    limitReached = 0,
    invalidInbox = 0
  } = props || {};
  const [showTooltip, setTooltip] = useState(false);
  const [styleCss, setStyleCss] = useState();

  const toggleTooltip = (event, value) => {
    let domPositionY =
      event.nativeEvent.layerY || event.nativeEvent.offsetY || 0;
    let diffHeight = window.innerHeight - event.clientY || event.pageY;
    let styleObject = {};
    if (diffHeight - domPositionY + event.currentTarget.clientHeight >= 144) {
      styleObject.top =
        event.clientY -
        event.nativeEvent.layerY +
        event.currentTarget.clientHeight;
    } else {
      styleObject.bottom = diffHeight + event.nativeEvent.layerY;
    }
    styleObject.right = window.innerWidth - event.clientX;
    setStyleCss(styleObject);
    setTooltip(true);
  };

  const hideTooltip = () => {
    setStyleCss({});
    setTooltip(false);
  };

  return (
    <div className="failedCountDetail">
      <span
        className="count"
        onMouseOver={event => (total > 0 && toggleTooltip(event, true)) || {}}
        onMouseOut={() => (total > 0 && hideTooltip(false)) || {}}
      >
        {total}
      </span>
      {showTooltip && (
        <div className="cardTooltip animatedFast" style={styleCss}>
          <div className="cardTitle">
            Failed to upload {total} contact{total > 1 ? "s" : ""}
          </div>
          <div className="cardBody">
            {(dupCount && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {dupCount} duplicate contact{dupCount > 1 ? "s" : ""}
                </span>
              </div>
            )) ||
              ""}
            {(excludedCount && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {excludedCount} due to sequence safety settings
                </span>
              </div>
            )) ||
              ""}
            {(bouncedCnt && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {bouncedCnt} bounced contact{bouncedCnt > 1 ? "s" : ""}
                </span>
              </div>
            )) ||
              ""}
            {(optedOutCnt && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {optedOutCnt} Opted out contact{optedOutCnt > 1 ? "s" : ""}
                </span>
              </div>
            )) ||
              ""}
            {(emailsFailed && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {emailsFailed} Invalid email address
                </span>
              </div>
            )) ||
              ""}
            {(donotContact && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">{`${donotContact} contact${
                  donotContact > 1 ? "s" : ""
                } stage is 'do not contact'`}</span>
              </div>
            )) ||
              ""}
            {(limitReached && (
              <div className="detailedInfoCount">
                <i className="material-icons-outlined listIcon">
                  radio_button_checked
                </i>
                <span className="text">{`${limitReached} contact${
                  limitReached > 1 ? "s" : ""
                } email limit reached`}</span>
              </div>
            )) ||
              ""}
            {(invalidInbox && (
              <div className="detailedInfoCount">
                <i className="material-icons-outlined listIcon">
                  radio_button_checked
                </i>
                {invalidInbox} Invalid inbox
              </div>
            )) ||
              ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(withRouter(CsvImports));
export { CsvImports };
