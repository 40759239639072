import { toasterState } from "Components/common/toaster";

const CommonUtils = {};

CommonUtils.viewAllJobsDom = (props = {}) => {
  return `
    <span class="showRevealedContacts">
     View all jobs
    </span>
  `;
};

CommonUtils.showToasterMsgWithCustomTimeout = (
  msg = "",
  timeout = 2000,
  statusType = "success",
  closeIcon = false,
  custom = false
) => {
  if (msg) {
    toasterState.setToasterTimeout(timeout);
    toasterState.setToastMsg(msg, statusType);
    toasterState.setPopupValues({
      ...toasterState.popupValues,
      showCloseIcon: closeIcon,
      custom,
      link: `/settings/import/linkedin`
    });
  }
  return false;
};

export { CommonUtils };
