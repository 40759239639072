import React, { useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { URLS, makeApi } from "Utils/apiURL";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { toasterState } from "Components/common/toaster";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { successPopupState } from "Components/common/SuccessPopup";
import CustomTooltip from "Components/common/CustomTooltip";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import ContactUploading from "Pages/Dashboard/contacts/ContactToSequence/ContactUploading";
import FieldValidatorMsgPopup from "Pages/Dashboard/contacts/ContactToSequence/FieldValidatorMsgPopup";
import TokenUtils from "Utils/TokenUtils";
import contactStoreData from "Stores/contactData";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import PaywallUtils from "Utils/PaywallUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";

const TaskButton = props => {
  const {
    taskEnabled = false,
    planName = "",
    inFreePlan = false
  } = userDetail.userFeatureFlag || {};

  const {
    contactIds = [],
    loading = false,
    checked = false,
    allContactSelected = false,
    resetCbk = () => {},
    totalCount = 0,
    stageId = "",
    contactIdsWithStage = [],
    pageType = ""
  } = props;

  const [showDescriptionTp, setShowDescriptionTp] = useState(false);
  const [showContactUploadingLoader, setContactUploadingLoader] =
    useState(false);
  const [showFieldErrorPopup, setShowFielsErrorPopup] = useState(false);
  const [uploadingText, setUploadingText] = useState("");
  const [fieldErrorDetails, setFieldErrorDetails] = useState("");

  const taskBtnAction = async event => {
    Utils.preventDefaultFn(event);
    if (
      taskEnabled &&
      checked &&
      !loading &&
      (await checkTeamSubscriptionActive())
    ) {
      addStagesPopupState.setPopupValues({
        pageSource: "Contacts",
        isTaskCreation: true,
        stageType: "manualEmail",
        btnText: "Next",
        contactIds,
        allContactSelected,
        createCbk: createTaskActionCbk,
        email:
          contactIdsWithStage.length === 1 ? contactIdsWithStage[0].email : ""
      });
      addStagesPopupState.setShowPopup(true);
    }
  };

  const constructBulkTaskPayload = (data = {}) => {
    const {
      dueDateString: date = "",
      content = "",
      subject = "",
      attachments = [],
      note = "",
      timeZone = "",
      type = "",
      linkedInTaskType = ""
    } = data || {};
    const dueDateString = Utils.createScheduleDate(date);
    const customEmailData = {
      content,
      subject,
      attachments
    };
    const { filter = {}, searchTerm = "" } =
      contactStoreData.filterPayload || {};
    const payload = {
      search: {
        filter,
        stageId,
        searchTerm,
        listId: customListState?.activeList?.id
      },
      customEmailData,
      dueDateString,
      timeZone,
      note,
      type: type === "manualEmail" ? "email" : type
    };
    let totalContactIds = [];
    if (contactIdsWithStage?.length > 0) {
      contactIdsWithStage.forEach(element => {
        totalContactIds.push(element?.id);
      });
    }
    !allContactSelected && (payload.contactIds = totalContactIds);
    if (type?.toLowerCase() === "linkedin" && linkedInTaskType) {
      payload.linkedInTaskType = linkedInTaskType;
    }
    return payload;
  };

  const createBulkContactsTask = async data => {
    const config = {
      url: URLS.bulkContactsTask,
      method: "POST",
      data
    };
    return await makeApi(config);
  };

  const createTaskActionCbk = async (taskData = {}, loaderCbk) => {
    if (taskData && Object.keys(taskData)?.length) {
      try {
        startUploadingContact();
        const payload = constructBulkTaskPayload(taskData);
        const res = await createBulkContactsTask(payload);
        const {
          tasksCreated = 0,
          responseCode = 0,
          missingFields = [],
          donotContactCount = 0
        } = res?.data || {};
        setContactUploadingLoader(false);
        if (!missingFields && tasksCreated) {
          loaderCbk();
          Utils.mixpanelTrack(MXP_EVENT.TASK_CREATED, {
            type: "Task button",
            taskType: payload?.type || "",
            pageType
          });
          if (donotContactCount === 0) {
            successPopupState.setPopupValues({
              type: "task",
              text: `Task successfully created for <br />selected contact${
                tasksCreated > 1 ? "s" : ""
              }`
            });
          } else {
            let displayText = `Task successfully created for ${tasksCreated}  contact${
              tasksCreated > 1 ? "s" : ""
            } <br />
              skipped ${donotContactCount} contact${
              donotContactCount > 1 ? "s" : ""
            } as their contact stage is 'do not contact'. `;
            successPopupState.setPopupValues({
              type: "task",
              text: displayText
            });
          }
          successPopupState.setPopup(true);
          resetCbk();
          return;
        } else if (responseCode === 406) {
          loaderCbk();
          toasterState.setToastMsg(
            "The contact does not have email. Email address is required to create manual email task",
            "failure"
          );
          resetCbk();
        } else if (responseCode === 412) {
          if (tasksCreated > 0) {
            loaderCbk();
            let displayText = `Task successfully created for <br /> ${tasksCreated} contact${
              tasksCreated > 1 ? "s" : ""
            } <br/><br/>
            Please add the following mandatory ${
              missingFields?.length > 1 ? "fields" : "field"
            } missing for contact(s)<br/><br/> ${TokenUtils.getMissingTokenList(
              missingFields
            )}`;
            successPopupState.setPopupValues({
              type: "task",
              text: displayText
            });
            successPopupState.setPopup(true);
            resetCbk();
            return;
          } else {
            loaderCbk(true);
            setFieldErrorDetails({
              missingFields,
              uploadedCount: allContactSelected
                ? totalCount
                : payload?.contactIds?.length,
              validCount: tasksCreated
            });
            resetCbk();
            setShowFielsErrorPopup(true);
          }
        } else {
          loaderCbk();
          if (
            (!tasksCreated || tasksCreated === 0) &&
            (!missingFields || missingFields === 0) &&
            donotContactCount > 0
          ) {
            toasterState.setToastMsg(
              `Oops! You cannot create tasks for contact${
                donotContactCount > 1 ? "s" : ""
              } with 'do not contact' stage.`,
              "fail"
            );
          } else {
            toasterState.setToastMsg("Bulk task creation failed", "fail");
          }
        }
      } catch (e) {
        toasterState.setToastMsg("Bulk task creation failed", "fail");
        loaderCbk();
      }
    }
  };

  const startUploadingContact = () => {
    let isPlural = allContactSelected
      ? "s"
      : (contactIds?.length > 1 && "s") || "";
    setUploadingText(`We are processing your contacts. Please wait.`);
    addStagesPopupState.setShowPopup(false);
    addStagesPopupState.setShowStepEmailEditorPopup(false);
    setContactUploadingLoader(true);
  };

  const closeAction = () => {
    addStagesPopupState.setResetValue();
    setShowFielsErrorPopup(false);
  };

  return (
    <>
      {showContactUploadingLoader && (
        <ContactUploading msg={uploadingText} title="Add to task" />
      )}
      {showFieldErrorPopup && (
        <FieldValidatorMsgPopup
          fieldResponse={fieldErrorDetails}
          close={closeAction}
          setSkipLoader={false}
          title="Add to task"
          isCustomTask={true}
        />
      )}
      <div
        className={`addBulkContactsTaskBtn  ${
          contactIds?.length && taskEnabled ? "enabled" : ""
        }`}
        onClick={e => taskBtnAction(e)}
        onMouseEnter={() => taskEnabled && setShowDescriptionTp(true)}
        onMouseLeave={() => taskEnabled && setShowDescriptionTp(false)}
      >
        <TaskIcon />
        {showDescriptionTp && (
          <CustomTooltip
            text={`${
              contactIdsWithStage?.length > 0
                ? "Create Task"
                : "Select one or more contacts to create tasks."
            }`}
            className={`${
              contactIdsWithStage?.length > 0 ? "" : "bulkAddToTask"
            }`}
          />
        )}
        {!taskEnabled && (
          <span className="wrapLockIcon">
            <UpgradeIconTooltip
              plan={PaywallUtils.getPlanName(planName, inFreePlan)}
              type="taskPage"
              enableTooltip={true}
              alignTooltip="topRight"
            />
          </span>
        )}
      </div>
    </>
  );
};

export default observer(TaskButton);
export { TaskButton };
