/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import SwirlArrow from "Utils/LottieFiles/swirlArrow.json";
import { ReactComponent as Playbutton } from "Assets/svg/discover/Playbutton.svg";
import YoutubeTutorial from "Components/common/YoutubeTutorial";
import Discover from "Assets/png/discoverSearch.png";
import Utils from "Utils/utils";
import { dataSearchState } from "../state";
import { MP_EVENT } from "static/constant";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: SwirlArrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const handleStartSearch = () => {
  Utils.mixpanelTrack(MP_EVENT.DIS_TOUR_CLICKED);
  dataSearchState.setFilterComponentData({
    ...dataSearchState.filterComponentData,
    country: ["United States", "USA", "United States of America"],
    employeeCount: ["250 - 1000"],
    excludeTeamOwns: true
  });
  dataSearchState.setSelectedFilteredList({
    ...dataSearchState?.selectedFilteredList,
    location: [
      {
        id: 106349,
        name: "United States",
        group: "country",
        searchText: ["United States", "USA", "United States of America"],
        checked: true
      }
    ]
  });
  dataSearchState.setDiscoverTableLoading(true);
  dataSearchState.searchPageData();
  Utils.startSearchingInDiscoverSection();
};

const NoFilterApplied = () => {
  const [showYtVideo, setShowYtVideo] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");

  const handleWatch = (ytLink = "") => {
    setYoutubeLink(ytLink);
    setShowYtVideo(true);
  };

  const closeVideo = () => {
    setShowYtVideo(false);
  };

  return (
    <div className="noFilters">
      <div className="wrapLottieText">
        <div className="discoverImageContainer">
          <img
            className="recordTableLoader"
            src={Discover}
            alt="Discover emails and phone numbers of 200M+ contacts from 25M+ companies."
          />
        </div>
        {showYtVideo && (
          <YoutubeTutorial videoId={youtubeLink} closeCbk={closeVideo} />
        )}
        <div className="lottieText">
          <div className="mainTextContainer">
            <div className="text">
              Unlock Access to 200M+ Verified Contacts.
            </div>
          </div>
          <div className="subTextContainer">
            <div className="subText">
              Start your search by filtering title, department, company size,
              industry, and more to find the exact prospects you need.
            </div>
          </div>
          <div className="startSearching">
            <div className="searchNowParentContainer">
              <button className="searchNow" onClick={() => handleStartSearch()}>
                Start Guided Search
              </button>
            </div>
          </div>
          <div className="quickDemo" onClick={() => handleWatch("qbBuFaDgIbc")}>
            <div className="watchVideoParentContainer">
              <div className="playButtonParentContainer">
                <Playbutton />
              </div>
              <div className="watchVideo">Watch a Quick 60-Second Demo</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NoFilterApplied };
export default observer(NoFilterApplied);
