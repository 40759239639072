import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import TabNavigation from "Components/common/TabNavigation";
import CsvImports from "./CsvImports";
import LinkedInImports from "./LinkedInImports";
import ImportsRouting from "./ImportsRouting";

const Imports = observer(props => {
  const location = useLocation();

  const IMPORT_MANAGEMENT_TABS = [
    {
      id: "csv",
      name: "CSV",
      path: "csv"
    },
    {
      id: "linkedin",
      name: "LinkedIn",
      path: "linkedin"
    }
  ];

  return (
    <>
      <TabNavigation
        tabList={IMPORT_MANAGEMENT_TABS}
        className="importsNavigation"
      />
      <ImportsRouting {...props} />
    </>
  );
});

export { Imports };
export default Imports;
