/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import DecOffer from "Assets/png/newyear.png";
import OfferEndingTime from "./OfferEndingTime";

const EventRegistrationBanner = observer((props = {}) => {
  const { setEventRegistered = () => {} } = props || {};

  const hideBanner = (value = false) => {
    setEventRegistered(true);
  };

  const registerNow = () => {
    Utils.mixpanelTrack(MP_EVENT.DISCOUNT_BANNER_CLICKED, {
      propertyType:
        "Outbound Strategy for Startup Multiple 3 Million Dollar Deals",
      type: "Web App"
    });
    hideBanner(true);
    window.Intercom("show");
  };

  return (
    userDetail?.userInfo?.userLoggedIn !== "" && (
      <div id="eventRegistrationBanner" className="eventRegistrationBanner">
        <img src={DecOffer} alt="" className="decBanner" />
        <div className="eventTextParent">
          <div className="eventText">
            Year-End Sale: Save <span>&nbsp;40%&nbsp;</span> on all Annual
            Plans!
          </div>
          <div className="secondEventText">
            Find more emails, phone numbers, and buyers faster. Don&apos;t miss
            out - Offer ends in&nbsp;
          </div>
        </div>
        <div className="OfferEndingTime">
          <OfferEndingTime /> &nbsp;
        </div>
        <div className="registerNow" onClick={registerNow}>
          Claim Now!
        </div>
        <span
          className="material-icons closeBanner"
          onClick={() => hideBanner(false)}
        >
          close
        </span>
      </div>
    )
  );
});

export default EventRegistrationBanner;
export { EventRegistrationBanner };
