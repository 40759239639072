import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import "styles/layout/common/_onboardingPopup.scss";
import { ReactComponent as SaveContactSvg } from "Assets/svg/onBoarding/savecontact.svg";
import { ReactComponent as FindProspectSvg } from "Assets/svg/onBoarding/findProspect.svg";
import { ReactComponent as MultiChannelSvg } from "Assets/svg/onBoarding/multiChannel.svg";
import { ReactComponent as WatchVideo } from "Assets/svg/onBoarding/watchVideo.svg";
import { ReactComponent as MailLinkedIn } from "Assets/svg/onBoarding/mailLinkedIn.svg";
import { ReactComponent as Completed } from "Assets/svg/onBoarding/completed.svg";
import { ReactComponent as EngagingProspects } from "Assets/svg/onBoarding/engagingProspects.svg";
import Utils from "Utils/utils";
import { onboarding } from "../Onboarding";
import YoutubeTutorial from "Components/common/YoutubeTutorial";
import { initialWelcomePopupState } from "Components/common/InitialWelcomePopup";
import onboardingStoreData from "Stores/onBoardingData";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import OnBoardStepPopupHeader from "../../onBoarding/OnBoardStepPopupHeader";
import OnBoardingCompleted from "../../onBoarding/OnBoardingCompleted";
import { MP_EVENT } from "static/constant";

const OnBoardingPopUp = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const [openPopup, setPopup] = useState(onboarding.popup);
  const [showYtVideo, setShowYtVideo] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [isFirstSeqFound, setIsFirstSeqFound] = useState(false);
  const [showStepsPopup, setShowStepsPopup] = useState(
    onboardingStoreData.showStepsPopup
  );
  const [triggerOnboardingComplete, setOnboardingComplete] = useState(false);

  const resolvedFirstSeqValue = useMemo(() => {
    if (
      onboarding?.onBoardingFlagValues &&
      onboarding?.onBoardingFlagValues?.firstSequence
    ) {
      setIsFirstSeqFound(true);
      return `/sequences/${onboarding?.onBoardingFlagValues?.firstSequence.name}/${onboarding?.onBoardingFlagValues?.firstSequence.id}`;
    }
    setIsFirstSeqFound(false);
    return null;
  }, [onboarding?.onBoardingFlagValues]);

  const hideBanner = (value = false) => {
    Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_CLOSED);
    onboarding.setPopup(false);
  };

  const isStepCompleted = (stepid = "") => {
    if (stepid) {
      let {
        isEmailAccountConnected = false,
        isOnboardingCompleted = false,
        isContactsAdded = false,
        isSequenceCreated = false,
        firstSequence = null
      } = onboarding?.onBoardingFlagValues;

      isSequenceCreated = firstSequence ? true : false;
      switch (stepid) {
        case "findProspects": {
          return (
            localStorage.getItem("findProspectsClicked") ||
            isOnboardingCompleted
          );
        }
        case "viewContacts": {
          return (
            localStorage.getItem("SaveViewContactsClicked") ||
            isOnboardingCompleted
          );
        }
        case "emailAccount":
          return isEmailAccountConnected;
        case "sequence":
          return isSequenceCreated;
        case "contacts":
          return isContactsAdded;
        default:
          return false;
      }
    }
  };

  const popupContent = [
    {
      heading: "Find the Right Prospects",
      content:
        "Use powerful filters to search for companies and contacts that match your ideal customer profile. Quickly identify leads that convert.",
      icon: <FindProspectSvg className="onboardInnerImageItem" />,
      subContent: "Find",
      link: "7MxOTZiouXs",
      statusFlag: isStepCompleted("findProspects"),
      productRedirectLink: "/discover"
    },
    {
      heading: "View and Save Contact Information",
      content:
        "Select the most relevant contacts from your search results. Click View to see details or Export to download their information for your campaigns.",
      icon: <SaveContactSvg className="onboardInnerImageItem" />,
      subContent: "View",
      link: "EXHQTZ_dYQ0",
      statusFlag: isStepCompleted("viewContacts"),
      productRedirectLink: "/contacts"
    },
    {
      heading: "Set Up Email & LinkedIn for Outreach",
      content:
        "Make your outreach seamless by connecting your email and LinkedIn accounts. This step ensures you can send messages and track responses directly from Salesgear.",
      icon: <MailLinkedIn className="onboardInnerImageItem" />,
      subContent: "Connect",
      link: "CUsBHQzK5AY",
      statusFlag: isStepCompleted("emailAccount"),
      productRedirectLink: "/settings/account/email"
    },
    {
      heading: "Create a Multi-Channel Campaign",
      content:
        "Combine the power of email, LinkedIn, and other channels to build campaigns that get noticed. Personalize your sequences to reach the right prospects at the right time.",
      icon: <MultiChannelSvg className="onboardInnerImageItem" />,
      subContent: "Create",
      link: "_MfkCnez5c8",
      statusFlag: isStepCompleted("sequence"),
      productRedirectLink: "/sequences/all"
    },
    {
      heading: "Start Engaging Your Prospects",
      content:
        "Enroll your saved contacts into your campaign. Sit back and let your outreach run across channels, driving more conversations and conversions.",
      icon: <EngagingProspects className="onboardInnerImageItem" />,
      subContent: "Activate",
      link: "yh2gqgDjCZU",
      statusFlag: isStepCompleted("contacts"),
      productRedirectLink: resolvedFirstSeqValue
    }
  ];

  const handleWatch = (ytLink = "") => {
    setYoutubeLink(ytLink);
    setShowYtVideo(true);
    switch (ytLink.toLowerCase()) {
      case "7mxotziouxs": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP1_VIDEO);
        break;
      }
      case "exhqtz_dyq0": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP2_VIDEO);
        break;
      }
      case "cusbhqzk5ay": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP3_VIDEO);
        break;
      }
      case "_mfkcnez5c8": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP4_VIDEO);
        break;
      }
      case "yh2gqgdjczu": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP5_VIDEO);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleOnBoardActionValue = (value = "") => {
    handleOnBoardAction(
      value === JSON.stringify(resolvedFirstSeqValue)
        ? resolvedFirstSeqValue
        : value
    );
  };

  const handleOnBoardAction = (value = "") => {
    if (
      !localStorage.getItem("findProspectsClicked") &&
      !onboarding?.onBoardingFlagValues?.isOnboardingCompleted
    ) {
      if (value.toLowerCase() === "/discover") {
        localStorage.setItem("findProspectsClicked", true);
        onboarding.setRemainingFeatureCount(
          (onboarding.remainingFeatureCount -= 1)
        );
        onboarding.setPopup(false);
      }
    }
    if (
      !localStorage.getItem("SaveViewContactsClicked") &&
      !onboarding?.onBoardingFlagValues?.isOnboardingCompleted
    ) {
      if (value.toLowerCase() === "/contacts") {
        localStorage.setItem("SaveViewContactsClicked", true);
        onboarding.setRemainingFeatureCount(
          (onboarding.remainingFeatureCount -= 1)
        );
        onboarding.setPopup(false);
      }
    }

    switch (value.toLowerCase()) {
      case "/discover": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP1_GO);
        break;
      }
      case "/contacts": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP2_GO);
        break;
      }
      case "/settings/account/email": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP3_GO);
        break;
      }
      case "/sequences/all": {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP4_GO);
        break;
      }
      default: {
        Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_STEP5_GO);
        break;
      }
    }

    const targetValue = value.startsWith("/") ? value : `/${value}`;
    if (location.pathname !== targetValue && targetValue) {
      history.push(targetValue);
      onboarding.setPopup(false);
    }
  };

  const closeVideo = () => {
    setShowYtVideo(false);
  };

  const toggleStepsPopup = e => {
    Utils.preventDefaultFn(e);
    initialWelcomePopupState.setRestrictOnboardingPopup(false);
    Utils.showOnboardingStepsPopup(!showStepsPopup);
  };

  const checkAndTriggerOnboardingComplete = () => {
    if (
      onboarding?.remainingFeatureCount === 0 &&
      !onboarding?.onBoardingFlagValues?.isOnboardingCompleted
    ) {
      setTimeout(() => {
        setOnboardingComplete(true);
      }, 3000);
    }
  };

  const setOnboardingCompletedInStore = () => {
    if (onboarding?.onBoardingFlagValues) {
      const tempFlags = JSON.parse(
        JSON.stringify(onboarding?.onBoardingFlagValues)
      );
      tempFlags.isOnboardingCompleted = true;
      onboarding.setOnBoardingFlagValues(tempFlags);
    }
  };

  const closeOnboardingCompletePopupCbk = async () => {
    setOnboardingComplete(false);
    setOnboardingCompletedInStore();
    Utils.getOnboardingData();
  };
  const handleIntercom = () => {
    window.Intercom("show");
  };
  useEffect(() => {
    setPopup(onboarding?.popup);
  }, [onboarding?.popup]);

  useEffect(() => {
    onboarding?.popup && Utils.mixpanelTrack(MP_EVENT.ONB_SLIDER_OPENED);
  }, [onboarding?.popup]);

  useEffect(() => {
    checkAndTriggerOnboardingComplete();
  }, [onboarding?.remainingFeatureCount, onboarding?.onBoardingFlagValues]);

  return (
    <div className="onboardParentContainer">
      {triggerOnboardingComplete && (
        <OnBoardingCompleted
          closeOnboardingCompletePopupCbk={closeOnboardingCompletePopupCbk}
        />
      )}
      {openPopup && (
        <div className="onboardMainContainer">
          <div className="onboardCard">
            <div className="cardHeading">
              <p className="onboardContent">Quick Start Guide</p>
              <span
                className="material-icons closeBanner"
                onClick={() => hideBanner(false)}
              >
                close
              </span>
            </div>
            <div className="cardMessage">
              <p className="onboardContent">
                {`We're glad you're here. Let's get you up and running in just five easy steps.`}
              </p>
              <div className="watchCreditsContainer">
                <OnBoardStepPopupHeader
                  remainingCount={onboarding?.remainingFeatureCount}
                  onBoardingFlagValues={onboarding?.onBoardingFlagValues}
                  toggleStepsPopup={toggleStepsPopup}
                />
              </div>
              {showYtVideo && (
                <YoutubeTutorial videoId={youtubeLink} closeCbk={closeVideo} />
              )}
            </div>
            <div className="onboardContainer">
              {popupContent?.map((onboardItem, index) => {
                return (
                  <div
                    key={index}
                    className={`onboardItem ${
                      onboardItem?.statusFlag ? "trueFlag" : ""
                    }`}
                  >
                    <div className="onboardInnerImageParentContainer">
                      <span
                        className={`onboardInnerImageContainer ${
                          onboardItem?.statusFlag ? "trueFlag" : ""
                        }`}
                      >
                        {onboardItem?.statusFlag ? (
                          <Completed className="isCompleted" />
                        ) : (
                          ""
                        )}
                        {onboardItem?.icon}
                      </span>
                    </div>
                    <div className="onboardInnerContentContainer">
                      <h3 className="cardHeadingContent">
                        {onboardItem?.heading}
                      </h3>
                      <p className="cardContent">{onboardItem?.content}</p>
                      <div className="onBoardButtons">
                        <div className="showMe">
                          <button
                            className={`subContent ${
                              !isFirstSeqFound &&
                              onboardItem?.subContent === "Activate"
                                ? "seqNotFound"
                                : ""
                            }`}
                            onClick={() =>
                              handleOnBoardActionValue(
                                onboardItem?.productRedirectLink
                              )
                            }
                            disabled={
                              !isFirstSeqFound &&
                              onboardItem?.subContent === "Activate"
                            }
                          >
                            {onboardItem?.subContent}
                          </button>
                        </div>
                        <span
                          className="watchVideo"
                          onClick={() => handleWatch(onboardItem?.link)}
                        >
                          <WatchVideo className="youtubeVideo" />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="onBoardChatButton"> */}
              {/* <div className="showChatButton">
                <div className="onBoardChatWithUs">
                  <button
                    className="buttonContent"
                    onClick={() => handleIntercom()}
                  >
                    Stuck on a step? Chat with us
                  </button>
                </div>
              </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
export default OnBoardingPopUp;
export { OnBoardingPopUp };
