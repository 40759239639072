import { observer } from "mobx-react";
import React, { useEffect, useState, useReducer } from "react";
import Button from "Components/common/Button";
import NumberInputBox from "Components/common/NumberInputBox";
import { linkedInReplyState } from "./state";
import LinkedInProfileCard from "./common/LinkedInProfileCard";
import FilterDropdown from "../../Triggers/AddEditTrigger/FilterDropdown";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import CustomNumberInput from "./common/CustomNumberInput";

const initialState = {
  maxInvitePerDay: 0,
  maxMessagesPerDay: 0,
  liTaskDueDays: 0,
  liStepFailureAction: "SKIP_TO_NEXT_STEP",
  liTaskDueAction: "SKIP_TO_NEXT_STEP",
  mailFailureAction: "SEND_AS_MESSAGE"
};

const formReducer = (state = {}, action = {}) => {
  const { type = "", payload = {}, value = "", field = "" } = action || {};
  switch (type) {
    case "SET_ENTIRE_STATE":
      return payload;
    case "CHANGE_FIELD":
      return {
        ...state,
        [field]: value
      };
    case "TOGGLE_FIELD":
      return {
        ...state,
        [field]: !state[field]
      };
    default:
      return state;
  }
};

const ConnectedLinkedInConfigure = observer((props = {}) => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [errors, setErrors] = useState({});
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const isPremiumAccount =
    !(
      linkedInReplyState?.configureData?.linkedinAccountType?.toLowerCase() ===
      "classic"
    ) || false;

  const handleChange = (value = "", field = "") => {
    dispatch({ type: "CHANGE_FIELD", field, value });
  };

  const handleDropdownChange = (key, value) => {
    dispatch({ type: "CHANGE_FIELD", field: value?.field, value: value?.key });
    setActiveDropdownIndex("");
  };

  const checkError = () => {
    const newErrors = {};
    let hasError = false;
    let maxCount = isPremiumAccount ? 100 : 50;
    Object.entries(initialFormFieldsConfig).forEach(([field, config]) => {
      if (config?.required) {
        if (state[field] === "") {
          newErrors[field] = "Field is empty";
          hasError = true;
        } else if (
          field === "maxInvitePerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum connection requests per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxMessagesPerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[field] = isPremiumAccount
            ? `Maximum messages/InMails per day should be less than or equal to ${maxCount}`
            : `Maximum messages per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxProfileViewsPerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum profile views per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxSearchPerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum api limit for search per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "liTaskDueDays" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum task due days should be less than or equal to ${maxCount}`;
          hasError = true;
        }
      }
    });
    setErrors(newErrors);
    if (hasError) {
      return true;
    }
    return false;
  };

  const saveSettings = async () => {
    let totalCount = 100;
    if (isPremiumAccount) {
      totalCount = 150;
    }
    if (total > totalCount) {
      toasterState.setToastMsg(
        `The total activities per day should be less than or equal to ${totalCount}.`,
        "failure"
      );
      return;
    }
    setSaveLoader(true);
    const config = {
      url: URLS?.saveSettingsInLinkedIn,
      method: "POST",
      data: {
        accountId: linkedInReplyState?.configureData?.id,
        settings: state
      }
    };
    let response = await makeApi(config);
    const { responseCode = "" } = response?.data || {};

    if (+responseCode === 200) {
      const { settings: linkedInSettings = {} } = response?.data?.data || {};

      if (linkedInSettings) {
        dispatch({
          type: "SET_ENTIRE_STATE",
          payload: linkedInSettings
        });
        toasterState.setToastMsg(
          "LinkedIn settings saved successfully.",
          "success"
        );
      }
      linkedInReplyState.getLinkedInAccounts();
    } else if (+responseCode === 401) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else if (+responseCode === 403) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else if (+responseCode === 406) {
    } else if (+responseCode === 500) {
    } else if (total > 100) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong, please try again later",
        "failure"
      );
    }
    setSaveLoader(false);
  };

  const handleSave = () => {
    if (!checkError()) {
      saveSettings();
    }
  };

  let maxCount = isPremiumAccount ? 100 : 50;

  const initialFormFieldsConfig = {
    maxInvitePerDay: {
      label: "Number of connection requests per day",
      subLabel: `(Recommended : ${
        isPremiumAccount ? 40 : 20
      }, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    maxMessagesPerDay: {
      label: isPremiumAccount
        ? "Number of messages/InMails per day"
        : "Number of messages per day",
      subLabel: `(Recommended : ${
        isPremiumAccount ? 40 : 30
      }, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    maxProfileViewsPerDay: {
      label: "Number of profile views per day ",
      subLabel: `(Recommended : 20, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    ...(isPremiumAccount && {
      maxSearchPerDay: {
        label: "Number of search API calls per day",
        subLabel: `(Recommended : ${
          isPremiumAccount ? 40 : 30
        }, Max : ${maxCount})`,
        type: "number",
        required: true,
        min: 1,
        max: maxCount,
        displayFlag: true,
        hasTooltip: true
      }
    }),
    liStepFailureAction: {
      label: "Whenever an automated LinkedIn step fails in a sequence",
      type: "dropdown",
      options: [
        {
          value: "Skip the contact to the next step",
          key: "SKIP_TO_NEXT_STEP",
          method: handleDropdownChange,
          field: "liStepFailureAction"
        },
        {
          value: "Mark the contact as failed",
          key: "MARK_AS_FAILED",
          method: handleDropdownChange,
          field: "liStepFailureAction"
        }
      ],
      displayFlag: true
    },
    ...(isPremiumAccount && {
      mailFailureAction: {
        label: "When sending InMail fails (due to limits or other reasons)",
        type: "dropdown",
        options: [
          {
            value: "Send it as a message",
            key: "SEND_AS_MESSAGE",
            method: handleDropdownChange,
            field: "mailFailureAction"
          },
          {
            value: "Skip the contact to the next step",
            key: "SKIP_TO_NEXT_STEP",
            method: handleDropdownChange,
            field: "mailFailureAction"
          },
          {
            value: "Mark the contact as failed",
            key: "MARK_AS_FAILED",
            method: handleDropdownChange,
            field: "mailFailureAction"
          }
        ],
        displayFlag: true
      }
    })
    // liTaskDueAction: {
    //   label1: "If automatic LinkedIn task is due in",
    //   label2: "Days",
    //   type: "dropdownWithNumberInput",
    //   className: "dropdownWithNumberInput",
    //   fieldName: "liTaskDueDays",
    //   hasLabel: false,
    //   options: [
    //     {
    //       value: "Convert automatic task to manual task",
    //       key: "AUTOMATIC_TO_MANUAL",
    //       method: handleDropdownChange,
    //       field: "liTaskDueAction"
    //     },
    //     {
    //       value: "Skip task",
    //       key: "SKIP_TO_NEXT_STEP",
    //       method: handleDropdownChange,
    //       field: "liTaskDueAction"
    //     }
    //   ]
    // }
  };

  const mappingObj = {
    liTaskDueAction: {
      SKIP_TO_NEXT_STEP: "Skip task and proceed to next",
      AUTOMATIC_TO_MANUAL: "Convert automatic tasks to manual"
    },
    liStepFailureAction: {
      SKIP_TO_NEXT_STEP: "Skip the contact to the next step",
      MARK_AS_FAILED: "Mark the contact as failed"
    },
    mailFailureAction: {
      SEND_AS_MESSAGE: "Send it as a message",
      SKIP_TO_NEXT_STEP: "Skip the contact to the next step",
      MARK_AS_FAILED: "Mark the contact as failed"
    }
  };

  useEffect(() => {
    let newTotal = 0;
    Object.entries(initialFormFieldsConfig).forEach(([field, value]) => {
      if (value.type === "number" && !isNaN(state[field])) {
        newTotal += parseInt(state[field], 10);
      }
    });
    setTotal(newTotal);
  }, [state, initialFormFieldsConfig]);

  useEffect(() => {
    const settingsData = linkedInReplyState?.configureData?.settings;
    dispatch({
      type: "SET_ENTIRE_STATE",
      payload: { ...state, ...settingsData }
    });
  }, [linkedInReplyState?.configureData]);

  useEffect(() => {
    return () => {
      linkedInReplyState.setShowConfigure(false);
    };
  }, []);

  return (
    <div className="connectedLinkedInConfigure">
      <Header />
      <div className="settingsWrapper">
        <LinkedInProfileCard
          {...linkedInReplyState?.configureData}
          showTitle={true}
          hasLinkedInIcon={true}
        />
        <div className="linkedInSettings">
          <div className="warmingSettingsCont">
            <div className="warmingSettingsHeader">
              LinkedIn Account Settings
            </div>
            <div className={`warmingSettingsBody`}>
              {Object.entries(initialFormFieldsConfig).map(
                (
                  [
                    field,
                    {
                      label,
                      label1,
                      label2,
                      type,
                      subLabel,
                      min,
                      max,
                      options,
                      fieldName,
                      hasLabel,
                      className,
                      hasTooltip
                    }
                  ],
                  index
                ) => {
                  return (
                    <>
                      {type === "number" && (
                        <>
                          <NumberInputBox
                            type={type}
                            changeCbk={value => handleChange(value, field)}
                            min={min}
                            max={max}
                            value={state[field]}
                            errMsg={errors[field]}
                            loader={false}
                            readOnly={false}
                            label={label}
                            subLabel={subLabel}
                            hasTooltip={hasTooltip}
                          />
                        </>
                      )}

                      {type === "dropdown" && (
                        <div className="dropdownWithLabelWrapper">
                          <FilterDropdown
                            label={label}
                            options={options}
                            showDropdown={activeDropdownIndex === `${index}`}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            index={`${index}`}
                            defaultValue={
                              mappingObj?.[field]?.[state?.[field]] ||
                              options?.[0]?.value
                            }
                          />
                        </div>
                      )}
                      {type === "dropdownWithNumberInput" && (
                        <div className="dropdownWithLabelWrapper dropdownWithNumberInputWrapper">
                          <CustomNumberInput
                            type={"number"}
                            changeCbk={value => handleChange(value, fieldName)}
                            min={min}
                            max={max}
                            value={state[fieldName] || 7}
                            errMsg={errors[fieldName]}
                            loader={false}
                            readOnly={false}
                            label1={label1}
                            label2={label2}
                            className={className}
                          />
                          <FilterDropdown
                            options={options}
                            showDropdown={activeDropdownIndex === `${index}`}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            index={`${index}`}
                            defaultValue={
                              mappingObj?.[field]?.[state?.[field]] ||
                              options?.[0]?.value
                            }
                            hasLabel={hasLabel}
                          />
                        </div>
                      )}
                    </>
                  );
                }
              )}
            </div>
            <ButtonComponent handleSave={handleSave} saveLoader={saveLoader} />
          </div>
        </div>
      </div>
    </div>
  );
});

const Header = observer(() => {
  return (
    <div className="sectionHeader">
      <div className="backBtnAndHeader">
        <span
          className="navigateToInbox"
          onClick={() => {
            linkedInReplyState.setShowConfigure(false);
          }}
        >
          <i className="material-icons backBtn">keyboard_backspace</i>
        </span>
        <h6>Connected Accounts</h6>
      </div>
    </div>
  );
});

const ButtonComponent = (props = {}) => {
  const { saveLoader = false, handleSave = () => {} } = props || {};

  const buttonList = [
    {
      id: "back",
      name: "back",
      btnText: "Back",
      type: "button",
      className: "cancelButton",
      click: () => {
        linkedInReplyState.getLinkedInAccounts();
        linkedInReplyState.setShowConfigure(false);
      }
    },
    {
      id: "save",
      name: "save",
      btnText: "Save",
      type: "button",
      className: `saveButton ${saveLoader ? "loading" : ""}`,
      click: handleSave,
      loader: saveLoader
    }
  ];

  return (
    <div className="buttonActionList">
      {buttonList.map(item => (
        <Button {...item} key={`${item?.id}Btn`} />
      ))}
    </div>
  );
};

export { ConnectedLinkedInConfigure };
export default ConnectedLinkedInConfigure;
