/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import PricingStore from "Stores/Pricing";
import ToggleBtnType from "Components/common/ToggleBtnType";
import PricingTableLoader from "./PricingTableLoader";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import {
  WEBSITE_URL,
  PopularPlanName,
  ENTERPRISE_FEATURE,
  FREQ_ASK_QUESTIONS,
  SUPPORT_MULTIPLE_INBOX_URL,
  SUPPORT_DIALER_CALL_CREDITS
} from "Static/constant";
import CollapsiveContent from "Components/common/CollapsiveContent";
import FreqAskQuesImg from "Assets/Pricing/freqAskQues.png";
import GmailImg from "Assets/Pricing/gmail.png";
import HubspotImg from "Assets/Pricing/hubspot.png";
import PipeDriveImg from "Assets/Pricing/pipedrive.png";
import SalesforceImg from "Assets/Pricing/salesforce.png";
import ZohoImg from "Assets/Pricing/zoho.png";
import LeftCrmIconsImg from "Assets/Pricing/leftCrmIcons.png";
import RightCrmIconsImg from "Assets/Pricing/rightCrmIcons.png";
import UpgradeBadge from "Components/Paywall/UpgradeBadge";
import QuestionOutlineImg from "Assets/Pricing/questionOutline.png";
import EmailOutlineImg from "Assets/Pricing/emailOutline.png";
import { ReactComponent as FeatureListIcon } from "Assets/svg/featrureListIcon.svg";
import CustomTooltip from "Components/common/CustomTooltip";
import Utils from "Utils/utils";
import PricingPageTrialBanner from "./PricingPageTrialBanner";

const PricingPlanTag = props => {
  const { displayName = "" } = props || {};
  const tempObj = {
    Free: <UpgradeBadge plan={"freePlan"} />,
    Basic: <UpgradeBadge plan={"basicPlan"} />,
    Pro: <UpgradeBadge plan={"proPlan"} />,
    Unlimited: <UpgradeBadge plan={"unlimitedPlan"} />,
    Custom: <UpgradeBadge plan={"customPlan"} />
  };
  return tempObj[displayName];
};

const PricingFeatureTable = props => {
  const { isLogoutPricing = false } = props || {};
  const {
    subscriptionPlanId,
    isAnnual,
    planName,
    trialEnabled,
    teamStatus,
    trialEndDate,
    inFreePlan = false,
    calendarIntegrationEnabled = false
  } = userDetail?.userFeatureFlag || {};
  const {
    team,
    userLoggedIn,
    calendarEnabled = false
  } = userDetail.userInfo || {};

  const enterpriseItemList = ENTERPRISE_FEATURE;
  const breadcrumbArr = [{ name: "Pricing", link: "/pricing" }];
  const [toogleType, setToogleType] = useState(
    PricingStore.paymentFormInfo.annual
  );
  const [subscriptionId, setSubscriptionId] = useState(subscriptionPlanId);
  const [tableLoading, setTableLoading] = useState(true);
  const [annualDiscount, setAnnualDiscount] = useState("");
  const [planDetails, setPlanDetails] = useState(PricingStore.activePlanData);
  const [showUnlimitedCreditsTp, setUnlimitedCreditsTp] = useState(false);
  const [showUnlimitedCreditsTpCustom, setUnlimitedCreditsTpCustom] =
    useState(false);
  const [showDialerTp, setDialerTp] = useState(false);
  const [multipleInboxTp, setMultipleInboxTp] = useState(false);
  const [isTrial, setIsTrial] = useState(false);

  const CrmIconList = [
    { id: "gmailCrm", img: GmailImg },
    { id: "sfCrm", img: SalesforceImg },
    { id: "hsCrm", img: HubspotImg },
    { id: "pdCrm", img: PipeDriveImg },
    { id: "zohoCrm", img: ZohoImg }
  ];

  const constructActivePlanDetails = planData => {
    if (planData && planData.length) {
      planData.map(value => {
        if (value?.name && isPopularPlan(value.name)) {
          value.isSelectedPlan = true;
          setAnnualDiscount(20);
        } else {
          value.isSelectedPlan = false;
        }
      });
      setPlanDetails(planData);
      setTableLoading(false);
    }
  };

  const upgradeBtn = (plan = {}, trial = "") => {
    let planDisplayName = plan?.displayName?.toLowerCase();
    if (userLoggedIn) {
      if (!team) {
        confirmationPopupState.setPopupValues({
          title: "Pricing Purchase Request?",
          content: `Please contact your account administrator to upgrade your account`,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => {
            confirmationPopupState.setShowPopup(false);
          }
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        if (
          !trialEnabled &&
          ["basic", "pro"].includes(planName?.toLowerCase()) &&
          ["unlimited", "pro", "basic"].includes(planDisplayName)
        ) {
          confirmationPopupState.setPopupValues({
            content: `Please reach out to <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer" >support@salesgear.io</a> to upgrade to ${plan?.displayName} plan`,
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });
          confirmationPopupState.setShowPopup(true);
        } else if (planDisplayName !== "custom") {
          PricingStore.setPaymentFormInfo(plan);
          localStorage.setItem("proceedToPay", true);
          trial && localStorage.setItem("trialPayment", true);
          props.history.push("pricing/payment");
        }
      }
    } else {
      if (planDisplayName !== "custom") {
        props.history.push("/signup");
      }
    }
  };

  const getTogglePlanType = value => {
    PricingStore.setPaymentFormInfo({ annual: value });
    setToogleType(value);
    setAnnualDiscount(20);
  };

  const isPopularPlan = planName => {
    if (
      planName &&
      PopularPlanName &&
      planName?.toLowerCase() === PopularPlanName?.toLowerCase()
    ) {
      return true;
    }
  };

  const showCurrentPlanDom = plan => {
    const { monthlyPlanId, annualPlanId } = plan;
    if (subscriptionId !== null) {
      if (subscriptionId === monthlyPlanId || subscriptionId === annualPlanId) {
        return true;
      }
    }
    return false;
  };

  const getSeatPrice = plan => {
    const { price = 0, annualPrice = 0 } = plan || {};
    if (subscriptionId !== null) {
      if (isAnnual || (toogleType && trialEnabled)) {
        return annualPrice || 0;
      } else {
        return price || 0;
      }
    } else {
      if (toogleType) {
        return annualPrice || 0;
      } else {
        return price || 0;
      }
    }
  };

  const getEnterpriseImg = id => {
    // if (id === "phone") {
    //   return PhoneOutlineImg;
    // } else
    if (id === "email") {
      return EmailOutlineImg;
    } else if (id === "questions") {
      return QuestionOutlineImg;
    }
  };

  const triggerFeatureEvent = id => {
    // if (id === "phone") {
    //   window.location.href = "tel:+1(415)8532917";
    // } else
    if (id === "email") {
      window.location.href = "mailto:sales@salesgear.io";
    } else if (id === "questions") {
      window.location.hash = "customPricing";
    }
  };

  const renderFreePlan = () => {
    const freeList = [
      "1 Email Inbox",
      "3 Sequences",
      "Unsubscribe",
      "Send up to 1,000 emails/month",
      "3 templates",
      "Sequence Reporting",
      "Gmail chrome extension",
      "Unlimited email tracking"
    ];

    return (
      <>
        {freeList.map((item, index) => {
          return (
            <li className="planItem" key={`proList${index}`}>
              <FeatureListIcon />
              <div className="itemName">{item}</div>
            </li>
          );
        })}
      </>
    );
  };

  const renderBasicPlan = () => {
    const basicList = [
      "Unlimited email inboxes",
      "Unlimited email warming",
      "Send up to 6,000 emails/month",
      "10 Sequences",
      "10 Templates",
      "Teams",
      "Gmail chrome extension",
      "Branded URL",
      "Unsubscribe",
      "Email validation",
      "CRM integrations",
      "Reports"
    ];
    return (
      <>
        {basicList.map((item, index) => {
          return (
            <li className="planItem" key={`proList${index}`}>
              <FeatureListIcon />
              <div className="itemName">{item}</div>
            </li>
          );
        })}
      </>
    );
  };

  const renderProPlan = () => {
    const proList = [
      "Send up to 25,000 emails/month",
      "Unlimited Sequences and Templates",
      "Dialer",
      "Calendar",
      "AI writing assistant",
      "A/B testing",
      "Export contacts",
      "10 Custom fields",
      "Slack integration",
      "Zapier",
      "Website tracking",
      "Triggers",
      "Manual Tasks"
      // "Domain health center"
    ];

    const dialerDom = () => {
      return (
        <span
          className="infoIconTp"
          onMouseOver={() => setDialerTp(true)}
          onMouseLeave={() => setDialerTp(false)}
        >
          <i className="material-icons-outlined">info</i>
          {showDialerTp && (
            <CustomTooltip
              text="Call charges applicable based on the usage."
              redirectText="Click here"
              redirectLink={SUPPORT_DIALER_CALL_CREDITS}
              hasRedirectLink={true}
              isNewTabUrl={true}
            />
          )}
        </span>
      );
    };

    const multipleInboxDom = () => {
      return (
        <span
          className="infoIconTp"
          onMouseOver={() => setMultipleInboxTp(true)}
          onMouseLeave={() => setMultipleInboxTp(false)}
        >
          <i className="material-icons-outlined">info</i>
          {multipleInboxTp && (
            <CustomTooltip
              text="Additional charges apply for additional inboxes."
              redirectText="Learn more."
              redirectLink={SUPPORT_MULTIPLE_INBOX_URL}
              hasRedirectLink={true}
              isNewTabUrl={true}
            />
          )}
        </span>
      );
    };

    const addTooltip = {
      dialer: dialerDom(),
      multipleinboxes: multipleInboxDom()
    };

    return (
      <>
        {proList.map((item, index) => {
          return (
            <li className="planItem" key={`proList${index}`}>
              <FeatureListIcon />
              <div className="itemName">
                {item}{" "}
                {addTooltip?.[
                  item?.trim()?.split(" ")?.join("")?.toLowerCase()
                ] || ""}
              </div>
            </li>
          );
        })}
      </>
    );
  };

  const renderUnlimitedPlan = () => {
    const unlimitedList = [
      "Send up to 100,000 emails/month",
      "30 Custom fields",
      "LinkedIn Automation"
    ];
    return (
      <>
        {unlimitedList.map((item, index) => {
          return (
            <li className="planItem" key={`unlimitedList${index}`}>
              <FeatureListIcon />
              <div className="itemName">{item}</div>
            </li>
          );
        })}
      </>
    );
  };

  const renderCustomPlan = () => {
    const customList = [
      "Discover - bulk purchase",
      "Unlimited emails/month",
      "Import from LinkedIn",
      "Priority support"
    ];
    return (
      <>
        {customList.map((item, index) => {
          return (
            <li className="planItem" key={`customList${index}`}>
              <FeatureListIcon />
              <div className="itemName">{item}</div>
            </li>
          );
        })}
      </>
    );
  };

  const getPlanType = () => {
    if (subscriptionId !== null) {
      return isAnnual || (toogleType && trialEnabled) ? "annual" : "month";
    } else {
      return toogleType ? "annual" : "month";
    }
  };

  const creditsInfoLayout = emailInfo => {
    const {
      emailCreditText = "",
      tooltipContent = <></>,
      className = ""
    } = emailInfo || {};
    return (
      <div className={`planItem ${className}`}>
        <FeatureListIcon />
        <span className="text">
          {emailCreditText}
          <>{tooltipContent}</>
        </span>
      </div>
    );
  };

  const showCreditsInfo = planObj => {
    const {
      displayName = "",
      phoneCreditsDailyLimit,
      emailCreditsDailyLimit,
      emailCreditsMonthlyLimit
    } = planObj || {};
    return displayName === "Unlimited" ? (
      <>
        {creditsInfoLayout({
          emailCreditText: `Unlimited email credits`,
          className: "unlimitedCredits",
          tooltipContent: (
            <span
              className="infoIconTp"
              onMouseOver={() => setUnlimitedCreditsTpCustom(true)}
              onMouseLeave={() => setUnlimitedCreditsTpCustom(false)}
            >
              <i className="material-icons-outlined">info</i>
              {showUnlimitedCreditsTpCustom && (
                <CustomTooltip
                  text={`Subject to <a href='${WEBSITE_URL}/terms-of-service' target='_blank' rel='noopener noreferrer'>Salesgear's fair usage policy</a> to prevent abuse.`}
                />
              )}
            </span>
          )
        })}
        {phoneCreditsDailyLimit &&
          creditsInfoLayout({
            emailCreditText: `${
              getPlanType() === "annual" ? "Yearly" : "Monthly"
            } phone numbers: ${
              getPlanType() === "annual"
                ? phoneCreditsDailyLimit * 12
                : phoneCreditsDailyLimit
            }`
          })}
        {creditsInfoLayout({ emailCreditText: "No daily email credit limit" })}
      </>
    ) : displayName === "Custom" ? (
      <>
        {creditsInfoLayout({
          emailCreditText: `Unlimited email credits`,
          className: "unlimitedCredits",
          tooltipContent: (
            <span
              className="infoIconTp"
              onMouseOver={() => setUnlimitedCreditsTp(true)}
              onMouseLeave={() => setUnlimitedCreditsTp(false)}
            >
              <i className="material-icons-outlined">info</i>
              {showUnlimitedCreditsTp && (
                <CustomTooltip
                  text={`Subject to <a href='${WEBSITE_URL}/terms-of-service' target='_blank' rel='noopener noreferrer'>Salesgear's fair usage policy</a> to prevent abuse.`}
                />
              )}
            </span>
          )
        })}
        {creditsInfoLayout({
          emailCreditText: "Monthly phone numbers : Custom"
        })}
        {creditsInfoLayout({ emailCreditText: "No daily email credit limit" })}
      </>
    ) : (
      <>
        {emailCreditsMonthlyLimit > 0 &&
          creditsInfoLayout({
            emailCreditText: `Monthly email credits: ${Utils.formatNumberToStringInUSFormat(
              emailCreditsMonthlyLimit
            )}`
          })}
        {emailCreditsDailyLimit > 0 &&
          creditsInfoLayout({
            emailCreditText:
              getPlanType() === "annual"
                ? `No daily email credit limit`
                : `Daily email credit limit: ${emailCreditsDailyLimit}`
          })}
        <div className="planItem extraHeight"></div>
      </>
    );
  };

  useEffect(() => {
    constructActivePlanDetails(PricingStore.activePlanData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PricingStore.activePlanData]);

  useEffect(() => {
    setSubscriptionId(userDetail.userFeatureFlag.subscriptionPlanId);
    setIsTrial(userDetail.userFeatureFlag.trialEnabled);
  }, [
    userDetail.userFeatureFlag.subscriptionPlanId,
    userDetail.userFeatureFlag.trialEnabled
  ]);

  return (
    <div
      className={`pricingPageSection ${
        isLogoutPricing ? "logoutPricing" : "loginPricing"
      }`}
    >
      <div className="pricingWrapper">
        <div className="pricingContainer">
          <div className="pricingTableSection">
            <div className="pricingTextSection">
              <div className="pricingTitle">
                <h1 className="title">
                  Super
                  <span className="gradIconText">
                    <span className="gradText">charge</span>
                    <i className="material-icons">flash_on</i>
                  </span>
                  your team’s productivity
                </h1>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: "No Contracts. No Obligations. Cancel any time."
                  }}
                />
              </div>
            </div>
            {!isLogoutPricing && trialEnabled && planName === "Pro" && (
              <div className="pricingPageTrialBannerWrapper">
                <PricingPageTrialBanner trialEndDate={trialEndDate} />
              </div>
            )}
            <div className="pricingToogle">
              {(subscriptionId === null || trialEnabled) && (
                <ToggleBtnType
                  firstText="Monthly"
                  defaultValue={toogleType}
                  offer={annualDiscount}
                  cbk={getTogglePlanType}
                  lastText="Annual"
                />
              )}
            </div>
            <div className="pricingList">
              {!tableLoading &&
                planDetails?.length > 0 &&
                planDetails.map((planObj, index) => {
                  const {
                    displayName = "",
                    emailCreditsDailyLimit = "",
                    phoneCreditsDailyLimit = "",
                    emailCreditsMonthlyLimit = ""
                  } = planObj || {};
                  let idValue =
                    displayName?.toLowerCase() == "custom"
                      ? "my_custom_link"
                      : "";

                  if (displayName !== "Free") {
                    const getActionButton = () => {
                      let buttonValue = "";
                      if (
                        !isLogoutPricing &&
                        displayName?.toLowerCase() !== "free"
                      ) {
                        if (displayName?.toLowerCase() === "custom") {
                          buttonValue = "Contact us";
                        } else {
                          buttonValue =
                            !subscriptionId || ![planName].includes(displayName)
                              ? "Upgrade"
                              : trialEnabled && "Upgrade";
                        }
                      }

                      return buttonValue ? (
                        <div
                          id={idValue}
                          className="upgradeBtn"
                          onClick={() => upgradeBtn(planObj)}
                        >
                          {buttonValue}
                        </div>
                      ) : (
                        ""
                      );
                    };
                    return (
                      <div
                        className="pricingCard"
                        key={`${displayName}Plan`}
                        id={`${displayName}Plan`}
                      >
                        <div className="planInfo">
                          <div className={`pricingPlan ${displayName}Plan`}>
                            <PricingPlanTag
                              className="planTagSvg"
                              displayName={displayName}
                            />
                          </div>
                          <div className="planPrice">
                            {displayName?.toLowerCase() !== "custom" && (
                              <span className="dollar">$</span>
                            )}
                            <span className="price">
                              {displayName?.toLowerCase() === "custom"
                                ? "Enterprise"
                                : getSeatPrice(planObj)}
                            </span>
                            {displayName !== "Free" &&
                              displayName?.toLowerCase() !== "custom" && (
                                <span className="seatText"> /seat/mo</span>
                              )}
                          </div>
                          {getActionButton()}
                          {(showCurrentPlanDom(planObj) ||
                            (inFreePlan && displayName === "Free")) &&
                            (isLogoutPricing ? (
                              <div className="upgradeBtn">Get Started</div>
                            ) : (
                              ((!isTrial &&
                                teamStatus.toLowerCase() !==
                                  "trial_completed") ||
                                (inFreePlan && displayName === "Free")) && (
                                <>
                                  <div
                                    className="currentPlanBtn"
                                    onClick={() =>
                                      props.history.push("/settings/billing")
                                    }
                                  >
                                    Current Plan
                                  </div>
                                </>
                              )
                            ))}
                          {displayName === planName && trialEnabled && (
                            <div className="trialMessageWrapper">
                              <div className="currentlyInTrialMessage">
                                Currently on Trial
                              </div>
                            </div>
                          )}
                          {displayName !== "Free" &&
                            isLogoutPricing &&
                            !subscriptionId &&
                            teamStatus?.toLowerCase() !== "trial_completed" && (
                              <div
                                id={idValue}
                                className="requestFreeTrial"
                                onClick={() => upgradeBtn(planObj, "trial")}
                              >
                                {displayName?.toLowerCase() == "custom"
                                  ? "CONTACT US"
                                  : "TRY FOR FREE"}
                              </div>
                            )}
                          {isLogoutPricing && (
                            <p className="noCardReq">No credit card required</p>
                          )}
                        </div>
                        <div className="planFeatureCard">
                          <div
                            className="planFeatureList"
                            style={{
                              borderBottom: `1px dashed #e0e0e0`
                            }}
                          >
                            {displayName !== "Free" && (
                              <p className="planText">
                                Everything on
                                <span className="highlightPlanText">
                                  {` ${
                                    displayName?.toLowerCase() === "custom"
                                      ? "Unlimited"
                                      : displayName?.toLowerCase() ===
                                        "unlimited"
                                      ? "Pro"
                                      : displayName?.toLowerCase() === "pro"
                                      ? "Basic"
                                      : "Free"
                                  } `}
                                </span>
                                plus
                              </p>
                            )}
                            {displayName?.toLowerCase() === "basic" &&
                              renderBasicPlan(planObj)}
                            {displayName?.toLowerCase() === "pro" &&
                              renderProPlan(planObj)}
                            {displayName?.toLowerCase() === "unlimited" &&
                              renderUnlimitedPlan(planObj)}
                            {displayName?.toLowerCase() === "custom" &&
                              renderCustomPlan(planObj)}
                          </div>
                          {
                            <div className="creditsInfo">
                              <p className="dataHeading">Data</p>
                              {showCreditsInfo(planObj)}
                            </div>
                          }
                        </div>
                      </div>
                    );
                  }
                })}
              {tableLoading && <PricingTableLoader noOfColumns={4} />}
            </div>
            <div
              className="freePlanFeatureListCard"
              key="freePlanFeatureListCard"
              id="freePlanFeatureListCard"
            >
              <div className="planInfo">
                <div className="pricingPlan freePlan">
                  <PricingPlanTag className="planTagSvg" displayName="Free" />
                </div>

                <div className="planPrice borderNone">
                  <span className="dollar">$</span>
                  <span className="price">0</span>
                </div>
                {isLogoutPricing && (
                  <div
                    className="requestFreeTrial"
                    onClick={() => upgradeBtn({}, "trial")}
                  >
                    Sign Up
                  </div>
                )}
              </div>
              <div className="verticalLine" />
              <div className="freePlanFeatureList">{renderFreePlan()}</div>
            </div>
          </div>
          <div className="enterprisePlanSection">
            <div className="enterprisePlan">
              <div className="aeFeatureSection">
                <p className="desc">Have any questions related to pricing?</p>
                <div className="aeFeatureList">
                  {enterpriseItemList.map(item => {
                    return (
                      <div
                        className="card"
                        id={item.id}
                        key={`enterprise${item.id}`}
                        onClick={() => triggerFeatureEvent(item.id)}
                      >
                        <img src={getEnterpriseImg(item.id)} alt="" />
                        <h6 className="name">{item.name}</h6>
                        <p className="value">{item.value}</p>
                        <span className="material-icons hoverArrowForward">
                          arrow_right_alt
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="crmSection">
            <h4 className="title">Works with all popular CRMs</h4>
            <div className=" crmIconList">
              {CrmIconList.map(item => {
                return (
                  <div className="crmIcon" key={item.id}>
                    <img src={item.img} alt="" />
                  </div>
                );
              })}
            </div>
            <img src={LeftCrmIconsImg} alt="" className="crmOverlayLeft" />
            <img src={RightCrmIconsImg} alt="" className="crmOverlayRight" />
          </div>
          <div className="freqAskQuesSection">
            <h4 className="title">Frequently Asked Questions</h4>
            <div className="freqAskQuesRow">
              <div className="imgCol">
                <img src={FreqAskQuesImg} alt="" />
              </div>
              <div className="contentCol">
                {FREQ_ASK_QUESTIONS.map((item, index) => {
                  return (
                    <CollapsiveContent
                      key={`freqAskQuestion${index + 1}`}
                      title={item.title}
                      content={item.description}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(observer(PricingFeatureTable));
