import React, { useState, useEffect } from "react";
const OfferEndingTime = () => {
  const [offerTime, setOfferTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    totalHours: 0
  });
  const deadline = new Date("Dec 31, 2024 23:59:00").getTime();
  const now = new Date().getTime();
  const timeDifference = deadline - now;
  const findTimeDifference = () => {
    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      setOfferTime({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        totalHours: days * 24 + hours
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(findTimeDifference, 1000);
    return () => clearInterval(interval);
  });
  return (
    <div className="finalTimeWrapper">
      {timeDifference > 0 ? (
        <div className="countdown">
          <div className="card">
            <div className="cardHeading">{offerTime.days}</div>
            <p className="label">Days</p>
          </div>
          <div className="card">
            <div className="cardHeading">{offerTime.hours}</div>
            <p className="label">Hrs</p>
          </div>
          <div className="card">
            <div className="cardHeading">{offerTime.minutes}</div>
            <p className="label">Min</p>
          </div>
          <div className="card">
            <div className="cardHeading">{offerTime.seconds}</div>
            <p className="label">Sec</p>
          </div>
        </div>
      ) : (
        <div className="countdown">
          <div className="card">
            <div className="cardHeading">00</div>
          </div>
          <div className="card">
            <div className="cardHeading">00</div>
          </div>
          <div className="card">
            <div className="cardHeading">00</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferEndingTime;
export { OfferEndingTime };
