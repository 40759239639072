import React, { useEffect } from "react";
import { observer } from "mobx-react";
import CheckBox from "Components/common/CheckBox";
import { pullContactState } from "./store";
import SelectSequenceWithSearch from "./SelectSequenceWithSearch";
import CustomAutoCompleteDropdown from "./CustomAutoCompleteDropdown";

const ListSequence = observer(() => {
  const addToListCbk = () => {
    pullContactState.setAddToListChecked(!pullContactState.addToListChecked);
  };

  const addToSeqCbk = () => {
    pullContactState.setAddToSeqChecked(!pullContactState.addToSeqChecked);
  };

  const addToListvalCbk = item => {
    pullContactState.setListInfo(item);
  };

  const addToSeqvalCbk = item => {
    pullContactState.setSelectedSequence(item);
  };

  const addToListObj = {
    name: "Add to List",
    checked: pullContactState.addToListChecked,
    id: "addToList",
    cbk: addToListCbk
  };

  const addToSeqObj = {
    name: "Add to Sequence",
    checked: pullContactState.addToSeqChecked,
    id: "addToSequence",
    cbk: addToSeqCbk
  };

  useEffect(() => {
    if (!pullContactState.allowSequence) {
      pullContactState.setAddToSeqChecked(true);
    }
  }, [pullContactState.allowSequence]);

  return (
    <div className="listSequenceWrapper">
      <div className="listFlex">
        <div className="listFlexColumn">
          <CheckBox {...addToListObj} />
          <CustomAutoCompleteDropdown
            optionList={pullContactState?.totalContactList}
            loader={!pullContactState?.totalContactList}
            disabled={!pullContactState.addToListChecked}
            cbk={addToListvalCbk}
          />
          {pullContactState.createListError && (
            <div className={"createListErrMsg"}>
              {pullContactState.createListError}
            </div>
          )}
        </div>
        <div className="listFlexColumn">
          <CheckBox {...addToSeqObj} />
          <SelectSequenceWithSearch
            optionList={pullContactState.sequenceList}
            disabled={!pullContactState.addToSeqChecked}
            loader={!pullContactState?.sequenceList}
            cbk={addToSeqvalCbk}
          />
        </div>
      </div>
    </div>
  );
});

export { ListSequence };
export default ListSequence;
