/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter, useLocation } from "react-router-dom";
import { observable, action } from "mobx";
import { sequenceStoreData } from "Stores/sequenceData";
import { scheduleDataStore } from "Stores/ScheduleData";
import Steps from "./Steps/Steps";
import SequenceStat from "./sequenceStat";
import SequenceContacts from "../SequenceContact/sequenceContacts";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import ConstantObj from "Static/constant";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { settingsStoreData } from "Stores/settingsData";
import contactStoreData from "Stores/contactData";
import AddStepBtn from "Components/common/addStepBtn";
import userDetail from "Stores/userDetail";
import { sequenceContactData } from "Stores/sequence/Contact.js";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { MP_EVENT } from "Static/constant";
import DriverMaster from "Components/driver/Driver";
import {
  checkTeamSubscriptionActive,
  fetchLinkedInAccounts
} from "Utils/commonAPI";
import { CONTACT_OWNED_FILTER_ITEMS } from "Static/constant";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import { addSequenceInContactPopupState } from "Pages/Dashboard/contacts/addSeqInContactPopup";
import ContactUploading from "Pages/Dashboard/contacts/ContactToSequence/ContactUploading";
import AddStageOverlay from "./AddStageOverlay";
import { tagsStoreData } from "Stores/TagsData";
import AddContactDropDown from "Components/common/AddContactDropDown";
import SignupSuccess from "Assets/png/signupSuccess.png";
import { editSequencePopupState } from "Components/common/EditSequencePopup";
import SequenceThrottlingSettings from "./SequenceThrottlingSettings";
import SequenceUtils from "Utils/SequenceUtils";
import StageLoader from "./Steps/StageLoader";
import { addSingleContactPopupState } from "Components/common/AddSingleContactPopup";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import customListState from "../../dataSearch/CustomList/state";
import { MessageResponseHandler } from "./MessageResponseHandler";
import SequenceTabNav from "./SequenceTabNav";
import PaywallUtils from "Utils/PaywallUtils";
import ContactOwnerButton from "./ContactOwnerButton";
import { ReactComponent as UserIcon } from "Assets/svg/userOutlined.svg";
import { ReactComponent as StageIcon } from "Assets/svg/stageIcon.svg";
import FieldValidatorMsgPopup from "Pages/Dashboard/contacts/ContactToSequence/FieldValidatorMsgPopup";
import ActivateSequence, { ActivateSequenceLoader } from "./ActivateSequence";
import ActivatedSequence from "./ActivatedSequence";
import sequenceContactStoreData, {
  defaultFilterPayload
} from "../SequenceContact/SequenceContactFilter/sequenceContactStore";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { exportToSalesforcePopupState } from "Components/common/ExportToSalesforcePopup";
import AddStageDropDown from "./AddStageDropDown";

const defaultContactOwner = {
  all: false,
  idArr: [],
  team: [],
  selected: ["you"]
};

let contactFilterObj = CONTACT_OWNED_FILTER_ITEMS;
let cancelAnalyticsRequest = null;
let cancelContactsRequest = null;

class SequenceRenderState {
  defaultPopupValue = {
    title: "New Sequence",
    inputVal: "",
    bodyContent: "",
    folderName: "",
    editID: "",
    callback() {}
  };

  @observable templateValues = this.defaultPopupValue;
  @observable contactOwner = defaultContactOwner;
  @observable filteredTags = [];
  @observable currentStage = "Total";
  @observable performingSearch = false;
  @observable filterByStep = null;
  @observable csvLength = 0;

  @action
  setCsvLength = val => {
    this.csvLength = val;
  };

  @action
  setPerformingSearch = val => {
    this.performingSearch = val;
  };

  @action
  setCurrentStage = val => {
    this.currentStage = val;
  };

  @action
  setSequenceValues = (val = this.defaultPopupValue) => {
    this.templateValues = val;
  };

  @action
  setFilteredTags = val => {
    this.filteredTags = val;
  };

  @action
  setContactOwnerValue(value) {
    this.performingSearch = false;
    this.contactOwner = { ...this.contactOwner, ...value };
  }

  @action
  setFilterByStep(value) {
    this.filterByStep = value;
  }
}

const sequenceRenderState = new SequenceRenderState();

let currentSeqData;
const sharedType = ConstantObj?.sequenceType?.shared?.type || "";

const SequenceProperties = props => {
  let seqIdFromURL,
    location = useLocation(),
    isFolder = false,
    folderIdFromURL = null,
    hashValue = location?.hash;
  let hashUrlArr = ["#contacts", "#analytics", "#settings"];

  const {
    match = "",
    setShowSharedBadge = () => {},
    history = ""
  } = props || {};
  const {
    taskEnabled = false,
    noOfContactsEligible = 0,
    noOfContactsUsed = 0,
    planName = "",
    inFreePlan = false,
    emailValidationCredits = 0,
    emailValidationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  let propsMatchParam = match?.params || {};

  const { selectedContactCard = () => {}, closeCbk = () => {} } = props || {};
  const [showAnalytics, setShowAnalytics] = useState(
    hashValue === "#analytics"
  );
  const [showProspects, setShowProspects] = useState(hashValue === "#contacts");
  const [showOverview, setShowOverview] = useState(
    hashValue === "" || !hashUrlArr.includes(hashValue)
  );
  const [showContactOwnerBtn, setShowContactOwnerBtn] = useState(
    hashValue === "" || !hashUrlArr.includes(hashValue)
  );
  const [showSettings, setSettings] = useState(hashValue === "#settings");
  const [steps, setSteps] = useState({});
  const [sequenceToggleBtn, setSequenceToggleBtn] = useState(true);
  const [showSequenceToggleBtnLoad, setSequenceToggleBtnLoad] = useState(true);
  const [showStageLoader, setShowStageLoader] = useState(true);
  const [showContactOwner, setShowContactOwner] = useState(false);
  const [exportAllContactSelected, setExportAllContactSelected] =
    useState(false);
  const [exportData, setExportData] = useState([]);
  const [scheduledIdData, setScheduledIdData] = useState([]);
  const [totalContactCount, setTotalContactsCount] = useState(0);
  const [contactsData, setContactsData] = useState(
    sequenceContactData.contactsData
  );
  const [totalPages, setTotalPages] = useState(null);
  const [contactLoader, setContactsLoader] = useState(true);
  const [analyticsLoader, setAnalyticsLoader] = useState(true);
  const [allContactSelected, setAllContactSelected] = useState(false);
  const [contactLimit, setContactLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [throttlingData, setThrottlingData] = useState("");
  const [throttlingLoader, setThrottlingLoader] = useState(true);
  const [accessType, setAccessType] = useState(false);
  const [updateSequenceContacts, setUpdateSequenceContacts] = useState(false);
  const [previousTab, setPreviousTab] = useState("");
  const [savedChanges, setSavedChanges] = useState(false);
  const [isFromStepBadgeNavigation, setFromStepBadgeNavigation] =
    useState(false); // Used to not make the initial contact api in SequenceContacts comp on clicking the step badge in overview.
  const [showActivateLoader, setShowActivateLoader] = useState(false);
  const [renderAnalyticsData, setRenderAnalyticsData] = useState(
    sequenceContactData.contactsTabCount
  );
  const [isContactUploading, setContactUploading] = useState(false);
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const [analyticStepLoader, setAnalyticStepLoader] = useState(true);

  const showInitialActivateLoader =
    hashValue === "#analytics"
      ? analyticsLoader
      : hashValue === "#contacts"
      ? contactLoader
      : hashValue === "#settings"
      ? throttlingLoader
      : showStageLoader;
  const [showContactUploadingLoader, setContactUploadingLoader] =
    useState(false);
  const [uploadingText, setUploadingText] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [fieldErrorDetails, setFieldErrorDetails] = useState("");
  const [dailyLimitContactData, setDailyLimitContactData] = useState("");

  let hasTask = SequenceUtils.hasTask(steps);

  const {
    hubspotIntegrationEnabled = false,
    salesforceIntegrationEnabled = false
  } = userDetail.userFeatureFlag || {};

  const setCurrentStage = (val = "Total") => {
    sequenceRenderState.setCurrentStage(val);
  };

  const getCurrentStage = () => {
    return sequenceRenderState?.currentStage || "";
  };

  let settingsData = settingsStoreData?.settingsData || {};

  if (propsMatchParam) {
    if (propsMatchParam.id && propsMatchParam.folderId) {
      isFolder = true;
      folderIdFromURL = propsMatchParam.folderId;
      seqIdFromURL = propsMatchParam.id;
    } else if (propsMatchParam.id) {
      seqIdFromURL = propsMatchParam.id;
    } else if (propsMatchParam.sharedId) {
      seqIdFromURL = propsMatchParam.sharedId;
    }
  }

  let seqId = seqIdFromURL || "";

  const calculateCoordinates = e => {
    if (e) {
      const offsetWidth = e.offsetWidth ? e.offsetWidth : 0;
      const offsetLeft = e.offsetLeft ? e.offsetLeft : 0;
      let paddingValue =
        window.getComputedStyle(e, null).getPropertyValue("padding-left") &&
        window.getComputedStyle(e, null).getPropertyValue("padding-left").length
          ? window
              .getComputedStyle(e, null)
              .getPropertyValue("padding-left")
              .replace("px", "")
          : 30;
      paddingValue = paddingValue / 2;
      const barElement = document.querySelector(".headerInnerCont");
      let n, r;
      barElement &&
        ((n = (offsetWidth - 2 * paddingValue) / barElement.offsetWidth),
        (r = offsetLeft + paddingValue));
      return { scale: n, left: r };
    } else return {};
  };

  const setAnimatedBorder = targetClass => {
    if (targetClass) {
      const targetDom =
        document.getElementsByClassName(targetClass) &&
        document.getElementsByClassName(targetClass)[0]
          ? document.getElementsByClassName(targetClass)[0]
          : "";
      if (targetDom) {
        const calculatedObj = calculateCoordinates(targetDom);
        if (calculatedObj.scale && calculatedObj.left) {
          document.getElementById(
            "animated-border"
          ).style.transform = `translateX(${calculatedObj.left}px) scaleX(${calculatedObj.scale})`;
        }
      }
    }
  };

  const getSelectedSeqData = async () => {
    const sequenceResData = await getSelectedDataFromAPI();
    if (sequenceResData?.response?.status === 403) {
      history.push("/sequences/all");
      return;
    }
    const { data: seqData = {}, message = "" } = sequenceResData || {};
    let resData = [];
    let StepsData = [];
    seqData ? resData.push(seqData) : {};
    seqData ? StepsData.push(seqData?.steps) : {};
    let finalData = SequenceUtils.getProccessedResponseData(resData);
    let firstStepEmailFound = SequenceUtils.getFirstEmailstep(StepsData);
    addStagesPopupState.setIsFirstMailStepFound(firstStepEmailFound);

    if (finalData?.length) {
      sequenceStoreData.setSelectedTemplateSequence(finalData);
    }

    if (message !== "cancel") {
      if (seqData && Object.keys(seqData)?.length) {
        setOverviewData(seqData);
        let sequenceData = seqData || { id: seqId, steps: {} };
        // Make analytics API request only for overview
        let isOverviewTab = !["#analytics", "#contacts", "#settings"]?.includes(
          hashValue?.toLowerCase()
        );
        let stepsAnalyticsResponse = [];
        if (isOverviewTab) {
          stepsAnalyticsResponse = await getStepsAnalytics();
          setAnalyticStepLoader(false);
        }
        // End make analytics API request only for overview
        currentSeqData = seqData;

        addStagesPopupState.setSelectedSequence(sequenceData);
        const {
          contactsAdded = 0,
          pendingCount = 0,
          step: stepsObj = "",
          scheduleWindowId = "",
          settings = {},
          accessType = "",
          shareType = "",
          memberId = "",
          inboxIds = [],
          reassignInbox = false,
          excludeContactEnabled = false,
          emailValidationEnabled = false,
          stopEmailOnMeetingBooked = false,
          stopEmailOnReplyReceived = false,
          stopEmailOnLinkedInReplyReceived = false
        } = seqData || {};
        setThrottlingData({
          scheduleWindowId,
          ...settings,
          inboxIds,
          reassignInbox,
          excludeContactEnabled,
          emailValidationEnabled,
          stopEmailOnMeetingBooked,
          stopEmailOnReplyReceived,
          stopEmailOnLinkedInReplyReceived
        });
        let hasAccess =
          shareType === "PRIVATE" ||
          (shareType === "SHARED" && accessType === "READ_WRITE") ||
          (shareType === "SHARED" &&
            accessType === "READ_ONLY" &&
            memberId === userDetail?.userInfo?.memberId);
        setAccessType(hasAccess);
        setThrottlingLoader(false);
        editSequencePopupState.setTotalContacts(contactsAdded);
        editSequencePopupState.setPendingContacts(pendingCount);
        (stepsObj === "" ||
          (stepsObj && Object.keys(stepsObj)?.length === 0)) &&
          Utils.triggerAddStagesDriver();
        (isSharedSequence(seqData) && setShowSharedBadge(true)) ||
          setShowSharedBadge(false);
        seqData.background = SequenceUtils.getSequenceStatus(currentSeqData);
        setOverviewData(seqData, stepsAnalyticsResponse);
        setShowActivateLoader(false);
        setSequenceToggleBtnLoad(seqData?.background?.loading || false);
      } else {
        setShowStageLoader(false);
        setSequenceToggleBtnLoad(false);
      }
    }
  };

  const getSelectedDataFromAPI = () => {
    const targetUrl = URLS.getSelectedSeqData.replace("<<seqID>>", seqId);
    const config = {
      url: targetUrl,
      method: "GET"
    };
    if (cancelContactsRequest !== null) {
      cancelContactsRequest("cancel");
      cancelContactsRequest = null;
    }
    const cancelReqCallback = value => {
      cancelContactsRequest = value;
    };
    return makeApi(config, cancelReqCallback);
  };

  const getStepsAnalytics = async () => {
    const config = {
      url: URLS.stepsAnalytics,
      method: "POST",
      data: { sequenceId: seqId }
    };
    if (cancelAnalyticsRequest !== null) {
      cancelAnalyticsRequest("cancel");
      cancelAnalyticsRequest = null;
    }
    const cancelReqCallback = value => {
      cancelAnalyticsRequest = value;
    };
    let res = await makeApi(config, cancelReqCallback);
    const { data = {}, message = "" } = res || {};
    if (message !== "cancel") {
      return data?.stageAnalytics?.length ? data?.stageAnalytics : [];
    } else {
      return false;
    }
  };

  const confirmationCbk = activeTab => {
    if (activeTab === "overview") {
      overViewAction(false);
      setPreviousTab("overview");
    } else if (activeTab === "analytics") {
      analyticsAction();
      setPreviousTab("analytics");
    } else if (activeTab === "prospects") {
      prospectsAction();
      setPreviousTab("prospects");
    } else {
      settingAction();
      setPreviousTab("settings");
    }
  };

  const checkForChanges = () => {
    let hasChange = false;
    if (savedChanges) {
      hasChange = false;
      setSavedChanges(false);
      return hasChange;
    }
    const {
      reassignInbox = false,
      scheduleWindowId = "",
      dailyEnrollingCount = "",
      maximumActiveCount = "",
      excludeContactEnabled = false,
      emailValidationEnabled = false,
      stopEmailOnMeetingBooked = false,
      stopEmailOnReplyReceived = false,
      stopEmailOnLinkedInReplyReceived = false
    } = sequenceStoreData?.tempThrottlingData || {};
    const {
      reassignInbox: thottlingReAssignInbox = false,
      scheduleWindowId: throttlingScheduleWindowId = "",
      dailyEnrollingCount: throttlingDailyEnrollingCount = "",
      maximumActiveCount: throttlingMaxiumumActiveCount = "",
      excludeContactEnabled: isExcludeContactEnabled = false,
      emailValidationEnabled: isEmailValidationEnabled = false,
      stopEmailOnMeetingBooked: isStopEmailOnMeetingBooked = false,
      stopEmailOnReplyReceived: isStopEmailOnReplyReceived = false,
      stopEmailOnLinkedInReplyReceived:
        isStopEmailOnLinkedInReplyReceived = false
    } = throttlingData || {};
    if (
      scheduleWindowId !== throttlingScheduleWindowId ||
      reassignInbox !== thottlingReAssignInbox ||
      excludeContactEnabled !== isExcludeContactEnabled ||
      emailValidationEnabled !== isEmailValidationEnabled ||
      stopEmailOnMeetingBooked !== isStopEmailOnMeetingBooked ||
      stopEmailOnReplyReceived !== isStopEmailOnReplyReceived ||
      stopEmailOnLinkedInReplyReceived !== isStopEmailOnLinkedInReplyReceived ||
      dailyEnrollingCount?.toString() !==
        throttlingDailyEnrollingCount?.toString() ||
      maximumActiveCount?.toString() !==
        throttlingMaxiumumActiveCount?.toString()
    ) {
      hasChange = true;
      return hasChange;
    }
    return hasChange;
  };

  const getActiveTab = activeTab => {
    let hasChange = false;
    if (previousTab === "settings") {
      hasChange = checkForChanges();
      if (hasChange) {
        confirmationPopupState.setPopupValues({
          content:
            "Are you sure you want to switch tab ?<br>  Any changes made will be lost.",
          actionBtnText: "Ok",
          callback: () => {
            confirmationCbk(activeTab);
          }
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        confirmationCbk(activeTab);
      }
    } else {
      confirmationCbk(activeTab);
    }
  };

  const overViewAction = async (tabClick = false) => {
    if (tabClick) {
      setThrottlingLoader(false);
      setShowProspects(false);
      setShowAnalytics(false);
      setShowOverview(true);
      setShowContactOwnerBtn(false);
      setSettings(false);
      setShowStageLoader(true);
      sequenceContactData.setEmptyContacts();
      setTimeout(function () {
        setAnimatedBorder("overViewCont");
      }, 100);
    } else {
      !showOverview &&
        history &&
        hashValue !== "#salesgearSupport" &&
        history.push("#");
    }
  };

  const analyticsAction = () => {
    setThrottlingLoader(false);
    setShowOverview(false);
    setShowProspects(false);
    setSettings(false);
    setShowAnalytics(true);
    setShowContactOwnerBtn(true);
    setShowStageLoader(false);
    sequenceContactData.setEmptyContacts();
    history && history.push("#analytics");
    setTimeout(function () {
      setAnimatedBorder("analyticsCont");
    }, 100);
  };

  const prospectsAction = () => {
    setThrottlingLoader(false);
    setShowOverview(false);
    setShowAnalytics(false);
    setShowProspects(true);
    setShowContactOwnerBtn(true);
    setSettings(false);
    setShowStageLoader(false);
    sequenceContactData.setEmptyContacts();
    history && history.push("#contacts");
    setTimeout(function () {
      setAnimatedBorder("prospectsCont");
    }, 100);
  };

  const settingAction = async () => {
    !throttlingData && setThrottlingLoader(true);
    setShowOverview(false);
    setShowAnalytics(false);
    setShowProspects(false);
    setShowContactOwnerBtn(false);
    setShowStageLoader(false);
    setSettings(true);
    sequenceContactData.setEmptyContacts();
    history && history.push("#settings");
    setTimeout(function () {
      setAnimatedBorder("settingsCont");
    }, 100);
  };

  const setOverviewData = (data = {}, stepsAnalyticsResponse = []) => {
    currentSeqData = data;
    const {
      steps: stepsObj = "",
      updateTs = "",
      background = {}
    } = currentSeqData || {};
    ["paused", "suspended", "pause"].includes(
      background?.status?.toLowerCase()
    ) && setSequenceToggleBtn(false);
    updateTs && delete currentSeqData?.updateTs;
    if (stepsAnalyticsResponse?.length > 0) {
      stepsAnalyticsResponse.map((item, index) => {
        stepsObj[index + 1]["analytics"] = item || {};
      });
    }
    stepsObj && setSteps(JSON.parse(JSON.stringify(stepsObj)));
    setShowStageLoader(false);
    setSequenceToggleBtnLoad(background?.loading);
  };

  const popupObj = {
    addVariant: "Sequence updated successfully.",
    editVariant: "Sequence updated successfully.",
    deleteVariant: "Sequence updated successfully.",
    cloneVariant: "Sequence updated successfully.",
    createStage: "Sequence updated successfully.",
    updateStage: "Sequence updated successfully."
  };

  const afterCreateUpdateCbk = () => {
    // After stage created
    triggerOnboardingActions("completed");
    Utils.mixpanelTrack(MP_EVENT.SQ_STAGE_ADDED);
    if (addStagesPopupState?.stageIntervalUpdated) {
      editSequencePopupState.setShowPopup(true);
      editSequencePopupState.setMessage(
        "Sequence updated successfully. Please note that the updated step interval will be reflected only for the new contacts scheduled in this step"
      );
      addStagesPopupState.setStageIntervalUpdated(false);
      editSequencePopupState.setImage(SignupSuccess);
    } else {
      toasterState.setToastMsg(
        addStagesPopupState?.action
          ? popupObj[addStagesPopupState.action]
          : "Sequence step updated successfully",
        "success"
      );
    }
    setShowStageLoader(false);
  };

  const triggerOnboardingActions = data => {
    // After first step created , still the stages will be empty , it will be updated after this method.
    if (steps && Object.keys(steps)?.length === 0) {
      Utils.getOnboardingData();
      Utils.trackOnboardingMixpanel("sequence_created");
    }
  };

  const setlocationState = () => {
    if (hashValue === "#analytics") {
      analyticsAction();
      setPreviousTab("analytics");
    } else if (hashValue === "#contacts") {
      prospectsAction();
      setPreviousTab("prospects");
    } else if (hashValue === "#settings") {
      settingAction();
      setPreviousTab("settings");
    } else if (!hashUrlArr.includes(hashValue)) {
      overViewAction(true);
      setPreviousTab("overview");
    }
  };

  const reloadSequenceTabs = async () => {
    await getSelectedSeqData();
    setUpdateSequenceContacts(true);
  };

  const checkBoxToggleAction = async event => {
    Utils.preventDefaultFn(event);
    let isChecked = event.target.checked;
    let type = isChecked ? "RESUME" : "PAUSE";

    const toggleBtnActionCbk = async () => {
      if (!showSequenceToggleBtnLoad) {
        setSequenceToggleBtnLoad(true);
        if (
          SequenceUtils.resumeSequenceEnabled(props, type) &&
          (await checkTeamSubscriptionActive()) &&
          seqId
        ) {
          if (
            !steps ||
            (steps &&
              (!hasTask ||
                (hasTask && taskEnabled) ||
                (sequenceToggleBtn && hasTask)))
          ) {
            let response =
              await sequenceStoreData.getBackgroundPauseResumeSequence({
                filter: { sequenceId: seqId },
                type,
                totalCount: currentSeqData?.contactsAdded || 0
              });
            if (response?.data) {
              const {
                background = false,
                bulkOperationResult = {},
                activeJob = false
              } = response?.data || {};
              if (isChecked) {
                if (
                  bulkOperationResult?.response &&
                  Object.keys(bulkOperationResult?.response)?.length
                ) {
                  const {
                    respCode = "",
                    successCount = 0,
                    failedCount = 0
                  } = bulkOperationResult?.response || {};
                  await reloadSequenceTabs();
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else if (
                    (+respCode === 200 &&
                      successCount === 0 &&
                      failedCount === 0) ||
                    background
                  ) {
                    setSequenceToggleBtn(
                      !["paused", "suspended", "pause"].includes(
                        type?.toLowerCase()
                      )
                    );
                    SequenceUtils.handleSuccessResponseForPauseResume(
                      background,
                      type
                    );
                  } else {
                    SequenceUtils.handleSuccessFailedResponseForResume(
                      props,
                      "",
                      bulkOperationResult?.response
                    );
                    setSequenceToggleBtn(successCount > 0);
                  }
                  return;
                } else {
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else {
                    await reloadSequenceTabs();
                    setSequenceToggleBtn(
                      !["paused", "suspended", "pause"].includes(
                        type?.toLowerCase()
                      )
                    );

                    SequenceUtils.handleSuccessResponseForPauseResume(
                      background,
                      type
                    );
                  }
                }
              } else {
                if (
                  bulkOperationResult?.error &&
                  Object.keys(bulkOperationResult?.error)?.length
                ) {
                  SequenceUtils.handleFailedResponseForPauseResume(
                    props,
                    "",
                    bulkOperationResult?.error
                  );
                  setSequenceToggleBtn(sequenceToggleBtn);
                  setSequenceToggleBtnLoad(false);
                  return;
                } else {
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else {
                    await reloadSequenceTabs();
                    const { failed = 0 } = bulkOperationResult || {};
                    if (failed > 0) {
                      SequenceUtils.handleSuccessFailedResponseForResume(
                        props,
                        "",
                        bulkOperationResult
                      );
                    } else {
                      setSequenceToggleBtn(
                        !["paused", "suspended", "pause"].includes(
                          type?.toLowerCase()
                        )
                      );
                      SequenceUtils.handleSuccessResponseForPauseResume(
                        background,
                        type
                      );
                    }
                  }
                }
              }
            } else {
              SequenceUtils.handleFailedResponseForPauseResume(
                props,
                "",
                "",
                response
              );
              setSequenceToggleBtn(sequenceToggleBtn);
              setSequenceToggleBtnLoad(false);
            }
          } else {
            setSequenceToggleBtn(sequenceToggleBtn);
            setSequenceToggleBtnLoad(false);
          }
          return;
        } else {
          setSequenceToggleBtn(sequenceToggleBtn);
          setSequenceToggleBtnLoad(false);
        }
      }
    };

    if (!showSequenceToggleBtnLoad) {
      let isChecked = event.target.checked;
      let type = isChecked ? "RESUME" : "PAUSE";
      if (type === "RESUME") {
        toggleBtnActionCbk(event);
      } else {
        confirmationPopupState.setPopupValues({
          content: `Are you sure you want to disable this sequence? All the scheduled emails and tasks from this sequence will be paused until it’s activated.`,
          actionBtnText: "Yes",
          cancelBtnText: "No",
          callback: toggleBtnActionCbk
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
  };

  const bulkUploadFormSubmitAction = bulkCsvData => {
    sequenceRenderState.setCsvLength(bulkCsvData?.length);
    const addTagPopupCbk = tagsList => {
      const extractedTagsArray = Utils.extractTagNameFromList(tagsList);
      showScheduleSequencePopup(seqId, bulkCsvData, extractedTagsArray);
    };
    Utils.showAddTagsPopup(addTagPopupCbk, bulkCsvData.length);
  };

  const activateDraftSeq = () => {
    if (currentSeqData?.status?.toLowerCase() === "draft") {
      sequenceStoreData.setSeqActivated(true);
    }
  };

  const showScheduleSequencePopup = (curSeqid, bulkCsvData, tagsList = []) => {
    const currentSeq = currentSeqData || {};
    const seqName = currentSeq?.name;
    const contactDataLen = bulkCsvData?.length;
    const scheduleCbk = async () => {
      const response = await Utils.scheduleSequence(
        settingsData,
        bulkCsvData,
        curSeqid,
        tagsList
      );
      const {
        data: { responseCode = 500 } = {},
        response: { status = 500 } = {}
      } = response || {};
      if (+responseCode === 200) {
        sequenceContactData.getSeqAnalyticsTab(
          seqId,
          sequenceContactStoreData?.filterPayload?.filters || {}
        );
        Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
          sequenceName: seqName,
          emailCount: contactDataLen,
          pageType: "Sequence"
        });
        activateDraftSeq();
        toasterState.setToastMsg("Sequence successfully scheduled", "success");
        tagsStoreData.getAllTags();
        sequenceStoreData.getSequenceStatusBySeqId(curSeqid);
        Utils.getOnboardingData();
        Utils.trackOnboardingMixpanel("contact_added");
        history.push("#contacts"); // As per prem's requirement, redirecting to contacts page.
      } else if (+responseCode === 105) {
        SequenceUtils.showNoInboxconnectedPopup();
      } else if (+responseCode === 509) {
        Utils.showContactsUploadLimitReached();
      } else if (status === 426) {
        Utils.showUpgradePopup("contacts", true, true);
      } else {
        Utils.showApiFailureResponse(response);
      }
      addBulkContactPopupState.setFileName("");
    };

    scheduleCbk();

    document.getElementById("seqBulkPopupWrapper").style.display = "none";
    document.getElementsByClassName("seqBulkCont")[0].innerHTML = "";
  };

  const remainingContactCount = () => {
    return noOfContactsEligible - noOfContactsUsed;
  };

  const addSingleContact = async id => {
    DriverMaster.killDriver();
    await getSelectedSeqData();
    if (await checkTeamSubscriptionActive()) {
      if (SequenceUtils.checkAndAllowContactUpload(props, currentSeqData, id)) {
        if (SequenceUtils.inboxConnected(currentSeqData)) {
          sequenceStoreData.setAddContactLoading(true);
          await sequenceStoreData.getSenderToken(seqId);
          if (sequenceStoreData?.missingSenderTokens?.length === 0) {
            remainingContactCount() > 0
              ? Utils.showAddSingleContactToSequencePopup(
                  currentSeqData,
                  addContactSuccessCbk
                )
              : PaywallUtils.upgradeUtilsFunction(
                  "contactsAdded",
                  false,
                  planName,
                  inFreePlan,
                  { contactCount: noOfContactsEligible }
                );
          } else {
            SequenceUtils.showMissingSenderTokenPopup(props);
          }
        } else {
          SequenceUtils.showNoInboxconnectedPopup(props, "#settings");
        }
      }
    }
  };

  const addContactSuccessCbk = (
    seqName = "",
    contactDataLen = 0,
    curSeqid = ""
  ) => {
    sequenceContactData.getSeqAnalyticsTab(
      curSeqid,
      sequenceContactStoreData?.filterPayload?.filters || {}
    );
    showProspects && setUpdateSequenceContacts(true);
    Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
      sequenceName: seqName,
      emailCount: contactDataLen,
      pageType: "Sequence"
    });
    activateDraftSeq();
    toasterState.setToastMsg("Sequence successfully scheduled", "success");
    tagsStoreData.getAllTags();
    sequenceStoreData.getSequenceStatusBySeqId(curSeqid);
    Utils.getOnboardingData();
    Utils.trackOnboardingMixpanel("contact_added");
    history && history.push("#contacts");
  };

  const checkUserHasTeam = () => {
    return userDetail?.userInfo?.team ? true : false;
  };

  const getTeamStatus = async () => {
    if (checkUserHasTeam()) {
      let res = await Utils.getTeamMember();
      if (res?.data?.length > 0) {
        if (res?.data?.length > 1) {
          sequenceRenderState.setContactOwnerValue({ team: res?.data || {} });
          setShowContactOwner(true);
        }
      }
    }
  };

  const resetContactOwnedFilter = () => {
    contactFilterObj.map(item => {
      item.checked = item.id === "you";
    });
  };

  const handleExportFail = () => {
    clearSelectedContacts();
    exportProcessingState.setShowPopup(false);
    toasterState.setToastMsg("Contact export failed", "failure");
  };

  const handleExportResponse = downloadLink => {
    if (!downloadLink) {
      handleExportFail();
    } else {
      exportProcessingState.completeAnimation();
      setTimeout(() => {
        Utils.downloadExportedContact(downloadLink);
        exportProcessingState.setShowPopup(false);
        clearSelectedContacts();
        toasterState.setToastMsg(
          "Contacts have been exported successfully.",
          "success"
        );
        Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
          pageType: "Sequences"
        });
      }, 1000);
    }
  };

  const getSeqFilterPayload = () => {
    if (seqId) {
      const {
        contactOwner: { idArr = [], all = false } = {},
        filterByStep = null
      } = sequenceRenderState || {};
      let sequenceFilter = {
        ...sequenceContactStoreData?.overallPayload?.filters,
        ...(idArr?.length ? { memberIds: idArr } : { all: all }),
        ...(searchKeyword && { searchTerm: searchKeyword }),
        ...(filterByStep && { stepMetrics: filterByStep ? [filterByStep] : [] })
      };
      return sequenceFilter;
    }
    return {};
  };

  const getRetryAllPayload = (typeText = "") => {
    const bulkActionType = typeText?.toLowerCase();
    const typeMap = {
      reactivate: "REACTIVATE_CONTACTS",
      resume: "RESUME",
      retry: "RETRY_CONTACT",
      hubspotexport: "HUBSPOT_CONTACT_EXPORT",
      salesforceexport: "SALESFORCE_CONTACT_EXPORT"
    };
    const type = typeMap[bulkActionType] || "";
    const payloadObj = {
      source: "SEQUENCE",
      type,
      totalCount: !allContactSelected
        ? exportData?.length
        : parseInt(totalContactCount),
      filter: {
        sequenceId: seqId,
        ...(!allContactSelected && { contactIds: exportData }),
        ...(!allContactSelected && { scheduledIds: scheduledIdData }),
        ...(allContactSelected && {
          sequenceFilter: {
            ...getSeqFilterPayload()
          }
        })
      }
    };
    return payloadObj;
  };

  const handleResumeSelectedContacts = (bulkOperationResult = {}) => {
    if (
      bulkOperationResult?.response &&
      Object.keys(bulkOperationResult?.response)?.length
    ) {
      SequenceUtils.handleSuccessFailedResponseForResume(
        props,
        "",
        bulkOperationResult?.response,
        bulkOperationResult
      );
    } else if (
      bulkOperationResult?.error &&
      Object.keys(bulkOperationResult?.error)?.length
    ) {
      SequenceUtils.handleFailedResponseForPauseResume(
        props,
        "",
        bulkOperationResult?.error
      );
    } else if (
      bulkOperationResult?.success > 0 ||
      bulkOperationResult?.failed > 0
    ) {
      confirmationPopupState.setPopupValues({
        content: (
          <div className="retryResponse">
            <MessageResponseHandler bulkOperationResult={bulkOperationResult} />
          </div>
        ),
        dynamicDom: true,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      toasterState.setToastMsg(
        "Something went wrong, Please try again later",
        "failure"
      );
    }
  };

  const showRetryConfirmationPopup = (
    background,
    bulkOperationResult,
    typeText
  ) => {
    if (background) {
      const actionTypes = {
        reactivate: "Reactivating",
        resume: "Resuming",
        retry: "Retrying"
      };
      const progressText = ["retry"].includes(typeText?.toLowerCase())
        ? "You can view the progress here"
        : "";
      const content = `${
        actionTypes[typeText?.toLowerCase()] || ""
      } contacts in progress; this may take a few to several minutes. ${progressText}`;
      confirmationPopupState.setPopupValues({
        content,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      if (typeText?.toLowerCase() === "resume") {
        handleResumeSelectedContacts(bulkOperationResult);
      } else {
        confirmationPopupState.setPopupValues({
          content: (
            <div className="retryResponse">
              <MessageResponseHandler
                bulkOperationResult={bulkOperationResult}
                typeText={typeText}
              />
            </div>
          ),
          dynamicDom: true,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
  };

  const retryContactCbk = (typeText = "") => {
    const confirmationCbk = async () => {
      const payload = getRetryAllPayload(typeText);
      const config = {
        url: URLS.performContactsBulkOperation,
        method: "POST",
        data: {
          ...payload
        }
      };
      const response = await makeApi(config);
      clearSelectedContacts();
      confirmationPopupState.setShowPopup(false);
      if (response?.data) {
        const {
          success = false,
          background = false,
          bulkOperationResult = {},
          activeJob = false
        } = response?.data || {};
        setUpdateSequenceContacts(true);
        if (activeJob) {
          SequenceUtils.showActiveJobPopupForBulkActions();
        } else if (success) {
          showRetryConfirmationPopup(background, bulkOperationResult, typeText);
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    };

    const contactCount = allContactSelected
      ? totalContactCount
      : exportData?.length;
    const currentSeq = currentSeqData || {};
    const seqName = currentSeq?.name;

    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to ${typeText} ${contactCount} ${
        contactCount > 1 ? "contacts" : "contact"
      } in ${seqName} sequence?`,
      actionBtnText: "Yes",
      cancelBtnText: "No",
      callback: confirmationCbk,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const showDeleteContactConfirmationPopup = (
    background = false,
    bulkOperationResult = {}
  ) => {
    if (background) {
      confirmationPopupState.setPopupValues({
        content: `Contacts are being removed from the sequence; this may take a few to several minutes.`, // You can view the progress here.
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      const { success = 0, failed = 0 } = bulkOperationResult || {};
      const successText = `${success} contact${
        success > 1 ? "s" : ""
      } removed successfully.`;
      const failureText = `Failed to delete ${failed} contact${
        failed > 1 ? "s" : ""
      }.`;
      let content = "";
      content += success > 0 ? successText : "";
      content += success > 0 && failed > 0 ? "<br/>" : "";
      content += failed > 0 ? failureText : "";
      confirmationPopupState.setPopupValues({
        content,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const handleResForBulkDeleteSeqContact = (res = {}) => {
    confirmationPopupState.setShowPopup(false);
    clearSelectedContacts();
    if (res?.data?.success === true) {
      const {
        background = false,
        bulkOperationResult = {},
        activeJob = false
      } = res?.data || {};
      if (activeJob) {
        SequenceUtils.showActiveJobPopupForBulkActions();
      } else {
        let reloadPage =
          background || bulkOperationResult?.success > 0 ? true : false;
        reloadPage && setUpdateSequenceContacts(true);
        showDeleteContactConfirmationPopup(background, bulkOperationResult);
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };

  const getBulkDeleteSeqContactPaylaod = () => {
    const payloadObj = {
      source: "SEQUENCE",
      type: "REMOVE_CONTACT",
      totalCount: !allContactSelected
        ? exportData?.length
        : parseInt(totalContactCount),
      filter: {
        sequenceId: seqId,
        ...(!allContactSelected && { contactIds: exportData }),
        ...(!allContactSelected && { scheduledIds: scheduledIdData }),
        ...(allContactSelected && {
          sequenceFilter: {
            ...getSeqFilterPayload()
          }
        })
      }
    };
    return payloadObj;
  };

  const bulkRemoveSeqContactAction = () => {
    const payload = getBulkDeleteSeqContactPaylaod();
    const count = allContactSelected
      ? parseInt(totalContactCount)
      : exportData?.length;
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to remove the selected contact${
        count > 1 ? "s" : ""
      }? All the scheduled emails & tasks for these contacts will be removed.`,
      actionBtnText: "Yes",
      cancelBtnText: "Cancel",
      cancelCbk: () => confirmationPopupState.setShowPopup(false),
      callback: () =>
        sequenceContactData.sequenceBulkContactDelete(
          payload,
          handleResForBulkDeleteSeqContact
        ),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const getExportSelectedPayload = (isContactListCheck = false) => {
    if (exportData) {
      const payloadObj = {
        totalCount: exportData ? exportData.length : 0
      };
      if (!isContactListCheck) {
        payloadObj.contactList = exportData;
      } else if (isContactListCheck) {
        payloadObj.contactIds = exportData;
      }
      return payloadObj;
    }
  };

  const getExportAllPayload = () => {
    if (seqId) {
      const currentStage = getCurrentStage();
      const payload = {
        sequenceId: seqId,
        all: currentStage?.toLowerCase() === "total",
        totalCount: parseInt(totalContactCount),
        sequenceFilter: getSeqFilterPayload()
      };
      return payload;
    }
    return {};
  };

  const getSelectedTab = (value = "") => {
    const temp = value ? value : getCurrentStage();
    return [temp?.toLowerCase() === "ongoing" ? "ACTIVE" : temp?.toUpperCase()];
  };

  const handleExportCsvResponse = (res = {}) => {
    if (res) {
      const { status = "", type = "", contactData = [] } = res || {};
      if (status === 200 && type === "limit_reached") {
        Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
          pageType: "Sequences",
          type: "Limit Reached"
        });
        exportProcessingState.completeAnimation();
        exportProcessingState.setShowPopup(false);
        exportEmailNotifyState.setShowPopup(true);
        clearSelectedContacts();
      } else if (contactData?.length > 0) {
        Utils.constructExportToCsvData(contactData, handleExportResponse);
      } else {
        handleExportFail();
      }
    }
  };

  const exportToSalesforceCbk = async (selectedEntity = "") => {
    const data = getRetryAllPayload("salesforceexport");
    data.data = { crmEntityType: selectedEntity?.toUpperCase() };
    exportToSalesforcePopupState.setShowPopup(false);
    exportToSalesforcePopupState.setSelectedKey("lead");
    exportProcessingState.setShowPopup(true);
    const config = {
      url: URLS.exportContactsToHubspot,
      method: "POST",
      data
    };
    const response = await makeApi(config);
    exportToSalesforcePopupState.setBtnLoader(false);
    exportProcessingState.handleSalesforceExportResponse(
      response,
      clearSelectedContacts,
      "salesforce",
      selectedEntity
    );
  };

  const resetPopupState = () => {
    exportToSalesforcePopupState.setBtnLoader(false);
    exportToSalesforcePopupState.setShowPopup(false);
    exportToSalesforcePopupState.setSelectedKey("lead");
  };

  const checkForLeadOrContact = async (
    selectedEntity = "",
    type = "salesforce"
  ) => {
    if (selectedEntity) {
      const {
        salesforceContactNotMapped = false,
        salesforceLeadNotMapped = false
      } = sequenceContactStoreData?.sfExportStatus || {};
      resetPopupState();
      if (
        (selectedEntity?.toLowerCase() === "lead" && salesforceLeadNotMapped) ||
        (selectedEntity?.toLowerCase() === "contact" &&
          salesforceContactNotMapped)
      ) {
        crmExportNotMapped(type?.toLowerCase(), `${selectedEntity}s`);
      } else {
        exportToSalesforceCbk(selectedEntity);
      }
    }
  };

  const CrmExportNotMappedContent = ({
    type = "",
    selectedEntity = "contacts"
  }) => {
    return (
      <div className="crmExportNotMapped">
        <div className="initText">
          {`Set up your field mappings to export ${selectedEntity} from Salesgear to
        ${type}.`}
        </div>
        <div className="noteText">
          Note: Only fields marked for ‘2-way sync’ or ‘outbound’ will be
          exported. Fields set as ‘inbound’ will not be exported.
        </div>
      </div>
    );
  };

  const CrmExportNotConnectedContent = ({ type = "" }) => {
    return (
      <div className="crmExportNotConnectedWrapper">
        <div className="initText">
          {`Connect your ${type} CRM to export contacts seamlessly.`}
        </div>
      </div>
    );
  };

  const crmExportNotMapped = (
    type = "hubspot",
    selectedEntity = "contacts"
  ) => {
    confirmationPopupState.setPopupValues({
      content: (
        <CrmExportNotMappedContent
          type={type}
          selectedEntity={selectedEntity}
        />
      ),
      actionBtnText: "Configure field mapping",
      cancelCbk: () => {
        exportToSalesforcePopupState.setBtnLoader(false);
      },
      callback: () => {
        localStorage.setItem(
          "goBackUrl",
          `${location.pathname}${location.hash}`
        );
        history.push(`/settings/crm/${type}?source=contact#${selectedEntity}`);
      },
      dynamicDom: true
    });
    confirmationPopupState.setShowPopup(true);
  };

  const crmExportNotConnected = (type = "hubspot") => {
    confirmationPopupState.setPopupValues({
      content: <CrmExportNotConnectedContent type={type} />,
      actionBtnText: `Connect ${type}`,
      cancelCbk: () => {
        resetPopupState();
        confirmationPopupState.setShowPopup(false);
      },
      callback: () => {
        localStorage.setItem(
          "goBackUrl",
          `${location.pathname}${location.hash}`
        );
        history.push(`/settings/crm/${type}?source=contact`);
      },
      dynamicDom: true
    });
    confirmationPopupState.setShowPopup(true);
  };

  const exportHubspotCbk = async () => {
    exportProcessingState.setShowPopup(true);
    let exportPayload = getRetryAllPayload("hubspotexport") || {};
    const config = {
      url: URLS.exportContactsToHubspot,
      method: "POST",
      data: exportPayload
    };
    const response = await makeApi(config);
    exportProcessingState.handleHubspotExportResponse(
      response,
      clearSelectedContacts,
      "hubspot"
    );
  };

  const exportCbk = async (type = "") => {
    const { isHubspotConnected = false, hubspotContactNotMapped = false } =
      sequenceContactStoreData?.hubspotExportStatus || {};
    const { isSfConnected = false } =
      sequenceContactStoreData?.sfExportStatus || {};
    if (["hubspot", "hubspotcontactexport"].includes(type?.toLowerCase())) {
      if (!hubspotIntegrationEnabled) {
        upgradePopupState.setPopupValues({ type: "exportHubspotUpgrade" });
        upgradePopupState.setPopup(true);
      } else if (!isHubspotConnected) {
        crmExportNotConnected(type?.toLowerCase());
      } else if (hubspotContactNotMapped) {
        crmExportNotMapped(type?.toLowerCase());
      } else {
        exportHubspotCbk();
      }
    } else if (
      ["salesforce", "salesforcecontactexport"].includes(type?.toLowerCase())
    ) {
      if (!salesforceIntegrationEnabled) {
        upgradePopupState.setPopupValues({ type: "exportSalesforceUpgrade" });
        upgradePopupState.setPopup(true);
      } else if (!isSfConnected) {
        crmExportNotConnected(type?.toLowerCase());
      } else {
        exportToSalesforcePopupState.setDefaultObj({
          cbk: checkForLeadOrContact
        });
        exportToSalesforcePopupState.setShowPopup(true);
      }
    } else if (
      !isHubspotConnected ||
      !isSfConnected ||
      ["csv"].includes(type?.toLowerCase()) ||
      ""
    ) {
      exportProcessingState.setShowPopup(true);
      let payload = allContactSelected
        ? getExportAllPayload()
        : getExportSelectedPayload();
      if (payload && Object.keys(payload)?.length) {
        const response = await Utils.exportContact(payload);
        if (response?.data) {
          handleExportCsvResponse(response?.data);
        } else {
          handleExportFail();
        }
      }
    }
  };

  const startSequence = async (data = {}) => {
    let settingsData = settingsStoreData?.settingsData || {};
    const { title = "", payload = {}, contacts = [] } = data || {};
    const { steps = {}, sequenceId = "", filters = {} } = payload || {};
    let contactTags = filters?.tagIds || [];
    const contactsArr = Utils.getBulkSequencePayload(contacts, true);
    const response = await Utils.scheduleSequenceForContacts(
      settingsData,
      contactsArr,
      sequenceId,
      contactTags
    );
    const {
      data: { responseCode = 500 } = {},
      response: { status = 500 } = {}
    } = response || {};
    if (+responseCode === 200) {
      Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
        sequenceName: title,
        emailCount: contacts.length,
        pageType: "Contact"
      });
      toasterState.setToastMsg("Sequence successfully scheduled", "success");
    } else if (+responseCode === 105) {
      SequenceUtils.showNoInboxconnectedPopup();
    } else if (+responseCode === 509) {
      Utils.showContactsUploadLimitReached();
    } else if (status === 426) {
      Utils.showUpgradePopup("contacts", true, true);
    } else {
      Utils.showApiFailureResponse(response);
    }
    addBulkContactPopupState.setFileName("");
  };

  const closeFieldValidatorPopup = () => {
    setShowMessage(false);
    setFieldErrorDetails("");
    setDailyLimitContactData("");
    clearSelectedContacts();
  };

  const skipAndAddContactToSequence = async (data = {}) => {
    setDailyLimitContactData(data);
    setShowMessage(false);
    startSequence(data);
    clearSelectedContacts();
  };

  const startUploadingContact = () => {
    let pluralCheck = allContactSelected
      ? "s"
      : (exportData?.length > 1 && "s") || "";
    setUploadingText(`We are processing your contacts. Please wait.`);
    setContactUploadingLoader(true);
  };

  const handleAddContactsToSeq = async (payload = {}, title = "") => {
    let tempPayload = { ...payload, sourceSequenceId: seqId };
    let res = await contactStoreData.validateDailyLimitForContacts(
      tempPayload,
      true
    );
    if (res && Object.keys(res)?.length) {
      let dataObj = { ...res, payload, title };
      setDailyLimitContactData(dataObj);
      const { contacts = [], fieldValidationResponse = {} } = res || "";
      const { missingFields = [], validCount = 0 } =
        fieldValidationResponse || {};
      if (
        fieldValidationResponse &&
        (missingFields?.length > 0 || !validCount)
      ) {
        fieldValidationResponse.contacts = contacts || [];
        setFieldErrorDetails(fieldValidationResponse);
        setShowMessage(true);
      } else {
        startSequence(dataObj);
        clearSelectedContacts();
      }
      setContactUploadingLoader(false);
      setUploadingText("");
    }
  };

  const checkAndAddContactToSequence = (objProp = {}) => {
    const { title = "", payload = {}, sequenceId = "" } = objProp || {};
    startUploadingContact();
    handleAddContactsToSeq(
      { ...payload, sequenceId, listId: customListState.activeList?.id || "" },
      title
    );
  };

  const addToSequenceAction = () => {
    let payload = allContactSelected
      ? getExportAllPayload()
      : getExportSelectedPayload(true);
    Utils.reloadFeatureFlag();
    const validateContactBeforeAddingToSequence = (
      data = {},
      sequenceId = "",
      currentSequence = {}
    ) => {
      if (currentSequence?.emailValidationEnabled) {
        let count = payload?.contactIds?.length || payload?.totalCount || 0;
        if (emailValidationEnabled && emailValidationCredits < count) {
          SequenceUtils.promptEmailValidationNoCredits(
            count - emailValidationCredits,
            history,
            () =>
              checkAndAddContactToSequence({
                ...data,
                payload,
                sequenceId
              }),
            clearSelectedContacts,
            "contact"
          );
          return;
        }
      }
      checkAndAddContactToSequence({ ...data, payload, sequenceId });
    };
    addSequenceInContactPopupState.setCallback(
      validateContactBeforeAddingToSequence
    );
    addSequenceInContactPopupState.setShowPopup(true);
  };

  const toggleContactsCheckbox = (val = false) => {
    contactsData &&
      contactsData.individualSequenceAnalyticsList &&
      contactsData.individualSequenceAnalyticsList.map(value => {
        value?.contactId && (value.isSelected = val);
      });
  };

  const clearSelectedContacts = () => {
    toggleContactsCheckbox(false);
    setExportAllContactSelected(false);
    setContactsData(contactsData);
    setExportData([]);
    setScheduledIdData([]);
  };

  const selectAllContactsCheckbox = () => {
    setExportAllContactSelected(true);
    toggleContactsCheckbox(true);
  };

  const constructContactsData = data => {
    if (data?.totalContacts > 0) {
      setTotalPages(data?.totalPages || 0);
      setContactsData(data);
      setContactsLoader(false);
    } else if (data?.totalContacts === 0) {
      setContactsData(data);
      setTotalPages(0);
      setContactsLoader(false);
    }
  };

  const isExportEnabled = () => {
    return (
      showProspects &&
      parseInt(totalContactCount) > 0 &&
      userDetail?.userFeatureFlag?.exportEnabled
    );
  };

  const getTotalCountForEachFilter = () => {
    if (
      sequenceContactData?.contactsTabCount &&
      Object.keys(sequenceContactData.contactsTabCount)?.length > 0
    ) {
      const {
        emailsOpened,
        emailsClicked,
        emailsBounced,
        totalReplyCount,
        completedCampaigns,
        ongoing,
        paused,
        contactsAdded,
        optedOut,
        emailsFailed,
        meetingBookedCount,
        liCrSentCount,
        liCrAcceptedCount,
        profileViewsCount
      } = sequenceContactData.contactsTabCount || {};

      switch (getCurrentStage()?.toLowerCase()) {
        case "total":
          setTotalContactsCount(contactsAdded);
          break;
        case "ongoing":
          setTotalContactsCount(ongoing);
          break;
        case "completed":
          setTotalContactsCount(completedCampaigns);
          break;
        case "paused":
          setTotalContactsCount(paused);
          break;
        case "opencount":
          setTotalContactsCount(emailsOpened);
          break;
        case "clickcount":
          setTotalContactsCount(emailsClicked);
          break;
        case "replied":
          setTotalContactsCount(totalReplyCount);
          break;
        case "liCrSentCount":
          setTotalContactsCount(liCrSentCount);
          break;
        case "liCrAcceptedCount":
          setTotalContactsCount(liCrAcceptedCount);
          break;
        case "profileViewsCount":
          setTotalContactsCount(profileViewsCount);
          break;
        case "failed":
          setTotalContactsCount(emailsFailed);
          break;
        case "bounced":
          setTotalContactsCount(emailsBounced);
          break;
        case "opted_out":
          setTotalContactsCount(optedOut);
          break;
        case "meeting_booked":
          setTotalContactsCount(meetingBookedCount);
          break;
        default:
          setTotalContactsCount(contactsAdded);
          break;
      }
    }
  };

  const updateNewStage = (
    updatedSequence = {},
    stepsAnalyticsResponse = []
  ) => {
    const { id = "", steps: editedSteps = {} } = updatedSequence || {};
    let stepsData = [];
    stepsData.push(editedSteps);

    if (id && editedSteps) {
      currentSeqData = updatedSequence;
      if (stepsAnalyticsResponse?.length > 0) {
        stepsAnalyticsResponse.map((item, index) => {
          editedSteps[index + 1]["analytics"] = item || {};
        });
      }

      let firstStepEmailFound = SequenceUtils.getFirstEmailstep(stepsData);
      addStagesPopupState.setIsFirstMailStepFound(firstStepEmailFound);
      setSteps(editedSteps);
    }
    return true;
  };

  const isSharedSequence = (sequenceData = currentSeqData) => {
    return sequenceData?.shareType === sharedType;
  };

  const resetSequenceRenderState = () => {
    sequenceRenderState.setContactOwnerValue(defaultContactOwner);
    sequenceRenderState.setCurrentStage("Total");
    sequenceRenderState.setFilteredTags([]);
  };

  const addBulkContactToSequence = async id => {
    DriverMaster.killDriver();
    await getSelectedSeqData();
    if (await checkTeamSubscriptionActive()) {
      if (SequenceUtils.checkAndAllowContactUpload(props, currentSeqData, id)) {
        if (SequenceUtils.inboxConnected(currentSeqData)) {
          sequenceStoreData.setAddContactLoading(true);
          await sequenceStoreData.getSenderToken(seqId);
          if (sequenceStoreData?.missingSenderTokens?.length === 0) {
            remainingContactCount() > 0
              ? Utils.showAddBulkContactToSequencePopup(
                  currentSeqData,
                  bulkUploadFormSubmitAction
                )
              : PaywallUtils.upgradeUtilsFunction(
                  "contactsAdded",
                  false,
                  planName,
                  inFreePlan,
                  { contactCount: noOfContactsEligible }
                );
          } else {
            SequenceUtils.showMissingSenderTokenPopup(props);
          }
        } else {
          SequenceUtils.showNoInboxconnectedPopup(props, "#settings");
        }
      }
    }
  };

  const resetToSelectedCheckbox = () => {
    let count = 0;
    contactsData?.individualSequenceAnalyticsList?.map(value => {
      value.isSelected = exportData?.indexOf(value?.contactId) !== -1;
      value.isSelected && count++;
    });
    setContactsData(contactsData);
    setAllContactSelected(false);
    setExportAllContactSelected(
      contactsData?.individualSequenceAnalyticsList?.length === count
    );
  };

  const getDefaultScheduleData = () => {
    if (
      throttlingData?.scheduleWindowId &&
      scheduleDataStore?.scheduleData?.data
    ) {
      const tempData = scheduleDataStore?.scheduleData?.data;
      const filteredData = tempData?.filter(
        item => item.id === throttlingData?.scheduleWindowId
      )?.[0];
      scheduleDataStore.setDefaultData({ ...filteredData });
    }
  };

  const getTotalDaysFromStart = () => {
    let daysFromStart = 1;
    if (steps && Object.keys(steps)?.length) {
      Object.keys(steps).map(key => {
        daysFromStart += steps[key]?.daysLater || 0;
      });
    }
    return daysFromStart;
  };

  const stepBadgeAction = e => {
    const { target = "" } = e;
    const uniqueid = target.getAttribute("data-uniqueid") || "";
    const fieldName = target.getAttribute("data-id") || "";
    const id = target.getAttribute("id") || "";
    if (uniqueid && id) {
      setContactsLoader(true);
      setFromStepBadgeNavigation(true);
      sequenceRenderState.setFilterByStep(+uniqueid);
      sequenceRenderState.setCurrentStage(id);
      setExportData([]);
      setScheduledIdData([]);
      let payload = {
        campaignId: seqId,
        limit: contactLimit,
        filters: {
          ...sequenceContactStoreData?.filterPayload?.filters,
          stepMetrics: uniqueid ? [+uniqueid] : []
        }
      };
      sequenceContactStoreData.setIsOverviewToContactsPage(true);
      if (Utils.isEligibleContactFilter(id)) {
        payload.filters.statuses = SequenceUtils.getSelectedTab(id);
      } else {
        if (fieldName) {
          let fields = [
            {
              fieldName,
              operator: "GREATER_THAN",
              value: 0
            }
          ];
          sequenceContactStoreData.setFilterPayload({
            ...sequenceContactStoreData?.filterPayload,
            filters: {
              ...sequenceContactStoreData?.filterPayload?.filters,
              fields
            }
          });
          payload.filters.fields = fields;
        }
      }
      sequenceContactStoreData.setOverallPayload(payload);
      confirmationCbk("prospects");
      sequenceContactData.getSeqContacts(payload);
    }
  };

  const sequenceTabNavList = [
    {
      id: "overview",
      name: "Overview",
      active: showOverview,
      className: "overViewCont",
      action: getActiveTab
    },
    {
      id: "analytics",
      name: "Analytics",
      active: showAnalytics,
      className: "analyticsCont",
      action: getActiveTab
    },
    {
      id: "prospects",
      name: "Contacts",
      active: showProspects,
      className: "prospectsCont",
      action: getActiveTab
    },
    {
      id: "settings",
      name: "Configurations",
      active: showSettings,
      className: "settingsCont",
      action: getActiveTab
    }
  ];

  const handleSequenceUpdated = async () => {
    if (addStagesPopupState.sequenceUpdated) {
      !showStageLoader && setShowStageLoader(true);
      let stepsAnalyticsResponse = await getStepsAnalytics();

      let value = updateNewStage(
        addStagesPopupState.selectedSequence,
        stepsAnalyticsResponse
      );
      value && afterCreateUpdateCbk();
      addStagesPopupState.setSequenceUpdated(false);
      setAnalyticStepLoader(false);
    }
  };

  const handleMoveStageAction = async (data = {}) => {
    !showStageLoader && setShowStageLoader(true);
    if ([200, "200"]?.includes(data?.status)) {
      Utils.mixpanelTrack(MP_EVENT.SQ_STAGE_MOVED, {
        sequenceName: currentSeqData?.name,
        direction: action === "moveUp" ? "up" : "down",
        pageType: "Sequence"
      });
      let stepsAnalyticsResponse = await getStepsAnalytics();
      updateNewStage(
        addStagesPopupState.selectedSequence,
        stepsAnalyticsResponse
      );
      toasterState.setToastMsg("Step moved successfully", "success");
    }
    setShowStageLoader(false);
    setAnalyticStepLoader(false);
  };

  const resetFilterPayload = () => {
    sequenceContactStoreData.setFilterPayload({
      ...defaultFilterPayload
    });
    sequenceContactStoreData.setOverallPayload({});
    sequenceContactStoreData.setIsOverviewToContactsPage(false);
  };

  const performActionOnHashValueChange = () => {
    let isContactsPage = hashValue === "#contacts";
    (!isFromStepBadgeNavigation || !isContactsPage) &&
      sequenceRenderState.setCurrentStage("Total");
    !isContactsPage && resetFilterPayload();
    setlocationState();
    sequenceRenderState.setFilteredTags([]);
    getSelectedSeqData();
  };

  const constructPhoneSummary = phoneResponse => {
    return phoneResponse?.callOutcomeSummary
      ? phoneResponse?.callOutcomeSummary
      : [];
  };

  const getStatisticsData = data => {
    if (data && Object.keys(data)?.length) {
      setRenderAnalyticsData(data);
      setContactUploading(data?.contactUploadStatus);
      data.phoneSummary && constructPhoneSummary(data.phoneSummary);
      setAnalyticsLoader(false);
    }
  };

  const handleStageClickCbk = param => {
    if (param?.key === "email" || taskEnabled) {
      let isTask = ["linkedin", "phone", "customtask", "linkedinauto"].includes(
        param?.key?.toLowerCase()
      );

      addStagesPopupState.setPopupValues({
        pageSource: "Sequences",
        btnText: isTask ? "Save" : "Next",
        currentStep: ((steps && Object.keys(steps)?.length) || 0) + 1,
        stageType: param?.key,
        daysFromStart: getTotalDaysFromStart() + 1
      });
      addStagesPopupState.setShowPopup(true);
    }
  };

  const handleStageClick = param => {
    if (param?.key?.toLowerCase() === "linkedinauto") {
      if (SequenceUtils.isLinkedinAccountConnected()) {
        handleStageClickCbk(param);
      } else {
        Utils.showAddLinkedInAccountPopup(props?.history);
      }
    } else {
      handleStageClickCbk(param);
    }
  };

  useEffect(() => {
    settingsStoreData.getUserProfile();
    userDetail?.userInfo?.team && getTeamStatus();
    getSelectedSeqData();
    fetchLinkedInAccounts();
    return () => {
      sequenceRenderState.setCurrentStage("Total");
      resetFilterPayload();
    };
  }, []);

  useEffect(() => {
    settingsData = settingsStoreData.settingsData;
  }, [settingsStoreData.settingsData]);

  useEffect(() => {
    performActionOnHashValueChange();
  }, [hashValue]);

  useEffect(() => {
    seqIdFromURL && sequenceStoreData.getSequenceStatusBySeqId(seqIdFromURL);
    seqIdFromURL && scheduleDataStore.getScheduleData();
    resetContactOwnedFilter();
    resetSequenceRenderState();
  }, [seqIdFromURL]);

  useEffect(() => {
    constructContactsData(sequenceContactData.contactsData);
    setExportAllContactSelected(false);
  }, [sequenceContactData.contactsData]);

  useEffect(() => {
    getTotalCountForEachFilter();
    getStatisticsData(sequenceContactData.contactsTabCount);
  }, [sequenceContactData.contactsTabCount]);

  useEffect(() => {
    allContactSelected &&
      (exportData?.length === 0 || !exportData) &&
      setAllContactSelected(false);
    contactsData?.individualSequenceAnalyticsList?.length ===
      exportData?.length && setExportAllContactSelected(true);
  }, [exportData]);

  useEffect(() => {
    getDefaultScheduleData(scheduleDataStore?.scheduleData);
  }, [throttlingData, scheduleDataStore.scheduleData]);

  useEffect(() => {
    sequenceContactData.getSeqAnalyticsTab(
      seqId,
      sequenceContactStoreData?.filterPayload?.filters || {}
    );
  }, [updateSequenceContacts]);

  useEffect(() => {
    handleSequenceUpdated();
  }, [addStagesPopupState.sequenceUpdated]);

  return (
    <>
      <section className="sequenceProperties">
        {showContactUploadingLoader && (
          <ContactUploading msg={uploadingText} title="Add to sequence" />
        )}
        {showMessage && (
          <FieldValidatorMsgPopup
            fieldResponse={fieldErrorDetails}
            close={closeFieldValidatorPopup}
            skipAndAddContactCbk={skipAndAddContactToSequence}
            setSkipLoader={false}
            data={dailyLimitContactData}
            title="Add to sequence"
            isSequence={true}
          />
        )}
        {showInitialActivateLoader || showActivateLoader ? (
          <ActivateSequenceLoader />
        ) : currentSeqData?.status?.toLowerCase() === "draft" ? (
          <ActivateSequence
            seqData={currentSeqData}
            status={currentSeqData?.status?.toLowerCase()}
            sequenceToggleBtn={sequenceToggleBtn}
            showSequenceToggleBtnLoad={showSequenceToggleBtnLoad}
            getSelectedSeqData={getSelectedSeqData}
            {...throttlingData}
            loader={throttlingLoader}
            setThrottlingData={setThrottlingData}
            accessType={accessType}
            isSharedSequence={isSharedSequence()}
            setSavedChanges={setSavedChanges}
            sequenceId={seqId}
            setShowActivateLoader={setShowActivateLoader}
            {...props}
          />
        ) : (
          <ActivatedSequence
            seqData={currentSeqData}
            status={currentSeqData?.status?.toLowerCase()}
            sequenceToggleBtn={sequenceToggleBtn}
            showSequenceToggleBtnLoad={showSequenceToggleBtnLoad}
            getSelectedSeqData={getSelectedSeqData}
            steps={steps}
            taskEnabled={taskEnabled}
            planName={planName}
            checkBoxToggleAction={checkBoxToggleAction}
          />
        )}
        <div className="propertiesHeader">
          <div className="headerInnerCont headerStyle">
            <>
              {sequenceTabNavList.map(item => (
                <SequenceTabNav key={item.id} {...item} />
              ))}
              <div className="animated-border-cont">
                <div id="animated-border" className="animated-border"></div>
              </div>
            </>
          </div>
          <div className="rightSectionHeader">
            {showContactOwnerBtn &&
              showContactOwner &&
              isSharedSequence(currentSeqData) && (
                <ContactOwnerButton
                  name="Contact Owner"
                  icon={<UserIcon />}
                  arrow={true}
                />
              )}
            <AddContactDropDown
              stages={steps}
              addSingleContact={addSingleContact}
              addBulkContact={addBulkContactToSequence}
              showButton={true}
              disabled={showStageLoader || throttlingLoader}
              allowContactsUpload={!hasTask || (hasTask && taskEnabled)}
              addContactLoading={sequenceStoreData.addContactLoading}
              allowSequence={false}
              sequence={currentSeqData}
            />
            {showOverview && (
              <div
                className="addStepsButton"
                id="addStages"
                onClick={e => DriverMaster.killDriver()}
              >
                <AddStepBtn
                  disabled={showStageLoader}
                  daysFromStart={getTotalDaysFromStart()}
                  seqId={seqId}
                  flag="sequence"
                  totalContactCount={totalContactCount}
                  stepLength={(steps && Object.keys(steps).length) || 0}
                  showDropDownMenu={showDropDownMenu}
                  setShowDropDownMenu={setShowDropDownMenu}
                  cbk={handleStageClick}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`propertiesBody ${
            showProspects
              ? "prospectsContainer"
              : showAnalytics
              ? "analyticsSection"
              : showSettings
              ? "throttlingSection"
              : ""
          }`}
        >
          {showOverview && (
            <div
              className={`overviewBody ${
                Object.keys(steps).length === 0 && !showStageLoader
                  ? "noStage"
                  : ""
              }`}
            >
              <div className="stagesList">
                {showStageLoader &&
                  Array.from({ length: 4 }, (value, index) => (
                    <div className="stagesCont" key={"stageLoader" + index}>
                      <StageLoader line={4 - index > 1 ? true : false} />
                    </div>
                  ))}
                {!showStageLoader && (
                  <Steps
                    showStageLoader={showStageLoader}
                    steps={steps}
                    seqId={seqId}
                    currentSeqData={currentSeqData}
                    setSteps={setSteps}
                    handleMoveStageAction={handleMoveStageAction}
                    setShowStageLoader={setShowStageLoader}
                    analyticStepLoader={analyticStepLoader}
                    setAnalyticStepLoader={setAnalyticStepLoader}
                    getStepsAnalytics={getStepsAnalytics}
                    getSelectedSeqData={getSelectedSeqData}
                    stepBadgeAction={stepBadgeAction}
                  />
                )}
              </div>
              {!showStageLoader && (
                <>
                  <div className="addStepHeader">
                    <div
                      className="stepHeaderCont"
                      onClick={() => setShowDropDownMenu(true)}
                    >
                      <StageIcon />
                      <span className="addStepName">Add Step</span>
                      <i className="material-icons arrowIcon">
                        keyboard_arrow_down
                      </i>
                    </div>
                    <AddStageDropDown
                      showDropDownMenu={showDropDownMenu}
                      setShowDropDownMenu={setShowDropDownMenu}
                      cbk={handleStageClick}
                      considerStepLen={true}
                      stepLen={(steps && Object.keys(steps).length) || 0}
                      className={"addStepDropdown"}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {showAnalytics && (
            <div className="analyticsBody">
              <SequenceStat
                seqId={seqId}
                sequence={currentSeqData}
                renderAnalyticsData={renderAnalyticsData}
                analyticsLoader={analyticsLoader}
                setAnalyticsLoader={setAnalyticsLoader}
                isContactUploading={isContactUploading}
                updateSequenceContacts={updateSequenceContacts}
                setUpdateSequenceContacts={setUpdateSequenceContacts}
              />
            </div>
          )}
          {showProspects && (
            <div className="prospectsBody">
              <SequenceContacts
                seqId={seqId}
                sequence={currentSeqData}
                exportAllContactSelected={exportAllContactSelected}
                setExportAllContactSelected={setExportAllContactSelected}
                setExportData={setExportData}
                setScheduledIdData={setScheduledIdData}
                setContactsData={setContactsData}
                contactsData={contactsData}
                loader={contactLoader}
                setLoader={setContactsLoader}
                totalPages={totalPages}
                setCurrentStage={setCurrentStage}
                selectedContactCard={selectedContactCard}
                closeCbk={closeCbk}
                totalContacts={parseInt(totalContactCount)}
                contactIds={exportData}
                resetContactCbk={clearSelectedContacts}
                selectAllCbk={selectAllContactsCheckbox}
                selectedEntityCount={exportData.length}
                exportCbk={exportCbk}
                retryContactCbk={() => retryContactCbk("retry")}
                bulkRemoveSeqContactCbk={bulkRemoveSeqContactAction}
                bulkReactivateSeqContactCbk={() =>
                  retryContactCbk("reactivate")
                }
                bulkResumeSeqContactCbk={() => retryContactCbk("resume")}
                exportEnabled={isExportEnabled()}
                activeTab={getCurrentStage().toLowerCase()}
                addSingleContact={addSingleContact}
                addBulkContact={addBulkContactToSequence}
                allContactSelected={allContactSelected}
                setAllContactSelected={setAllContactSelected}
                contactLimit={contactLimit}
                setContactLimit={setContactLimit}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                resetContactOwnedFilter={resetContactOwnedFilter}
                resetToSelectedCheckboxCbk={resetToSelectedCheckbox}
                updateSequenceContacts={updateSequenceContacts}
                setUpdateSequenceContacts={setUpdateSequenceContacts}
                isFromStepBadgeNavigation={isFromStepBadgeNavigation}
                setFromStepBadgeNavigation={setFromStepBadgeNavigation}
                {...props}
                allowContactsUpload={
                  steps && (!hasTask || (hasTask && taskEnabled))
                }
                sequenceToggleBtn={sequenceToggleBtn}
                scheduledIdData={scheduledIdData}
                folderId={propsMatchParam?.folderId}
                showContactOwner={showContactOwner}
                isSharedSequenceWithUser={isSharedSequence(currentSeqData)}
                addToSequenceAction={addToSequenceAction}
                reloadSequenceTabs={reloadSequenceTabs}
                getTotalCountForEachFilter={getTotalCountForEachFilter}
              />
            </div>
          )}
          {showSettings && (
            <SequenceThrottlingSettings
              sequenceId={seqId}
              {...throttlingData}
              loader={throttlingLoader}
              setThrottlingData={setThrottlingData}
              accessType={accessType}
              isSharedSequence={isSharedSequence()}
              setSavedChanges={setSavedChanges}
              sequence={currentSeqData}
              {...props}
            />
          )}
        </div>
      </section>
      {sequenceStoreData?.seqActivated && (
        <div
          className="firstContactAddedOverlay"
          onClick={() => sequenceStoreData.setSeqActivated(false)}
        />
      )}
    </>
  );
};

export default withRouter(observer(SequenceProperties));
export { SequenceProperties, sequenceRenderState };
