/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable array-callback-return */
/* eslint-disable no-fallthrough */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React, { useState } from "react";
import makeApi, { URLS } from "./apiURL";
import userDetail from "Stores/userDetail";
import { toasterState } from "../Components/common/toaster";
import contactStoreData from "Stores/contactData";
import ConstantObj, {
  MP_EVENT,
  CspValue,
  TOKENS,
  TOKENS_FALLBACK_OBJ,
  TOKENS_HIGHLIGHT_OBJ,
  CSV_HEADER_ORDER_LIST,
  CSV_PLACEHOLDER_OBJ,
  NEW_STAGE_ANALYTICS_DATE,
  ALPHABETS_REGEX,
  TEST_EMAIL_CONNECT_INBOX_MSG,
  getCustomFieldsCRM
} from "Static/constant";
import { SENDER_TOKENS } from "Model/model";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import onboardingStoreData from "Stores/onBoardingData";
import DriverMaster from "Components/driver/Driver";
import {
  settingsSteps,
  createNewSequenceSteps,
  addContactSteps,
  createNewTemplateSteps,
  addNewContactsSteps,
  startSearchingDriverStep
} from "Components/driver/DriverSteps";
import { addStagesSteps } from "Components/driver/DriverSteps";
import { IntercomTour } from "Static/constant";
import { sequenceStoreData } from "Stores/sequenceData";
import { ADD_CSV_CONTACT_HEADERS } from "Model/model";
import TemplateDataStore from "Stores/Template";
import Tooltip from "Components/common/Tooltip";
import { comingSoonPopupState } from "Components/common/ComingSoonPopup";
import { settingsStoreData } from "Stores/settingsData";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import { addSingleContactPopupState } from "Components/common/AddSingleContactPopup";
import { tagPopupState } from "Components/common/tags/TagPopup";
import { fileLimitExceedState } from "Components/FileAttachment/FileLimitExceedPopup";
import InboxUtils from "./InboxUtils";
import TokenUtils from "./TokenUtils";
import { fetchConnectedEmails, getFeatureFlagWithTeam } from "./commonAPI";
import { SequenceUtils } from "Utils/SequenceUtils";
import { messagePopupState } from "Pages/Dashboard/settings/MessagePopup";
import DateUtils from "./DateUtils";
import { addStagesPopupState } from "../Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import onboarding from "../Components/common/Onboarding";

const Utils = {};
// eslint-disable-next-line no-useless-escape

Utils.getDateForTemplates = dateStr => {
  let date = new Date(dateStr);
  let options = {
    month: "short",
    day: "numeric"
  };
  return date.toLocaleDateString("en-US", options);
};

Utils.getPhoneObj = phoneData => {
  let phoneMap = {};
  for (let temp of phoneData) {
    const key = temp["_id"];
    const value = temp["total"];
    phoneMap[key] = value;
  }
  return phoneMap;
};

Utils.getFolderDetails = () => {
  const targetDom = document.querySelector(".folder.selected");
  let folderDetails = {};
  if (targetDom) {
    folderDetails.folderName = targetDom.getAttribute("data-name");
    folderDetails.folderId = targetDom.getAttribute("data-id");
  }
  return folderDetails;
};

Utils.setPaginationDefaults = () => {
  sequenceStoreData.setDynamicCurrentPage(1);
  sequenceStoreData.setDynamicPageLimit(15);
  sequenceStoreData.setPageLimit(15);
  sequenceStoreData.setCurrentPage(1);
  sequenceStoreData.setPaginationDataLoading(true);
};

Utils.constructPayload = (additionalInfo = {}) => {
  return {
    ...sequenceStoreData.filterPayload,
    pageNo: sequenceStoreData?.dynamicCurrentPage,
    limit: sequenceStoreData?.dynamicPageLimit,
    ...additionalInfo
  };
};

Utils.constructPaginationLimitPayload = () => {
  return {
    ...sequenceStoreData.filterPayload,
    limit: sequenceStoreData?.pageLimit,
    pageNo: sequenceStoreData?.currentPage
  };
};

Utils.getFolderSequenceResponse = async (
  folderId,
  cbk,
  externalPayload = null
) => {
  try {
    if (folderId?.length) {
      let targetURL = URLS.getMySequenceData;

      const config = {
        url: targetURL,
        method: "POST",
        data: externalPayload
      };
      Utils.cancelPreviousSequenceRequest();
      let res = await makeApi(
        config,
        sequenceStoreData.cancelSequenceCallAction
      );
      if (res && res?.data) {
        const {
          sequences = [],
          totalPages = 1,
          currentPage = 1,
          totalSequences = 1
        } = res?.data || {};
        if (sequences) {
          cbk((sequences?.length && sequences) || []);
        } else {
          Utils.showApiFailureResponse(res);
        }

        if (totalSequences) {
          sequenceStoreData.setTotalSequences(totalSequences);
        }
        if (currentPage) {
          sequenceStoreData.setCurrentPage(currentPage);
        }

        if (totalPages) {
          sequenceStoreData.setTotalPage(totalPages);
        }
      }
    }
  } catch (e) {
    console.error("Get Folder Data error ", e);
  }
};

Utils.getCurrentUserTimeZone = () => {
  const date = new Date();
  const time = Utils.getTimeInString(date.getTimezoneOffset());
  let sign = "+";
  if (date.getTimezoneOffset() > 0) {
    sign = "-";
  }
  return "UTC" + sign + time;
};

Utils.getTimeInString = a => {
  a = Math.abs(a);
  let hours = Math.trunc(a / 60).toString();
  hours = ("0" + hours).slice(-2);
  let minutes = ("0" + (a % 60)).slice(-2);
  return hours + ":" + minutes;
};

Utils.checkSeqStatus = async (
  type,
  id,
  cbk,
  msg,
  activeStatusCbk,
  showPrompt = false
) => {
  try {
    let targetURL = URLS.checkSeqStatus;
    targetURL = targetURL + id;
    const config = {
      url: targetURL,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response.data) {
      const { requestStatus, modifyAccess, message } = response.data;
      const isSequenceActive = requestStatus;
      const hasModifyAccess = modifyAccess;

      if (hasModifyAccess) {
        if (isSequenceActive && type !== "editActiveStep") {
          activeStatusCbk && activeStatusCbk();
          if (showPrompt) {
            confirmationPopupState.setPopupValues({
              content: msg,
              actionBtnText: "Ok",
              needCancelBtn: false,
              callback: () => confirmationPopupState.setShowPopup(false)
            });
            confirmationPopupState.setShowPopup(true);
          } else {
            toasterState.setToastMsg(msg, "failure");
          }
        } else {
          cbk && cbk(id);
        }
      } else {
        activeStatusCbk && activeStatusCbk();
        toasterState.setToastMsg(message, "failure");
      }
    } else {
      activeStatusCbk && activeStatusCbk();
      Utils.showApiFailureResponse(response);
    }
  } catch (error) {
    console.error("Check seq status error ", error);
  }
};

Utils.getEditableText = (value, field) => {
  const input = document.createElement("input");
  input.type = "text";
  if (!value) value = "";
  input.value = value.trim();
  input.setAttribute("required", true);
  input.setAttribute("title", "Click here to edit");
  input.setAttribute("header_field", field);
  if (!input.value) {
    showError(input);
  }
  input.addEventListener("focus", () => {
    input.style.background = "none";
  });
  input.addEventListener("blur", () => {
    if (input.value.length < 1) {
      showError();
    }
  });
  function showError() {
    input.style.background = "#ffc7c7";
  }
  return input;
};

Utils.convertArrStringToLowerCase = list => {
  const newArr = [];
  if (list && list !== "all" && list.length) {
    list.map(item => {
      newArr.push(item.toLowerCase());
    });
  }
  return newArr;
};

Utils.createCsvHeaderOrdering = object => {
  const defaultCsvObj = Utils.createObjWithoutRef(CSV_HEADER_ORDER_LIST);
  return { ...defaultCsvObj, ...object };
};

Utils.getPlacholderCsvText = field => {
  if (field) {
    const placeholderText = Utils.createObjWithoutRef(CSV_PLACEHOLDER_OBJ);
    return placeholderText[field];
  }
  return "";
};

Utils.checkCsvRowIsNotEmpty = obj => {
  let count = 0;
  if (obj && Object.keys(obj).length) {
    let newObjArr = Object.values(obj);
    newObjArr.map(value => {
      if (!value || (value && value.trim() === "")) {
        count += 1;
      }
    });
    if (count < newObjArr.length) {
      return true;
    }
  }
  return false;
};

Utils.removeEmptyRecordFromCsv = csvlist => {
  const newCsvlist = [];
  if (csvlist && csvlist.length) {
    csvlist.map(item => {
      if (Utils.checkCsvRowIsNotEmpty(item)) {
        newCsvlist.push(item);
      }
    });
  }
  return newCsvlist;
};

Utils.createInputFields = (inputArr, targetDom, fromFlag, cbk, tokenList) => {
  if (inputArr && inputArr.length && targetDom) {
    let tokenArrList = [];
    if (tokenList && (tokenList !== "all" || tokenList.length)) {
      tokenArrList = Utils.convertArrStringToLowerCase(tokenList);
    }
    if (fromFlag && fromFlag === "csv") {
      for (let i = 0; i < inputArr.length; i++) {
        if (Utils.checkCsvRowIsNotEmpty(inputArr[i])) {
          const parentDiv = document.createElement("div");
          parentDiv.classList.add("addRecipientContainer");
          // remove btn
          const deleteBut = document.createElement("div");
          deleteBut.innerHTML =
            "<div class='ae-td-close' title='Remove Prospect'><i class='material-icons delete' event-type='delete_contact'>delete_outline</i></div>";
          let deleteContact = deleteBut.querySelector(
            "i[event-type='delete_contact']"
          );
          deleteContact.addEventListener("click", () => {
            parentDiv.remove();
            if (
              document.querySelector(".dataPreviewCont") &&
              document.querySelector(".dataPreviewCont").childElementCount === 0
            ) {
              cbk && cbk(true); // Showing file upload dom
            }
          });
          parentDiv.appendChild(deleteContact);
          const currentInputObj = Utils.createCsvHeaderOrdering(inputArr[i]);
          if (currentInputObj) {
            const keys = Object.keys(currentInputObj);
            const keysLen = keys.length;
            const values = Object.values(currentInputObj);
            const csvHeaders = ADD_CSV_CONTACT_HEADERS;
            for (let i = 0; i < keysLen; i++) {
              if (keys[i] && csvHeaders && csvHeaders.includes(keys[i])) {
                const inputCont = document.createElement("div");
                inputCont.classList.add("inputCont");
                const input = document.createElement("input");
                input.type = "text";
                input.value = values[i];
                if (
                  keys[i] === "email" ||
                  (tokenArrList &&
                    (tokenList === "all" ||
                      (tokenArrList.length &&
                        tokenArrList.indexOf(keys[i]) !== -1)))
                ) {
                  input.setAttribute("required", true);
                }
                input.setAttribute(
                  "placeholder",
                  Utils.getPlacholderCsvText(keys[i])
                );
                input.setAttribute("header_field", keys[i]);
                input.classList.add("addContactInput");
                inputCont.appendChild(input);
                parentDiv.appendChild(inputCont);
              }
            }
          }
          targetDom.prepend(parentDiv);
        }
      }
    } else {
      const parentDiv = document.createElement("div");
      parentDiv.classList.add("addRecipientContainer");
      // remove btn
      const deleteBut = document.createElement("div");
      deleteBut.innerHTML =
        "<div class='ae-td-close' title='Remove Prospect'><i class='material-icons delete' event-type='delete_contact'>delete_outline</i></div>";
      let deleteContact = deleteBut.querySelector(
        "i[event-type='delete_contact']"
      );
      deleteContact.addEventListener("click", () => {
        parentDiv.remove();
        if (
          document.querySelector(".dataPreviewCont") &&
          document.querySelector(".dataPreviewCont").childElementCount === 0
        ) {
          cbk(true); // Showing file upload dom
        }
      });
      parentDiv.appendChild(deleteContact);
      for (let i = 0; i < inputArr.length; i++) {
        const currentInputObj = inputArr[i];
        if (currentInputObj && currentInputObj.type) {
          const inputCont = document.createElement("div");
          inputCont.classList.add("inputCont");
          const input = document.createElement("input");
          input.type = currentInputObj.type ? currentInputObj.type : "text";
          input.value = "";
          if (currentInputObj.required || (tokenList && tokenList === "all")) {
            input.setAttribute("required", true);
          }
          if (currentInputObj.placeholder) {
            input.setAttribute("placeholder", currentInputObj.placeholder);
          }
          input.setAttribute("header_field", currentInputObj.id);
          input.classList.add("addContactInput");
          inputCont.appendChild(input);
          parentDiv.appendChild(inputCont);
        }
      }
      targetDom.prepend(parentDiv);
    }
    Utils.checkAndEnableNextBtn();
  }
};

Utils.checkAndEnableNextBtn = () => {
  if (
    document.querySelector(".dataPreviewCont") &&
    document.querySelector(".dataPreviewCont").hasChildNodes()
  ) {
    document.querySelector(".nextBtn").classList.remove("disabled");
  } else if (document.querySelector(".nextBtn")) {
    document.querySelector(".nextBtn").classList.add("disabled");
  }
};

Utils.getUserModifiedCsvData = () => {
  let json = [];
  const ele = document.querySelector(".dataPreviewCont");
  const trs = ele.querySelectorAll(".addRecipientContainer");
  for (let tr of trs) {
    let obj = {};
    const tds = tr.querySelectorAll(".inputCont");
    for (let td of tds) {
      const input = td.querySelector("input");
      if (input) {
        obj[input.getAttribute("header_field")] = input.value;
      }
    }
    if (obj && Object.keys(obj).length) {
      json.push(obj);
    }
  }
  return json;
};

Utils.formatNumberToStringInUSFormat = (num = 0) => {
  if (!num || isNaN(num)) {
    return 0;
  } else {
    return num.toLocaleString("en-US");
  }
};

Utils.formatNumberToString = (num = 0) => {
  if (!num || isNaN(num)) {
    return 0;
  }
  let digit;
  if (num < 9999) {
    digit = num?.toLocaleString();
  } else if (num < 1000000) {
    digit = Math.round(num / 1000) + "K";
  } else if (num < 10000000) {
    digit = (num / 1000000).toFixed(2) + "M";
  } else if (num < 1000000000) {
    digit = Math.round(num / 1000000) + "M";
  } else if (num < 1000000000000) {
    digit = Math.round(num / 1000000000) + "B";
  } else {
    return "1T+";
  }
  let value = Utils.numberWithCommas(digit);
  return value;
};

Utils.numberWithCommas = num => {
  num = num.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(num)) {
    num = num.replace(pattern, "$1,$2");
  }
  return num;
};

Utils.formatDate = date => {
  if (date) {
    let d = new Date(date);
    return `${
      ConstantObj.months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()}`;
  }
  return "--";
};

Utils.getDateFromString = (dateStr = "") => {
  if (dateStr) {
    let newDateStr = dateStr?.split("T")?.[0] || "";
    let dateArr = newDateStr?.split("-") || [];
    return (
      (dateArr?.length &&
        `${ConstantObj.months[+dateArr[1] - 1]} ${dateArr[2]}, ${
          dateArr[0]
        }`) ||
      "--"
    );
  }
  return "--";
};

Utils.getFormattedDateToDisplay = date => {
  date = new Date(date);
  let options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  return date.toLocaleDateString("en-US", options);
};

Utils.checkPreviousBreadCrumbValue = (cureSeqName, breadcrumbArr) => {
  const previousBreadCrumbValue = Utils.createObjWithoutRef(breadcrumbArr);
  const previousBreadCrumbValueLen = previousBreadCrumbValue.length;
  let tempFlag = true;
  for (let i = 0; i < previousBreadCrumbValueLen; i++) {
    if (previousBreadCrumbValue?.[i]?.name === cureSeqName) {
      tempFlag = false;
    }
  }
  return tempFlag;
};

Utils.addSpaceInHtmlToStringConversion = contentChildNodes => {
  const childNodesLen =
    contentChildNodes && contentChildNodes.childNodes
      ? contentChildNodes.childNodes.length
      : 0;
  let constructedString = "";
  if (childNodesLen) {
    for (let i = 0; i < childNodesLen; i++) {
      const childrenNode = contentChildNodes.children
        ? contentChildNodes.children[i]
        : "";
      if (childrenNode) {
        if (childrenNode.innerHTML) {
          const innerHTML = childrenNode.innerHTML;
          const tempDom = document.createElement("div");
          tempDom.innerHTML = innerHTML;
          const tempDomChildNodes = tempDom.childNodes ? tempDom.childNodes : 0;
          if (tempDom) {
            for (let j = 0; j < tempDomChildNodes.length; j++) {
              const childrenNode = tempDom.children ? tempDom.children[j] : "";
              const innerText = childrenNode && childrenNode.innerText;
              constructedString += ` ${innerText}`;
            }
          }
        }
      }
    }
    return constructedString;
  }
  return "";
};

Utils.removeInputRequired = e => {
  document.addEventListener(
    "invalid",
    (() => {
      return function (e) {
        e.preventDefault();
      };
    })(),
    true
  );
};

Utils.emailValidate = email => {
  return ConstantObj.emailRegex.test(email);
};

Utils.domainCheck = domain => {
  var re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  );
  return domain.match(re);
};

Utils.CheckElemInArray = (value, arr) => {
  if (arr && arr.length > 0 && value && value.length > 0) {
    return arr.indexOf(value) !== -1 ? true : false;
  }
  return false;
};

Utils.validateStringLength = (value, len) => {
  let str = value?.trim();
  return str?.length > 0 && str?.length >= len;
};

Utils.validatePhoneNumberLength = (value, len) => {
  let str = value
    ?.trim()
    .replace("-", "")
    .replace("(", "")
    .replace(")", "")
    .replace("+", "")
    .replace(/ /g, "");
  return str?.length > 0 && str?.length >= len;
};

Utils.getUsersTimeZone = () => {
  let currentTimeInGmt =
    new Date().toString().slice(25, 31) +
    ":" +
    new Date().toString().slice(31, 33);
  const zoneNames = [
    "Eniwetok, Kwajalein",
    "Midway Island, Samoa",
    "Hawaii",
    "Alaska",
    "Pacific Time US/Canada",
    "Mountain Time US/Canada",
    "Central Time US/Canada, Mexico City",
    "Eastern Time US/Canada, Bogota, Lima",
    "Atlantic Time Canada, Caracas, La Paz",
    "Newfoundland",
    "Brazil, Buenos Aires, Georgetown",
    "America/St_Johns",
    "Mid-Atlantic",
    "Azores, Cape Verde Islands",
    "Western Europe Time, London, Lisbon, Casablanca",
    "Brussels, Copenhagen, Madrid, Paris",
    "Kaliningrad, South Africa",
    "Baghdad, Riyadh, Moscow, St. Petersburg",
    "Tehran",
    "Abu Dhabi, Muscat, Baku, Tbilisi",
    "Kabul",
    "Ekaterinburg, Islamabad, Karachi, Tashkent",
    "Chennai, Mumbai, New Delhi, Kolkata",
    "Kathmandu",
    "Almaty, Dhaka, Colombo",
    "Asia/Rangoon",
    "Bangkok, Hanoi, Jakarta",
    "Beijing, Perth, Singapore, Hong Kong",
    "Asia/Pyongyang",
    "Australia/Eucla",
    "Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    "Adelaide, Darwin",
    "Eastern Australia, Guam, Vladivostok",
    "Australia/LHI",
    "Magadan, Solomon Islands, New Caledonia",
    "Auckland, Wellington, Fiji, Kamchatka",
    "Antarctica/McMurdo",
    "NZ-CHAT",
    "Etc/GMT-14"
  ];
  const zoneValues = [
    "GMT-12:00",
    "GMT-11:00",
    "GMT-10:00",
    "GMT-09:00",
    "GMT-08:00",
    "GMT-07:00",
    "GMT-06:00",
    "GMT-05:00",
    "GMT-04:00",
    "GMT-03:30",
    "GMT-03:00",
    "GMT-02:30",
    "GMT-02:00",
    "GMT-01:00",
    "GMT+00:00",
    "GMT+01:00",
    "GMT+02:00",
    "GMT+03:00",
    "GMT+03:30",
    "GMT+04:00",
    "GMT+04:30",
    "GMT+05:00",
    "GMT+05:30",
    "GMT+05:45",
    "GMT+06:00",
    "GMT+06:30",
    "GMT+07:00",
    "GMT+08:00",
    "GMT+08:30",
    "GMT+08:45",
    "GMT+09:00",
    "GMT+09:30",
    "GMT+10:00",
    "GMT+10:30",
    "GMT+11:00",
    "GMT+12:00",
    "GMT+13:00",
    "GMT+13:45",
    "GMT+14:00"
  ];
  let position = zoneValues.indexOf(currentTimeInGmt);
  if (position !== -1) {
    return {
      zone_name: zoneNames[position],
      zone_value: zoneValues[position]
    };
  } else {
    return {
      zone_name: "Pacific Time US/Canada",
      zone_value: "GMT-08:00"
    };
  }
};

Utils.validateEUTimeZone = () => {
  let userTimezone = Utils.getUsersTimeZone();
  userTimezone = userTimezone.zone_value;
  if (
    userTimezone === ConstantObj.europeanTimeZone._C_GMT_PLUS_1 ||
    userTimezone === ConstantObj.europeanTimeZone._C_GMT_PLUS_2 ||
    userTimezone === ConstantObj.europeanTimeZone._C_GMT_PLUS_3
  ) {
    return true;
  } else {
    return false;
  }
};

Utils.isEuCheckboxEnabled = (agreeTC, storePersonalInfo) => {
  if (agreeTC && storePersonalInfo) {
    return true;
  }
  return false;
};

Utils.readCookie = name => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

Utils.setCookie = (cname, cvalue, exdays, exHours) => {
  let d = new Date();
  if (exHours === undefined) {
    exHours = 24;
  }
  d.setTime(d.getTime() + exdays * exHours * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

Utils.deleteCookie = name => {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

Utils.getUrlParameter = () => {
  const newList = {};
  let pageUrl = decodeURIComponent(window.location.search.substring(1)),
    urlVariable = pageUrl.split("&");
  urlVariable.forEach(async param => {
    let paramList = param.split("=");
    newList[paramList[0]] = paramList[1];
  });
  return newList;
};

Utils.checkLocationPathname = (path, staticPath) => {
  return path.indexOf(staticPath) !== -1;
};

Utils.validateMatchPassword = (newPassword, confirmPassword) => {
  return confirmPassword === newPassword;
};

Utils.findLinkFromEditor = editorValue => {
  const tempSpanEle = document.createElement("span");
  tempSpanEle.innerHTML = editorValue;
  const linkEle = tempSpanEle.querySelector("a");
  if (linkEle) {
    const linkValue = linkEle.attributes.href;
    const linkName = linkEle.text;
    return { url: linkValue, text: linkName };
  } else {
    return {};
  }
};

Utils.create1pxImage = identifier => {
  if (identifier && identifier.trackingId) {
    let img = document.createElement("img");
    img.id = ConstantObj.TRACK_IMG_ID;
    img.className = ConstantObj.TRACK_IMG_CLASS;
    img.src = getNewTrackingImgUrl(identifier.trackingId);
    img.style.display = "none";
    img.style.position = "absolute";
    img.style.width = "1px";
    img.style.height = "1px";
    return img;
  }
};

Utils.remove1pxImage = domVal => {
  let temp = domVal.querySelector(`#${ConstantObj.TRACK_IMG_ID}`);
  if (temp) {
    temp.parentElement.removeChild(temp);
  }
  return domVal;
};

function getNewTrackingImgUrl(identifier) {
  if (!identifier) identifier = uuid();
  const imgUrl = `${Utils.addBrandUrlForTracking()}${
    ConstantObj.ZeroPxBaseUrl
  }${getBrowserTrackingCode()}/${identifier}`;
  return imgUrl;
}

function getBrowserTrackingCode() {
  return localStorage.getItem(ConstantObj.BROWSER_TRACKING_CODE);
}

Utils.uuid = () => {
  let hexDigits = "0123456789";
  let macAddress = "";
  for (let i = 0; i < 10; i++) {
    macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
    macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
  }
  const date = new Date();
  const milliSeconds = date.getTime();
  return parseInt(macAddress) + parseInt(milliSeconds);
};

Utils.updateElementWithLinkTracking = dom => {
  const links = dom.getElementsByTagName("a");
  const trackerId = getTrackerId(dom);
  let count = 0;
  window.trackedLinks = [];
  if (trackerId) {
    identifyLinkFromText(dom);
    for (let link of links) {
      let url = link.getAttribute("href");
      if (url.indexOf("http") === -1) {
        url = "http://" + url;
      }
      window.trackedLinks.push({
        text: link.innerText,
        url: url
      });
      count = count + 1;
      link.href = createTrackingLink(trackerId, count, url);
    }
  }
};

function identifyLinkFromText(domEle) {
  const elements = domEle.getElementsByTagName("*");
  for (let element of elements) {
    if (element.innerText && isUrl(element.innerText) && !element.href) {
      const link = document.createElement("a");
      link.innerText = element.innerText;
      link.href = constructValidUrl(element.innerText);
      element.innerText = "";
      element.appendChild(link);
    }
  }
}

function createTrackingLink(trackerId, imgId, link) {
  let trackLinkUrl = `${Utils.addBrandUrlForTracking()}/mt/lte?tid=${trackerId}&lid=${imgId}&targetURL=${encodeURIComponent(
    link
  )}`;
  return trackLinkUrl;
}

function getTrackerId(domEle) {
  const url = getUrlOfTrackingImg(domEle);
  if (url) {
    const temp = url.split("/");
    return temp[temp.length - 1].replace(".htm", "");
  }
}

export function getUrlOfTrackingImg(domEle) {
  if (domEle === undefined) domEle = document;
  const img = domEle.getElementsByClassName(ConstantObj.TRACK_IMG_CLASS);
  if (img[0]) {
    return decodeURI(img[0].src);
  }
}

function isUrl(s) {
  if (!isUrl.rx_url) {
    isUrl.rx_url =
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    isUrl.prefixes = ["http://", "https://", "ftp://", "www."];
    isUrl.domains = [
      "com",
      "ru",
      "net",
      "org",
      "de",
      "jp",
      "uk",
      "br",
      "pl",
      "in",
      "it",
      "fr",
      "au",
      "info",
      "nl",
      "ir",
      "cn",
      "es",
      "cz",
      "kr",
      "ua",
      "ca",
      "eu",
      "biz",
      "za",
      "gr",
      "co",
      "ro",
      "se",
      "tw",
      "mx",
      "vn",
      "tr",
      "ch",
      "hu",
      "at",
      "be",
      "dk",
      "tv",
      "me",
      "ar",
      "no",
      "us",
      "sk",
      "xyz",
      "fi",
      "id",
      "cl",
      "by",
      "nz",
      "il",
      "ie",
      "pt",
      "kz",
      "io",
      "my",
      "lt",
      "hk",
      "cc",
      "sg",
      "edu",
      "pk",
      "su",
      "bg",
      "th",
      "top",
      "lv",
      "hr",
      "pe",
      "club",
      "rs",
      "ae",
      "az",
      "si",
      "ph",
      "pro",
      "ng",
      "tk",
      "ee",
      "ai",
      "asia",
      "mobi"
    ];
  }

  if (!isUrl.rx_url.test(s)) return false;
  for (let i = 0; i < isUrl.prefixes.length; i++)
    if (s.startsWith(isUrl.prefixes[i])) return true;
  for (let i = 0; i < isUrl.domains.length; i++)
    if (
      s.indexOf("@") === -1 &&
      (s.endsWith("." + isUrl.domains[i]) ||
        s.includes("." + isUrl.domains[i] + "/") ||
        s.includes("." + isUrl.domains[i] + "?"))
    )
      return true;
  return false;
}

function constructValidUrl(str) {
  if (str.indexOf("http://") !== -1 || str.indexOf("https://") !== -1) {
    const temp = str.split("://");
    str = temp[1];
  }
  return "http://" + str;
}

Utils.registerBrowserTrackingCode = () => {
  const trackingCode = localStorage.getItem(ConstantObj.BROWSER_TRACKING_CODE);
  if (!Utils.isValidBrowserTrackingCode(trackingCode)) {
    const trackingcode = uuid();
    localStorage.setItem(ConstantObj.BROWSER_TRACKING_CODE, trackingcode);
  }
};

Utils.getPercentage = (v1 = 0, v2 = 0, decimal = 0) => {
  if (v1 && v2) {
    if (v1 === 0 && v2 === 0) return 0;
    if (v1 > v2) return 100;
    if (v1 > 0 && v2 === 0) return 0;
    return ((v1 / v2) * 100).toFixed(decimal);
  }
  return 0;
};

Utils.roundToDecimalOrWhole = (number, places) => {
  const hasDecimal = number % places !== 0;
  if (hasDecimal) {
    return number.toFixed(places);
  } else {
    return number.toString();
  }
};

Utils.resumeSeq = async (id, cbk, props = {}, redirectUrl = "") => {
  try {
    await cbk("load", id);
    setTimeout(async () => {
      const { teamStatus = "" } = userDetail?.userFeatureFlag || {};
      if (teamStatus?.toLowerCase() === "trial_completed") {
        Utils.showUpgradePopup("pause-resume", true, true);
        cbk && cbk(null, id);
      } else if (!Utils.isAccountConnected()) {
        Utils.showAddAccountPopup(
          props,
          "Please connect your email account before resuming the sequences"
        );
        cbk && cbk(null, id);
      } else {
        const targetURL = URLS.resumeSeq ? URLS.resumeSeq + id : "";
        const config = {
          url: targetURL,
          method: "POST"
        };
        let res = await makeApi(config);
        if (res?.data) {
          const {
            requestStatus = "",
            message = "",
            responseCode = ""
          } = res.data || {};
          if (requestStatus && message) {
            cbk && cbk("true", id);
            toasterState.setToastMsg(
              `Sequence successfully resumed`,
              "success"
            );
          } else if (responseCode === 404) {
            confirmationPopupState.setPopupValues({
              content:
                "Please assign your inbox to the sequence to resume your contacts.",
              actionBtnText: "Ok",
              needCancelBtn: false,
              callback: () => {
                props.history.push(
                  redirectUrl ? `${redirectUrl}/#settings` : "#settings"
                );
              }
            });
            confirmationPopupState.setShowPopup(true);
            cbk && cbk(null, id);
          } else {
            cbk && cbk(null, id);
            Utils.showApiFailureResponse(res.data);
          }
        } else {
          cbk && cbk(null, id);
          Utils.showApiFailureResponse(res);
        }
      }
    }, 1000);
  } catch (error) {
    cbk && cbk(null, id);
    console.error("Pause seq error ", error);
  }
};

Utils.pauseSeq = async (id = "", cbk = () => {}, seqDataObj = "") => {
  try {
    cbk("load", id);
    const targetURL = URLS.pauseSeq + id;
    const config = {
      url: targetURL,
      method: "POST"
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { requestStatus, successCount, failedCount } = response.data || {};
      if (requestStatus) {
        cbk && cbk("true", id);
        toasterState.setToastMsg(`Sequence disabled successfully`, "success");
      } else if (successCount > 0 && failedCount > 0) {
        cbk && cbk("false", id);
        showPartialPausedErrorMessage();
      } else {
        cbk && cbk("false", id);
        Utils.showApiFailureResponse(response.data);
      }
    } else {
      cbk && cbk("false", id);
      Utils.showApiFailureResponse(response);
    }
  } catch (error) {
    cbk && cbk(null, id);
    console.error("Pause seq error ", error);
  }
};

Utils.findCurrentObj = (data, id) => {
  return data.find(arrayValue => arrayValue?.id === id);
};

Utils.readFile = fileUpload => {
  if (
    fileUpload?.value?.length
    // && fileUpload?.value?.toLowerCase().indexOf(".csv") !== -1
  ) {
    if (typeof FileReader != "undefined") {
      let reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = function (e) {
          if (e?.target?.result) {
            const rows = e?.target?.result?.split(/\r?\n/);
            const filteredRows = rows?.filter(row => {
              const values = row?.split(",");
              return !values?.every(value => value?.trim() === ""); // A valid row may have some column empty, so the condition is added likewise
            });
            resolve(filteredRows.join("\n"));
          }
        };
        reader.onerror = () => {
          reader.abort();
          reject("Failed");
        };
        reader.readAsText(fileUpload.files[0]);
      });
    }
  }
};

Utils.validateAndGetDataFromAPI = (dataMap, cbk) => {
  const config = {
    url: URLS.validateBulkFiles,
    method: "POST",
    data: dataMap
  };
  return makeApi(config);
};

Utils.firstLetterCapitialize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

Utils.processContactFromCsvFile = async (
  fileData,
  id,
  isFrom,
  renderContactCbk,
  bulkPopupCloseCbk
) => {
  if (fileData.target && fileData.target.files) {
    const uploadedData = fileData.target;
    let resultObj;
    try {
      resultObj = await Utils.readFile(uploadedData);
    } catch (e) {
      console.error("File read error", e);
    }
    validateBulkDataFromApi(
      uploadedData.files[0].name || "",
      resultObj,
      id,
      renderContactCbk,
      bulkPopupCloseCbk
    );
  }
};

Utils.processBulkDomainsFromCsvFile = async (fileData, uploadAction) => {
  if (fileData.target && fileData.target.files) {
    const uploadedData = fileData.target;
    let resultObj;
    try {
      resultObj = await Utils.readFile(uploadedData);
      const dataStringLines = resultObj.split(/\r\n|\n/);
      uploadAction([...dataStringLines]);
    } catch (e) {
      console.error("File read error", e);
    }
  }
};

Utils.contactUploadLimitExceedPopup = () => {
  fileLimitExceedState.setMessage(
    `Failed to upload CSV with size greater than 10 MB. Please upload file with size less than 10MB.`
  );
  fileLimitExceedState.setType("csv");
  fileLimitExceedState.setShowPopup(true);
};

const validateBulkDataFromApi = async (
  fileName,
  csvContent,
  id,
  renderContactCbk,
  bulkPopupCloseCbk
) => {
  const date = new Date().toLocaleDateString("sv-SE");
  const dataMap = { date, csvContent, id };
  const response = await Utils.validateAndGetDataFromAPI(dataMap);
  if (response?.data) {
    const { message = "", data = "" } = response?.data || {};
    data
      ? renderContactCbk("success", null, data, fileName)
      : renderContactCbk("error", message);
  } else {
    bulkPopupCloseCbk();
    response?.request?.status === 413
      ? Utils.contactUploadLimitExceedPopup()
      : toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
  }
};

Utils.scheduleSequence = (
  settingsData,
  bulkCsvData,
  sequenceId,
  contactTags
) => {
  toasterState.setToastMsg("Scheduling your sequence. Please wait", "info");

  const dataMap = {
    csvFileName: addBulkContactPopupState.fileName,
    data: (bulkCsvData && JSON.stringify(bulkCsvData)) || {},
    sequenceId,
    contactTags
  };
  const { insertBcc = false, bccEmail = "" } = settingsData || {};
  if (insertBcc && bccEmail?.length) {
    dataMap.bccAddress = bccEmail;
  }
  const config = {
    url: URLS.scheduleBulk,
    method: "POST",
    data: dataMap
  };
  return makeApi(config);
};

Utils.getFormattedDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  // JavaScript months are 0-based.
  const month = today.getMonth() + 1;
  const date = today.getDate();
  const hour = today.getHours();
  const min = today.getMinutes();
  const sec = today.getSeconds();
  return `${year}_${month}_${date}_${hour}${min}${sec}`;
};

Utils.scheduleSequenceForContacts = (
  settingsData,
  contacts,
  sequenceId,
  contactTags = []
) => {
  toasterState.setToastMsg("Scheduling your sequence. Please wait", "info");
  const dataMap = {
    csvFileName: `Contacts_${Utils.getFormattedDateTime()}`,
    data: (contacts && JSON.stringify(contacts)) || {},
    sequenceId,
    contactTags
  };
  const { insertBcc = false, bccEmail = "" } = settingsData || {};
  if (insertBcc && bccEmail?.length) {
    dataMap.bccAddress = bccEmail;
  }
  const config = {
    url: URLS.scheduleBulk,
    method: "POST",
    data: dataMap
  };
  return makeApi(config);
};

Utils.manualEmailTracking = editorValue => {
  const trackingId = "{{ae_tracking_id}}";
  const trackImg = Utils.create1pxImage({ trackingId });
  if (trackImg) {
    const outerImgHtml = trackImg.outerHTML;
    editorValue = editorValue.concat(outerImgHtml);
    let tempDom = document.createElement("div");
    tempDom.innerHTML = editorValue;
    Utils.updateElementWithLinkTracking(tempDom);
    return tempDom.outerHTML;
  }
};

Utils.showLoggedOutPopup = () => {
  const handleRedirection = () => {
    window.location.href = "/login";
  };

  const PopupContent = () => {
    const [time, setTime] = useState(3);
    var interval = setInterval(() => {
      if (time === 1) {
        clearInterval(interval);
        handleRedirection();
        confirmationPopupState.setShowPopup(false);
        return;
      } else {
        time > 1 && setTime(time - 1);
      }
    }, 1000);

    return (
      <div className="logoutCont">
        Your account has been logged out, please login to continue.
        <div className="redirectMsg">{`Redirecting to login page in ${time} second(s)`}</div>
      </div>
    );
  };

  confirmationPopupState.setPopupValues({
    content: <PopupContent />,
    actionBtnText: "Ok",
    callback: handleRedirection,
    needCancelBtn: false,
    dynamicDom: true
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.showRedirection = (redirectUrl = "/contacts", timer = 3, history) => {
  let globalTime = timer;
  const handleRedirection = () => {
    history.push(redirectUrl);
  };

  const PopupContent = () => {
    const [time, setTime] = useState(globalTime);
    var interval = setInterval(() => {
      if (globalTime === null) {
        clearInterval(interval);
        confirmationPopupState.setShowPopup(false);
      } else if (time === 1) {
        clearInterval(interval);
        handleRedirection();
        confirmationPopupState.setShowPopup(false);
        return;
      } else {
        time > 1 && setTime(time - 1);
      }
    }, 1000);

    return (
      <div className="logoutCont">
        <div className="redirectMsg">{`Redirecting to contacts page in ${time} second(s)`}</div>
      </div>
    );
  };

  confirmationPopupState.setPopupValues({
    content: <PopupContent />,
    actionBtnText: "Ok",
    callback: handleRedirection,
    cancelCbk: () => {
      globalTime = null;
    },
    dynamicDom: true
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.showApiFailureResponse = response => {
  if (response?.response?.status) {
    const responseStatus = response.response.status;
    if (responseStatus === 401) {
      Utils.showLoggedOutPopup();
    } else if (responseStatus === 426) {
      Utils.showAccountSuspendedPopup();
    } else if (responseStatus === 408) {
      toasterState.setToastMsg("Request Timed Out", "failure");
    } else if (responseStatus === 406) {
      toasterState.setToastMsg(
        "This contact has invalid subject line",
        "failure"
      );
    } else if (responseStatus === 409) {
      toasterState.setToastMsg(
        `Oops! Something went wrong. To resolve the issue, please contact us at support@salesgear.io`,
        "failure"
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  } else if (
    response?.message?.message === "cancel" ||
    response?.message === "cancel"
  ) {
    console.error("Request cancelled");
  } else if (response?.responseCode === 403) {
    toasterState.setToastMsg(
      "Please contact the sequence owner to pause or resume this sequence",
      "failure"
    );
  } else {
    toasterState.setToastMsg(
      "Something went wrong. Please try again later",
      "failure"
    );
  }
};

Utils.showAccountSuspendedPopup = () => {
  confirmationPopupState.setPopupValues({
    content:
      "Sorry, your subscription has been suspended as we are not able to process the payment for your subscription. Please <a target='_blank' class='supportMailLink' rel='nofollow' href='mailto:support@salesgear.io'>contact support</a> to reactivate your subscription",
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => {}
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.preventDefaultFn = event => {
  if (event) {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  }
};

Utils.showAddLinkedInAccountPopup = (history, message, cbk = false) => {
  if (!message) {
    message =
      "Please connect your LinkedIn account before adding a LinkedIn (Automatic) step to the sequence.";
  }
  const navigateToAddAccounts = () => {
    if (history) {
      if (cbk) {
        cbk(true);
      }
      addStagesPopupState.setShowPopup(false);
      history.push("/settings/account/linkedin");
    }
  };
  confirmationPopupState.setPopupValues({
    title: "Connect your account to continue",
    content: message,
    actionBtnText: "Connect",
    callback: navigateToAddAccounts
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.showAddAccountPopup = (props, message, cbk = false) => {
  if (!message) {
    message =
      "Please connect your email account before adding contacts to a sequence";
  }
  const navigateToAddAccounts = () => {
    if (props && props.history) {
      if (cbk) {
        cbk(true);
      }
      props.history.push("/settings/account/email");
    }
  };
  confirmationPopupState.setPopupValues({
    title: "Connect your account to continue",
    content: message,
    actionBtnText: "Connect",
    callback: navigateToAddAccounts
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.findNoOfDaysFromCurrentData = (date = new Date()) => {
  const currentDate = new Date();
  const targetDate = new Date(date);
  return Math.floor(
    (Date.UTC(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate()
    ) -
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )) /
      (1000 * 60 * 60 * 24)
  );
};

Utils.domPosition = target => {
  if (target) {
    return target.getBoundingClientRect();
  }
};

Utils.getStartingDateOfthisWeek = date => {
  let diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

// Mixapnel
Utils.registerMixpanel = () => {
  const { email } = userDetail.userInfo || {};
  const { createTs = "" } = userDetail.userFeatureFlag;
  mixpanel.register({
    userEmail: email,
    distinct_id: email,
    signupDate: (createTs && new Date(createTs).toISOString()) || ""
  });
  mixpanel.identify(email);
  mixpanel.alias(email);
};

Utils.mixpanelTrack = (eventName = "", property = {}) => {
  let {
    createTs = "",
    trialEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};
  property.userEmail = property?.userEmail || userDetail?.userInfo?.email || "";
  property.currentUrl = window && window.location && window.location.href;
  property.signupDate = createTs || "";
  property.planType = trialEnabled
    ? "Trial"
    : inFreePlan || planName?.toLowerCase() === "free"
    ? "Free"
    : planName;
  window.mixpanel.track(eventName, property);
};

Utils.compareTwoString = (str1 = "", str2 = "") => {
  if (str1 && str2) {
    return str1 === str2;
  }
  return false;
};

Utils.getSequenceType = newSequenceType => {
  if (newSequenceType === ConstantObj.sequenceType.private) {
    return ConstantObj.sequenceType.shared.rw;
  } else {
    return newSequenceType;
  }
};

Utils.getSequenceShareType = newSequenceType => {
  if (newSequenceType === ConstantObj.sequenceType.private) {
    return newSequenceType;
  } else {
    return ConstantObj.sequenceType.shared.type;
  }
};

Utils.checkTeamUrl = (url, currentPath) => {
  if (url) {
    if (ConstantObj.teamUrlPath.indexOf(currentPath) !== -1) {
      if (
        localStorage.getItem("inviteTeam") !== null &&
        localStorage.getItem("inviteTeam") !== undefined &&
        Object.keys(JSON.parse(localStorage.getItem("inviteTeam"))).length > 0
      ) {
        userDetail.setUserTeamInfo(
          JSON.parse(localStorage.getItem("inviteTeam"))
        );
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

Utils.validateCaptcha = async token => {
  const config = {
    url: `${URLS.validateCaptcha}?token=${token}`,
    data: { token },
    method: "POST"
  };
  return await makeApi(config);
};

Utils.convertFromDateToLocalString = date => {
  if (date) {
    return (
      date.toLocaleString("sv-SE", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }) + `T00:00:00`
    );
  }
};

Utils.convertToDateToLocalString = date => {
  if (date) {
    return (
      date.toLocaleString("sv-SE", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }) + `T23:59:59`
    );
  }
};

Utils.getDateWithTimestamp = ({ filterFromDate = "", filterToDate = "" }) => {
  let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
  let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
  return { fromDate, toDate };
};

Utils.getFromToDateWithTimeZoneByDaysCount = day => {
  let dateValue =
    (day === 0 && new Date()) || Utils.getPreviousDateByNumber(day);
  return {
    filterFromDate: Utils.convertFromDateToLocalString(dateValue),
    filterToDate: Utils.convertToDateToLocalString(
      (day > 1 && new Date()) || dateValue
    ),
    timeZone: Utils.getCurrentUserTimeZone()
  };
};

Utils.getFromToDateTimeStamp = (key = "") => {
  if (key) {
    const today = new Date();
    let filterPayload = {};
    switch (key) {
      case "today":
        filterPayload = {
          filterFromDate: Utils.convertFromDateToLocalString(today),
          filterToDate: Utils.convertToDateToLocalString(today)
        };
        break;
      case "yesterday":
        const yesterdayObj = Utils.getFromToDateWithTimeZoneByDaysCount(1);
        filterPayload = {
          filterFromDate: yesterdayObj?.filterFromDate,
          filterToDate: yesterdayObj?.filterToDate
        };
        break;
      case "week":
        const sevenDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(6);
        filterPayload = {
          filterFromDate: sevenDateObj?.filterFromDate,
          filterToDate: sevenDateObj?.filterToDate
        };
        break;
      case "month":
        const thirtyDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(29);
        filterPayload = {
          filterFromDate: thirtyDateObj?.filterFromDate,
          filterToDate: thirtyDateObj?.filterToDate
        };
        break;
      case "allTask":
        filterPayload = {
          filterToDate: Utils.convertToDateToLocalString(today)
        };
        break;
      default:
        return {};
    }
    if (filterPayload) {
      filterPayload.timeZone = Utils.getCurrentUserTimeZone();
    }
    return filterPayload;
  }
};

Utils.getPreviousDateByNumber = number => {
  if (number) {
    let now = new Date();
    let defaultDate = now - 1000 * 60 * 60 * 24 * number;
    return new Date(defaultDate);
  }
};

Utils.isShared = (type = "") => {
  return type && type === ConstantObj.sequenceType.shared.type;
};

Utils.showUpgradePopup = (page, show, overlay, count) => {
  userDetail.setUpgradePopupContent({
    page,
    show,
    overlay,
    count
  });
};

Utils.showUpgradeSettingsPopup = (page, show, overlay, count) => {
  userDetail.setUpgradeSettingsPopupContent({
    page,
    show,
    overlay,
    count
  });
};

Utils.getTeamMember = async () => {
  try {
    const config = {
      url: URLS.getTeamActiveList ? URLS.getTeamActiveList : ""
    };
    return await makeApi(config);
  } catch (err) {
    console.error(err, "Getting Team Member List Err");
  }
};

Utils.getAllTeam = async () => {
  try {
    const config = {
      url: URLS.getTeam,
      method: "POST"
    };
    return await makeApi(config);
  } catch (err) {
    console.error(err, "Getting All Team Member List Err");
  }
};

Utils.isValidPhone = phone => {
  let numberPattern = /[0-9]/g;
  let matchNo = phone.match(numberPattern);
  if (matchNo) {
    return phone.match(numberPattern).length > 6;
  }
  return false;
};

Utils.textElipsis = (text, count) => {
  if (text.length > count) {
    return `${text.substring(0, count + 1)}...`;
  }
  return text;
};

Utils.getOnboardingData = () => {
  // check added to prevent un necessary api calls
  if (onboardingStoreData.OnBoardingEnabled) {
    onboardingStoreData.getOnBoardingData();
  }
};

Utils.getOnboardingPopupData = () => {
  if (onboarding.onBoardingFlagValues.firstSequence === null) {
    onboardingStoreData.getOnBoardingData();
  }
};

Utils.getOnboardingPopupDataAfterContactUpload = () => {
  if (onboarding.onBoardingFlagValues.isContactsAdded === false) {
    onboardingStoreData.getOnBoardingData();
  }
};

Utils.showOnboardingStepsPopup = value => {
  onboardingStoreData.setShowStepsPopup(value);
};

Utils.trackOnboardingMixpanel = flag => {
  if (onboardingStoreData.OnBoardingEnabled && flag) {
    switch (flag) {
      case "account_connected":
        Utils.mixpanelTrack(MP_EVENT.ON_MAIL_CONNECTED);
        break;
      case "sequence_created":
        Utils.mixpanelTrack(MP_EVENT.ON_SEQUENCE_CREATED);
        break;
      case "contact_added":
        Utils.mixpanelTrack(MP_EVENT.ON_CONTACTS_ADDED);
        break;
      default:
        "";
    }
  }
};

Utils.triggerAddStagesDriver = () => {
  if (onboardingStoreData.OnBoardingEnabled) {
    const { isSequenceCreated } = onboardingStoreData.onBoardingFlags;
    if (!isSequenceCreated) {
      if (window?.Intercom?.booted && IntercomTour.newSequenceSteps) {
        window.Intercom("startTour", IntercomTour.newSequenceSteps);
      } else {
        setTimeout(() => {
          DriverMaster.execute(addStagesSteps);
        }, 1000);
      }
    }
  }
};

Utils.startSearchingInDiscoverSection = () => {
  const intervalId = setInterval(() => {
    const contactFirstElement = document.querySelector("[has-data='true']");
    if (contactFirstElement) {
      DriverMaster.execute(startSearchingDriverStep);
      clearInterval(intervalId);
    }
  }, 500);

  setTimeout(() => {
    clearInterval(intervalId);
  }, 5000);
};

// Export Contact Start

Utils.exportContact = async data => {
  const config = {
    url: URLS.exportLimitedContact,
    method: "POST",
    data
  };
  return await makeApi(config);
};

function convertToCSV(objArray) {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}

Utils.exportCSVFile = (headers, items, fileTitle, cbk, isCsvString = false) => {
  let blob;
  if (!isCsvString) {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    blob = new Blob([csv], { type: "data:text/csv;charset=utf-8" });
  } else {
    blob = new Blob([items], { type: "data:text/csv;charset=utf-8" });
  }
  const exportedFilenmae = fileTitle + ".csv" || "export.csv";
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      cbk(link); // sending the download link back to cbk fn()
    }
  }
};

const getSeqeunceName = sequence => {
  if (sequence && sequence.length) {
    let sequenceName = '"';
    const seqLen = sequence.length;
    sequence.map((value, index) => {
      if (value && value.name) {
        sequenceName += value.name;
        if (index + 1 !== seqLen) {
          sequenceName += ",";
        }
      }
    });
    sequenceName += '"';
    return sequenceName;
  }
  return "";
};

const getContactTags = contactTags => {
  if (contactTags && contactTags.length) {
    let tagName = '"';
    const tagsLen = contactTags.length;
    contactTags.map((value, index) => {
      if (value) {
        tagName += value;
        if (index + 1 !== tagsLen) {
          tagName += ",";
        }
      }
    });
    tagName += '"';
    return tagName;
  }
  return "";
};

const getLinkedInUrl = linkedInId => {
  if (linkedInId) {
    const regex = /^https?:/;
    if (!regex.test(linkedInId)) {
      linkedInId = `https://${linkedInId}`;
    }
    return linkedInId;
  }
  return "";
};

const getDateString = date => {
  if (date) {
    const newDate = new Date(date);
    return newDate.toDateString();
  }
  return "";
};

export const getContactExportHeader = () => {
  return {
    fname: "First Name",
    lName: "Last Name",
    email: "Email Address",
    title: "Job Title",
    phone: "Phone Number",
    mobilePhone: "Mobile Number",
    otherPhone: "Other Number",
    company: "Company",
    industry: "Industry",
    notes: "Contact Notes",
    street: "Street",
    city: "City",
    state: "State",
    country: "Country",
    zipCode: "Zip Code",
    website: "Website",
    linkedIn: "LinkedIn URL",
    facebook: "Facebook",
    twitter: "Twitter",
    contactTags: "Contact Tags",
    customField1:
      settingsStoreData?.customFields?.["customField1"] || "Custom Field 1",
    customField2:
      settingsStoreData?.customFields?.["customField2"] || "Custom Field 2",
    customField3:
      settingsStoreData?.customFields?.["customField3"] || "Custom Field 3",
    customField4:
      settingsStoreData?.customFields?.["customField4"] || "Custom Field 4",
    customField5:
      settingsStoreData?.customFields?.["customField5"] || "Custom Field 5",
    customField6:
      settingsStoreData?.customFields?.["customField6"] || "Custom Field 6",
    customField7:
      settingsStoreData?.customFields?.["customField7"] || "Custom Field 7",
    customField8:
      settingsStoreData?.customFields?.["customField8"] || "Custom Field 8",
    customField9:
      settingsStoreData?.customFields?.["customField9"] || "Custom Field 9",
    customField10:
      settingsStoreData?.customFields?.["customField10"] || "Custom Field 10",
    customField11:
      settingsStoreData?.customFields?.["customField11"] || "Custom Field 11",
    customField12:
      settingsStoreData?.customFields?.["customField12"] || "Custom Field 12",
    customField13:
      settingsStoreData?.customFields?.["customField13"] || "Custom Field 13",
    customField14:
      settingsStoreData?.customFields?.["customField14"] || "Custom Field 14",
    customField15:
      settingsStoreData?.customFields?.["customField15"] || "Custom Field 15",
    customField16:
      settingsStoreData?.customFields?.["customField16"] || "Custom Field 16",
    customField17:
      settingsStoreData?.customFields?.["customField17"] || "Custom Field 17",
    customField18:
      settingsStoreData?.customFields?.["customField18"] || "Custom Field 18",
    customField19:
      settingsStoreData?.customFields?.["customField19"] || "Custom Field 19",
    customField20:
      settingsStoreData?.customFields?.["customField20"] || "Custom Field 20",
    customField21:
      settingsStoreData?.customFields?.["customField21"] || "Custom Field 21",
    customField22:
      settingsStoreData?.customFields?.["customField22"] || "Custom Field 22",
    customField23:
      settingsStoreData?.customFields?.["customField23"] || "Custom Field 23",
    customField24:
      settingsStoreData?.customFields?.["customField24"] || "Custom Field 24",
    customField25:
      settingsStoreData?.customFields?.["customField25"] || "Custom Field 25",
    customField26:
      settingsStoreData?.customFields?.["customField26"] || "Custom Field 26",
    customField27:
      settingsStoreData?.customFields?.["customField27"] || "Custom Field 27",
    customField28:
      settingsStoreData?.customFields?.["customField28"] || "Custom Field 28",
    customField29:
      settingsStoreData?.customFields?.["customField29"] || "Custom Field 29",
    customField30:
      settingsStoreData?.customFields?.["customField30"] || "Custom Field 30",
    companyIndustry: "Company Industry",
    companySector: "Company Sector",
    companyrevenue: "Company Revenue",
    companyHeadCount: "Company Head Count",
    companyHqNumber: "Company HQ Number",
    companyStreet: "Company Street",
    companycity: "Company City",
    companyState: "Company State",
    companyCountry: "Company Country",
    companyzip: "Company Zip",
    companyLinkedin: "Company LinkedIn",
    companyTwitter: "Company Twitter",
    companyFacebook: "Company Facebook",
    owner: "Owner Name",
    addedDate: "Added Date",
    lastContactedDate: "Last Contacted Date",
    stage: "Stage",
    sequences: "Sequences",
    emailsSent: "Emails Sent",
    emailOpensCount: "Email Opens Count",
    emailClicksCount: "Email Clicks Count",
    EmailRepliesCount: "Email Replies Count",
    callsMade: "Calls Made",
    callsLogged: "Calls Logged"
  };
};

const getCallLogExportHeader = () => {
  return {
    name: "Contact Name",
    email: "Contact Email",
    title: "Contact Title",
    company: "Contact Company",
    phone: "Phone Number",
    callDateAndTime: "Call Date & Time",
    duration: "Call Duration",
    state: "Call State",
    recordingUrl: "Recording Url",
    callNotes: "Call Notes",
    callerName: "Caller Name/Email"
  };
};

const getReportsExportHeader = (tableType = "") => {
  return {
    name: `${tableType}`,
    contactsAddedCount: "Contacts Added",
    contactsAddedViaSequencesCount: "Contacts Added Via Sequences",
    emailsSentCount: "Emails Sent",
    emailsOpenCount: "Emails Opened",
    openRate: "Open Rate",
    emailsClickCount: "Emails Clicked",
    clickRate: "Click Rate",
    emailsReplyCount: "Emails Replied",
    ReplyRate: "Reply Rate",
    emailsBounceCount: "Emails Bounced",
    BounceRate: "Bounce Rate",
    emailsOptedOutCount: "Emails Opted Out",
    callsMadeCount: "Calls Made",
    callsMadeViaSequencesCount: "Calls Via Sequence",
    callsAnswered: "Calls Answered",
    callsNotAnswered: "Calls Not Answered",
    callsLoggedCount: "Calls Logged",
    avgCallDurationInSecs: "Average Call Duration",
    totalTimeOnCall: "Total Time On Call",
    meetingBookedCount: "Meeting Booked",
    linkedInTaskCompletedCount: "LinkedIn Task Completed",
    otherTaskCompletedCount: "Other Task Completed",
    linkedInMessagesSent: "LinkedIn Messages Sent",
    linkedinRepliesReceived: "LinkedIn Replies Received",
    linkedinConnectionRequestsSent: "LinkedIn Connections Sent",
    linkedinConnectionRequestsAccepted: "LinkedIn Connections Accepted"
  };
};

const getSequenceExportHeader = (tableType = "") => {
  return {
    name: `${tableType}`,
    status: "Status",
    type: "Type",
    owner: "Owner",
    created: "Created",
    contactsAddedCount: "Contacts Added",
    emailsSentCount: "Emails Sent",
    emailsOpenCount: "Emails Opened",
    openRate: "Open Rate",
    emailsClickCount: "Emails Clicked",
    clickRate: "Click Rate",
    emailsReplyCount: "Emails Replied",
    ReplyRate: "Reply Rate",
    emailsBounceCount: "Emails Bounced",
    BounceRate: "Bounce Rate",
    emailsOptedOutCount: "Emails Opted Out",
    callsMadeCount: "Calls Made",
    callsAnswered: "Calls Answered",
    callsNotAnswered: "Calls Not Answered",
    callsLoggedCount: "Calls Logged",
    avgCallDurationInSecs: "Average Call Duration",
    totalTimeOnCall: "Total Time On Call",
    meetingBookedCount: "Meeting Booked",
    websiteVisitedCount: "Website Visited",
    linkedInTaskCompletedCount: "LinkedIn Task Completed",
    otherTaskCompletedCount: "Other Task Completed",
    linkedInMessagesSent: "LinkedIn Messages Sent",
    linkedinRepliesReceived: "LinkedIn Replies Received",
    linkedinConnectionRequestsSent: "LinkedIn Connections Sent",
    linkedinConnectionRequestsAccepted: "LinkedIn Connections Accepted"
  };
};

Utils.constructExportToCsvData = (data, cbk) => {
  if (data?.length) {
    const dataArr = [];
    data.map(value => {
      if (value?.id) {
        const tempObj = {};
        const { taggedSequences, insights, owner, contactTags } = value;
        const customFieldInput = value?.customFields || {};
        const companyDetails = value?.companyDetails || {};
        const companyAddress = companyDetails?.address?.[0] || {};
        tempObj.firstName = `"${value.firstName || ""}"`;
        tempObj.lastName = `"${value.lastName || ""}"`;
        tempObj.email = `"${value.email || ""}"`;
        tempObj.jobTitle = `"${value.jobTitle || ""}"`;
        tempObj.phone = `"${value.phone || ""}"`;
        tempObj.mobilePhone = `"${value.mobilePhone || ""}"`;
        tempObj.otherPhone = `"${value.otherPhone || ""}"`;
        tempObj.company = `"${value.company || ""}"`;
        tempObj.industry = `"${value.industry || ""}"`;
        tempObj.contactNotes = `"${value.contactNotes || ""}"`;
        tempObj.street = `"${value.street || ""}"`;
        tempObj.city = `"${value.city || ""}"`;
        tempObj.state = `"${value.state || ""}"`;
        tempObj.country = `"${value.country || ""}"`;
        tempObj.zipCode = `"${value.zipCode || ""}"`;
        tempObj.website = getLinkedInUrl(value.website);
        tempObj.linkedinUrl = getLinkedInUrl(value.linkedinId);
        tempObj.facebook = getLinkedInUrl(value.facebook);
        tempObj.twitter = getLinkedInUrl(value.twitter);
        tempObj.tags = getContactTags(contactTags);
        tempObj.customField1 = customFieldInput?.customField1 || "";
        tempObj.customField2 = customFieldInput?.customField2 || "";
        tempObj.customField3 = customFieldInput?.customField3 || "";
        tempObj.customField4 = customFieldInput?.customField4 || "";
        tempObj.customField5 = customFieldInput?.customField5 || "";
        tempObj.customField6 = customFieldInput?.customField6 || "";
        tempObj.customField7 = customFieldInput?.customField7 || "";
        tempObj.customField8 = customFieldInput?.customField8 || "";
        tempObj.customField9 = customFieldInput?.customField9 || "";
        tempObj.customField10 = customFieldInput?.customField10 || "";
        tempObj.customField11 = customFieldInput?.customField11 || "";
        tempObj.customField12 = customFieldInput?.customField12 || "";
        tempObj.customField13 = customFieldInput?.customField13 || "";
        tempObj.customField14 = customFieldInput?.customField14 || "";
        tempObj.customField15 = customFieldInput?.customField15 || "";
        tempObj.customField16 = customFieldInput?.customField16 || "";
        tempObj.customField17 = customFieldInput?.customField17 || "";
        tempObj.customField18 = customFieldInput?.customField18 || "";
        tempObj.customField19 = customFieldInput?.customField19 || "";
        tempObj.customField20 = customFieldInput?.customField20 || "";
        tempObj.customField21 = customFieldInput?.customField21 || "";
        tempObj.customField22 = customFieldInput?.customField22 || "";
        tempObj.customField23 = customFieldInput?.customField23 || "";
        tempObj.customField24 = customFieldInput?.customField24 || "";
        tempObj.customField25 = customFieldInput?.customField25 || "";
        tempObj.customField26 = customFieldInput?.customField26 || "";
        tempObj.customField27 = customFieldInput?.customField27 || "";
        tempObj.customField28 = customFieldInput?.customField28 || "";
        tempObj.customField29 = customFieldInput?.customField29 || "";
        tempObj.customField30 = customFieldInput?.customField30 || "";
        tempObj.companyIndustry = getFirstElem(companyDetails?.industry);
        tempObj.companySector = getFirstElem(companyDetails?.sector);
        tempObj.companyrevenue = companyDetails?.revenue || "";
        tempObj.companyHeadCount = companyDetails?.employeeCount || "";
        tempObj.companyHqNumber = getPhoneNumber(companyDetails?.phone);
        tempObj.companyStreet = companyAddress?.street || "";
        tempObj.companycity = companyAddress?.city || "";
        tempObj.companyState = companyAddress?.state || "";
        tempObj.companyCountry = companyAddress?.country || "";
        tempObj.companyzip = companyAddress?.zip || "";
        tempObj.companyLinkedin = companyDetails?.linkedIn || "";
        tempObj.companyTwitter = companyDetails?.facebook || "";
        tempObj.companyFacebook = companyDetails?.twitter || "";
        tempObj.ownerName = Utils.getOwnerName(owner);
        tempObj.addedDate = getDateString(value.createdDate);
        tempObj.lastContactedDate = getDateString(value.lastContactDate);
        tempObj.stage = value?.stage || "";
        tempObj.sequences = getSeqeunceName(taggedSequences);
        tempObj.emailSent = insights?.sentCount || 0;
        tempObj.emailOpen = insights?.openCount || 0;
        tempObj.emailClicks = insights?.clickCount || 0;
        tempObj.replyCount = insights?.replyCount || 0;
        tempObj.callsMade = insights?.callsMade || 0;
        tempObj.callsLogged = insights?.callsLogged || 0;
        dataArr.push(tempObj);
      }
    });

    Utils.exportCSVFile(
      getContactExportHeader(),
      dataArr,
      "Salesgear-Contacts",
      cbk
    );
  }
};

Utils.constructCallLogsExportToCsvData = (data, cbk) => {
  if (data?.length) {
    const dataArr = [];
    data.map(value => {
      if (value?.id) {
        const tempObj = {};
        const { contact = {} } = value || {};
        let tempName = Utils.getName(contact?.firstName, contact?.lastName);
        tempObj.name = tempName === "--" ? " " : tempName;
        tempObj.email = `"${contact?.email || ""}"`;
        tempObj.jobTitle = `"${contact?.jobTitle || ""}"`;
        tempObj.company = `"${contact?.company || ""}"`;
        tempObj.phone = `"${value?.toNumber || ""}"`;
        tempObj.callDateAndTime = DateUtils.convertTimestampToDateAndTime(
          value?.createTs
        );
        tempObj.duration = value?.duration || "";
        tempObj.state = value?.outcome || "";
        tempObj.recordingUrl = value?.recordingUrl || "";
        tempObj.callNotes = value?.notes || "";
        tempObj.callerName = value?.memberName || "";
        dataArr.push(tempObj);
      }
    });
    Utils.exportCSVFile(
      getCallLogExportHeader(),
      dataArr,
      "Salesgear-Calls",
      cbk
    );
  }
};

Utils.constructReportExportToCsvData = (data, tableType, cbk) => {
  if (data?.length) {
    const dataArr = [];
    data.map(value => {
      if (value?.id || value?.memberId) {
        const tempObj = {};
        tempObj.name = Utils.getName(
          value?.user?.firstName,
          value?.user?.lastName
        );
        tempObj.contactsAddedCount = value?.contactsAddedCount || "0";
        tempObj.contactsAddedViaSequencesCount =
          value?.contactsAddedViaSequencesCount || "0";
        tempObj.emailsSentCount = value?.emailsSentCount || "0";
        tempObj.emailsOpenCount = value?.emailsOpenCount || "0";
        tempObj.openRate = value?.openRate || "0";
        tempObj.emailsClickCount = value?.emailsClickCount || "0";
        tempObj.clickRate = value?.clickRate || "0";
        tempObj.emailsReplyCount = value?.emailsReplyCount || "0";
        tempObj.ReplyRate = value?.ReplyRate || "0";
        tempObj.emailsBounceCount = value?.emailsBounceCount || "0";
        tempObj.BounceRate = value?.BounceRate || "0";
        tempObj.emailsOptedOutCount = value?.emailsOptedOutCount || "0";
        tempObj.callsMadeCount = value?.callsMadeCount || "0";
        tempObj.callsMadeViaSequencesCount =
          value?.callsMadeViaSequencesCount || "0";
        tempObj.callsAnswered = value?.callsAnswered || "0";
        tempObj.callsNotAnswered = value?.callsNotAnswered || "0";
        tempObj.callsLoggedCount = value?.callsLoggedCount || "0";
        tempObj.avgCallDurationInSecs =
          DateUtils.getTimeFromSeconds(value?.avgCallDurationInSecs) ||
          "00:00:00";
        tempObj.totalTimeOnCall =
          DateUtils.getTimeFromSeconds(value?.totalTimeOnCall) || "00:00:00";
        tempObj.meetingBookedCount = value?.meetingBookedCount || "0";
        tempObj.linkedInTaskCompletedCount =
          value?.linkedInTaskCompletedCount || "0";
        tempObj.otherTaskCompletedCount = value?.otherTaskCompletedCount || "0";
        tempObj.linkedInMessagesSent = value?.linkedInMessagesSent || "0";
        tempObj.linkedinRepliesReceived = value?.linkedinRepliesReceived || "0";
        tempObj.linkedinConnectionRequestsSent =
          value?.linkedinConnectionRequestsSent || "0";
        tempObj.linkedinConnectionRequestsAccepted =
          value?.linkedinConnectionRequestsAccepted || "0";
        dataArr.push(tempObj);
      }
    });
    Utils.exportCSVFile(
      getReportsExportHeader(tableType),
      dataArr,
      "Salesgear-Reports",
      cbk
    );
  }
};

Utils.constructSequenceReportExportToCsvData = (data, tableType, cbk) => {
  if (data?.length) {
    const dataArr = [];
    data.map(value => {
      if (value?.id || value?.memberId) {
        const tempObj = {};
        tempObj.name = value?.name || "";
        tempObj.status =
          Utils.captializeFirstCharacterAnsRestLowerCase(value?.status) ||
          "Active";
        tempObj.type =
          Utils.captializeFirstCharacterAnsRestLowerCase(value?.type) || "";
        tempObj.owner = Utils.getOwnerName(value?.owner);
        tempObj.created = DateUtils.getMonthDateYear(value?.created);
        tempObj.contactsAddedCount = value?.contactsAddedCount || "0";
        tempObj.emailsSentCount = value?.emailsSentCount || "0";
        tempObj.emailsOpenCount = value?.emailsOpenCount || "0";
        tempObj.openRate = value?.openRate || "0";
        tempObj.emailsClickCount = value?.emailsClickCount || "0";
        tempObj.clickRate = value?.clickRate || "0";
        tempObj.emailsReplyCount = value?.emailsReplyCount || "0";
        tempObj.ReplyRate = value?.ReplyRate || "0";
        tempObj.emailsBounceCount = value?.emailsBounceCount || "0";
        tempObj.BounceRate = value?.BounceRate || "0";
        tempObj.emailsOptedOutCount = value?.emailsOptedOutCount || "0";
        tempObj.callsMadeCount = value?.callsMadeCount || "0";
        tempObj.callsAnswered = value?.callsAnswered || "0";
        tempObj.callsNotAnswered = value?.callsNotAnswered || "0";
        tempObj.callsLoggedCount = value?.callsLoggedCount || "0";
        tempObj.avgCallDurationInSecs =
          DateUtils.getTimeFromSeconds(value?.avgCallDurationInSecs) ||
          "00:00:00";
        tempObj.totalTimeOnCall =
          DateUtils.getTimeFromSeconds(value?.totalTimeOnCall) || "00:00:00";
        tempObj.meetingBookedCount = value?.meetingBookedCount || "0";
        tempObj.websiteVisitedCount = value?.websiteVisitedCount || "0";
        tempObj.linkedInTaskCompletedCount =
          value?.linkedInTaskCompletedCount || "0";
        tempObj.otherTaskCompletedCount = value?.otherTaskCompletedCount || "0";
        tempObj.linkedInMessagesSent = value?.linkedInMessagesSent || "0";
        tempObj.linkedinRepliesReceived = value?.linkedinRepliesReceived || "0";
        tempObj.linkedinConnectionRequestsSent =
          value?.linkedinConnectionRequestsSent || "0";
        tempObj.linkedinConnectionRequestsAccepted =
          value?.linkedinConnectionRequestsAccepted || "0";
        dataArr.push(tempObj);
      }
    });
    Utils.exportCSVFile(
      getSequenceExportHeader("Sequence Name"),
      dataArr,
      "Salesgear-Sequences",
      cbk
    );
  }
};

const getFirstElem = (arr = []) => {
  return arr?.[0] || "";
};

const getPhoneNumber = (phoneArr = []) => {
  const phoneObj = getFirstElem(phoneArr);
  return phoneObj?.number || "";
};

Utils.downloadExportedContact = (link = "") => {
  if (link && link.href && document.body.contains(link)) {
    link.click();
    document.body.removeChild(link);
  }
};

// Export Contact End

Utils.isEligibleForAdvancedOptions = (stages, stageLen) => {
  let flag = false;
  const parsedStageLen = parseInt(stageLen);
  let stageList = Utils.convertObjectToArray(stages);
  stageList = stageList.slice(0, parsedStageLen - 1);
  if (stageList?.length) {
    stageList.map(value => {
      if (["email", "manualEmail"].includes(value?.type)) {
        flag = true;
      }
    });
  }
  return flag;
};

Utils.convertObjectToArray = (stages = {}) => {
  let stagesList = [];
  if (stages) {
    Object.keys(stages).map(key => {
      return stagesList.push(stages[key]);
    });
  }
  return stagesList;
};

Utils.getPreviousMail = (stages, index) => {
  if (stages?.length) {
    index = parseInt(index);
    let manipulatedStages = stages.slice(0, index);
    manipulatedStages = manipulatedStages.reverse();
    const previousMail = manipulatedStages.filter((item, key) => {
      if (item?.type === "email" || item?.type === "manualEmail") {
        return item.subject || item.subject === "";
      }
    });
    return previousMail && previousMail[0];
  }
};

Utils.getPreviousStage = (stages, index) => {
  if (stages?.length) {
    index = parseInt(index);
    return stages[index - 1];
  }
};

Utils.getPreviousMailSubject = previousMail => {
  if (previousMail?.subject) {
    return previousMail.subject;
  }
};

Utils.cancelPreviousSequenceRequest = () => {
  if (sequenceStoreData.cancelSequenceCall !== null) {
    sequenceStoreData.cancelSequenceCall("cancel");
    sequenceStoreData.cancelSequenceCall = null;
  }
};

Utils.convertTaskTypeEnumToString = type => {
  return (type && ConstantObj.taskSubject[type?.toLowerCase()]) || "";
};

Utils.createScheduleDate = date => {
  if (date) {
    const manipulatedDate = date.toLocaleString("sv-SE", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });

    const manipulatedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hourCycle: "h23"
    });
    return `${manipulatedDate}T${manipulatedTime}`;
  }
};

Utils.isEligibleContactFilter = (stage = "") => {
  return ![
    "total",
    "opencount",
    "clickcount",
    "meeting_booked",
    "bounced",
    "replied",
    "licrsentcount",
    "licracceptedcount",
    "profileviewscount"
  ].includes(stage?.toLowerCase());
};

Utils.scrollFilterAutomatically = id => {
  if (localStorage.getItem("scrollFilter")) {
    localStorage.removeItem("scrollFilter");

    const filterContainer = document.getElementById(id);

    window.scrollInterval = setInterval(() => {
      if (
        filterContainer &&
        filterContainer.scrollLeft + filterContainer.offsetWidth !==
          filterContainer.scrollWidth
      ) {
        scrollLeft();
      } else {
        clearInterval(window.scrollInterval);
      }
    }, 12);

    const scrollLeft = () => {
      filterContainer.scrollTo(filterContainer.scrollLeft + 1, 0);
    };
  }
};

Utils.trackFilterMixpanelForContacts = (name, pageType) => {
  return Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
    type: name,
    pageType
  });
};

Utils.trackFilterMixpanel = (name, pageType) => {
  if (name) {
    name = name.toLowerCase();
    switch (name) {
      case "total":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Total",
          pageType
        });
        break;
      case "completed":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Completed",
          pageType
        });
        break;
      case "ongoing":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Ongoing",
          pageType
        });
        break;
      case "paused":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Paused",
          pageType
        });
        break;
      case "opencount":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Opened",
          pageType
        });
        break;
      case "clickcount":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Clicked",
          pageType
        });
        break;
      case "replied":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Replied",
          pageType
        });
        break;
      case "bounced":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Bounced",
          pageType
        });
        break;
      case "opted_out":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Opted Out",
          pageType
        });
        break;
      case "not_contacted":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Not Contacted",
          pageType
        });
        break;
      case "contacted":
        Utils.mixpanelTrack(MP_EVENT.FL_STATISTICS_FILTER, {
          type: "Contacted",
          pageType
        });
        break;
      default:
        break;
    }
  }
};

Utils.removeHttpFromString = (str = "") => {
  if (str?.trim() !== "") {
    return str?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  }
  return str;
};

Utils.getRandomValue = () => {
  let a = Math.floor(Math.random() * 100 + 1);
  let b = Math.floor(Math.random() * 100 + 1);
  return a + b;
};

Utils.getQueryParams = queryString => {
  return new URLSearchParams(queryString);
};

Utils.getFullName = (
  firstName = "",
  lastName = "",
  name = "",
  className = "",
  email = ""
) => {
  if (firstName && lastName) {
    return (
      <h6 className={className}>
        {firstName} {lastName}
      </h6>
    );
  } else if (name && name.trim().length > 0) {
    return <h6 className={className}>{name}</h6>;
  } else if (firstName) {
    return <h6 className={className}>{firstName}</h6>;
  } else if (lastName) {
    return <h6 className={className}>{lastName}</h6>;
  }
  return <h6 className={className}>{email}</h6>;
};

Utils.getFirstLetter = (firstName, lastName, name) => {
  if (firstName) {
    return firstName?.charAt(0);
  } else if (name?.trim()?.length > 0) {
    return name.charAt(0);
  } else if (lastName) {
    return lastName?.charAt(0);
  }
  return "U";
};

Utils.getName = (firstName = "", lastName = "", name = "", email = "") => {
  if (firstName && lastName) {
    return `${firstName?.trim()} ${lastName?.trim()}`;
  } else if (name?.trim()?.length > 0) {
    return name;
  } else if (firstName?.trim()?.length) {
    return firstName?.trim();
  } else if (lastName?.trim()?.length) {
    return lastName?.trim();
  }
  return email || "--";
};

Utils.generateRandomNumber = () => {
  return Math.floor(1000000000 + Math.random() * 9000000000);
};

Utils.checkAddNonceAndCspTag = () => {
  const isCspMetaTagPresent = () => {
    const metaTags = document.getElementsByTagName("meta");
    if (metaTags?.length) {
      for (let i = 0; i < metaTags.length; i++) {
        if (
          metaTags?.[i]?.getAttribute("http-equiv") ===
          "Content-Security-Policy"
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const addNonceToScriptTags = nonce => {
    const scriptTags = document.querySelectorAll("script");
    if (scriptTags?.length) {
      for (let i = 0; i <= scriptTags.length; i++) {
        if (scriptTags?.[i]?.getAttribute("nonce")) {
          scriptTags[i].setAttribute("nonce", nonce);
        }
      }
    }
  };

  const replaceNonceFromCsp = (nonceValue, csp) => {
    if (csp && nonceValue) {
      return csp.replace("{{nonce}}", nonceValue);
    }
  };

  const addCspHeader = randomNo => {
    if (CspValue && !isCspMetaTagPresent()) {
      var meta = document.createElement("meta");
      meta.httpEquiv = "Content-Security-Policy";
      const csp = replaceNonceFromCsp(randomNo, CspValue);
      if (csp) {
        meta.content = csp;
        document.getElementsByTagName("head")[0].prepend(meta);
      }
    }
  };

  const randomNo = Utils.generateRandomNumber();

  if (randomNo) {
    addCspHeader(randomNo);
    addNonceToScriptTags(randomNo);
  }
};

Utils.formatUsPhoneNumber = number => {
  if (number) {
    var cleaned = ("" + number).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return number;
  }
};

Utils.getUSFormattedPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    const removeCountryCode = phoneNumber.replace("+1", "");
    const formattedNumber = Utils.formatUsPhoneNumber(removeCountryCode);
    if (formattedNumber) {
      if (formattedNumber.indexOf("+") !== -1) {
        return formattedNumber;
      } else {
        return `+1 ${formattedNumber}`;
      }
    } else {
      return phoneNumber;
    }
  }
};

Utils.showProductDiscountBanner = () => {
  return true;
};

Utils.cancelPreviousTemplateRequest = () => {
  if (TemplateDataStore.cancelTemplateCall !== null) {
    TemplateDataStore.cancelTemplateCall("cancel");
    TemplateDataStore.cancelTemplateCall = null;
  }
};

Utils.getOwnerName = (owner = {}) => {
  if (owner) {
    const { firstName: fn = "", lastName: ln = "", email = "" } = owner || {};
    let name =
      fn && ln
        ? `${Utils.capitalize(fn)} ${Utils.capitalize(ln)}`
        : fn
        ? `${Utils.capitalize(fn)}`
        : ln
        ? `${Utils.capitalize(ln)}`
        : email || "";
    return name?.trim() || "";
  }
  return "";
};

Utils.capitalize = (string = "") => {
  return string?.[0]?.toUpperCase() + string?.slice(1) || "";
};

Utils.isAdmin = () => {
  return userDetail?.userInfo?.team;
};

Utils.fetchBlockedDomains = () => {
  const config = {
    url: URLS.addDomain,
    method: "GET"
  };
  return makeApi(config);
};

Utils.isEligibleSeatCount = () => {
  const { noOfSeatsEligible = 0, noOfSeatsUsed = 0 } =
    userDetail.userFeatureFlag;
  if (noOfSeatsEligible !== null && noOfSeatsUsed !== null) {
    let seats = noOfSeatsEligible - noOfSeatsUsed;
    return seats > 0;
  }
  return false;
};

Utils.isToShowEyeIcon = memberId => {
  const userInfo = userDetail.userInfo;
  if (userInfo?.memberId) {
    return userInfo.memberId === memberId;
  }
};

Utils.copyToClipboard = str => {
  if (str) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
};

Utils.getOwnerInitial = owner => {
  if (owner) {
    const { firstName: fn, lastName: ln, email } = owner || {};
    if (fn && ln) {
      return fn[0] + ln[0];
    } else if (fn) {
      return fn.slice(0, 2);
    } else if (ln) {
      return ln.slice(0, 2);
    } else if (email) {
      return email.slice(0, 2);
    }
  }
};

Utils.updateCustomUrlType = () => {
  const { team } = userDetail.userInfo;
  const { brandedUrlEnabled } = userDetail.userFeatureFlag;
  if (!brandedUrlEnabled) {
    return !team ? "customUrl-admin" : "customUrl";
  } else {
    if (!team) {
      return "customUrl-admin";
    }
  }
};

Utils.getEntityNameUpdated = (
  shareType,
  accessType,
  owner,
  name,
  memberId,
  folderName
) => {
  return (
    <>
      <span className="nameText" title={name}>
        {name || "-"}
      </span>
      {Utils.isShared(shareType) && owner && (
        <Tooltip
          placement="right"
          tooltip={`Shared by ${Utils.getOwnerName(owner)}`}
        >
          <div className="sharedBadgeWrapper">
            <span className="material-icons-outlined sharedFolder">
              folder_shared
            </span>
            <span className="ownerBadgeWrap">{shareType}</span>
          </div>
        </Tooltip>
      )}
      {folderName && (
        <div className="folderNameBadgeWrapper">
          <span className="material-icons-outlined sharedFolder">folder</span>
          <span className="folderNameBadge" title={folderName}>
            {folderName}
          </span>
        </div>
      )}
    </>
  );
};

Utils.getEntityName = (shareType, accessType, owner, name, memberId) => {
  return (
    <>
      <span className="nameText">{name || "-"}</span>
      {Utils.isShared(shareType) && (
        <span className="sharedBadge">{shareType}</span>
      )}
      {Utils.isShared(shareType) && owner && (
        <Tooltip
          placement="right"
          tooltip={`Shared by ${Utils.getOwnerName(owner)}`}
        >
          <div className="ownerCont">
            <span className="ownerBadge" id={name} data-tip>
              {Utils.getOwnerInitial(owner)}
            </span>
            {Utils.isReadOnlyEntity(accessType, shareType) &&
              (Utils.isToShowEyeIcon(memberId) ? (
                <i className="material-icons-outlined sequenceOwnerReadOnlyIcon">
                  remove_red_eye
                </i>
              ) : (
                <i className="material-icons lockIcon">lock</i>
              ))}
          </div>
        </Tooltip>
      )}
    </>
  );
};

Utils.addBrandUrlForTracking = () => {
  const { brandedUrlEnabled = false } = userDetail?.userFeatureFlag || {};
  const { domain = "" } = userDetail?.brandUrlInfo || {};
  if (brandedUrlEnabled && domain) {
    return domain;
  } else {
    return ConstantObj.LINK_TRACKER_BASE_URL;
  }
};

Utils.isReadOnlyEntity = (accesType, shareType) => {
  if (Utils.isShared(shareType)) {
    if (accesType === ConstantObj.sequenceType.shared.rw) {
      return false;
    }
    return true;
  }
};

Utils.windowRedirect = (
  url = "",
  target = "_blank",
  noOpener = true,
  validateHttp = false
) => {
  if (url) {
    if (validateHttp && !["https://", "http://"].includes(url)) {
      url = "https://" + url;
    }
    if (noOpener) {
      window.open(url, target, "noopener noreferrer");
    } else {
      window.open(url, target);
    }
  }
};

Utils.getFileExtensionType = (fileName = "") => {
  if (fileName) {
    return fileName.split(".").pop().toLowerCase();
  }
  return false;
};

Utils.getFileSizeAndType = (size = 0) => {
  let number = 0;
  if (size > 0) {
    if (size > 1024) {
      number = size / 1024;
      return `${number.toFixed(2)} MB`;
    } else {
      return `${size.toFixed(2)} KB`;
    }
  }
  return `0.00 KB`;
};

Utils.sequenceHaveAttachments = steps => {
  if (steps && Object.keys(steps).length) {
    let flag = false;
    for (var stepsItem in steps) {
      const variants = steps?.[stepsItem]?.variants;
      for (var key in variants) {
        if (variants[key]?.attachments?.length) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }
};

Utils.getUniqueAttachments = attachmentsArray => {
  if (attachmentsArray && attachmentsArray.length) {
    const flags = [],
      output = [],
      l = attachmentsArray.length;
    for (let i = 0; i < l; i++) {
      if (flags[attachmentsArray[i].id]) continue;
      flags[attachmentsArray[i].id] = true;
      output.push(attachmentsArray[i]);
    }
    return output;
  }
};

Utils.getAttachmentsArr = (attachments = [], existAttachments = []) => {
  const attList = Utils.createObjWithoutRef(attachments);
  const newAttArr = [...existAttachments];
  if (attList?.length > 0) {
    attList.map(ele => {
      if (ele?.attachments) {
        const {
          id = "",
          attachmentName = "",
          attachmentSizeInKB = ""
        } = ele?.attachments || {};
        if (id && attachmentName) {
          const tempObj = { id, attachmentName, attachmentSizeInKB };
          newAttArr.push(tempObj);
        }
      }
    });
  }
  return Utils.getUniqueAttachments(newAttArr);
};

Utils.arrayHasObject = (arr = []) => {
  if (arr && arr.length > 0) {
    return true;
  }
  return false;
};

Utils.contentReadyOnly = (content = "") => {
  return (
    content?.replace(
      new RegExp('contenteditable="true"', "g"),
      'contenteditable="false"'
    ) || ""
  );
};

Utils.sequenceHasPersonalizedToken = string => {
  const tokenList = [];
  if (string?.length) {
    if (TOKENS) {
      for (let i = 0; i < TOKENS.length; i++) {
        const tempToken = TOKENS[i];
        if (tempToken?.value && string.includes(tempToken.value)) {
          tokenList.push(tempToken.key);
        }
      }
    }
  }
  return tokenList;
};

Utils.stringHasToken = string => {
  let newArr = Utils.sequenceHasPersonalizedToken(string);
  if (newArr?.length > 0) {
    return newArr;
  }
  return false;
};

Utils.getUniqueArrayList = list => {
  if (list && list.length > 0) {
    const resultArr = list.filter((data, index) => {
      return list.indexOf(data) === index;
    });
    return resultArr;
  }
  return false;
};

Utils.checkSequenceHasPersonalizedToken = seqObj => {
  const tokenArrList = [];
  const { steps = "" } = seqObj || "";
  if (steps && Object.keys(steps).length > 0) {
    const stagesLen = Object.keys(steps).length;
    for (let i = 1; i <= stagesLen; i++) {
      const variants = steps?.[i]?.variants;
      for (let property in variants) {
        const updatedProperty = variants[property];
        let subjectTokenList = Utils.stringHasToken(updatedProperty?.subject);
        let contentTokenList = Utils.stringHasToken(updatedProperty?.content);
        let notesTokenList = Utils.stringHasToken(updatedProperty?.notes);
        if (subjectTokenList) {
          subjectTokenList.map(item => {
            tokenArrList.push(item.toLowerCase());
          });
        }
        if (contentTokenList) {
          contentTokenList.map(item => {
            tokenArrList.push(item.toLowerCase());
          });
        }
        if (notesTokenList) {
          notesTokenList.map(item => {
            tokenArrList.push(item.toLowerCase());
          });
        }
      }
    }
  }
  return Utils.getUniqueArrayList(tokenArrList);
};

Utils.replaceSenderTokenWithValue = (string, preview = true) => {
  if (string?.length) {
    if (SENDER_TOKENS) {
      for (let i = 0; i < SENDER_TOKENS.length; i++) {
        const senderTokenObj = SENDER_TOKENS[i];
        const senderTokenKey = senderTokenObj.key;
        const senderTokenValue = senderTokenObj.value;
        const senderTokenFallBackObj = settingsStoreData.userProfileData;
        if (
          senderTokenKey &&
          senderTokenValue &&
          string.includes(senderTokenValue) &&
          senderTokenFallBackObj?.[senderTokenKey]
        ) {
          string = string
            .split(senderTokenValue)
            .join(
              preview
                ? `<span class='htYellowPT'>${senderTokenFallBackObj[senderTokenKey]}</span>`
                : senderTokenFallBackObj[senderTokenKey]
            );
        }
      }
    }
    return string;
  }
};

const customFieldsFallBackObj = customFieldsValue => {
  let obj = {};

  for (let index = 0; index <= 30; index++) {
    const customVal =
      customFieldsValue[`customField${index + 1}`] || `customField${index + 1}`;
    obj = {
      ...obj,
      [`customField${index + 1}`]: `{{${customVal}}}`
    };
  }
  return obj;
};

const customieldsHighLightObj = customFieldsValue => {
  let obj = {};
  for (let index = 0; index <= 30; index++) {
    const customVal =
      customFieldsValue[`customField${index + 1}`] || `customField${index + 1}`;
    obj = {
      ...obj,
      [`customField${
        index + 1
      }`]: `<span class='htYellowPT'>{{${customVal}}}</span>`
    };
  }
  return obj;
};

Utils.replaceTokenWithValue = (
  string,
  preview = true,
  getUnresolvedTokens = false,
  customFields = {}
) => {
  if (string?.length) {
    string = Utils.replaceSenderTokenWithValue(string, preview);
    if (TOKENS) {
      for (let i = 0; i < TOKENS.length; i++) {
        const tempTokenObj = TOKENS[i];
        const tokeFallBackObj = preview
          ? TOKENS[i].uniqueKey === "customFields"
            ? customieldsHighLightObj(customFields)
            : TOKENS_HIGHLIGHT_OBJ
          : TOKENS[i].uniqueKey === "customFields"
          ? customFieldsFallBackObj(customFields)
          : TOKENS_FALLBACK_OBJ;
        const tokenKey = tempTokenObj.key;
        const tokenValue = tempTokenObj.value;
        if (
          tokenKey &&
          tokenValue &&
          string?.includes(tokenValue) &&
          tokeFallBackObj &&
          tokeFallBackObj[tokenKey]
        ) {
          string = string.split(tokenValue).join(tokeFallBackObj[tokenKey]);
        }
      }
      string = string.replaceAll(/\s*<div>\s*\t*<\/div>/g, "<div><br></div>");
    }
    return getUnresolvedTokens
      ? TokenUtils.hasInvalidTokens(string, customFields)
      : TokenUtils.unresolvedTokenHighLight(string, customFields);
  }
  return "";
};

Utils.isAccountConnected = () => {
  const { connectedInboxes = [] } = userDetail || {};
  if (connectedInboxes?.length > 0) {
    let hasActiveAccount = false;
    connectedInboxes.forEach(account => {
      if (account?.email && account?.status === "ACTIVE") {
        hasActiveAccount = true;
      }
    });
    return hasActiveAccount;
  }
  return false;
};

Utils.addSignatureForTestEmailContent = (content = "") => {
  let signature = userDetail?.connectedInboxes?.[0]?.signature || "";
  if (content && signature?.length) {
    const signatureDom = document.createElement("div");
    const parsedDom = document.createElement("div");
    signatureDom.innerHTML = signature;
    if (
      signatureDom &&
      signatureDom.getElementsByClassName("ql-editor").length &&
      signatureDom.getElementsByClassName("ql-editor")[0]
    ) {
      const extractedSignatureHtml =
        signatureDom.getElementsByClassName("ql-editor")[0].innerHTML;
      parsedDom.innerHTML = extractedSignatureHtml;
      const finalDom = document.createElement("div");
      finalDom.innerHTML = content;
      if (finalDom && finalDom.getElementsByClassName("ql-editor").length) {
        finalDom.getElementsByClassName("ql-editor")[0].append(parsedDom);
      }
      return finalDom.innerHTML;
    }
  }
  return content;
};

Utils.sendTestEmail = async (data, dataMap, cbk, props, closePopup) => {
  try {
    const config = {
      url: URLS.sendTestEmail ? URLS.sendTestEmail : "",
      method: "POST",
      data
    };
    const res = await makeApi(config);
    let email = Utils.isAccountConnected() || "to your connected account";
    if (+res?.data?.statusCode === 200) {
      toasterState.setToastMsg(
        res?.data?.message || `Test email successfully sent to ${email}`,
        "success"
      );
      Utils.mixpanelTrack(MP_EVENT.TEST_EMAIL_SENT, dataMap);
    } else if (+res?.response?.status === 404) {
      InboxUtils.checkAndAddConnectInbox([]);
      Utils.showAddAccountPopup(
        props,
        TEST_EMAIL_CONNECT_INBOX_MSG,
        closePopup
      );
    } else if (+res?.response?.status === 401) {
      toasterState.setToastMsg(
        "Failed to send test email. Please reconnect your inbox and try again.",
        "fail"
      );
      Utils.mixpanelTrack(MP_EVENT.TEST_EMAIL_FAILED, {
        ...dataMap,
        status: "ACCESS_REVOKED"
      });
      await fetchConnectedEmails();
    } else {
      toasterState.setToastMsg(
        `Sending test email failed. Please try again`,
        "fail"
      );
      Utils.mixpanelTrack(MP_EVENT.TEST_EMAIL_FAILED, dataMap);
      await fetchConnectedEmails();
    }
    onboardingStoreData.getOnBoardingData();
    cbk(false);
  } catch (err) {
    toasterState.setToastMsg(
      `Sending test email failed. Please try again`,
      "fail"
    );
    console.error(err, "Sending Test Email Failed");
    cbk(false);
  }
};

Utils.contentBodyHasTextOrImg = contentBody => {
  const tempDom = document.createElement("div");
  tempDom.innerHTML = contentBody;
  let isValidContent = tempDom.innerText;
  const hasImgInTemplate = tempDom && tempDom.getElementsByTagName("img");
  if (
    (isValidContent && isValidContent.length) ||
    (hasImgInTemplate && hasImgInTemplate.length)
  ) {
    return true;
  }
  return false;
};

Utils.getAttachmentIds = (att = []) => {
  const attIds = [];
  if (att && att.length) {
    att.map(item => {
      if (item && item.id) {
        attIds.push(item.id);
      }
    });
  }
  return attIds;
};

Utils.smtpCommingSoonPopup = () => {
  comingSoonPopupState.setPopupDetails({
    descHtml:
      "This Email has attachments. We provide support for attachments only for <b>Outlook email accounts</b> and <b>Gmail accounts</b>. Support for <b>SMTP accounts</b> coming soon."
  });
  comingSoonPopupState.setShowPopup(true);
};

Utils.extractTagNameFromList = tagsList => {
  if (tagsList && tagsList.length) {
    const tempSet = new Set();
    tagsList.map(obj => {
      const { value } = obj;
      if (value) {
        tempSet.add(value);
      }
    });
    return [...tempSet];
  }
};

Utils.showAddSingleContactToSequencePopup = (sequenceData, successCbk) => {
  const unrefSequenceData = Utils.createObjWithoutRef(sequenceData);
  const tokenList =
    Utils.checkSequenceHasPersonalizedToken(unrefSequenceData) || [];
  if (unrefSequenceData && Object.keys(unrefSequenceData).length) {
    addSingleContactPopupState.setSequenceData(unrefSequenceData);
  }
  addSingleContactPopupState.setTokenList(tokenList);
  addSingleContactPopupState.setSubmitCallback(
    Utils.showSingleContactSchedulePopup
  );
  addSingleContactPopupState.setSuccessCallback(successCbk);
  addSingleContactPopupState.setShowPopup(true);
};

Utils.showAddTagsPopup = (cbk, contactsLen) => {
  const tempObj = { cbk, contactsLen };
  tagPopupState.setPopupValues(tempObj);
  tagPopupState.setShowPopup(true);
};

Utils.singleContactScheduleSequenceAction = (
  contactPayload,
  sequenceData,
  successCbk
) => {
  const { id: sequenceId = "", name: seqName = "" } = sequenceData || {};
  let contact = contactPayload?.contacts?.[0];
  const scheduleCbk = async () => {
    if (contactPayload?.contacts) {
      toasterState.setToastMsg("Scheduling your sequence. Please wait", "info");
      const payload = { contact, sequenceId };
      payload.sequenceId = sequenceId;
      payload.contactTags = contactPayload.contactTags || [];
      payload.force = true;
      const response = await Utils.scheduleSingleContactToSequence(payload);
      const { data: { responseCode = 200 } = {} } = response;
      if (responseCode === 200) {
        contactPayload?.contactTags?.length &&
          Utils.mixpanelTrack(MP_EVENT.TAG_ADDED, {
            pageType: window.location.pathname
          });
        successCbk(seqName, 1, sequenceId, response);
      } else if (responseCode === 105) {
        SequenceUtils.showNoInboxconnectedPopup();
      } else if (+responseCode === 509) {
        Utils.showContactsUploadLimitReached();
      } else {
        Utils.handleSingleContactFailure(responseCode);
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  };
  scheduleCbk();
  document.getElementById("seqBulkPopupWrapper").style.display = "none";
  document.getElementsByClassName("seqBulkCont")[0].innerHTML = "";
};

Utils.showSingleContactSchedulePopup = async (
  contactPayload,
  sequenceData,
  successCbk
) => {
  if (sequenceData && Object.keys(sequenceData)?.length) {
    const {
      id = "",
      folderId = "",
      sharedType = "",
      owner = {}
    } = sequenceData || {};
    const { email = "" } = owner || {};
    let contact = contactPayload?.contacts?.[0];
    const [excludeContact, optedOutContact] = await Promise.all([
      await sequenceStoreData.valideExcludeContact(id, contact?.email),
      await sequenceStoreData.validateToContactStatus(contact?.email)
    ]);
    if (optedOutContact) {
      toasterState.setToastMsg(
        "Cannot add sequence to opted out email address. Please add a valid recipient address",
        "fail"
      );
    } else if (excludeContact) {
      const sequenceRedirectUrl = SequenceUtils.createSequenceRedirectUrl(
        id,
        folderId,
        sharedType === "SHARED",
        email,
        "#settings"
      );
      if (sequenceRedirectUrl) {
        confirmationPopupState.setPopupValues({
          content: `This contact was previously added to another sequence. Please select a different sequence or <a href=${sequenceRedirectUrl} style="text-decoration:none;" rel="noopener">update your sequence safety setting</a>`,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        toasterState.setToastMsg(
          "Upload failed. This contact has already been added to some other sequence.",
          "fail"
        );
      }
    } else {
      Utils.singleContactScheduleSequenceAction(
        contactPayload,
        sequenceData,
        successCbk
      );
    }
  }
};

Utils.scheduleSingleContactToSequence = async data => {
  const config = {
    url: URLS.addSingleContactToSequence ? URLS.addSingleContactToSequence : "",
    method: "POST",
    data
  };
  return makeApi(config);
};

Utils.handleSingleContactFailure = (code = 500) => {
  if (code) {
    let message = "";
    switch (+code) {
      case 501:
        message = "Invalid Contact. Please try with a different contact.";
        break;
      case 104:
        message = "Upload failed. The contact has bounced.";
        break;
      case 502:
      case 426:
        message =
          "Contact limit reached. Upgrade to add more contacts in Salesgear.";
        break;
      case 103:
        message = "Upload failed. This contact has been opted out.";
        break;
      case 109:
        message =
          "Email validation failed. This contact has been opted out / bounced.";
        break;
      case 102:
      case 409:
        message = "Contact already present in Salesgear.";
        break;
      default:
        message = "Something went wrong. Please try again later.";
    }
    toasterState.setToastMsg(message, "failure");
  }
};

Utils.createObjWithoutRef = list => {
  return JSON.parse(JSON.stringify(list));
};

Utils.findIsRequiredFieldForSingleContactSequence = (
  id = "",
  tokenList = []
) => {
  if (id && tokenList?.length) {
    return tokenList.includes(id.toLowerCase());
  }
  return false;
};

Utils.showAddBulkContactToSequencePopup = (sequenceData, scheduleSeqCbk) => {
  const unrefSequenceData = Utils.createObjWithoutRef(sequenceData);
  const tokenList = Utils.checkSequenceHasPersonalizedToken(unrefSequenceData);
  addBulkContactPopupState.setPageType("sequence");
  addBulkContactPopupState.setTokenList(tokenList);
  addBulkContactPopupState.setScheduleSequenceCbk(scheduleSeqCbk);
  addBulkContactPopupState.setSequenceData(sequenceData);
  addBulkContactPopupState.setSubmitCallback(bulkContactUpload);
  addBulkContactPopupState.setShowPopup(true);
};

Utils.addUserUpdatedCusotmFields = (optionsList, fieldCount = 0) => {
  const stringsArray = [];
  if (fieldCount >= 1) {
    for (let i = 1; i <= fieldCount; i++) {
      const str = "customField" + i;
      stringsArray?.push(str);
    }
  }
  const extractedFields = {};
  for (const key of Object.keys(settingsStoreData?.customFields)) {
    if (stringsArray?.includes(key)) {
      extractedFields[key] = settingsStoreData?.customFields[key];
    }
  }
  const userUpdatedFields = extractedFields;
  const mappingFieldKeys =
    Utils.extractObjectKeysFromArrayOfObject(optionsList);

  if (userUpdatedFields) {
    Object.keys(userUpdatedFields).map(value => {
      if (value && value.toLowerCase().indexOf("customfield") !== -1) {
        const fieldNumber = value.split("customField").pop();
        const label = userUpdatedFields[value]
          ? userUpdatedFields[value]
          : `Custom Field ${fieldNumber}`;

        if (mappingFieldKeys.includes(value)) {
          // If the options exists already, just update the value of field
          optionsList.map(fieldObj => {
            if (fieldObj.key === value) {
              fieldObj.label = label;
            }
          });
        } else {
          // If the options doesnt exist, just add a new option
          const tempObj = { key: value, label };
          optionsList.push(tempObj);
        }
      }
    });
    return optionsList;
  }
};

Utils.extractObjectKeysFromArrayOfObject = fields => {
  const tempArr = [];
  const mappingFieldsModel = fields;
  mappingFieldsModel &&
    mappingFieldsModel.map(value => {
      const { key } = value;
      tempArr.push(key);
    });
  return tempArr;
};

const bulkContactUpload = (payload, sequenceCbk, sequenceId) => {
  const bulkContactPayload = Utils.getBulkSequencePayload(payload);
  sequenceCbk && sequenceCbk(bulkContactPayload, sequenceId);
};

Utils.getBulkSequencePayload = (payload, contactPage = false) => {
  if (payload?.length) {
    const contactArr = [];
    payload.map(obj => {
      const contact = {};
      contact.firstname = obj.firstname || obj.firstName || null;
      contact.lastname = obj.lastname || obj.lastName || null;
      contact.email = obj.email || null;
      contact.linkedinid = Utils.removeHttpFromString(
        obj.linkedinId || obj.linkedinid || null
      );
      contact.mobilenumber = Utils.convertPhoneAsArray(
        obj.mobilePhone ||
          obj.mobilephone ||
          obj.mobile ||
          obj.mobilenumber ||
          obj.mobileNumber ||
          null
      );
      contact.othernumber = Utils.convertPhoneAsArray(
        obj.otherPhone ||
          obj.otherphone ||
          obj.otherNumber ||
          obj.othernumber ||
          null
      );
      contact.phonenumber = Utils.convertPhoneAsArray(
        obj.phone || obj.phonenumber || obj.phoneNumber || null
      );
      contact.companyname = obj.company || obj.companyname || null;
      contact.jobtitle = obj.jobTitle || obj.jobtitle || null;
      contact.industry = obj.industry || null;
      contact.street = obj.street || null;
      contact.city = obj.city || null;
      contact.state = obj.state || null;
      contact.country = obj.country || null;
      contact.zipcode = obj.zipCode || obj.zip || obj.zipcode || null;
      contact.website = obj.website || null;
      contact.facebook = obj.facebook || null;
      contact.twitter = obj.twitter || null;
      contact.contactnotes =
        obj.contactNotes || obj.contactnotes || obj.notes || null;
      contact.customfield = Utils.getMappedCustomFieldsFromPayload(
        (contactPage && obj.customFields) || obj || {}
      );
      contactArr.push(contact);
    });
    return contactArr;
  }
};

Utils.getMappedCustomFieldsFromPayload = (obj, isCamelCaseField = false) => {
  const customFieldObj = {};
  if (obj && Object.keys(obj).length) {
    Object.keys(obj).map(keys => {
      keys &&
        obj[keys] &&
        keys.toLowerCase().indexOf("customfield") !== -1 &&
        (customFieldObj[
          isCamelCaseField ? keys?.replace("f", "F") : keys.toLowerCase()
        ] = obj?.[keys] || null);
    });
  }
  return customFieldObj;
};

Utils.convertPhoneAsArray = (phNumber = "") => {
  if (phNumber && typeof phNumber === "string") {
    return phNumber.split(",").toString();
  }
  return phNumber?.toString() || null;
};

Utils.findDuplicatesInArray = array => {
  return array.filter(
    (
      s => v =>
        s.has(v) || !s.add(v)
    )(new Set())
  );
};

Utils.isOldSequence = date => {
  if (date) {
    let formatDate = new Date(date);
    let seqDate = new Date(
      `${
        formatDate.getMonth() + 1
      }/${formatDate.getDate()}/${formatDate.getFullYear()}`
    );
    let dateDiff = NEW_STAGE_ANALYTICS_DATE.getTime() - seqDate.getTime();
    return dateDiff / (1000 * 3600 * 24) > 0;
  }
  return "--";
};

Utils.triggerDriverForNewSequnce = () => {
  if (onboardingStoreData?.onBoardingFlags?.firstSequence === null) {
    return DriverMaster.execute(createNewSequenceSteps);
  }
};

Utils.initiateIntercom = id => {
  window.Intercom("startTour", IntercomTour?.[id]);
  if (id === "sequence") {
    window.Intercom("startTour", IntercomTour.newSequenceSteps);
  }
};

Utils.initiateDriver = (type, id) => {
  if (id) {
    switch (id) {
      case "emailAccount":
        DriverMaster.execute(settingsSteps);
        return;
      case "sequence":
        return type === "demo"
          ? DriverMaster.execute(createNewSequenceSteps)
          : Utils.triggerDriverForNewSequnce();
      case "contacts":
        return DriverMaster.execute(addContactSteps);
      case "contactsPage":
        return DriverMaster.execute(addNewContactsSteps);
      case "sequenceContacts":
        return DriverMaster.execute(addContactSteps);
      case "templates":
        return DriverMaster.execute(createNewTemplateSteps);
      default:
        return false;
    }
  }
};

Utils.captializeFirstCharacter = (text = "") => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
};

Utils.captializeFirstCharacterAnsRestLowerCase = (text = "") => {
  return text
    ? text.charAt(0).toUpperCase() + text.slice(1)?.toLowerCase()
    : "";
};

Utils.getPageType = () => {
  let urlPathArr = window.location.pathname.split("/");
  let tempUrlPath = urlPathArr.filter(item => item.length);
  return Utils.captializeFirstCharacter(tempUrlPath[0]);
};

Utils.isValidBrowserTrackingCode = trackingCode => {
  if (trackingCode) {
    if (ALPHABETS_REGEX.test(trackingCode)) {
      return false; // Not valid if alphanumeric presents in the trackingCode. EN-522
    }
    return true;
  } else {
    return false;
  }
};

Utils.getAllMembersInTeam = async () => {
  let res = await Utils.getTeamMember();
  return (res?.data?.length > 0 && res.data) || [];
};

Utils.userTeamList = async (allData = false) => {
  let res = await Utils.getTeamMember();
  return allData
    ? res?.data
    : (res?.data?.length > 0 && res.data.length > 1 && res.data) || [];
};

const showPartialPausedErrorMessage = () => {
  confirmationPopupState.setPopupValues({
    content:
      "Sorry, we were not able to pause the sequence for all contacts. Please try pausing the sequence again",
    actionBtnText: "Ok",
    callback: () => {}
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.getDateDiffNumber = (date1, date2) => {
  let formatDate1 = new Date(date1);
  let formatDate2 = new Date(date2);
  let dateDiff = formatDate1.getTime() - formatDate2.getTime();
  return Math.abs(dateDiff / (1000 * 3600 * 24)) + 1;
};

Utils.formatDateHypen = date => {
  if (date) {
    let d = new Date(date);
    return `${d.getFullYear()}-${
      d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)
    }-${d.getDate() > 9 ? d.getDate() : "0" + d.getDate()}`;
  }
  return "--";
};

const getSignatureForConnectedAccount = (email = "") => {
  if (userDetail?.connectedInboxes?.length) {
    const filteredInbox = userDetail.connectedInboxes.filter(
      item => item?.email === email
    );
    return filteredInbox?.[0]?.signature;
  }
};

Utils.getContentWithSignature = (
  editorValue,
  dynamicInboxId = false,
  email = ""
) => {
  let signature = dynamicInboxId
    ? getSignatureForConnectedAccount(email)
    : userDetail?.connectedInboxes?.[0]?.signature || "";

  const previewDom = document.createElement("div");
  previewDom.innerHTML = editorValue;
  if (previewDom?.firstChild?.nodeName === "#text") {
    previewDom.innerHTML += signature;
    return previewDom;
  } else {
    if (previewDom.firstChild && previewDom?.firstChild?.nodeName !== "#text") {
      previewDom.firstChild.insertAdjacentHTML("beforeend", signature || "");
    } else {
      previewDom.innerHTML = signature || "";
    }
  }

  return previewDom.firstChild;
};

Utils.isPlural = count => {
  return (count > 1 && "s") || "";
};

Utils.checkEligibleTemplateCount = () => {
  const { noOfTemplatesEligible = 0, noOfTemplatesUsed = 0 } =
    userDetail.userFeatureFlag;

  let count = noOfTemplatesEligible - noOfTemplatesUsed;
  return count > 0;
};

Utils.getTimeInHours = time => {
  if (time) {
    let [hour] = time.split(":");
    hour = parseInt(hour);
    var suffix = hour >= 12 ? "pm" : "am";
    var hours = ((hour + 11) % 12) + 1 + " " + suffix;
    return hours;
  }
};

Utils.getCustomTime = time => {
  if (time) {
    let [hour, minutes] = time.split(":");
    hour = parseInt(hour);
    var suffix = hour >= 12 ? "pm" : "am";
    var hours = ((hour + 11) % 12) + 1 + ":" + minutes + " " + suffix;
    return hours;
  }
};

Utils.getFormattedTime = value => {
  const updatedValue = value?.trim();
  let [hours, minutes, modifier] = updatedValue.split(/[: ]+/);
  hours = hours?.length > 1 ? hours : `0${hours}`;
  if (hours === "12") {
    hours = "00";
  }
  if (modifier?.toLowerCase() === "pm") {
    hours = parseInt(hours, 10) + 12;
  }
  return { hours: `${hours}:${minutes}`, hoursInNumber: `${hours}${minutes}` };
};

Utils.getFirstEmailStatus = steps => {
  let replyAsThread = false;
  let exitLoop = false;
  for (let property in steps) {
    const variants = steps?.[property]?.variants;
    for (let variantProperty in variants) {
      const variantType = variants?.[variantProperty]?.type;
      if (["email", "manualEmail"].includes(variantType)) {
        exitLoop = true;
        const variantReplyAsThread = variants[variantProperty]?.replyAsThread;
        const variantStatus = variants[variantProperty]?.status === "ACTIVE";
        if (variantReplyAsThread && variantStatus) {
          replyAsThread = variantReplyAsThread;
          break;
        }
      }
    }
    if (exitLoop) break;
  }
  return replyAsThread;
};

Utils.copyCount = (word = "", matchKey = "", symbol = " ") => {
  if (word) {
    let newArr = word?.trim()?.toLowerCase()?.split(symbol);
    let hasCopyText = newArr.reduce(
      (acc, value) => (matchKey === value?.trim() ? acc + 1 : acc),
      0
    );
    if (hasCopyText === 1) {
      return 1;
    } else {
      let count =
        word
          ?.trim()
          ?.toLowerCase()
          ?.match(/\(([^)]+)\)/)?.[1] || 0;
      return +count >= 1 ? +count + 1 : 0;
    }
  }
  return 0;
};

Utils.getFullNameFromUserInfo = (user = {}) => {
  const { firstName = "", lastName = "", email = "" } = user || {};
  return (
    (firstName && lastName && `${firstName} ${lastName}`) ||
    firstName ||
    lastName ||
    email
  );
};

Utils.focusInputBox = (id = "") => {
  if (id) {
    let domId = document.getElementById(id);
    domId &&
      setTimeout(() => {
        domId.focus();
        domId.selectionStart = domId.value.length;
      }, 0);
  }
};

Utils.addNullForEmptyObject = (obj = null) => {
  if (obj && Object.keys(obj).length > 0) {
    Object.keys(obj).map(keys => {
      obj[keys] = obj?.[keys] || null;
    });
  }
  return obj;
};

Utils.dialerSettingsType = () => {
  const { planName = "", trialEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const paywallContent =
    planName?.toLowerCase() === "unlimited" && !trialEnabled
      ? "dialerSettingsForUnlimitedDisabled"
      : planName?.toLowerCase() === "pro" && !trialEnabled
      ? "dialerSettingsForProDisabled"
      : "dialerSettings";
  return paywallContent;
};

Utils.getActiveFilterKey = activeTab => {
  const tabList = {
    opencount: "OPENED",
    clickcount: "CLICKED"
  };
  return tabList?.[activeTab?.toLowerCase()] || null;
};

Utils.showMessagePopup = (
  msg = "Please contact your account administrator to upgrade",
  cbk = false
) => {
  messagePopupState.setCallback(cbk);
  messagePopupState.setMessage(msg);
  messagePopupState.setShowPopup(true);
};

Utils.hasObject = (obj = {}) => {
  return obj && Object.keys(obj)?.length > 0;
};

Utils.isSubmitKeyAction = (event = {}) => {
  Utils.preventDefaultFn(event);
  if (
    Utils.hasObject(event) &&
    (event?.key === "Enter" || event?.charCode === 13 || event?.keyCode === 13)
  ) {
    return true;
  }
  return false;
};

Utils.checkCustomerIdAvailable = () => {
  const responseCode = userDetail?.userStripeCode || "";
  let msg = "";
  if (responseCode) {
    switch (responseCode) {
      case 200:
        return 200;
      case 203:
        return 203;
      case 206:
        msg =
          "Please contact your account administrator to change your call settings";
        Utils.showMessagePopup(msg);
        break;
      default:
        msg = `Please contact <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to add phone credits`;
        Utils.showMessagePopup(msg);
        return "";
    }
  } else {
    return "";
  }
};

Utils.isEligibleToShowPaymentPopup = (cardResponse = "") => {
  const { dialerEnabled = false, subscriptionPlanId = "" } =
    userDetail?.userFeatureFlag || {};
  return cardResponse === 203 && !subscriptionPlanId && dialerEnabled;
};

Utils.reloadFeatureFlag = async (allowMultipleAPI = false) => {
  let activeSubscription = await getFeatureFlagWithTeam(allowMultipleAPI);
  userDetail.setUserFeatureFlag(activeSubscription);
};

Utils.convertDomToPlainText = (content = "") => {
  if (content?.length) {
    const updatedContent = content.replace(
      /(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g,
      ""
    );
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = updatedContent;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  return "";
};

// --------- EMAIL REPLY & FORWARD FUNCTION UTILS -------------------
Utils.addEmptySpaceAboveThreadEmail = (content = "") => {
  let tempDom = document.createElement("div");
  tempDom.innerHTML = content || "<div></div>";
  let addDom = tempDom.querySelector("div");
  if (addDom) {
    addDom.insertAdjacentHTML("afterbegin", "<div><br/></div>");
  }
  return tempDom.innerHTML;
};

Utils.joinThreadedEmailAndTemplate = (
  threadContent = "",
  templateContent = ""
) => {
  let tempDom = document.createElement("div");
  tempDom.innerHTML = threadContent || "<div></div>";
  let addDom = tempDom.querySelector("div");
  if (addDom) {
    addDom.insertAdjacentHTML(
      "afterbegin",
      `${templateContent}<div><br/></div>`
    );
  }
  return tempDom.innerHTML;
};

Utils.replaceBlockquoteEmptyTag = (content = "") => {
  let tempContent = content?.replace(
    /\s*<blockquote>\s*\t*<\/blockquote>/g,
    ""
  );
  let tempDom = document.createElement("div");
  tempDom.innerHTML = "<div></div>";
  tempDom.firstChild.insertAdjacentHTML(
    "beforeend",
    `<br/><br/>${tempContent}`
  );
  return tempDom.innerHTML;
};

Utils.splitAndJoinDomWithoutSpace = (content = "") => {
  return (
    content
      ?.split("\n")
      ?.map(item => item.trim())
      ?.join("") ||
    content ||
    ""
  );
};

Utils.getEmailList = (data = {}) => {
  let emailList = [];
  let listIterKey = ["to", "cc", "bcc"];
  listIterKey.forEach(key => {
    if (data?.[key]?.length) {
      let list = data[key];
      list.map(element => {
        emailList.push(element.email);
      });
    }
  });
  return emailList;
};

Utils.validateEmailAddressStatus = async (data = []) => {
  if (data?.length > 0) {
    const url = URLS.validateEmailStatus;
    const config = {
      url,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    return res?.data;
  }
  return false;
};

Utils.addEmailStatus = (data = {}, obj = {}) => {
  if (obj && Object.keys(obj)?.length > 0) {
    Object.keys(obj).map(key => {
      if (["BOUNCED", "OPTED_OUT"].includes(obj[key])) {
        let listIterKey = ["to", "cc", "bcc"];
        listIterKey.forEach(item => {
          if (data?.[item]?.length) {
            let list = data[item];
            list = list.map(element => {
              if (element.email === key) {
                element.invalid = obj[key];
              } else {
                let isValidEmail = Utils.emailValidate(
                  element?.email?.trim() || ""
                );
                element.invalid = isValidEmail ? "" : "INVALID";
              }
              return element;
            });
            data[item] = list;
          }
        });
      }
    });
  }
  return data;
};

Utils.insertSignature = (
  email,
  isInsertTemplate = false,
  templateContent = ""
) => {
  let tempDom = document.createElement("div");
  tempDom.innerHTML = "<div></div>";
  let signatureDom = "";
  if (isInsertTemplate) {
    let signature =
      userDetail?.connectedInboxes.filter(
        item => item?.email === email && item
      )?.[0]?.signature || "";
    if (signature) {
      signatureDom = document.createElement("div");
      signatureDom.innerHTML = signature;
    }
  }
  tempDom.firstChild.insertAdjacentHTML(
    "beforeend",
    `${templateContent}<br/><br/><div>${signatureDom?.innerHTML || ""}</div>`
  );
  return tempDom.innerHTML || templateContent || "";
};

Utils.arrayContainsValidValues = (arr = []) => {
  return arr.some(el => el !== null);
};

Utils.filterByUserTeamFilterList = (list = []) => {
  let tempList = [...list];
  const { team = false } = userDetail.userInfo || {};
  return (
    tempList.filter(item => {
      if ((!["all", "selectOwner"].includes(item.id) && !team) || team) {
        return item;
      }
    }) || list
  );
};

Utils.performActionByClosestDom = (
  event = "",
  tag = "",
  key = "",
  status = ""
) => {
  if (event) {
    Utils.preventDefaultFn(event);
    let listElem = event.target.closest(tag) || "";
    if (status) {
      return (
        (listElem &&
          ["ACTIVE"].includes(listElem.getAttribute(status)) &&
          listElem.getAttribute(key)) ||
        ""
      );
    }
    return (listElem && listElem.getAttribute(key)) || "";
  }
  return "";
};

Utils.isTimeOverlaps = (a, b) => {
  const getMinutes = s => {
    const splitVal = s.split(":").map(Number);
    return splitVal[0] * 60 + splitVal.pop();
  };
  return (
    getMinutes(a.toTime) >= getMinutes(b.fromTime) &&
    getMinutes(b.toTime) >= getMinutes(a.fromTime)
  );
};

Utils.getDueDateToRender = taskDue => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const noOfDays = taskDue && Utils.findNoOfDaysFromCurrentData(taskDue);
  let dueElapsed = false;
  let dueToday = false;
  let textColor = "";
  if (noOfDays === 0) {
    dueToday = true;
    taskDue = "Due Today";
  } else if (noOfDays <= 3 && noOfDays > 0) {
    taskDue = `Due in ${noOfDays}d`;
  } else if (noOfDays < 0) {
    dueElapsed = true;
    taskDue = `Due ${Math.abs(noOfDays)}d ago`;
  } else {
    taskDue = new Date(taskDue).toLocaleDateString("en-US", options);
  }

  if (dueElapsed) {
    textColor = "#f33a3a";
  } else if (dueToday) {
    textColor = "#3953fb";
  } else textColor = "#666666";

  return { dueDate: taskDue, color: textColor };
};

Utils.getVisitorFullName = (visitData = {}) => {
  const {
    contactFirstName = "",
    contactLastName = "",
    userEmail = "",
    contactEmail = ""
  } = visitData || {};
  let email = userEmail || contactEmail;

  return contactFirstName && contactLastName
    ? contactFirstName + " " + contactLastName
    : contactFirstName
    ? contactFirstName
    : contactLastName
    ? contactLastName
    : email
    ? email
    : "anonymous";
};

Utils.showContactsUploadLimitReached = () => {
  confirmationPopupState.setPopupValues({
    content: `You have exceeded the contact limit. Please contact <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to remove the limit`,
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => confirmationPopupState.setShowPopup(false)
  });
  confirmationPopupState.setShowPopup(true);
};

Utils.focusInputInsideDOM = (event = "") => {
  Utils.preventDefaultFn(event);
  const clickedElement = event.target;
  const innerInput = clickedElement.querySelector("input");
  if (innerInput && document.activeElement !== innerInput) {
    innerInput.focus();
  }
};

Utils.getContactFullName = (firstName = "", lastName = "", email = "") => {
  return (
    (firstName && lastName && `${firstName} ${lastName}`) ||
    firstName ||
    lastName ||
    email
  );
};

Utils.getOwnerName = (owner = {}) => {
  if (owner && Object.keys(owner)?.length) {
    const { firstName = "", lastName = "", email = "" } = owner || {};
    return Utils.getContactFullName(firstName, lastName, email);
  }
};

Utils.getUserProfileIcon = (firstName = "", lastName = "", email = "") => {
  let fn = firstName?.trim() || "";
  let ln = lastName?.trim() || "";
  if (fn && ln) {
    return `${fn?.charAt(0)}${ln?.charAt(0)}`;
  } else {
    let name = fn || ln || email || "SG";
    return name?.slice(0, 2).toUpperCase();
  }
};

const getPercentage = (name = "") => {
  if (
    ["openrate", "clickrate", "bouncerate", "replyrate"].includes(
      name?.toLowerCase()
    )
  ) {
    return " %";
  } else {
    return "";
  }
};

Utils.getLinkedInDisplayName = (
  displayName = "",
  name = "",
  directDisplayName = false
) => {
  const updatedDisplayName = directDisplayName
    ? displayName
    : `${displayName}${getPercentage(name)}`;
  if (
    [
      "linkedInMessagesSent",
      "linkedinRepliesReceived",
      "linkedinConnectionRequestsSent",
      "linkedinConnectionRequestsAccepted"
    ]?.includes(name)
  ) {
    return (
      <div className="displayNameWithIcon">
        <LinkedinIcon />
        <span>{updatedDisplayName}</span>
      </div>
    );
  }
  return updatedDisplayName;
};

export default Utils;
export { Utils };
