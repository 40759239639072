import React from "react";
import { SEQUENCE_STAGE_ICON } from "Model/model";

export let SERVER_URL = "http://localhost:9000";
export let WEBSITE_URL = "https://www.salesgearqe.com";
export let MP_KEY = "4321da6d089a7ef82a9d38bd59d3502f";
let LINK_TRACKING = "http://localhost:8099";
export let AUTH_CLIENT_ID =
  "195353880857-dlahsd8o3i9c13fhu9oncl6m0k2tngj5.apps.googleusercontent.com";
export let AUTH_SCOPES_CALENDAR =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.settings.readonly";
export let AUTH_SCOPES_INCR_CALENDAR =
  "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.settings.readonly";
export let AUTH_SCOPES =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/contacts.readonly";
export let WARMING_AUTH_SCOPES = `email profile https://mail.google.com&include_granted_scopes=true`;
export let CLIENT_URL = "http://localhost:3000";
export let AUTH_URL = "http://localhost:9000";
export let CAPTCHA_TOKEN = "6Le9faoaAAAAAPPhuMU6vPI-N3r7ExBIKQPkFhsk";
export let STRIPE_KEY = "pk_test_eGNN2BH4OKBm3q9nXOUU3CCv0013dr17Hn";
export let OUTLOOK_CLIENT_ID = "27c872ad-7d45-4412-8d0d-b7c3dd494caa";
export let OUTLOOK_REDIRECT_URL = CLIENT_URL + "/connect/ol/callback";
export let SLACK_OAUTH_URL = "";
export let ZOHO_OAUTH_URL = "";
export let HUBSPOT_OAUTH_URL = "";
export let PIPEDRIVE_OAUTH_URL = "";
export let INTERCOM_CHAT_API_KEY = "vxkuugq0";
export let TOKEN_FOR_IP_INFO = "2fd3401c48b545";
export let SALESFORCE_OAUTH_URL = "";
export let DOMAIN_KEY = "";
export let WEBSITE_TRACKING_ENV = "prod";
export let TRACKING_KEY = "SG-1683870746739-15233";
export let WARMING_CLIENT_ID =
  "1040215185167-m0mfbvhs370ipiruman6ocqr9t2c66vc.apps.googleusercontent.com";

if (process.env.BUILD_ENV === "local") {
  SERVER_URL = "http://localhost:9000";
  LINK_TRACKING = "https://core.salesgearqe.com";
  CLIENT_URL = "http://localhost:3000";
  AUTH_URL = "http://localhost:9000";
  SLACK_OAUTH_URL =
    "https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write&user_scope=channels:write,users.profile:read&client_id=1927541528146.2436890380000&redirect_uri=https://a45c-2409-4072-6d1a-a9eb-7c11-a34-daa1-b281.ngrok.io/connect/sl/callback";
  ZOHO_OAUTH_URL =
    "https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.READ,ZohoCRM.settings.ALL,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.contacts.CREATE,ZohoCRM.modules.contacts.UPDATE,ZohoCRM.modules.Leads.READ,ZohoCRM.modules.Leads.CREATE,ZohoCRM.modules.Leads.UPDATE,ZohoCRM.bulk.CREATE,ZohoCRM.bulk.READ,ZohoCRM.bulk.UPDATE,ZohoCRM.coql.READ,ZohoCRM.modules.Calls.READ,ZohoCRM.modules.Calls.CREATE,ZohoCRM.modules.Calls.UPDATE,ZohoCRM.modules.Tasks.READ,ZohoCRM.modules.Tasks.CREATE,ZohoCRM.modules.Tasks.UPDATE&client_id=1000.ZVZAPJKP1M32MRS4PQ04QUF3ZSTIXB&response_type=code&access_type=offline&redirect_uri=http://localhost:3000/connect/zoho/callback";
  HUBSPOT_OAUTH_URL =
    "https://app.hubspot.com/oauth/authorize?client_id=c3be011d-52fb-4516-a096-40bb3fe68f6b&redirect_uri=http://localhost:3000/connect/hubspot/callback&scope=oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.lists.write%20crm.objects.owners.read%20crm.objects.companies.read%20crm.schemas.companies.read";
  PIPEDRIVE_OAUTH_URL =
    "https://oauth.pipedrive.com/oauth/authorize?client_id=5bf7a57591b3aa9d&redirect_uri=http://localhost:3000/connect/pipedrive/callback";
  SALESFORCE_OAUTH_URL =
    "https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG97quAmFZJfVzLtK9YNAI5kHztBKCnPOshjQz73swOmmChtg8JQLwF3g72YhTaPcVqA9MclwwAP2DIaWUT&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fconnect%2Fsf%2Fcallback";
  WEBSITE_TRACKING_ENV = "staging";
} else if (process.env.BUILD_ENV === "staging") {
  AUTH_URL = "https://core.salesgearqe.com";
  SERVER_URL = "https://core.salesgearqe.com";
  LINK_TRACKING = "https://core.salesgearqe.com";
  CLIENT_URL = "https://app.salesgearqe.com";
  SLACK_OAUTH_URL =
    "https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write&user_scope=users.profile:read,channels:write&client_id=1927541528146.2436890380000&redirect_uri=https://app.salesgearqe.com/connect/sl/callback";
  ZOHO_OAUTH_URL =
    "https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.READ,ZohoCRM.settings.ALL,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.contacts.CREATE,ZohoCRM.modules.contacts.UPDATE,ZohoCRM.modules.Leads.READ,ZohoCRM.modules.Leads.CREATE,ZohoCRM.modules.Leads.UPDATE,ZohoCRM.bulk.CREATE,ZohoCRM.bulk.READ,ZohoCRM.bulk.UPDATE,ZohoCRM.coql.READ,ZohoCRM.modules.Calls.READ,ZohoCRM.modules.Calls.CREATE,ZohoCRM.modules.Calls.UPDATE,ZohoCRM.modules.Tasks.READ,ZohoCRM.modules.Tasks.CREATE,ZohoCRM.modules.Tasks.UPDATE&client_id=1000.ZVZAPJKP1M32MRS4PQ04QUF3ZSTIXB&response_type=code&access_type=offline&redirect_uri=https://app.salesgearqe.com/connect/zoho/callback";
  HUBSPOT_OAUTH_URL =
    "https://app.hubspot.com/oauth/authorize?client_id=c3be011d-52fb-4516-a096-40bb3fe68f6b&redirect_uri=https://app.salesgearqe.com/connect/hubspot/callback&scope=oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.lists.write%20crm.objects.owners.read%20crm.objects.companies.read%20crm.schemas.companies.read";
  PIPEDRIVE_OAUTH_URL =
    "https://oauth.pipedrive.com/oauth/authorize?client_id=5bf7a57591b3aa9d&redirect_uri=https://app.salesgearqe.com/connect/pipedrive/callback";
  SALESFORCE_OAUTH_URL =
    "https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG97quAmFZJfVzLtK9YNAI5kHztBKCnPOshjQz73swOmmChtg8JQLwF3g72YhTaPcVqA9MclwwAP2DIaWUT&redirect_uri=https%3A%2F%2Fapp.salesgearqe.com%2Fconnect%2Fsf%2Fcallback";
  DOMAIN_KEY = ".salesgearqe.com";
  WEBSITE_TRACKING_ENV = "staging";
} else if (process.env.BUILD_ENV === "production") {
  WEBSITE_URL = "https://www.salesgear.io";
  AUTH_CLIENT_ID =
    "713464356129-qhkt2r7mnbej8jfimvuum8q684v6jr5n.apps.googleusercontent.com";
  AUTH_URL = "https://core.salesgear.io";
  SERVER_URL = "https://core.salesgear.io";
  LINK_TRACKING = "https://sgoutreach.com";
  MP_KEY = "255f982822d635d1d98b7a53934e7a4a";
  CLIENT_URL = "https://app.salesgear.io";
  CAPTCHA_TOKEN = "6LfuBbIaAAAAAIK0nEj4raci-M75XH7-gs6DEkL_";
  STRIPE_KEY = "pk_live_b0H97oMnB0fYJP9UcuLSkGLK00bCxGy5c6";
  OUTLOOK_CLIENT_ID = "bdd6bcfe-5986-4aca-a87c-e3c08545f0a9";
  SLACK_OAUTH_URL =
    "https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write&user_scope=users.profile:read,channels:write&client_id=1927541528146.2444229889111&redirect_uri=https://app.salesgear.io/connect/sl/callback";
  ZOHO_OAUTH_URL =
    "https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.READ,ZohoCRM.settings.ALL,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.contacts.CREATE,ZohoCRM.modules.contacts.UPDATE,ZohoCRM.modules.Leads.READ,ZohoCRM.modules.Leads.CREATE,ZohoCRM.modules.Leads.UPDATE,ZohoCRM.bulk.CREATE,ZohoCRM.bulk.READ,ZohoCRM.bulk.UPDATE,ZohoCRM.coql.READ,ZohoCRM.modules.Calls.READ,ZohoCRM.modules.Calls.CREATE,ZohoCRM.modules.Calls.UPDATE,ZohoCRM.modules.Tasks.READ,ZohoCRM.modules.Tasks.CREATE,ZohoCRM.modules.Tasks.UPDATE&client_id=1000.FEYVM05GYD8XY6TZBU7Q3B8DP96R8Y&response_type=code&access_type=offline&redirect_uri=https://app.salesgear.io/connect/zoho/callback";
  HUBSPOT_OAUTH_URL =
    "https://app.hubspot.com/oauth/authorize?client_id=f2c81ee7-cf2e-496f-b024-0defc7a22add&redirect_uri=https://app.salesgear.io/connect/hubspot/callback&scope=oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.lists.write%20crm.objects.owners.read%20crm.objects.companies.read%20crm.schemas.companies.read";
  PIPEDRIVE_OAUTH_URL =
    "https://oauth.pipedrive.com/oauth/authorize?client_id=225d17a454c1bcba&redirect_uri=https://app.salesgear.io/connect/pipedrive/callback";
  TOKEN_FOR_IP_INFO = "70c244458b7170";
  SALESFORCE_OAUTH_URL =
    "https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG97quAmFZJfVzLtK9YNAI5kEACB_KMIgMvPWDZNY_adsMJ9zwJW4Zn28mTX211OyAk1SNK8s.X9zObJMBR&redirect_uri=https%3A%2F%2Fapp.salesgear.io%2Fconnect%2Fsf%2Fcallback";
  INTERCOM_CHAT_API_KEY = "h7yuq7g6";
  DOMAIN_KEY = ".salesgear.io";
  TRACKING_KEY = "SG-1684919891147-58353";
  WARMING_CLIENT_ID =
    "1098982709342-mr670su26v21uquhl408oid4ec0tn6t5.apps.googleusercontent.com";
}

export let AUTH_REDIRECT_URL = CLIENT_URL + "/connect/gAuth";
export let WARM_REDIRECT_URL = CLIENT_URL + "/connect/google/warming";
export let SALESFORCE_PAGE_URL = ".lightning.force.com";
export let SALESFORCE_PAGE_CLASSIC_URL = ".salesforce.com";
OUTLOOK_REDIRECT_URL = CLIENT_URL + "/connect/ol/callback";
export const INSTALL_PLUGIN_URL =
  "https://chrome.google.com/webstore/detail/free-email-tracker-gmail/nbmmhcmajjdfjobnmgnkbdhfpnbhpkef";
export const SUPPORT_URL_RESUMING_FAILED_CONTACTS =
  "https://support.salesgear.io/en/articles/5690921-resuming-failed-contacts-from-your-salesgear-sequences";
export const SUPPORT_MULTIPLE_INBOX_URL =
  "https://support.salesgear.io/en/articles/5753012-connect-multiple-inboxes-to-send-thousands-of-emails-daily";
export const SUPPORT_DIALER_CALL_CREDITS =
  "https://support.salesgear.io/en/articles/6058343-dialer-call-credit-charges";

const ConstantObj = {
  helpURL:
    "https://adaptio.freshdesk.com/support/solutions/categories/36000122792",

  manipulateSeqPayload: {
    bulk: false,
    connectedEmail: null,
    currentStage: null,
    folderId: null,
    folderName: null,
    name: null,
    stages: {},
    type: null
  },

  sequenceType: {
    private: "PRIVATE",
    shared: {
      ro: "READ_ONLY",
      rw: "READ_WRITE",
      type: "SHARED"
    }
  },

  stepsArr: [
    {
      name: "Automatic Email",
      id: "email",
      iconName: "Email",
      sub: false,
      toolTip: false,
      toolTipMsg:
        "Please upgrade to one of our premium plans to add automatic email steps to your sequence",
      className: "autoEmail"
    },
    {
      name: "Manual Email",
      id: "manualEmail",
      sub: false,
      toolTip: false,
      toolTipMsg:
        "Please upgrade to one of our premium plans to add manual email steps to your sequence",
      className: "manualEmail"
    },
    {
      name: "LinkedIn Task",
      id: "linkedin",
      sub: true,
      toolTip: false,
      toolTipMsg:
        "Please upgrade to one of our premium plans to add LinkedIn steps to your sequence",
      className: "linkedin"
    },
    {
      name: "Phone Call",
      id: "phone",
      sub: false,
      toolTip: false,
      toolTipMsg:
        "Please upgrade to one of our premium plans to add phone call steps to your sequence",
      className: "phoneCall"
    },
    {
      name: "Custom task",
      id: "customTask",
      sub: false,
      toolTip: false,
      toolTipMsg:
        "Please upgrade to one of our premium plans to add custom steps to your sequence",
      className: "customTask"
    }
    // {
    //   name: "Coming Soon",
    //   id: "upcoming",
    //   iconName: "Upcoming",
    //   inActive: true,
    //   sub: false,
    //   toolTip: false
    // },
    // { name: "SMS", id: "sms", inActive: true, sub: false, toolTip: false }
  ],

  linkedinInSubmenu: [
    {
      value: "View Profile",
      key: "linkedin",
      subKey: "LI_VIEW_PROFILE",
      id: "viewProfile"
    },
    {
      value: "Send Connection Request",
      key: "linkedin",
      subKey: "LI_SEND_CONNECTION_REQUEST",
      id: "sendConnectionRequest"
    },
    {
      value: "Send Message",
      key: "linkedin",
      subKey: "LI_SEND_MESSAGE",
      id: "sendMessage"
    },
    {
      value: "Interact With a Post",
      key: "linkedin",
      subKey: "LI_INTERACT_WITH_POST",
      id: "interactWithPost"
    }
  ],

  liAutomationObj: [
    {
      value: "Send Connection Request",
      key: "linkedin",
      subKey: "LI_SEND_CONNECTION_REQUEST",
      id: "sendConnectionRequest"
    },
    {
      value: "Send Message",
      key: "linkedin",
      subKey: "LI_SEND_MESSAGE",
      id: "sendMessage"
    },
    {
      value: "View Profile",
      key: "linkedin",
      subKey: "LI_VIEW_PROFILE",
      id: "viewProfile"
    },
    {
      value: "Send InMail",
      key: "linkedin",
      subKey: "LI_SEND_INMAIL",
      id: "sendInMail"
    }
  ],

  taskSubject: {
    li_send_inmail: "Send InMail",
    li_view_profile: "View Profile",
    li_send_connection_request: "Send Connection Request",
    li_send_message: "Send Message",
    li_interact_with_post: "Interact With a Post",
    customtask: "Custom Task",
    phone: "Make a call"
  },
  stageTypeObj: {
    email: "Email (Automatic)",
    manualEmail: "Email (Manual)",
    linkedin: "LinkedIn (Manual)",
    phone: "Call",
    customTask: "Custom Task",
    linkedinAuto: "LinkedIn (Automatic)"
  },
  teamUrlPath: [
    "/teamInvite/login",
    "/teamInvite/signup",
    "/teamInvite/success"
  ],
  contactFilterOptionsArr: [
    { name: "Total", id: "total", iconClass: "account_circle" },
    { name: "Contacted", id: "contacted", iconClass: "checked" },
    { name: "Not Contacted", id: "notcontacted", iconClass: "close" },
    { name: "Replied", id: "replied", iconClass: "reply" },
    { name: "Bounced", id: "bounced", iconClass: "call_missed_outgoing" },
    { name: "Opted Out", id: "opted", iconClass: "block" }
  ],
  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_REGEX: /^[-+()][0-9][-+()0-9]$/,
  SUB_DOMAIN_REGEX:
    /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?){2,})$/,
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  europeanTimeZone: {
    _C_GMT_PLUS_1: "GMT+01:00",
    _C_GMT_PLUS_2: "GMT+02:00",
    _C_GMT_PLUS_3: "GMT+03:00"
  },
  TRACK_IMG_ID: "en_img",
  TRACK_IMG_CLASS: "salesgear-img",
  LINK_TRACKER_BASE_URL: LINK_TRACKING,
  ZeroPxBaseUrl: "/mt/track/",
  BROWSER_TRACKING_CODE: "ae_browser_tracking_code",
  onBoardingTotalSteps: 5,
  CustomPlanType: "2",
  teamSuspendedMessage:
    "We’ve been having difficulties processing the payment for your subscription. Sending emails and creating new sequences/contacts <span>have been disabled. Please <a target='_blank' class='supportMailLink' rel='nofollow' href='mailto:support@salesgear.io'>contact us</a> to activate your subscription</span>",
  pluginInstallText:
    "Get Salesgear on your Gmail and CRM. Install the Chrome extension now.",
  attachmentFileSize: {
    total: 20,
    single: 3
  },
  trialDays: 30,
  customFieldText:
    "You can now import more data types like location, industry or create your own custom fields for Contacts and include in Sequences!",
  stageSequenceFailureMessage:
    "Failed. You are trying to move a step that is set to send as reply to the previous step as the first email step. It cannot be moved up"
};

export const CONTACT_OWNED_FILTER_ITEMS = [
  {
    id: "you",
    value: "You",
    checked: true
  },
  {
    id: "all",
    value: "All",
    checked: false
  },
  {
    id: "selectOwner",
    value: "Select Owner",
    checked: false
  }
];

export const numberWithLetter = [
  { number: 1, letter: "" },
  { number: 2, letter: "ABC" },
  { number: 3, letter: "DEF" },
  { number: 4, letter: "GHI" },
  { number: 5, letter: "JKL" },
  { number: 6, letter: "MNO" },
  { number: 7, letter: "PQRS" },
  { number: 8, letter: "TUV" },
  { number: 9, letter: "WXYZ" },
  { number: "*", letter: "" },
  { number: 0, letter: "" },
  { number: "#", letter: "" }
];

export const ENTERPRISE_FEATURE = [
  // {
  //   id: "phone",
  //   name: "Phone",
  //   value: "+1 (415) 853 2917"
  // },
  {
    id: "email",
    name: "Email",
    value: "sales@salesgear.io"
  }
  // {
  //   id: "questions",
  //   name: "Questions",
  //   value: " Chat Now"
  // }
];

export const SF_EXPORT_TYPE = [
  {
    id: "exportTypeLead",
    value: "Add as a Lead",
    checked: false
  },
  {
    id: "exportTypeContact",
    value: "Add as a Contact",
    checked: false
  }
];

export const FREQ_ASK_QUESTIONS = [
  {
    title: "Do you offer a free trial?",
    description: `Yes, we offer a 14-day free trial. Once you sign up for the trial, you will get full access to all the features for 14 days at no cost. Additionally, you also get free email and phone number credits with which you can reach out to millions of contacts and companies.`
  },
  {
    title: "What email service providers do you support?",
    description:
      "Salesgear is compatible with Gmail, GSuite, and Office 365 email accounts. Additionally, you can also connect any provider’s email account with Salesgear via SMTP (sending) and IMAP (receiving) protocols."
  },
  {
    title: "What are email credits, phone number credits and dialer credits?",
    description: `Email credits are required to purchase email addresses in Salesgear. For every purchased email address, you will be charged an email credit.<br /><br />Similarly, phone number credits are charged for all the phone numbers purchased within Salesgear. And dialer credits are charged for making and recording phone calls in Salesgear. `
  },
  {
    title: "What is included in the Unlimited Plan?",
    description: `Salesgear’s Unlimited plan includes everything needed for prospecting & sales engagement such as sequences, triggers, dialer, CRM integration, zapier integration, and contacts purchase.<br /><br />Consequently, this plan is governed by fair use policies to prevent abuse and ensure your safety. The email credit limit is 10,000 credits per month and the phone number credit limit is 50 credits per month. You can get in touch with us to get additional credits customized to your requirements.`
  },
  {
    title: "Do you have any discounts for startups?",
    description: `Please reach out to us at <a href="mailto:sales@salesgear.io">sales@salesgear.io</a> to learn more about the customized plans available for your startup.`
  }
];

export const TOASTER_COLOR = {
  DANGER: "#E54C3C",
  SUCCESS: "#28ad56",
  WARNING: "#ff7800",
  INFO: "#282b2c",
  UPCOMING: "rgba(255, 241, 220, 0.47058823529411764)"
};

ConstantObj["LINK_TRACKER_BASE_URL"] = LINK_TRACKING;

export const MP_PAGETYPE = {
  SEQUENCE: "Sequence",
  SEQUENCE_CONTACT: "Sequence Contacts",
  TEMPLATES: "Templates",
  CONTACTS: "Contacts",
  SETTINGS: "Settings",
  TASKS: "Tasks",
  TASK_START: "Start Task"
};

export const MP_EVENT = {
  SQ_CREATED: "Sequence created",
  SQ_STARTED: "Sequence started",
  SQ_UPDATED: "Sequence Updated",
  SQ_TP_INSERTED: "Sequence template inserted",
  SQ_TP_UPDATED: "Template Used",
  SQ_DRAG_DROP_PERFORMED: "Sequence drag & drop performed",
  TP_ADDED: "Template created",
  TP_UPDATED: "Template updated",
  TP_DRAG_DROP_PERFORMED: "Template drag & drop performed",
  CT_ADDED: "Contacts added",
  TK_COMPLETED: "Task completed",
  TK_PAGE_VIEWED: "Task page viewed",
  SQ_ANAL_VIEWED: "Sequence analytics viewed",
  SQ_CT_VIEWED: "Sequence contacts viewed",
  SQ_STAGE_ADDED: "Sequence step added",
  SQ_STAGE_MOVED: "Sequence step moved",
  SQ_SETTING_VIEWED: "Sequence settings viewed",
  SQ_SETTING_BANNER_LINK_CLICKED: "Sequence settings banner link clicked",
  SQ_SETTING_SAVED: "Sequence settings saved",
  SQ_SETTING_HERE_CLICKED: "Sequence settings: create new schedule clicked",
  TK_FILTERED: "Task filtered",
  ST_ADD_ACCOUNT_CLICKED: "Add email account clicked",
  ST_VERIFY_ACCOUNT_CLICKED: "Verify email account clicked",
  ST_ACCOUNT_VERFIED: "Verify email account successful",
  ST_ACCOUNT_FAILED: "Verify email account failed",
  ST_SCHEDULE_VIEWED: "Settings schedule viewed",
  ST_SCHEDULE_SAVED: "Settings schedule saved",
  ST_SCHEDULE_CREATE_CLICKED: "Settings schedule - create clicked",
  TM_INVITED: "Team Member Invited",
  ON_GET_STARTED: "Onboarding Started",
  ON_START_BTN_CLICK: "Onboarding Welcome - start clicked",
  ON_FEEDBACK: "Onboarding Feedback",
  ON_MAIL_CONNECTED: "Onboarding Connect Inbox Completed",
  ON_SEQUENCE_CREATED: "Onboarding Sequence Created",
  ON_CONTACTS_ADDED: "Onboarding Contacts Added",
  ON_COMPLETED: "Onboarding Complete",
  ON_STEP_SEQUENCE: "Onboarding step - create sequence clicked",
  ON_STEP_EMAIL_CONNECT: "Onboarding step - connect inbox clicked",
  ON_STEP_ADD_CONTACT: "Onboarding step - add contacts clicked",
  HP_BUG_REPORTED: "Bug Reported",
  HP_FEATURE_REQUESTED: "Feature Requested",
  ST_INBOX_FAIL_SHOWN: "Inbox Connect Assist popup shown",
  ST_INBOX_FAIL_CLICK: "Inbox Connect Assist popup clicked",
  ST_INBOX_FAIL_CLOSE: "Inbox Connect Assist popup dismissed",
  CONTACT_EXPORTED: "Contacts Exported",
  FL_STATISTICS_FILTER: "Contacts Filtered",
  ADD_TO_SALESFORCE: "Added to Salesforce",
  NAV_TUTORIAL_CLICKED: "tutorial slider clicked",
  DIALER_SETTINGS_VIEWED: "Dialer Settings Page Viewed",
  PHONE_NUMBER_PURCHASED: "Phone Number Purchased",
  DIALER_UPGRADE_CLICKED: "Upgrade Clicked",
  SIGN_UP_FAILED: "Sign up failed",
  PRICING_DISCOUNT_BTN_CLICKED: "Pricing Discount Banner Button Clicked",
  PLUGIN_INSTALL_CLICKED: "Plugin Install Now Clicked",
  SEQUENCE_IN_SMTP_NA: "Sequence in SMTP NA",
  PRICING_PAGE_VIEWED: "Pricing page viewed",
  CHECKOUT_PAGE_VIEWED: "Checkout page viewed",
  UPGRADE_POPUP_SHOWN: "Upgrade popup shown",
  UPGRADE_POPUP_CLICKED: "Upgrade popup clicked",
  UPGRADE_TOOLTIP_SHOWN: "Upgrade shown",
  UPGRADE_TOOLTIP_CLICKED: "Upgrade clicked",
  UPGRADE_SEQUENCE_CARD_CLICKED: "Upgrade sequence card clicked",
  TEST_EMAIL_SENT: "Test Email Sent",
  TEST_EMAIL_FAILED: "Test Email Failed",
  BOOK_NOW_CLICKED: "Book Now Button Clicked",
  CF_TRYNOW_CLICKED: "Custom Field Try Now clicked",
  CF_PAGE_VIEWED: "Custom Field Page viewed",
  CF_CREATED: "Custom Field created",
  RP_VIEWED: "Reports viewed",
  TAG_ADDED: "Tag added",
  TUTORIAL_VIDEO_OPENED: "Tutorial Video Opened",
  TRIAL_EXPIRED_TOOLTIP_CLUICKED: "Trial Expired Tooltip Clicked",
  TRIAL_EXPIRED_TOOLTIP_VIEWED: "Trial Expired Tooltip Viewed",
  USER_REGISTERATION: "User Sign Up",
  REPORT_FILTERED_BY_DATE: "Report filtered by date",
  REPORT_FILTERED_BY_USER: "Report filtered by user",
  FT_CONTINUE_CLCIKED: "Free Trial Continue Clicked",
  TRIAL_STARTED: "Trial started",
  TEAM_CREATED: "Team created",
  TEAM_FAILED: "Team creation failed",
  TEAM_HAS_MEMBER: "Team has member",
  TRIAL_INITIATION_FAILED: "Trial initiation failed",
  TRIAL_INITIATED: "Trial initiated",
  LOGIN_CLICKED: "Log in clicked",
  LOGIN_VISITED: "Login visited",
  SIGNUP_VISITED: "Sign up visited",
  SIGNUP_CLICKED: "Sign up clicked",
  SIGNUP_SUCCESS: "Sign up success",
  CSV_IMPORTS_VIEWED: "CSV imports viewed",
  REVOKE_ACCESS_CONNECT_INBOX_BTN_CLICKED:
    "Revoke access connect inbox button clicked",
  OUTBOX_VIEWED: "Outbox viewed",
  OUTBOX_FILTER: "Outbox filtered by the user",
  OUTBOX_DELAYED_VIEWED: "Outbox section delayed viewed",
  OUTBOX_POPUP_VIEWED: "Outbox popup viewed",
  EVENT_PROMOTIONAL_BANNER_CLICKED: "Event promotional banner clicked",
  AB_TESTING_ADD_VARIANT_CLICKED: "AB Testing Add Variant Clicked",
  TR_CREATED: "Trigger Created",
  ADD_TR_CLICKED: "Add Trigger Clicked",
  TR_PAGE_VIEWED: "Trigger page Viewed",
  SQ_MULTI_INBOX_LEARN_MORE_CLICKED:
    "Sequence multiple inbox learn more clicked",
  CHAT_US_CLICKED: "Chat us clicked",
  DISCOUNT_BANNER_CLICKED: "Year end discount banner clicked",

  // Writing Assistant
  WA_SLG_CLICKED: "WA - Generate subject clicked",
  WA_SLG_API_CALLED: "WA - Generate subject API called",
  WA_SUBJECT_SELECTED: "WA - Recommended subject selected",
  ZAPIER_CONFIGURATION_CLICKED: "Zapier integration - configuration clicked",
  ZAPIER_COPY_CLICKED: "Zapier integration - copy APIkey clicked",

  // CALLS
  CALLS_VIEWED: "Calls Viewed",
  CALLS_FILTERED_BY_USER: "Calls filtered by user",
  MAKE_CALL_CLICKED: "Make a Call Clicked",

  //Discover
  DIS_TOUR_CLICKED: "Discover section start tour clicked",

  //Onboarding Events
  ONB_SLIDER_CLOSED: "Onboarding slider closed",
  ONB_SLIDER_STEP1_VIDEO: "Onboarding slider step1 watch video clicked",
  ONB_SLIDER_STEP1_GO: "Onboarding slider step1 go to section clicked",
  ONB_SLIDER_STEP2_VIDEO: "Onboarding slider step2 watch video clicked",
  ONB_SLIDER_STEP2_GO: "Onboarding slider step2 go to section clicked",
  ONB_SLIDER_STEP3_VIDEO: "Onboarding slider step3 watch video clicked",
  ONB_SLIDER_STEP3_GO: "Onboarding slider step3 go to section clicked",
  ONB_SLIDER_STEP4_VIDEO: "Onboarding slider step4 watch video clicked",
  ONB_SLIDER_STEP4_GO: "Onboarding slider step4 go to section clicked",
  ONB_SLIDER_STEP5_VIDEO: "Onboarding slider step5 watch video clicked",
  ONB_SLIDER_STEP5_GO: "Onboarding slider step5 go to section clicked",
  ONB_SLIDER_OPENED: "Onboarding slider opened"
};

export const REPORT_DATE_FILTER_MIN_DATE = "2021/02/01"; // YYYY/MM/DD
export const UnlimitedCount = 1000000;
export const PopularPlanName = "basic";
export const REQUEST_DEMO_CALENDAR_LINK =
  "https://calendar.salesgear.io/premsanth/salesgear-demo";
export const CspValue =
  "script-src 'self' 'nonce-{{nonce}}' 'unsafe-eval' 'unsafe-inline' https://www.googletagmanager.com https://www.google.com https://*.clarity.ms https://cdn4.mxpnl.com https://js.stripe.com *.hotjar.com https://www.google-analytics.com https://www.youtube.com https://js.hs-scripts.com https://www.lfeeder.com https://js.hs-banner.com https://js.hs-analytics.net https://js.hsadspixel.net *.mixpanel.com https://www.googleadservice.com https://googleads.g.doubleclick.com *.intercomcdn.com *.intercom.io http://www.youtube.com/iframe_api https://*.chameleon.io *.ytimg.com;style-src 'self' 'unsafe-inline' font.googleapis.com fonts.googleapis.com https://cdnjs.cloudflare.com *.hotjar.js *.intercomcdn.com *.intercom.io";

export const getCustomFieldTokens = () => {
  return Array.from({ length: 30 }, (value, index) => {
    return {
      key: `customField${index + 1}`,
      value: `{{customField${index + 1}}}`,
      name: `{{customField${index + 1}}}`,
      text: `Custom Field ${index + 1}`,
      uniqueKey: "customFields"
    };
  });
};

export const TOKENS = [
  {
    key: "firstName",
    value: "{{firstName}}",
    name: "John",
    text: "First Name"
  },
  { key: "lastName", value: "{{lastName}}", name: "Smith", text: "Last Name" },
  {
    key: "email",
    value: "{{email}}",
    name: "john@microsoft.com",
    text: "Email"
  },
  {
    key: "companyName",
    value: "{{companyName}}",
    name: "Microsoft",
    text: "Company"
  },
  {
    key: "jobTitle",
    value: "{{jobTitle}}",
    name: "VP of Sales",
    text: "Title"
  },
  {
    key: "industry",
    value: "{{industry}}",
    name: "Software & Internet",
    text: "Industry"
  },
  { key: "city", value: "{{city}}", name: "Seattle", text: "City" },
  { key: "state", value: "{{state}}", name: "Washington", text: "State" },
  { key: "country", value: "{{country}}", name: "USA", text: "Country" },
  {
    key: "phoneNumber",
    value: "{{phoneNumber}}",
    name: "800-285-7772",
    text: "Phone Number"
  },
  {
    key: "mobileNumber",
    value: "{{mobileNumber}}",
    name: "425-706-4400",
    text: "Mobile Number"
  },
  {
    key: "website",
    value: "{{website}}",
    name: "www.microsoft.com",
    text: "Website"
  },
  ...getCustomFieldTokens()
];

export const senderTokenElementList = [
  "{{sender.firstName}}",
  "{{sender.lastName}}",
  "{{sender.email}}",
  "{{sender.phoneNumber}}",
  "{{sender.jobTitle}}",
  "{{sender.calendarUrl}}",
  "{{sender.companyName}}"
];

export const tokenElementList = [
  "{{firstName}}",
  "{{lastName}}",
  "{{email}}",
  "{{companyName}}",
  "{{jobTitle}}",
  "{{industry}}",
  "{{city}}",
  "{{state}}",
  "{{country}}",
  "{{phoneNumber}}",
  "{{mobileNumber}}",
  "{{website}}",
  ...senderTokenElementList
];

export const ADD_CONTACT_INPUT = [
  {
    id: "firstname",
    placeholder: "First Name",
    type: "text",
    required: false
  },
  { id: "lastname", placeholder: "Last Name", type: "text", required: false },
  { id: "jobtitle", placeholder: "Job Title", type: "text", required: false },
  {
    id: "companyname",
    placeholder: "Company Name",
    type: "text",
    required: false
  },
  { id: "email", placeholder: "Email", type: "email", required: true }
];

export const CSV_PLACEHOLDER_OBJ = {
  firstname: "First Name",
  lastname: "Last Name",
  jobtitle: "Job Title",
  companyname: "Company Name",
  email: "Email"
};

export const CSV_HEADER_ORDER_LIST = {
  firstname: "",
  lastname: "",
  jobtitle: "",
  companyname: "",
  email: ""
};

export const TOKENS_HIGHLIGHT_OBJ = {
  firstName: "<span class='htYellowPT'>John</span>",
  lastName: "<span class='htYellowPT'>Smith</span>",
  email: "<span class='htYellowPT'>john@microsoft.com</span>",
  companyName: "<span class='htYellowPT'>Microsoft</span>",
  jobTitle: "<span class='htYellowPT'>VP of Sales</span>",
  industry: "<span class='htYellowPT'>Software & Internet</span>",
  city: "<span class='htYellowPT'>Seattle</span>",
  state: "<span class='htYellowPT'>Washington</span>",
  country: "<span class='htYellowPT'>USA</span>",
  phoneNumber: "<span class='htYellowPT'>800-285-7772</span>",
  mobileNumber: "<span class='htYellowPT'>425-706-4400</span>",
  website: "<span class='htYellowPT'>www.microsoft.com</span>"
};

export const TOKENS_FALLBACK_OBJ = {
  firstName: "John",
  lastName: "Smith",
  companyName: "Microsoft",
  jobTitle: "VP of Sales",
  industry: "Software & Internet",
  city: "Seattle",
  state: "Washington",
  country: "USA",
  phoneNumber: "800-285-7772",
  mobileNumber: "425-706-4400",
  website: "www.microsoft.com"
};

export const TEST_EMAIL_CONNECT_INBOX_MSG =
  "Please connect your email account to send test emails";

export const SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS = ["email"];

export const featureVideoLinkObj = {
  emailAccount: "E7I5UR1uOxs",
  sequence: "_MfkCnez5c8",
  sequenceContacts: "l5QN5xBBsPM",
  contactsPage: "K4Y116qO1ps",
  templates: "kgd5yCty0rs",
  triggers: "ds3PQX0Vni0",
  customUrl: "c120mBM7KF4"
};

export const CONTACT_BULK_DROPDOWN_DEFAULT_OPTION = "---";

export const NEW_STAGE_ANALYTICS_DATE = new Date("12/31/2020");

export const DAILY_EMAIL_SEND_LIMIT = 1000;

export const ALPHABETS_REGEX = /[a-zA-Z]/g;

export const MAX_IMAGE_SIZE_ALLOWED = 3;

export const USER_SELECT_DROPDOWN_LIST = [
  {
    id: "all",
    value: "All",
    checked: true
  },
  {
    id: "you",
    value: "You",
    checked: false
  },
  {
    id: "selectOwner",
    value: "Select Member",
    checked: false
  },
  {
    id: "Team",
    value: "Select Team",
    checked: false
  }
];

export const DATE_FILTER_LIST = [
  {
    name: "Custom",
    id: "filterCustomDate",
    value: "customDate",
    showPicker: false
  },
  {
    name: "Today",
    id: "filterTodayDate",
    value: "today"
  },
  {
    name: "Yesterday",
    id: "filterYesterdayDate",
    value: "yesterday"
  },
  {
    name: "7D",
    id: "filterWeekDate",
    value: "week"
  },
  {
    name: "30D",
    id: "filterMonthDate",
    value: "month"
  }
];

export const contactFilterDropdownList = [
  {
    label: "Tags",
    value: "tags"
  },
  {
    label: "Contact owners",
    value: "contactOwners"
  }
];

export const contactTimelineFilter = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Email",
    value: "EMAIL"
  },
  {
    label: "Task",
    value: "TASK"
  },
  {
    label: "Phone",
    value: "PHONE"
  },
  {
    label: "Contact Update",
    value: "CONTACT_UPDATE"
  }
];

export const outboxFilterDropdownList = [
  {
    label: "Contact owners",
    value: "contactOwners"
  }
];

export const TASK_TABLE_HEADER = [
  {
    name: "Contact",
    key: "contactInfo",
    className: "contactInfo"
  },
  { name: "Task type", key: "taskType", className: "taskType" },
  { name: "Notes", key: "taskNotes", className: "taskNotes" },
  { name: "Due", key: "taskDueDate", className: "taskDueDate" },
  { name: "", key: "taskMoreOptions", className: "taskMoreOptions" }
];

export const TASK_DATE_FILTER_LIST = [
  {
    name: "All",
    id: "allTask",
    key: "allTask"
  },
  {
    name: "Today",
    id: "today",
    key: "today"
  },
  {
    name: "Custom",
    id: "filterCustomDate",
    key: "customDate",
    showPicker: false
  },
  {
    name: "Yesterday",
    id: "filterYesterdayDate",
    key: "yesterday"
  },
  {
    name: "7D",
    id: "filterWeekDate",
    key: "week"
  },
  {
    name: "30D",
    id: "filterMonthDate",
    key: "month"
  }
];

export const ACCOUNT_TASK_DATE_FILTER_LIST = [
  {
    name: "All",
    id: "allTask",
    key: "allTask"
  },
  {
    name: "Today",
    id: "today",
    key: "today"
  },
  {
    name: "Custom",
    id: "filterCustomDate",
    key: "customDate",
    showPicker: false
  }
];

export const TASK_STATUS_FILTER_LIST = [
  {
    name: "Due",
    id: "due",
    key: "due"
  },
  {
    name: "Upcoming",
    id: "upcoming",
    key: "upcoming"
  },
  {
    name: "Completed",
    id: "completed",
    key: "completed"
  },
  {
    name: "Skipped",
    id: "skipped",
    key: "skipped"
  }
];

export const schdeuleWindowMessage = `The schedule ensures that all the emails from the sequence are delivered only during the time frame configured below. You can also create new schedules from `;

export const weekDays = {
  1: { long: "Monday", short: "Mon" },
  2: { long: "Tuesday", short: "Tue" },
  3: { long: "Wednesday", short: "Wed" },
  4: { long: "Thursday", short: "Thu" },
  5: { long: "Friday", short: "Fri" },
  6: { long: "Saturday", short: "Sat" },
  7: { long: "Sunday", short: "Sun" }
};

export const IMPORT_CSV_DATE = new Date("07/21/2021");
export const BULK_TASKS_OPERATION_DATE = new Date("07/21/2021");

export const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const REPORTS_TAB_NAVIGATION = [
  {
    name: "Summary",
    id: "teamSummaryReport",
    path: "summary"
  },
  {
    name: "Insights",
    id: "teamInsightsReport",
    path: "insights"
  }
];

export const CONTACT_TIMELINE_TAB_NAVIGATION = [
  {
    id: "activity",
    value: "Activity"
  }
  // {
  //   name: "Emails",
  //   id: "ctTlEmails",
  //   path: "emails"
  // },
  // {
  //   name: "Calls",
  //   id: "ctTlCalls",
  //   path: "calls"
  // },
  // {
  //   name: "Sequences",
  //   id: "ctTlSequences",
  //   path: "sequences"
  // },
  // {
  //   name: "Notes",
  //   id: "ctTlNotes",
  //   path: "notes"
  // }
];

export const ACCOUNTS_NAVIGATION = [
  {
    name: "Overview",
    id: "accountOverview",
    path: "overview"
  },
  {
    name: "Contacts",
    id: "accountContacts",
    path: "contacts"
  },
  {
    name: "Tasks",
    id: "accountTasks",
    path: "tasks"
  },
  {
    name: "Notes",
    id: "accountsNotes",
    path: "notes"
  }
];

export const REPORTS_NAVIGATION = [
  {
    name: "Activity",
    id: "reportActivity",
    path: "activity"
  },
  {
    name: "Sequence",
    id: "reportSequence",
    path: "sequence"
  }
];

export const CALLS_TAB_NAVIGATION = [
  {
    name: "Overview",
    id: "callsOverview",
    path: "overview"
  },
  {
    name: "Analytics",
    id: "callsAnalytics",
    path: "analytics"
  }
];

export const ACCOUNTS_FILTER_ARR = [
  { name: "All" },
  { name: "New" },
  { name: "Working" },
  { name: "Meeting Booked" },
  { name: "Qualified" },
  { name: "Negotiating" },
  { name: "Disqualified" }
];

export const ACCOUNTS_HEADER_ARR = [
  "Name",
  "Website",
  "Stage",
  "Contacts",
  "Owner",
  "Last Contacted",
  "Actions"
];

export const CONNECTED_INBOX_TAB_NAVIGATION = [
  {
    value: "Settings",
    id: "settings",
    path: "settings"
  },
  {
    value: "Warming",
    id: "warming",
    path: "warming"
  }
];

export const TRIGGERS_TAB_NAVIGATION = [
  {
    name: "Overview",
    id: "triggersOverview",
    path: "settings/triggers"
  }
];

export const triggerTypeObject = {
  CONTACT_CREATED: "Contact created",
  CONTACT_UPDATED: "Contact updated",
  EMAIL_DELIVERED: "Email delivered to the contact",
  EMAIL_OPENED: "Email opened by the contact",
  EMAIL_CLICKED: "Clicked on a link by the contact",
  EMAIL_REPLIED: "Reply Received (Email)",
  LINKEDIN_REPLIED: "Reply Received (LinkedIn)",
  EMAIL_LINKEDIN_REPLY_RECEIVED: "Reply Received (Email or LinkedIn)",
  CONTACT_OPTEDOUT: "Contact Opted out",
  EMAIL_BOUNCED: "Contact bounced",
  CALL_LOGGED: "Call logged for a contact",
  WEBSITE_VISITED: "Website Visited",
  LI_CONNECTION_REQUEST_ACCEPTED: "LinkedIn Connection accepted"
};

export const actionObject = {
  COMPLETE_TASK: "Complete all task",
  ADD_TO_SEQUENCE: "Add to new sequence",
  COMPLETE_SEQUENCE: "Complete sequence",
  CREATE_TASK: "Create a task",
  UPDATE_CONTACT: "Update contact",
  ADD_TAG: "Add Tags",
  REMOVE_TAG: "Remove Tags",
  UPDATE_STAGE: "Update Stage",
  SLACK_NOTIFICATION: "Send slack notification"
};

export const conditionsOperatorObject = {
  EQUALS: "<span class='conditionSymbol'>&equals;</span>",
  NOT_EQUALS: "<span class='conditionSymbol'>&ne;</span>",
  GREATER_THAN: "<span class='conditionSymbol'>&gt;</span>",
  LESS_THAN: "<span class='conditionSymbol'>&lt;</span>",
  CONTAINS: "<span class='conditionSymbol'>&#8717;</span>",
  IS_SET:
    "<span class='conditionSymbol customSymbol' style='font-Size:17px;'>&lcub;&rcub;</span>",
  IS_NOT_SET:
    "<span class='conditionSymbol customSymbol strikeThrough' style='font-Size:17px;'>&lcub;&rcub;</span>",
  BETWEEN: "<span class='conditionSymbol'>&lt;&gt;</span>",
  STARTS_WITH: "<span class='conditionSymbol'>&#94;</span>",
  ENDS_WITH:
    "<span class='conditionSymbol' style='font-Size:17px;'>&#36;</span>"
};

export const tokenVariableRegex = /{{+[a-zA-Z-0-9-._]+}}/gi;

export const zohoLeadFieldMapping = {
  First_Name: "First name",
  Last_Name: "Last name",
  Company: "Company",
  Designation: "Designation",
  City: "City",
  Country: "Country",
  Email: "Email",
  Industry: "Industry",
  State: "State",
  Street: "Street",
  Website: "Website",
  Zip_Code: "Zipcode",
  Twitter: "Twitter",
  Phone: "Phone",
  Mobile: "Mobile"
};

export const zohoContactFieldMapping = {
  First_Name: "First name",
  Last_Name: "Last name",
  Mailing_City: "City",
  Mailing_Country: "Country",
  Email: "Email",
  Title: "Title",
  Mailing_State: "State",
  Mailing_Street: "Street",
  Mailing_Zip: "Zipcode",
  Twitter: "Twitter",
  Phone: "Phone",
  Mobile: "Mobile"
};

export const salesgearFieldMapping = {
  firstName: "First name",
  lastName: "Last name",
  name: "Name",
  company: "Company",
  city: "City",
  country: "Country",
  email: "Email",
  industry: "Industry",
  state: "State",
  street: "Street",
  website: "Website",
  zipCode: "Zipcode",
  twitter: "Twitter",
  phone: "Phone",
  phoneNumber: "Phone Number",
  phonenumber: "Phone Number",
  mobileNumber: "Mobile Number",
  mobilePhone: "Mobile",
  otherPhone: "Other number",
  contactNotes: "Contact Notes",
  jobTitle: "Job Title",
  linkedinId: "LinkedIn URL",
  facebook: "Facebook",
  tags: "Contact Tags",
  employeeCount: "Employee Count",
  industry: "Industry",
  revenue: "Revenue",
  zip: "ZipCode",
  customField1: "CustomField 1",
  customField2: "CustomField 2",
  customField3: "CustomField 3",
  customField4: "CustomField 4",
  customField5: "CustomField 5",
  customField6: "CustomField 6",
  customField7: "CustomField 7",
  customField8: "CustomField 8",
  customField9: "CustomField 9",
  customField10: "CustomField 10",
  customField11: "CustomField 11",
  customField12: "CustomField 12",
  customField13: "CustomField 13",
  customField14: "CustomField 14",
  customField15: "CustomField 15",
  customField16: "CustomField 16",
  customField17: "CustomField 17",
  customField18: "CustomField 18",
  customField19: "CustomField 19",
  customField20: "CustomField 20",
  customField21: "CustomField 21",
  customField22: "CustomField 22",
  customField23: "CustomField 23",
  customField24: "CustomField 24",
  customField25: "CustomField 25",
  customField26: "CustomField 26",
  customField27: "CustomField 27",
  customField28: "CustomField 28",
  customField29: "CustomField 29",
  customField30: "CustomField 30",
  customfield1: "Custom Field 1",
  customfield2: "Custom Field 2",
  customfield3: "Custom Field 3",
  customfield4: "Custom Field 4",
  customfield5: "Custom Field 5",
  customfield6: "Custom Field 6",
  customfield7: "Custom Field 7",
  customfield8: "Custom Field 8",
  customfield9: "Custom Field 9",
  customfield10: "Custom Field 10",
  customfield11: "Custom Field 11",
  customfield12: "Custom Field 12",
  customfield13: "Custom Field 13",
  customfield14: "Custom Field 14",
  customfield15: "Custom Field 15",
  customfield16: "Custom Field 16",
  customfield17: "Custom Field 17",
  customfield18: "Custom Field 18",
  customfield19: "Custom Field 19",
  customfield20: "Custom Field 20",
  customfield21: "Custom Field 21",
  customfield22: "Custom Field 22",
  customfield23: "Custom Field 23",
  customfield24: "Custom Field 24",
  customfield25: "Custom Field 25",
  customfield26: "Custom Field 26",
  customfield27: "Custom Field 27",
  customfield28: "Custom Field 28",
  customfield29: "Custom Field 29",
  customfield30: "Custom Field 30"
};

export const salesgearContactFields = [
  { value: "firstName", name: "First name" },
  { value: "lastName", name: "Last name" },
  { value: "email", name: "Email" },
  { value: "jobTitle", name: "Job Title" },
  { value: "phone", name: "Phone" },
  { value: "mobilePhone", name: "Mobile" },
  { value: "company", name: "Company" },
  { value: "industry", name: "Industry" },
  { value: "street", name: "Street" },
  { value: "city", name: "City" },
  { value: "state", name: "State" },
  { value: "country", name: "Country" },
  { value: "zipCode", name: "Zipcode" },
  { value: "website", name: "Website" },
  { value: "twitter", name: "Twitter" },
  { value: "facebook", name: "Facebook" },
  { value: "linkedinId", name: "LinkedIn" }
];

export const getCustomFields = (value = 30) => {
  return Array.from({ length: value }, (val, index) => {
    return {
      value: `customField${index + 1}`,
      name: `Custom Field ${index + 1}`
    };
  });
};

export const getCustomFieldsCRM = (value = 30) => {
  return Array.from({ length: value }, (val, index) => {
    return {
      value: `customField${index + 1}`,
      name: `Custom Field ${index + 1}`
    };
  });
};

export const customContactFields = getCustomFields(30);

export const IntercomTour = {
  emailAccount: 307271,
  newSequenceSteps: 307272,
  sequence: 284291,
  sequenceContacts: 308142,
  templates: 310759,
  viewEmailFromSeoPage: 378979
};

export const LEARN_MORE_LINK = {
  calls: "https://support.salesgear.io/en/collections/3340564-dialer",
  reports: "https://support.salesgear.io/en/collections/3174616-reports",
  discover: "https://support.salesgear.io/en/collections/3578426-prospecting",
  websiteTracking:
    "https://support.salesgear.io/en/collections/3578426-prospecting"
};

export const cryptoKey = "COhJR+BFJ3p0eaafJUcw5w==";

export const taskTypeIcon = {
  linkedin: {
    label: "LinkedIn Task",
    icon: SEQUENCE_STAGE_ICON["linkedin"]
  },
  phone: { label: "Phone call", icon: SEQUENCE_STAGE_ICON["phone"] },
  email: {
    label: "Manual Email",
    icon: <i className="material-icons-outlined manualEmail">email</i>
  },
  manualEmail: {
    label: "Manual Email",
    icon: <i className="material-icons-outlined manualEmail">email</i>
  },
  customTask: {
    label: "Custom task",
    icon: SEQUENCE_STAGE_ICON["customTask"]
  }
};

export const CAPTCHA_INIT_PAGES = [
  "/login",
  "/signup",
  "/resetPassword",
  "/forgotPassword",
  "/settings/account",
  "/teamInvite/signup",
  "/settings/general/profile",
  "/settings/account/email",
  "/teamInvite/login",
  "/dealify-signup"
];

export const EMAIL_VALIDATION_CREDITS_PRICE = 0.005;

export const WEBINAR_EVENT_DATE = "2024-12-31";

export const SUPPORT_LINK = "https://support.salesgear.io/en/";

export const DONT_CONTACT_DOMAIN_MESSAGE = {
  calendar:
    "Oops! You cannot book meeting for a contact with 'do not contact' stage.",
  email:
    "Oops! You cannot send an email to a contact with 'do not contact' stage.",
  sequence:
    "Oops! You cannot add a contact with 'do not contact' stage to a sequence.",
  task: "Oops! You cannot create task for a contact with 'do not contact' stage.",
  call: "Oops! You cannot make call for a contact with 'do not contact' stage."
};

export const OutboxHeaderArr = [
  {
    name: "Contact Email",
    classname: "toAddress"
  },
  {
    name: "Message",
    classname: "email"
  },
  {
    name: "Sequence Name",
    classname: "sequenceName"
  },
  {
    name: "Date",
    classname: "date"
  },
  {
    name: "Time",
    classname: "time"
  },
  {
    name: "Owner",
    classname: "owner"
  }
];

export const AI_SCORE_CONSTANT = [
  {
    name: "Readability Score",
    id: "readability_score",
    nameList: [
      "readability_score",
      "readability score",
      "readability_scores",
      "readability scores",
      "readabilityscore",
      "readabilityscores"
    ],
    keysObj: {
      gunning_fog_index: "Gunning Fog Index",
      flesch_kincaid_reading_ease: "Flesch-Kincaid Reading Ease",
      automated_readability_index: "Automated Readability Index"
    },
    keyList: [
      "gunning_fog_index",
      "flesch_kincaid_reading_ease",
      "automated_readability_index"
    ]
  },
  {
    name: "Spam Score",
    id: "spam_score",
    nameList: [
      "spam_score",
      "spam_scores",
      "spam score",
      "spam scores",
      "spamscore",
      "spamscores"
    ],
    keysObj: {
      bayesian_filter: "Bayesian filter",
      keyword_based_filter: "Keyword-based filter"
    },
    keyList: ["bayesian_filter", "keyword_based_filter"]
  }
];

export default ConstantObj;
export { ConstantObj };
