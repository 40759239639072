/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import SequenceContactsNav from "./SequenceContactsNav";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import userDetail from "Stores/userDetail";
import SequenceUtils from "Utils/SequenceUtils";
import InboxUtils from "Utils/InboxUtils";
import { sequenceRenderState } from "./../SequencePage/sequenceProperties";
import EmailInsightComponent from "Components/common/EmailInsights";
import { sequenceContactData } from "Stores/sequence/Contact";
import { toasterState } from "Components/common/toaster";
import {
  MP_EVENT,
  SUPPORT_URL_RESUMING_FAILED_CONTACTS
} from "Static/constant";
import ootoCalendarIcon from "Assets/png/ootoCalendar.png";
import PaginationComponent from "Components/common/PaginationComponent";
import CheckBox from "Components/common/CheckBox";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import ContactSelectedTagList from "Components/common/ContactSelectedTagList";
import LinearProgressLoader from "Components/LinearProgressLoader";
import ExportBtn from "Components/common/ExportBtn";
import AddToSequenceButton from "Components/common/AddToSequenceButton";
import NoContactImg from "Assets/png/contact.png";
import AddContactDropDown from "Components/common/AddContactDropDown";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import ContactMoreOption from "Components/common/ContactFilter/ContactMoreOption";
import { deselectCheckedPopupState } from "Components/common/DeselectCheckedPopup";
import PageLimit from "Components/common/PageLimit";
import { contactSelectedTagListStore } from "Components/common/ContactSelectedTagList";
import CustomTooltip from "Components/common/CustomTooltip";
import SequenceMoreOption from "./SequenceMoreOption";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { editContactPopupState } from "../../contacts/EditContactPopup";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import FilterByStep from "./FilterByStep";
import StepsList from "./StepsList";
import PaywallUtils from "Utils/PaywallUtils";
import SearchByContactKeyword from "../../contacts/SearchByContactKeyword";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import { ReactComponent as RepliedIcon } from "Assets/svg/repliedIconSeq.svg";
import SequenceContactFilter from "./SequenceContactFilter/index";
import sequenceContactStoreData from "./SequenceContactFilter/sequenceContactStore";
import { tagsStoreData } from "Stores/TagsData";
import { SEQ_CONTACT_TAB_FILTER_OBJECT } from "Model/model";
import { sequenceStoreData } from "Stores/sequenceData";
import { ReactComponent as WarningField } from "Assets/svg/autoReplyWarning.svg";
import { ReactComponent as LinkedInIcon } from "../../settings/ConnectedAccounts/LinkedInAccounts/svg/linkedIn.svg";
import { linkedInReplyPopupState } from "Components/PreviewPopup/LinkedInReplyContentPopup";

const SeqContactTeamDataFilterContext = createContext({});

const SequenceContacts = (props = {}) => {
  const {
    sequence = {},
    seqId = "",
    exportAllContactSelected = false,
    contactsData = {},
    folderId = "",
    isSharedSequenceWithUser = false,
    showContactOwner = false,
    loader = false,
    totalPages = 0,
    totalContacts = 0,
    selectedEntityCount = 0,
    contactIds = [],
    exportEnabled = false,
    activeTab = "",
    allContactSelected = "",
    contactLimit = 10,
    setContactLimit = 10,
    updateSequenceContacts = false,
    allowContactsUpload = false,
    sequenceToggleBtn = true,
    isFromStepBadgeNavigation = false,
    setExportAllContactSelected = () => {},
    setExportData = () => {},
    setScheduledIdData = () => {},
    setContactsData = () => {},
    setLoader = () => {},
    setCurrentStage = () => {},
    selectedContactCard = () => {},
    exportCbk = () => {},
    retryContactCbk = () => {},
    addToSequenceAction = () => {},
    bulkRemoveSeqContactCbk = () => {},
    bulkReactivateSeqContactCbk = () => {},
    bulkResumeSeqContactCbk = () => {},
    addTagsCbk = () => {},
    removeTagsCbk = () => {},
    resetContactCbk = () => {},
    selectAllCbk = () => {},
    setActiveTab = () => {},
    addSingleContact = () => {},
    addBulkContact = () => {},
    setAllContactSelected = () => {},
    searchKeyword = "",
    setSearchKeyword = () => {},
    resetToSelectedCheckboxCbk = () => {},
    setUpdateSequenceContacts = () => {},
    setUpdatedContact = () => {},
    setFromStepBadgeNavigation = () => {},
    getTotalCountForEachFilter = () => {}
  } = props || {};

  const allDataForSteps = {
    all: true,
    count: 0,
    selected: false,
    order: "all",
    stepMetrics: []
  };

  const { planName = "", inFreePlan = false } =
    userDetail.userFeatureFlag || {};

  const history = useHistory();

  const [isContactUploading, setContactUploading] = useState(false);
  const [isEmailClose, setEmailClose] = useState(false);
  const [showStepsList, setShowStepsList] = useState(isFromStepBadgeNavigation);
  const [showContactFilter, setShowContactFilter] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [filterArr, setFilterArr] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [teamFilterDropDownData, setTeamFilterDropDownData] = useState([]);
  const [teamCount, setTeamCount] = useState(0);
  const [data, setData] = useState({
    ...(sequenceContactStoreData?.contactFilterObject || {})
  });
  const [activeFilter, setActiveFilter] = useState(false);
  const [metricData, setMetricData] = useState([{}]);
  const [analyticStepLoader, setAnalyticStepLoader] = useState(true);
  const [outofOotoTooltip, setOutofOotoTooltip] = useState(false);
  const [showExportTypeDropdown, setShowExportTypeDropdown] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  let defaultSelectAllTeamMemberObject = [
    {
      id: "selectAll",
      value: "Select All",
      show: true
    }
  ];

  const constructTeamMemberList = (teamFilterDataResponse = []) => {
    let temp = [...teamFilterDataResponse];
    const arrList = [...defaultSelectAllTeamMemberObject];
    temp.forEach(item => {
      const { group = {}, members = [] } = item || {};
      if (members?.length > 0) {
        arrList.push({
          name: group?.name,
          id: group?.id,
          value: group?.name,
          show: true,
          showSubmenu: true,
          submenu: members?.map(item => {
            const { memberId = "" } = item || {};
            return {
              name: Utils.getFullNameFromUserInfo(item),
              value: Utils.getFullNameFromUserInfo(item),
              show: true,
              id: memberId
            };
          })
        });
      }
    });
    return arrList;
  };

  const constructTeamDataInFilter = async () => {
    const config = {
      url: URLS.getTeamData,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.status === 200 && response?.data?.groups) {
      setTeamFilterDropDownData(
        constructTeamMemberList(response?.data?.groups)
      );
    } else {
      setTeamFilterDropDownData([]);
    }
  };

  const getPayloadFilterStatus = (tab = "", payload = {}) => {
    if (!sequenceContactStoreData?.isOverviewToContactsPage) {
      const filterPayloadValue =
        sequenceContactStoreData?.filterPayload?.filters || {};
      let tempFilter = {};
      payload.filters = {
        ...filterPayloadValue
      };
      if (Utils.isEligibleContactFilter(tab)) {
        payload.filters = {
          ...filterPayloadValue,
          statuses: SequenceUtils.getSelectedTab(tab)
        };
        tempFilter = {
          statuses: SequenceUtils.getSelectedTab(tab)
        };
      } else if (tab?.toLowerCase() !== "total") {
        let fields = [
          {
            fieldName: SEQ_CONTACT_TAB_FILTER_OBJECT[tab?.toLowerCase()] || tab,
            operator: "GREATER_THAN",
            value: 0
          }
        ];
        tempFilter = {
          fields
        };
        if (filterPayloadValue?.fields?.length > 0) {
          fields = filterPayloadValue.fields.concat(fields);
        }
        payload.filters = { ...payload.fields, fields };
      }
      const { all = false, idArr = [] } =
        sequenceRenderState?.contactOwner || {};
      if (idArr?.length > 0) {
        payload.filters.memberIds = idArr;
      } else {
        payload.filters.all = all;
      }
      sequenceContactStoreData.setOverallPayload(payload);
      sequenceContactStoreData.setTopFilter(tempFilter);
      return payload;
    }
    return {};
  };

  const getTableContacts = (tab = "Total", tagIds = [], loaderFlag = true) => {
    if (!sequenceContactStoreData?.isOverviewToContactsPage) {
      loaderFlag && setLoader(true);
      const { all = false, idArr = [] } =
        sequenceRenderState?.contactOwner || {};
      setExportData([]);
      setScheduledIdData([]);
      let payload = {
        campaignId: seqId,
        limit: contactLimit,
        filters: { searchTerm: searchKeyword }
      };
      payload = getPayloadFilterStatus(tab, payload, tagIds);
      if (idArr?.length > 0) {
        payload.filters.memberIds = idArr;
      } else {
        payload.filters.all = all;
      }
      payload.filters.stepMetrics = sequenceRenderState?.filterByStep
        ? [sequenceRenderState?.filterByStep]
        : [];
      sequenceContactData.getSeqContacts(payload);
    }
  };

  const changeStatusClicked = (seqId = "") => {
    let contactList = contactsData.individualSequenceAnalyticsList;
    const updatedContactList = contactList.map(e => {
      if (e) {
        if (e.sequenceId === seqId) {
          e.showStatusDropDown = !e.showStatusDropDown;
        } else {
          e.showStatusDropDown = false;
        }
      }
      return e;
    });
    contactsData.individualSequenceAnalyticsList = updatedContactList;
    setContactsData(contactsData);
  };

  const hasContacts = () => {
    return (
      contactsData?.individualSequenceAnalyticsList &&
      contactsData?.totalContacts > 0
    );
  };

  const hideChangeStatusDropDOwnMenu = id => {
    let contactList = contactsData?.individualSequenceAnalyticsList;
    const updatedContactList = contactList.map(item => {
      item.sequenceId === id && (item.showStatusDropDown = false);
      return item;
    });
    contactsData.individualSequenceAnalyticsList = updatedContactList;
    setContactsData(contactsData);
  };

  const pauseContactSequence = async (id, contact) => {
    hideChangeStatusDropDOwnMenu(id);
    if (await checkTeamSubscriptionActive()) {
      toasterState.setToastMsg("Processing...", "info");
      let targetUrl = URLS.pauseContactSeq || "";
      if (targetUrl) {
        targetUrl = targetUrl?.replace("<<seqId>>", id);
        const config = {
          url: targetUrl,
          method: "PUT"
        };
        let res = await makeApi(config);
        if (res?.data) {
          const { requestStatus = "" } = res?.data || {};
          if (requestStatus) {
            setUpdateSequenceContacts(true);
            const status = "PAUSED";
            handleSequenceStatusUpdate(id, status);
            toasterState.setToastMsg(
              "Contact has been paused successfully",
              "success"
            );
          } else {
            handleSequenceStatusUpdate(id);
            toasterState.setToastMsg(
              "Failed to pause the contact from the sequence",
              "failure"
            );
          }
        } else {
          Utils.showApiFailureResponse(res);
        }
      }
    }
  };

  const handleSequenceStatusUpdate = (id, status) => {
    let contactList = contactsData.individualSequenceAnalyticsList;
    const updatedContactList = contactList.map(e => {
      if (e && e.sequenceId === id) {
        if (status) e.status = status;
        e.showStatusDropDown = false;
      }
      return e;
    });
    contactsData.individualSequenceAnalyticsList = updatedContactList;
    setContactsData(contactsData);
    sequenceContactData.getSeqAnalyticsTab(
      seqId,
      sequenceContactStoreData?.filterPayload?.filters || {}
    );
    filterContactByStatus(sequenceRenderState.currentStage);
  };

  const resumeContactCbk = async id => {
    toasterState.setToastMsg("Processing...", "info");
    let targetUrl = URLS.resumeContactSeq ? URLS.resumeContactSeq : "";
    if (targetUrl) {
      targetUrl = targetUrl.replace("<<seqId>>", id);
      const config = {
        url: targetUrl,
        method: "PUT"
      };
      let res = await makeApi(config);
      if (res?.data) {
        const { inboxError = {}, respCode = "" } = res?.data || {};
        if (
          +respCode === 200 &&
          (!inboxError || (inboxError && !Object.keys(inboxError)?.length))
        ) {
          setUpdateSequenceContacts(true);
          const status = "ACTIVE";
          handleSequenceStatusUpdate(id, status);
          toasterState.setToastMsg(
            "Contact has been activated successfully",
            "success"
          );
        } else if (+respCode === 407) {
          toasterState.setToastMsg(
            "Failed to resume contact. Contact's scheduled inbox is unavailable.",
            "failure"
          );
        } else if (+respCode === 403) {
          toasterState.setToastMsg(
            "Failed to resume contact. You don't have access to this contact.",
            "failure"
          );
        } else if (inboxError && Object.keys(inboxError)?.length) {
          const email = Object.keys(inboxError)?.[0];
          confirmationPopupState.setPopupValues({
            content: `Failed to activate the contact as <a class="emailLink" href="mailto:${email}">${email}</a> is not connected to this sequence.`,
            actionBtnText: "Ok",
            callback: () => {
              confirmationPopupState.setShowPopup(false);
              history && history.push("#settings");
            },
            needCancelBtn: false
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg(
            "Failed to resume contact from the sequence",
            "failure"
          );
        }
      } else {
        Utils.showApiFailureResponse(res);
      }
    }
  };

  const resumeOOOContactCbk = async id => {
    toasterState.setToastMsg("Processing...", "info");
    let targetUrl = URLS.resumeOOOContactSeq || "";
    if (targetUrl) {
      targetUrl = targetUrl.replace("<<seqId>>", id);
      const config = {
        url: targetUrl,
        method: "POST"
      };
      let res = await makeApi(config);
      if (res?.data) {
        if (res?.data?.status) {
          setUpdateSequenceContacts(true);
          const status = "ACTIVE";
          handleSequenceStatusUpdate(id, status);
          toasterState.setToastMsg(
            "Contact has been activated successfully",
            "success"
          );
        } else {
          toasterState.setToastMsg(
            "Failed to resume contact from the sequence",
            "failure"
          );
        }
      } else {
        Utils.showApiFailureResponse(res);
      }
    }
  };

  const OOOPopupContent = props => {
    const { contact = {} } = props;
    return (
      <>
        <div>Are you sure you want to resume this PAUSED (ooo) contact?</div>
        <div style={{ fontSize: "13px", marginTop: "4px" }}>
          {`This contact will resume automatically on ${getFormattedDate(
            contact?.rescheduleDate
          )}. However, clicking on 'Yes' will resume the contact immediately.`}
        </div>
      </>
    );
  };

  const resumeContactSequence = async (id, status, contact = {}) => {
    hideChangeStatusDropDOwnMenu(id);
    if (await checkTeamSubscriptionActive()) {
      if (allowContactsUpload) {
        if (!Utils.isAccountConnected()) {
          Utils.showAddAccountPopup(
            props,
            "Please connect your email account before resuming the sequences"
          );
        } else {
          if (status === "PAUSED_OOTO") {
            confirmationPopupState.setPopupValues({
              content: <OOOPopupContent contact={contact} />,
              actionBtnText: "Yes",
              cancelBtnText: "Cancel",
              cancelCbk: () => confirmationPopupState.setShowPopup(false),
              callback: () => resumeOOOContactCbk(id),
              dynamicDom: true
            });
            confirmationPopupState.setShowPopup(true);
          } else {
            resumeContactCbk(id);
          }
        }
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "pauseResumeSequence",
          false,
          planName,
          inFreePlan
        );
      }
    }
  };

  const filterContactByStatus = (value = "") => {
    setLoader(true);
    resetValueToInitial();
    setCurrentStage(value);
    getTableContacts(value);
  };

  const paginate = (pageNoObj, limit) => {
    if (!loader) {
      resetValueToInitial();
      let payload = {
        campaignId: seqId
      };
      payload.pageNo = pageNoObj.selected ? pageNoObj.selected + 1 : 1;
      payload.limit = contactLimit;
      payload = getPayloadFilterStatus(
        sequenceRenderState?.currentStage || "",
        payload
      );
      payload.filters = {
        ...payload?.filters,
        ...sequenceContactStoreData?.filterPayload?.filters
      };
      const { all, idArr } = sequenceRenderState.contactOwner;
      if (idArr.length > 0) {
        payload.filters.memberIds = idArr;
      } else {
        payload.filters.all = all;
      }
      payload.filters.stepMetrics = sequenceRenderState?.filterByStep
        ? [sequenceRenderState?.filterByStep]
        : [];
      setLoader(true);
      sequenceContactData.getSeqContacts(payload);
    }
  };

  const addStatusColor = status => {
    switch (status) {
      case "ACTIVE":
        return "color-active";
      case "PAUSED":
      case "PAUSED_OOTO":
        return "color-paused";
      case "COMPLETED":
        return "color-complete";
      case "MEETING_BOOKED":
        return "color-meeting-booked";
      case "REPLIED":
        return "color-complete";
      case "SUSPENDED":
        return "color-hold";
      case "OPTED_OUT":
        return "color-hold";
      case "PENDING":
        return "color-pending";
      case "FAILED":
        return "color-failed";
      default:
        return "color-default";
    }
  };

  const changeStatusIcon = status => {
    switch (status) {
      case "ACTIVE":
        return "access_time";
      case "PAUSED":
      case "PAUSED_OOTO":
        return "pause_circle_outline";
      case "COMPLETED":
        return "check_circle";
      case "MEETING_BOOKED":
        return "event";
      case "REPLIED":
        return "reply";
      case "SUSPENDED":
        return "schedule";
      case "OPTED_OUT":
        return "block";
      case "BOUNCED":
        return "call_missed_outgoing";
      case "PENDING":
        return "hourglass_top";
      case "FAILED":
        return "error_outline";
      default:
        return "warning";
    }
  };

  const getUploadContact = value => {
    setContactUploading(value);
  };

  const getFormattedDate = date => {
    return Utils.getFormattedDateToDisplay(date);
  };

  const canShowStatusDropDown = (status = "") => {
    if (["PAUSED", "PAUSED_OOTO"]?.includes(status) && !sequenceToggleBtn) {
      return false;
    } else {
      return ["ACTIVE", "PAUSED", "PAUSED_OOTO"].includes(status);
    }
  };

  const StatuSection = (props = {}) => {
    const { contact = {}, viewEmailReply = () => {} } = props || {};
    const {
      status = "",
      code = "",
      missingFields = [],
      errorCode = "",
      replyDetectionSource = ""
    } = contact || {};

    const { multipleInboxEnabled = false } = userDetail?.userFeatureFlag || {};

    const getDropdownTopValue = seqId => {
      if (seqId) {
        const targetDom = document.getElementById(seqId);
        const parentDom = document.querySelector(".contactContent");
        const targetOffset = targetDom ? targetDom.offsetTop + 16 : 0; // 16 top is padding and border of contact card
        if (
          targetOffset &&
          parentDom &&
          targetOffset > parentDom.offsetHeight - 60
        ) {
          return "-48px";
        }
      }
    };

    const extractPhoneNumbersFromContact = contact => {
      if (contact?.phone?.length) {
        contact.phone = contact.phone[0];
      }
      if (contact?.mobilePhone?.length) {
        contact.mobilePhone = contact.mobilePhone[0];
      }
      if (contact?.otherPhone?.length) {
        contact.otherPhone = contact.otherPhone[0];
      }
      return contact;
    };

    const handleUpdateContactSuccess = (contact = {}) => {
      let contactList = contactsData.individualSequenceAnalyticsList;
      const updatedContactList = contactList.map(item => {
        if (item?.emailAddress === contact?.email) {
          item.name = `${contact?.firstName} ${contact?.lastName}`;
        }
        return item;
      });
      contactsData.individualSequenceAnalyticsList = updatedContactList;
      setContactsData(contactsData);
    };

    const handleContactClick = async () => {
      const { contactId = "", id = "" } = contact || {};
      let tempId = id || contactId;
      if (tempId) {
        try {
          const url = URLS.getContactDetail.replace("<<contactId>>", tempId);
          const config = {
            url
          };
          let res = await makeApi(config);
          if (res?.data?.contacts?.length) {
            let updatedItem = extractPhoneNumbersFromContact(
              res?.data?.contacts?.[0]
            );
            editContactPopupState.setContact(updatedItem);
            editContactPopupState.setShowPopup(true);
            editContactPopupState.setCallback(handleUpdateContactSuccess);
          } else {
            setUpdatedContact([]);
          }
        } catch (error) {
          console.error("Getting contact card info error ", error);
        }
      }
    };

    const PopupContent = () => {
      return (
        <div className="invalidVariablesText">
          <div>
            The following mandatory fields are missing. Please update the
            contact with these fields and click <b>Retry</b>.{" "}
            <div className="LearnMoreBtnWrapper">
              <a
                href={SUPPORT_URL_RESUMING_FAILED_CONTACTS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              <span className="material-icons-outlined launchIcon">launch</span>
            </div>
          </div>
          {SequenceUtils.tokensOrderList(missingFields)}
        </div>
      );
    };

    const getFailedToolTip = () => {
      confirmationPopupState.setPopupValues({
        content: <PopupContent />,
        actionBtnText: "Update",
        dynamicDom: true,
        callback: async () => {
          confirmationPopupState.setShowPopup(false);
          handleContactClick();
        }
      });
      confirmationPopupState.setShowPopup(true);
    };

    const autoReplyErrorCode = {
      LEFT_THE_COMPANY: "Left the company",
      EMAIL_ADDRESS_CHANGED: "Email address changed",
      USER_INBOX_FULL: "User inbox is full"
    };

    const statusMsgObject = {
      ACTIVE: "ongoing",
      SUSPENDED: "on hold",
      OPTED_OUT: "opted out",
      IN_ACTIVE: "inactive",
      MEETING_BOOKED: "meeting booked",
      AUTO_REPLY: "auto reply",
      PAUSED_OOTO: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "4px" }}>paused </span>
          <span style={{ fontSize: "8px" }}>(OOO)</span>
        </div>
      )
    };

    const getStatusType = () => {
      return status?.toLowerCase() === "paused_ooto"
        ? "paused"
        : status?.toLowerCase();
    };

    const handleStatusClick = () => {
      if (["active", "paused", "paused_ooto"].includes(status?.toLowerCase())) {
        changeStatusClicked(contact?.sequenceId);
      }
    };

    const handleRepliedStatusClick = () => {
      if (status?.toLowerCase() === "replied") {
        if (replyDetectionSource?.toLowerCase() !== "linkedin") {
          viewEmailReply(contact, status);
        } else {
          viewLinkedInReply(contact, status, replyDetectionSource);
        }
      }
    };

    return (
      <>
        <div
          className={`statusContainer ${getStatusType()} ${
            replyDetectionSource?.toLowerCase() === "linkedin"
              ? "linkedinReply"
              : ""
          }`}
          onClick={handleRepliedStatusClick}
          onMouseEnter={handleStatusClick}
          onMouseLeave={() =>
            hideChangeStatusDropDOwnMenu(contact?.sequenceId || "")
          }
        >
          <span
            className={`status-name ${status.toLowerCase()} ${addStatusColor(
              status
            )} ${status === "FAILED" ? "cursor" : ""} ${
              status === "PAUSED_OOTO" ? "paused_ooto" : ""
            }`}
          >
            {replyDetectionSource?.toLowerCase() === "linkedin" &&
            status?.toLowerCase() !== "opted_out" ? (
              <LinkedInIcon />
            ) : status?.toLowerCase() === "auto_reply" ? (
              <span className="autoReplyStatusIcon">
                <WarningField />
              </span>
            ) : (
              <i className="material-icons-outlined">
                {changeStatusIcon(status)}
              </i>
            )}
            &nbsp;
            {status?.toLowerCase() === "replied"
              ? replyDetectionSource?.toLowerCase() === "linkedin"
                ? "View Reply (LinkedIn)"
                : "View Reply (Email)"
              : statusMsgObject?.[status] || status?.toLowerCase()}
          </span>
          {status === "FAILED" &&
            (code?.toLowerCase() === "missing_token" || !code) && (
              <CustomTooltip dynamicDom={true} className="missingTokenVariable">
                <>
                  Missing contact Variable.{" "}
                  {!missingFields || !missingFields?.length > 0 ? (
                    <a
                      href={SUPPORT_URL_RESUMING_FAILED_CONTACTS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more.
                    </a>
                  ) : (
                    <span className="linkHighLight" onClick={getFailedToolTip}>
                      Show more.
                    </span>
                  )}
                </>
              </CustomTooltip>
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "inbox_not_found" && (
              <CustomTooltip
                text={`Inbox unavailable. Please connect inbox to resume.`}
                redirectText="Connect."
                className="inboxUnavailable"
                redirectLink={
                  multipleInboxEnabled ? "#settings" : "/settings/account"
                }
                hasRedirectLink={true}
                {...props}
              />
            )}
          {status?.toUpperCase() === "FAILED" &&
            code === "CONTACT_EMAIL_NOT_FOUND" && (
              <CustomTooltip
                text={`Contact's email address is not available.`}
                {...props}
              />
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "li_error" && (
              <CustomTooltip
                text={`This scenario is not applicable`}
                {...props}
              />
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "do_not_contact" && (
              <CustomTooltip
                text={`Contact's stage is 'do not contact'`}
                {...props}
              />
            )}
          {status === "FAILED" &&
            code?.toLowerCase() === "removed_from_do_not_contact" && (
              <CustomTooltip text="removed from do not contact" {...props} />
            )}
          {status === "FAILED" &&
            code?.toLowerCase() === "email_send_limit" && (
              <CustomTooltip text={`email send limit reached`} {...props} />
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "invalid_subject" && (
              <CustomTooltip
                text={`Missing email subject line.`}
                {...props}
                className="missingSubjectLine"
              />
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "contact_not_eligible" && (
              <CustomTooltip
                text={`LinkedIn profile URL unavailable for this contact. Please update and try again.`}
                {...props}
                className="contactNotEligible"
              />
            )}
          {status === "REPLIED" &&
            replyDetectionSource?.toLowerCase() !== "linkedin" && (
              <span className="replyVisibility">
                <RepliedIcon />
              </span>
            )}
          {status?.toUpperCase() === "FAILED" &&
            code?.toLowerCase() === "li_inbox_not_found" && (
              <CustomTooltip
                text={`LinkedIn account unavailable. Please connect account to resume.`}
                redirectText="Connect."
                className="inboxUnavailable"
                redirectLink={"/settings/account/linkedIn"}
                hasRedirectLink={true}
                {...props}
              />
            )}
          {status?.toLowerCase() === "auto_reply" && errorCode && (
            <CustomTooltip
              className="autoReplyTooltip"
              text={autoReplyErrorCode?.[errorCode] || "Uncategorised"}
              {...props}
            />
          )}
          {canShowStatusDropDown(status) && (
            <span className="change-status">
              <i className="material-icons icon-arrow">keyboard_arrow_down</i>
              {contact.showStatusDropDown && (
                <div>
                  <ul
                    className="status-dropdown"
                    style={{ top: getDropdownTopValue(contact.sequenceId) }}
                  >
                    {contact.status === "ACTIVE" && (
                      <li
                        key="pause"
                        className="status-dropdown-item color-paused"
                        onClick={e =>
                          pauseContactSequence(
                            contact?.sequenceId || "",
                            contact
                          )
                        }
                      >
                        <i className="material-icons-outlined">
                          pause_circle_outline
                        </i>
                        <span>Pause</span>
                      </li>
                    )}
                    {["PAUSED", "PAUSED_OOTO"]?.includes(status) && (
                      <li
                        key="resume"
                        className="status-dropdown-item color-active"
                        onClick={e => {
                          resumeContactSequence(
                            contact.sequenceId,
                            status,
                            contact
                          );
                        }}
                      >
                        <i className="material-icons-outlined">play_arrow</i>
                        <span>Resume</span>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </span>
          )}{" "}
        </div>
      </>
    );
  };

  const isEligibleToShowNextStep = status => {
    return (
      status &&
      ![
        "COMPLETED",
        "BOUNCED",
        "REPLIED",
        "OPTED_OUT",
        "PENDING",
        "FAILED"
      ].includes(status)
    );
  };

  const markSelectedContact = (value, contactId) => {
    if (allContactSelected) {
      deselectCheckedPopupState.setPopupValues({
        callback: resetToSelectedCheckboxCbk
      });
      deselectCheckedPopupState.setPopup(true);
    } else {
      if (contactId === "all") {
        setExportAllContactSelected(value);
        toggleSelectAllTableContact();
        constructExportData("all");
        !value && resetValueToInitial();
      } else {
        setExportAllContactSelected(false);
        toggleSelectContactById(contactId);
        constructExportData();
      }
    }
  };

  const toggleSelectContactById = contactId => {
    const contactList =
      contactsData && contactsData.individualSequenceAnalyticsList;
    if (contactId) {
      contactList &&
        contactList.map(value => {
          value?.contactId === contactId &&
            (value.isSelected = !value.isSelected);
        });
      updateContactsData(contactList);
    }
  };

  const toggleSelectAllTableContact = () => {
    const contactList =
      contactsData && contactsData.individualSequenceAnalyticsList;
    contactList &&
      contactList.map(value => {
        if (value && value.contactId) {
          if (exportAllContactSelected) {
            value.isSelected = false;
            setExportAllContactSelected(false);
          } else {
            value.isSelected = true;
          }
        }
      });
    updateContactsData(contactList);
  };

  const constructExportData = flag => {
    const tempArr = [];
    const scheduledId = [];
    const contactList =
      contactsData && contactsData.individualSequenceAnalyticsList;
    if (flag === "all") {
      contactList &&
        contactList.map(value => {
          if (value?.contactId) {
            tempArr.push(value?.contactId);
            scheduledId.push(value?.sequenceId);
          }
        });
    } else {
      contactList &&
        contactList.map(value => {
          if (value?.contactId && value?.isSelected) {
            tempArr.push(value?.contactId);
            scheduledId.push(value?.sequenceId);
          }
        });
    }
    setExportData(tempArr);
    setScheduledIdData(scheduledId);
  };

  const updateContactsData = contactList => {
    if (contactList) {
      const dupContactsData = { ...contactsData };
      dupContactsData.individualSequenceAnalyticsList = contactList;
      setContactsData(dupContactsData);
    }
  };

  const slideContactCard = (contact = {}) => {
    selectedContactCard(contact);
  };

  const resetValueToInitial = () => {
    setExportAllContactSelected(false);
    setExportData([]);
    setScheduledIdData([]);
    clearSearchInput();
    clearSearchPerformingState();
  };

  const filterAnalyticsByTags = (
    contactTags = [],
    searchKeyword = null,
    payload = {}
  ) => {
    if (contactTags?.length) {
      clearSearchPerformingState();
      sequenceContactData.getSeqAnalyticsTab(seqId, payload);
    } else if (searchKeyword) {
      sequenceContactData.getSeqAnalyticsTab(seqId, payload, searchKeyword);
    } else {
      clearSearchPerformingState();
      let tempPayload = sequenceContactStoreData?.filterPayload?.filters || {};
      tempPayload.stepMetrics = sequenceRenderState?.filterByStep
        ? [sequenceRenderState?.filterByStep]
        : [];
      sequenceContactData.getSeqAnalyticsTab(seqId, tempPayload);
    }
  };

  const showDemoVideoCard = () => {
    return (
      !(
        contactsData &&
        Object.keys(contactsData).length &&
        contactsData?.totalContacts > 0
      ) && activeTab === "total"
    );
  };

  const hasContactChecked = () => {
    return selectedEntityCount || contactIds?.length ? true : false;
  };

  const searchContact = e => {
    Utils.preventDefaultFn(e);
    const { all = false, idArr = [] } = sequenceRenderState?.contactOwner || {};
    const payload = {
      campaignId: seqId,
      limit: contactLimit,
      pageNo: contactsData?.currentPage,
      filters: {
        ...sequenceContactStoreData?.filterPayload?.filters,
        searchTerm: searchKeyword
      }
    };
    if (idArr?.length > 0) {
      payload.filters.memberIds = idArr;
    } else {
      payload.filters.all = all;
    }
    payload.filters.stepMetrics = sequenceRenderState?.filterByStep
      ? [sequenceRenderState?.filterByStep]
      : [];
    setShowStepsList(false);
    sequenceRenderState.setPerformingSearch(true);
    contactSelectedTagListStore.setEmptyTagList();
    sequenceRenderState.setCurrentStage("Total");
    sequenceRenderState.setFilteredTags([]);
    setLoader(true);
    filterAnalyticsByTags(null, searchKeyword, payload?.filters);
    sequenceContactData.getSeqContacts(payload);
  };

  const handleSearchAction = event => {
    Utils.preventDefaultFn(event);
    setSearchKeyword(event.currentTarget.value);
    setEmailClose(!event.currentTarget.value.length < 1);
  };

  const submitSearchByEmailInput = e => {
    e?.preventDefault();
    e?.keyCode === 13 && searchContact(e);
  };

  const clearSearch = () => {
    clearSearchInput();
    if (sequenceRenderState.performingSearch) {
      setLoader(true);
      resetValueToInitial();
      filterAnalyticsByTags();
      getTableContacts(sequenceRenderState.currentStage);
    }
  };

  const clearSearchInput = () => {
    setSearchKeyword("");
    setEmailClose(false);
  };

  const clearSearchPerformingState = () => {
    sequenceRenderState.setPerformingSearch(false);
  };

  const reloadSequenceContactsPage = () => {
    getTableContacts(sequenceRenderState.currentStage);
    sequenceContactData.getSeqAnalyticsTab(
      seqId,
      sequenceContactStoreData?.filterPayload?.filters || {}
    );
  };

  const reloadAndResetContact = (completedContactIds = []) => {
    if (completedContactIds?.length) {
      reloadSequenceContactsPage();
    }
    resetContactCbk();
  };

  const getStageNextScheduledDateTooltip = (contactId = "", value = false) => {
    if (contactId) {
      let contactList = contactsData.individualSequenceAnalyticsList;
      const updatedContactList = contactList.map(item => {
        item?.contactId === contactId && (item.showNextDateTooltip = value);
        return item;
      });
      contactsData.individualSequenceAnalyticsList = updatedContactList;
      setContactsData(contactsData);
    }
  };

  const navigateToOutBox = (
    campaignId = "",
    nextTaskType = "",
    emailAddress
  ) => {
    if (["email"].includes(nextTaskType?.toLowerCase())) {
      let payload = {
        pageNo: 1,
        limit: 10,
        sortDirection: "ASC",
        sortParam: "SCHEDULED_DATE",
        filters: {
          all: false,
          memberIds: [userDetail?.userInfo?.memberId],
          campaignId: [campaignId],
          toAddress: [emailAddress]
        }
      };
      history.push("/inbox", { payload });
    }
  };

  const handleViewLinkedInContent = (resData = {}) => {
    const { liMessages = [] } = resData || {};
    linkedInReplyPopupState.setShowPopup(true);
    linkedInReplyPopupState.setEditorLoading(false);
    linkedInReplyPopupState.setLiMessages(liMessages);
  };

  const handleViewEmailContent = (resData = {}, contactData = {}) => {
    const { emailInsights = {} } = contactData || {};
    const {
      id = "",
      touchPoint = {},
      sentDate = "",
      replyDate = "",
      sequenceId = "",
      replyContent = "",
      sequenceName = "",
      order = "",
      taskType = "",
      oneOffEmail = "",
      timelineEmail = "",
      content = "",
      shortContent = ""
    } = resData || {};
    const {
      fromAddress = "",
      toAddress = "",
      ccAddress = "",
      bccAddress = "",
      subject = "",
      repliedFromAddress = "",
      repliedToAddress = [],
      repliedCcAddress = []
    } = touchPoint || {};
    emailPreviewContentPopupState.setEditorLoading(false);
    emailPreviewContentPopupState.setShowReplyContent(true);
    emailPreviewContentPopupState.setPopupValues({
      taskId: id,
      eventType: "REPLIED",
      showViewReplyBtn: false,
      emailData: {
        from: repliedFromAddress
          ? { email: repliedFromAddress }
          : { email: fromAddress },
        to:
          Array.isArray(repliedToAddress) && repliedToAddress.length > 0
            ? InboxUtils.getEmailAddressList(repliedToAddress)
            : InboxUtils.getEmailAddressList(toAddress),
        cc:
          Array.isArray(repliedCcAddress) && repliedCcAddress.length > 0
            ? InboxUtils.getEmailAddressList(repliedCcAddress)
            : InboxUtils.getEmailAddressList(ccAddress),
        bcc: InboxUtils.getEmailAddressList(bccAddress),
        subject,
        content: replyContent || content || shortContent || ""
      },
      createTs: replyDate || sentDate,
      taskNotes: touchPoint?.notes,
      pageType: "sequenceContacts",
      sequenceId,
      replyContent,
      sequenceName,
      order,
      currentUserEmail: userDetail?.userInfo?.email,
      folderId,
      sequenceShareType: sequence?.shareType,
      email: "",
      taskType,
      autoEmail: taskType?.toLowerCase() === "email",
      group: "EMAIL",
      emailInsights,
      hideViewReplyBack: true,
      oneOffEmail,
      timelineEmail,
      isEnableReplyFrwd: true,
      pageType: "sequenceReply"
    });
    emailPreviewContentPopupState.setShowPopup(true);
  };

  const viewLinkedInReply = async (
    contact = {},
    status = "",
    replyDetectionSource = ""
  ) => {
    linkedInReplyPopupState.setShowPopup(true);
    linkedInReplyPopupState.setEditorLoading(true);
    linkedInReplyPopupState.setContactSequenceId(contact?.sequenceId);
    if (
      status?.toLowerCase() === "replied" &&
      replyDetectionSource?.toLowerCase() === "linkedin"
    ) {
      const { sequenceId = "" } = contact || {};
      if (sequenceId) {
        const config = {
          url: `${URLS?.getEmailContentViaScheduledId}${sequenceId}`,
          method: "GET"
        };
        let res = await makeApi(config);
        if (res?.data) {
          handleViewLinkedInContent(res?.data);
        }
      } else {
        toasterState.setToastMsg("something went wrong", "failure");
      }
    }
  };

  const viewEmailReply = async (contact = {}, status = "") => {
    if (status?.toLowerCase() === "replied") {
      emailPreviewContentPopupState.setShowPopup(true);
      emailPreviewContentPopupState.setEditorLoading(true);
      const { sequenceId = "", emailInsights = {} } = contact || {};
      if (sequenceId) {
        const config = {
          url: `${URLS?.getEmailContentViaScheduledId}${sequenceId}`,
          method: "GET"
        };
        let res = await makeApi(config);
        if (res?.data) {
          handleViewEmailContent(res?.data, { emailInsights });
        }
      } else {
        toasterState.setToastMsg("something went wrong", "failure");
      }
      emailPreviewContentPopupState.setEditorLoading(false);
    }
  };

  const resetOwnersList = (item = []) => {
    const { hasTeamMember } = userDetail?.userInfo || {};
    if (filterArr?.length && filterArr.includes("owners") && item?.length) {
      let tempData = [...item];
      tempData.map(item => (item.checked = false));
      userDetail.setUserInfo({
        ...userDetail.userInfo,
        hasTeamMember: tempData || false
      });
      return tempData;
    }
    return hasTeamMember;
  };

  const resetTagsList = (item = []) => {
    if (filterArr?.length && filterArr.includes("tags") && item?.length) {
      let tempData = [...item];
      tempData.map(item => (item.checked = false));
      tagsStoreData.setTagsData(tempData);
      return tempData;
    }
    return tagsStoreData.tagsData;
  };

  const addFilter = (currentStep = "all", data = []) => {
    const tempData = Utils.createObjWithoutRef(data);
    tempData.map((val = {}) => {
      const updatedCurrentStep = val?.currentStep || 0;
      val.selected = currentStep === updatedCurrentStep;
    });
    setMetricData(tempData);
    // Just clear the filter to get all the contacts if currentStep is "all"
    sequenceRenderState.setFilterByStep(
      currentStep === "all" ? null : currentStep
    );
  };

  const modifyInitialData = (response = {}) => {
    const { totalContacts = 0, stepMetrics = [] } = response || {};
    const isFilterSelected = sequenceRenderState?.filterByStep;
    if (isFilterSelected) {
      // applying the filter which already applied from step badge click from overview section
      allDataForSteps.count = totalContacts || 0;
      stepMetrics.unshift(allDataForSteps);
      addFilter(isFilterSelected, stepMetrics);
    } else {
      [...stepMetrics].map(val => {
        val.selected = false;
      });
      allDataForSteps.selected = true;
      allDataForSteps.count = totalContacts || 0;
      stepMetrics.unshift(allDataForSteps);
      setMetricData(stepMetrics);
    }
    setAnalyticStepLoader(false);
  };

  const getStepMetricData = async (currentStep = null, hasStep = false) => {
    const url = URLS.getStepLevelMetrics.replace("<<sequenceId>>", seqId);
    let data = {};
    // if (
    //   sequenceContactStoreData?.overallPayload?.filters &&
    //   Object.keys(sequenceContactStoreData?.overallPayload?.filters)?.length
    // ) {
    //   data = {
    //     ...sequenceContactStoreData?.overallPayload?.filters,
    //     ...(hasStep && {
    //       stepMetrics:
    //         [
    //           sequenceContactStoreData?.isOverviewToContactsPage
    //             ? sequenceContactStoreData?.overallPayload?.filters?.currentStep
    //             : currentStep
    //         ] || []
    //     })
    //   };
    // } else {
    //   data = sequenceContactStoreData?.overallPayload;
    // }
    const config = {
      url: url,
      method: "POST",
      data: {
        stepMetrics: []
      }
    };
    const response = await makeApi(config);
    modifyInitialData(response?.data);
  };

  const resetContactFilter = () => {
    setActiveFilter("");
    let tags = resetTagsList(data?.tags);
    let owners = resetOwnersList(data?.owners);
    const defaultFilterObject = {
      contactFields: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      metrics: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      dates: [
        {
          fieldName: "",
          isSet: false,
          isNotSet: false,
          fromDate: new Date(),
          toDate: null,
          operator: "BETWEEN"
        }
      ],
      sequences: [
        {
          fieldName: "",
          sequenceId: "",
          sequenceName: ""
        }
      ]
    };
    let tempObj = { ...defaultFilterObject, ...{ tags, owners } };
    setData(tempObj);
    sequenceContactStoreData.setContactFilterObject(tempObj);
    let filters =
      SequenceUtils.getActiveTabFilterPayload(
        sequenceRenderState?.currentStage || ""
      ) || {};
    const payload = {
      filters,
      pageNo: 1,
      limit: 10,
      campaignId: seqId
    };
    sequenceContactStoreData.setTopFilter(payload?.filters);
    sequenceContactStoreData.setFilterPayload(payload);
    sequenceContactStoreData.setOverallPayload(payload);
  };

  const checkForNotMapped = (data, platform, configType) => {
    return (
      data?.[platform]?.filter(
        item =>
          +item?.status === 101 &&
          item?.configType?.toLowerCase() === configType
      )?.length > 0
    );
  };

  const verifyCrmForExportInSequence = async () => {
    const config = {
      url: URLS.checkCrmConnectionForExport,
      method: "GET"
    };
    const response = await makeApi(config);
    if (+response?.status === 200) {
      const respData = response?.data || {};
      const isHubspotConnected = "HUBSPOT" in respData;
      const isSfConnected = "SALESFORCE" in respData;
      const salesforceLeadNotMapped = checkForNotMapped(
        respData,
        "SALESFORCE",
        "lead"
      );
      const salesforceContactNotMapped = checkForNotMapped(
        respData,
        "SALESFORCE",
        "contact"
      );
      const hubspotContactNotMapped = checkForNotMapped(
        respData,
        "HUBSPOT",
        "contact"
      );
      sequenceContactStoreData.setHubspotExportStatus({
        ...sequenceContactStoreData.hubspotExportStatusExportStatus,
        hubspotContactNotMapped,
        isHubspotConnected
      });
      sequenceContactStoreData.setSfExportStatus({
        ...sequenceContactStoreData.sfExportStatus,
        salesforceLeadNotMapped,
        salesforceContactNotMapped,
        isSfConnected
      });
    }
  };

  const fetchUploadCountAndContactData = async interval => {
    try {
      const response = await sequenceContactStoreData.getContactUploadStatus(
        seqId
      );
      if (
        isContactUploading &&
        ["INPROGRESS"].includes(isContactUploading?.toUpperCase())
      ) {
        await sequenceContactData.getSeqAnalyticsTab(seqId);
        getTableContacts("total", "", false);
      }
      const { status = "", progressPercent = "" } = response?.[0] || {};
      if (status) {
        setProgressPercent(progressPercent);
        setContactUploading(status);
      } else {
        setProgressPercent(100);
        setContactUploading(false);
      }
      if (status?.toUpperCase() === "PROCESSED" || !status) {
        clearInterval(interval);
        await sequenceContactData.getSeqAnalyticsTab(seqId);
        getTableContacts("total", "", false);
        return;
      }
    } catch (error) {
      clearInterval(interval);
    }
  };

  const calculateProcessingTime = (contactCount = 1) => {
    return contactCount > 0
      ? (10 + Math.floor(contactCount / 1000) * 4) * 1000
      : 0;
  };

  useEffect(() => {
    let interval;
    if (
      isContactUploading &&
      ["INPROGRESS", "IN_PROGRESS", "TO_BE_PROCESSED"].includes(
        isContactUploading?.toUpperCase()
      )
    ) {
      interval = setInterval(
        fetchUploadCountAndContactData,
        calculateProcessingTime(sequenceRenderState?.csvLength || 10)
      );
      return () => clearInterval(interval);
    }
  }, [isContactUploading]);

  useEffect(() => {
    if (selectedFilter) {
      setShowContactFilter(false);
      setSelectedFilter(true);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (!sequenceRenderState.performingSearch) {
      setLoader(true);
      resetValueToInitial();
      getTableContacts(sequenceRenderState.currentStage);
    }
  }, [
    sequenceRenderState.contactOwner.all,
    sequenceRenderState.contactOwner.idArr,
    contactLimit
  ]);

  useEffect(() => {
    setFilterArr(sequenceContactStoreData?.filteredList);
  }, [sequenceContactStoreData?.filteredList]);

  useEffect(() => {
    if (!isFromStepBadgeNavigation) {
      setLoader(true);
      resetValueToInitial();
      getTableContacts();
    }
    constructTeamDataInFilter();
    verifyCrmForExportInSequence();
    if (sequence) {
      Utils.mixpanelTrack(MP_EVENT.SQ_CT_VIEWED, {
        pageType: "Sequence Contacts",
        totalContacts: sequence?.contactsAdded,
        sequenceName: sequence?.name
      });
    }
    return () => {
      setSearchKeyword("");
      sequenceContactStoreData.setHubspotExportStatus({});
      sequenceContactStoreData.setSfExportStatus({});
      setFromStepBadgeNavigation(false);
      resetContactFilter();
      sequenceRenderState.setFilterByStep(null);
    };
  }, []);

  useEffect(() => {
    showStepsList && getTableContacts(sequenceRenderState?.currentStage);
  }, [showStepsList]);

  useEffect(() => {
    if (updateSequenceContacts) {
      setLoader(true);
      getTableContacts(sequenceRenderState.currentStage);
      setUpdateSequenceContacts(false);
    }
  }, [updateSequenceContacts]);

  useEffect(() => {
    if (sequenceStoreData.reloadSequenceContactPage) {
      reloadSequenceContactsPage();
      sequenceStoreData.setReloadSequenceContactPage(false);
    }
  }, [sequenceStoreData.reloadSequenceContactPage]);

  return (
    <div
      className={`seqContactWrapper ${showStepsList ? "stepsListShown" : ""} ${
        showContactFilter ? "contactFilterShown" : ""
      }`}
    >
      <div
        className={`seq-contacts-section ${
          totalPages > 1 ? "" : "hidePagination"
        }`}
      >
        <SequenceContactsNav
          cbk={filterContactByStatus}
          seqId={seqId}
          currentTab={sequenceRenderState.currentStage}
          uploadedContactCbk={getUploadContact}
          addFilter={addFilter}
          metricData={metricData}
          setMetricData={setMetricData}
          getStepMetricData={getStepMetricData}
          getTotalCountForEachFilter={getTotalCountForEachFilter}
          preventLoader={["TO_BE_PROCESSED", "INPROGRESS"].includes(
            isContactUploading && isContactUploading?.toUpperCase()
          )}
        />
        <div
          className={`exportFilterSequenceSection ${
            totalContacts < 11 ? "hidePageLimitSpace" : ""
          }`}
        >
          {selectedEntityCount > 0 && !loader ? (
            <ContactSelectedCount
              selectedCount={selectedEntityCount}
              totalCount={totalContacts}
              loading={loader}
              allContactSelected={allContactSelected}
              setAllContactSelected={setAllContactSelected}
              resetCbk={resetContactCbk}
              selectAllCbk={selectAllCbk}
            />
          ) : (
            <SearchByContactKeyword
              searchContact={searchContact}
              searchKeyword={searchKeyword}
              submitForm={submitSearchByEmailInput}
              handleSearchAction={handleSearchAction}
              clearSearch={clearSearch}
              isEmailClose={isEmailClose}
            />
          )}
          <div className="tagSelectedSection">
            <ContactSelectedTagList />
          </div>
          <div className="filterExportBtnWrap">
            {totalContacts > 0 && (
              <>
                <PageLimit
                  limitPerPage={contactLimit}
                  text="Showing"
                  cbk={setContactLimit}
                  totalCount={totalContacts}
                />
                <AddToSequenceButton
                  selectedEntityCount={selectedEntityCount}
                  cbk={addToSequenceAction}
                  loading={loader}
                  checked={hasContactChecked()}
                />
                <ExportBtn
                  selectedEntityCount={selectedEntityCount}
                  cbk={exportCbk}
                  allEntityCount={totalContacts}
                  checked={hasContactChecked()}
                  isExportEnabled={exportEnabled}
                  showExportTypeDropdown={showExportTypeDropdown}
                  setShowExportTypeDropdown={setShowExportTypeDropdown}
                  pageType="sequences"
                />
                {SequenceUtils.hasSteps(sequence) && (
                  <FilterByStep
                    setShowStepsList={setShowStepsList}
                    showStepsList={showStepsList}
                    resetContactCbk={resetContactCbk}
                  />
                )}
              </>
            )}
            <div className="filterWrapper">
              <div
                className="filterContactBtn"
                onClick={() => {
                  setShowContactFilter(!showContactFilter);
                  setShowStepsList(false);
                  resetContactCbk();
                }}
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <FilterIcon />
                {showTooltip && <CustomTooltip text="Filter by fields" />}
              </div>
            </div>
            <ContactMoreOption
              contactIds={contactIds}
              stage={sequenceRenderState?.currentStage}
              tableLoading={loader}
              resetContactCbk={reloadAndResetContact}
              contactCount={totalContacts}
              allContactSelected={allContactSelected}
              checked={hasContactChecked()}
              pageType={"sequence"}
              searchEmail={searchKeyword}
              sequenceId={seqId}
              activeTab={activeTab}
              selectedEntityCount={selectedEntityCount}
              retryContactCbk={retryContactCbk}
              bulkRemoveSeqContactCbk={bulkRemoveSeqContactCbk}
              bulkReactivateSeqContactCbk={bulkReactivateSeqContactCbk}
              bulkResumeSeqContactCbk={bulkResumeSeqContactCbk}
              addTagsCbk={addTagsCbk}
              removeTagsCbk={removeTagsCbk}
              sequenceToggleBtn={sequenceToggleBtn}
            />
          </div>
        </div>
        <div className="seq-contacts-table-section seqContactPage">
          <div className="seq-contacts-table-header">
            <div className="contactSelectionCbx">
              {contactsData?.totalContacts > 0 && !loader && (
                <CheckBox
                  key="sequenceAllContactsCheck"
                  id="all"
                  checked={exportAllContactSelected}
                  cbk={markSelectedContact}
                  allContactsChecked={allContactSelected}
                />
              )}
            </div>
            <div className="contact-user-name">Name</div>
            <div className="ctUserEmail">Email</div>
            <div className="contact-status">Status</div>
            <div className="contact-stage-insight">Email Insights</div>
            <div className="contact-insight-date">Next Step</div>
            <div className="contact-date">Last Contacted</div>
            <div className="moreActionDot"></div>
          </div>
          {["TO_BE_PROCESSED", "INPROGRESS"].includes(
            isContactUploading && isContactUploading?.toUpperCase()
          ) && (
            <LinearProgressLoader
              count={totalContacts}
              progress={progressPercent}
            />
          )}
          {!loader && hasContacts() && (
            <div className="contactContent">
              <div
                className="seqContactsTableList"
                style={{ overflow: "unset" }}
              >
                {contactsData?.individualSequenceAnalyticsList?.map(
                  (contact, index) => {
                    if (contact) {
                      const {
                        isSelected = false,
                        sequenceId = "",
                        contactId = "",
                        emailAddress = "",
                        emailInsights,
                        lastContacted = "",
                        status = "",
                        nextStep = 0,
                        nextEmailDate = "",
                        showNextDateTooltip = false,
                        nextTaskType = ""
                      } = contact;

                      return (
                        <div
                          className="seq-contacts-table-body"
                          key={sequenceId}
                          id={sequenceId}
                        >
                          <div className="contactSelectionCbx">
                            <CheckBox
                              key="autoBcc"
                              id={contactId}
                              checked={isSelected || false}
                              cbk={markSelectedContact}
                            />
                          </div>
                          <div className="contact-user-name">
                            <h6
                              className="triggerContactCard anchorHighLight"
                              onClick={() => slideContactCard(contact)}
                            >
                              {contact?.name || "--"}
                            </h6>
                          </div>
                          <div className="ctUserEmail">
                            <p
                              className="triggerContactCard show-elipsis anchorHighLight"
                              onClick={() => slideContactCard(contact)}
                            >
                              {emailAddress}
                            </p>
                          </div>

                          <div className="contact-status">
                            <StatuSection
                              contact={contact}
                              {...props}
                              viewEmailReply={viewEmailReply}
                            />
                            <OutofOotoInfoSection
                              emailAddress={emailAddress}
                              status={status}
                              outofOotoTooltip={outofOotoTooltip}
                              setOutofOotoTooltip={setOutofOotoTooltip}
                              contact={contact}
                              sequenceToggleBtn={sequenceToggleBtn}
                              getFormattedDate={getFormattedDate}
                            />
                          </div>
                          <div className="contact-stage-insight">
                            <EmailInsightComponent
                              emailInsight={emailInsights}
                            />
                          </div>
                          <div className="contact-insight-date">
                            {nextStep !== 0 &&
                            status &&
                            isEligibleToShowNextStep(status) &&
                            nextEmailDate ? (
                              <span
                                className="stage-in"
                                onMouseEnter={() =>
                                  getStageNextScheduledDateTooltip(
                                    contactId,
                                    true
                                  )
                                }
                                onMouseLeave={() =>
                                  getStageNextScheduledDateTooltip(
                                    contactId,
                                    false
                                  )
                                }
                                onClick={() =>
                                  navigateToOutBox(
                                    seqId,
                                    nextTaskType,
                                    emailAddress
                                  )
                                }
                              >
                                {SequenceUtils.getNextScheduledEstimated(
                                  nextStep,
                                  nextEmailDate
                                )}
                                {showNextDateTooltip && (
                                  <CustomTooltip
                                    className={
                                      index !== 0 ? "topCenter" : "bottomCenter"
                                    }
                                    text={`Step ${nextStep} is scheduled on ${DateUtils.getDateAndMonth(
                                      nextEmailDate
                                    )} at ${DateUtils.getTimeByMerdian(
                                      nextEmailDate
                                    )} (${DateUtils.getTimeZone(
                                      nextEmailDate
                                    )})`}
                                  />
                                )}
                              </span>
                            ) : (
                              "--"
                            )}
                          </div>
                          <div className="contact-date">
                            {lastContacted !== null
                              ? Utils.getDateFromString(lastContacted)
                              : "--"}
                          </div>
                          <SequenceMoreOption
                            count={index}
                            contact={contact}
                            updateContact={setUpdateSequenceContacts}
                            seqId={seqId}
                            getTableContacts={getTableContacts}
                            activeTab={activeTab}
                          />
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
          {!loader &&
            !hasContacts() &&
            ((isContactUploading &&
              !["INPROGRESS", "IN_PROGRESS", "TO_BE_PROCESSED"].includes(
                isContactUploading?.toUpperCase()
              )) ||
              isContactUploading === null) && (
              <div className="add-contact-stage">
                {activeTab !== "total" && (
                  <>
                    <i className="material-icons-outlined">
                      supervisor_account
                    </i>
                    No Contacts Found
                  </>
                )}
                {showDemoVideoCard() && (
                  <>
                    <img src={NoContactImg} alt="" />
                    <h6>Add Contacts</h6>
                    <AddContactDropDown
                      addSingleContact={addSingleContact}
                      addBulkContact={addBulkContact}
                      showButton={true}
                      btnId="sequenceAddContactBtn"
                      allowContactsUpload={allowContactsUpload}
                      allowSequence={false}
                      sequence={sequence}
                    />
                    <FeatureDemoVideo
                      name={"Add Contacts"}
                      linkText={"How to Add contacts in Sequence"}
                      type={"sequenceContacts"}
                    />
                  </>
                )}
              </div>
            )}
          {loader && (
            <div
              style={{ height: "100%", overflow: "auto", background: "white" }}
            >
              {Array.from({ length: 10 }, (value, index) => (
                <div
                  className="seq-contacts-table-body table-loader"
                  key={`seqContactTablerLoader${index}`}
                >
                  <div className="contactSelectionCbx">
                    <span
                      className="linear-background"
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "3px"
                      }}
                    />
                  </div>
                  <div className="contact-user-name">
                    <h6 className="linear-background">&nbsp;</h6>
                  </div>
                  <div className="ctUserEmail">
                    <h6 className="linear-background">&nbsp;</h6>
                  </div>
                  <div className="contact-status">
                    <div className="linear-background"></div>
                  </div>
                  <div className="contact-stage-insight insight-loader">
                    {Array.from({ length: 4 }, (value, index) => (
                      <div
                        key={`contactTable${index}`}
                        className="linear-background"
                      />
                    ))}
                  </div>
                  <div className="contact-insight-date">
                    <div className="linear-background" />
                  </div>
                  <div className="contact-date">
                    <div className="linear-background" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <PaginationComponent
          callback={paginate}
          loading={loader}
          totalPages={totalPages}
          currentPage={contactsData?.currentPage || 1}
        />
      </div>
      {showContactFilter && !showStepsList && (
        <SeqContactTeamDataFilterContext.Provider
          value={{
            defaultSelectAllTeamMemberObject,
            teamFilterDropDownData,
            setTeamFilterDropDownData,
            teamCount,
            setTeamCount
          }}
        >
          <SequenceContactFilter
            filterArr={filterArr}
            setFilterArr={setFilterArr}
            searchTerm={searchKeyword}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            limit={contactLimit}
            filterLoadingFn={setLoader}
            tableLoadingFn={setLoader}
            setContactFilter={setShowContactFilter}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            pageNo={contactsData?.currentPage || 1}
            seqId={seqId}
            isSharedSequenceWithUser={isSharedSequenceWithUser}
            showContactOwner={showContactOwner}
            defaultSelectAllTeamMemberObject={defaultSelectAllTeamMemberObject}
            data={data}
            setData={setData}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            resetAll={resetContactFilter}
          />
        </SeqContactTeamDataFilterContext.Provider>
      )}
      {showStepsList && (
        <StepsList
          seqId={seqId}
          getTableContacts={getTableContacts}
          clearSearch={clearSearch}
          addFilter={addFilter}
          metricData={metricData}
          setMetricData={setMetricData}
          getStepMetricData={getStepMetricData}
          analyticStepLoader={analyticStepLoader}
          setAnalyticStepLoader={setAnalyticStepLoader}
        />
      )}
    </div>
  );
};

const OutofOotoInfoSection = (props = {}) => {
  const {
    emailAddress = "",
    status = "",
    sequenceToggleBtn = false,
    outofOotoTooltip = false,
    setOutofOotoTooltip = () => {},
    getFormattedDate = () => {},
    contact = {}
  } = props || {};
  return (
    <>
      {status?.toUpperCase() === "PAUSED_OOTO" &&
        outofOotoTooltip === emailAddress && (
          <div className="outOfOfficeOverlay">
            <div className="leftContainer">
              <span className="outOfOfficeText">Out of Office</span>
              <div className="sequenceResumeText">
                {!sequenceToggleBtn
                  ? `This contact will not resume automatically since the sequence is disabled.`
                  : `This contact will auto-resume on ${getFormattedDate(
                      contact?.rescheduleDate
                    )}`}
              </div>
            </div>
            <img src={ootoCalendarIcon} alt="Out Of Office Calendar" />
          </div>
        )}
      {status === "PAUSED_OOTO" && (
        <i
          className="material-icons-outlined infoIcon"
          onMouseEnter={() => setOutofOotoTooltip(emailAddress)}
          onMouseLeave={() => setOutofOotoTooltip("")}
        >
          info
        </i>
      )}
    </>
  );
};

export default observer(SequenceContacts);
export { SequenceContacts, SeqContactTeamDataFilterContext };
