import React from "react";

const TabNavigation = props => {
  const {
    tabList = [],
    className = "",
    activeTab = tabList[0]?.id,
    handleTabNavigation = () => {}
  } = props;

  return (
    <ul className={`tabNavigation ${className}`}>
      {tabList.map((item, index) => {
        const { id = "", value = "", show = true } = item || {};
        if (show) {
          return (
            <li
              key={`${id}-${index}`}
              className={`${id} tabItem ${activeTab === id ? "activeTab" : ""}`}
              onClick={() => handleTabNavigation(id)}
            >
              {value}
            </li>
          );
        }
      })}
    </ul>
  );
};

export { TabNavigation };
export default TabNavigation;
