/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
/* eslint-disable import/no-unresolved */
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { ReactComponent as TickIcon } from "Assets/svg/tick.svg";
import { ReactComponent as ErrorIcon } from "Assets/svg/error.svg";
import { ReactComponent as WarningIcon } from "Assets/svg/warning.svg";
import { TOASTER_COLOR } from "Static/constant";
import CustomTooltip from "Components/common/CustomTooltip";

const defaultPopupValues = {
  showUndoButton: false,
  callback: () => {}
};

class ToasterState {
  @observable toastMsg = "";

  isNegativeMessage = false;
  isWarningMessage = false;
  isInfoMessagae = false;
  isUpcomingMessage = false;

  @observable bgcolor = "#28ad56";
  @observable toasterTimeout = 3500;

  @observable showStatusPopup = false;
  @observable popupValues = defaultPopupValues;

  @observable additionalErrMessage = "";
  @observable showTooltip = false;

  @action
  setShowTooltip = value => {
    this.showTooltip = value;
    if (value) {
      this.toastMsg = this.toastMsg.replace(
        /<a href="#" id="showMoreLink">Show More<\/a>/,
        ""
      );
    }
  };

  @action
  setToasterTimeout = value => {
    this.toasterTimeout = value;
  };

  @action
  setPopupValues = (obj = {}) => {
    this.popupValues = obj;
  };

  @action
  setToastMsg = (customTxt, msgType, additionalErrMessage) => {
    let str = "";
    if (customTxt !== null && customTxt !== undefined) {
      if (str !== "") str += " <br />";
      str += customTxt;
    }

    if (additionalErrMessage !== null && additionalErrMessage !== undefined) {
      this.additionalErrMessage = additionalErrMessage.slice(
        1,
        additionalErrMessage.length - 1
      );
      if (!this.showTooltip) {
        str += ` <a href="#" id="showMoreLink">Show More</a>`;
      }
    } else {
      this.additionalErrMessage = "";
    }
    this.showStatusPopup = true;
    this.toastMsg = str;
    const thisObj = this;

    setTimeout(function () {
      const showMoreLink = document.getElementById("showMoreLink");
      if (showMoreLink) {
        showMoreLink.addEventListener("click", function (event) {
          event.preventDefault();
          thisObj.setShowTooltip(true);
        });
      }
    });

    if (msgType && msgType.length) {
      switch (msgType.toLowerCase()) {
        case "success":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.SUCCESS;
          break;
        case "warning":
          this.isNegativeMessage = false;
          this.isWarningMessage = true;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.WARNING;
          break;
        case "info":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = true;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.INFO;
          break;
        case "fail" || "failure":
          this.isNegativeMessage = true;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.DANGER;
          break;
        case "upcoming" || "coming_soon":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = true;
          this.bgcolor = TOASTER_COLOR.UPCOMING;
          break;
        default:
          this.isWarningMessage = false;
          this.bgcolor = TOASTER_COLOR.DANGER;
          this.isNegativeMessage = true;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          break;
      }
    }

    if (window.timeoutVar !== undefined) clearTimeout(window.timeoutVar);
    setTimeout(function () {
      thisObj.showStatusPopup = true;
      thisObj.toastMsg = str;
      window.timeoutVar = setTimeout(function () {
        thisObj.close();
      }, thisObj.toasterTimeout || 3500);
    }, 100);
  };

  @action
  close = () => {
    this.showStatusPopup = false;
    this.isNegativeMessage = false;
    this.isWarningMessage = false;
    this.isInfoMessagae = false;
    this.isUpcomingMessage = false;
    this.toastMsg = "";
    this.bgcolor = "#28ad56";
    this.showTooltip = false;
    this.toasterTimeout = 3500;
    if (this.popupValues.showUndoButton) {
      this.popupValues = defaultPopupValues;
    }
  };
}
const toasterState = new ToasterState();

const Toaster = observer(() => {
  const history = useHistory();
  useEffect(() => {
    if (toasterState?.popupValues?.custom) {
      const revealedContactsDom = document.querySelector(
        ".showRevealedContacts"
      );
      if (revealedContactsDom) {
        revealedContactsDom.addEventListener("click", function () {
          history.push(toasterState?.popupValues?.link || "/contacts");
        });
      }
    }
  }, [toasterState.toastMsg]);

  return (
    <>
      <div
        style={{
          backgroundColor: toasterState.bgcolor
        }}
        className={`common-toast-wrapper ${
          toasterState.showStatusPopup ? "show" : ""
        } ${toasterState.isUpcomingMessage ? "upcomingToase" : ""}`}
      >
        <div className="messageCont">
          {toasterState.isNegativeMessage ? (
            <span className="crossIcon svgIcon">
              <ErrorIcon />
            </span>
          ) : toasterState.isWarningMessage ? (
            <span className="svgIcon tickIcon">
              <WarningIcon />
            </span>
          ) : toasterState.isInfoMessagae ? (
            <span className="svgIcon infoIcon">
              <i className="material-icons">info</i>
            </span>
          ) : toasterState.isUpcomingMessage ? (
            ""
          ) : (
            <span className="svgIcon tickIcon">
              <TickIcon />
            </span>
          )}
          <span
            className="message" // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: toasterState.toastMsg || ""
            }}
          />
          {toasterState.popupValues.showUndoButton && (
            <>
              <span
                className="undoButton"
                onClick={() => {
                  toasterState.popupValues.callback();
                  toasterState.close();
                }}
              >
                Undo
              </span>
              <span
                className="closeToaster material-icons"
                onClick={toasterState.close}
              >
                close
              </span>
            </>
          )}
          {toasterState.popupValues.showCloseIcon && (
            <span
              className="closeToaster material-icons"
              onClick={toasterState.close}
            >
              close
            </span>
          )}
          {toasterState.showTooltip && (
            <div className="toolTip">
              <CustomTooltip
                text={toasterState.additionalErrMessage}
                className="inboxUnavailable"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export { Toaster, toasterState };
export default Toaster;
