/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import DropdownMenu from "Components/common/DropDownMenu";
import userDetail from "Stores/userDetail";
import CustomTooltip from "Components/common/CustomTooltip";
import PaywallUtils from "Utils/PaywallUtils";
import { ReactComponent as EnrichEmails } from "Assets/svg/Enrich/enrichEmails.svg";
import { ReactComponent as EnrichEmailsPhone } from "Assets/svg/Enrich/enrichEmailsPhone.svg";
import { ReactComponent as EnrichIcon } from "Assets/svg/Enrich/enrich.svg";

const EnrichBtn = props => {
  const {
    selectedEntityCount = 0,
    cbk = () => {},
    loading = false,
    checked = false,
    pageType = "",
    showEnrichTypeDropdown = false,
    setShowEnrichTypeDropdown = () => {},
    isEnrichEnabled = true
  } = props || {};

  const { inFreePlan = false, planName = "" } =
    userDetail.userFeatureFlag || {};
  const [showDescriptionTp, setShowDescriptionTp] = useState(false);

  const enrichAction = async ({}, item = {}) => {
    setShowEnrichTypeDropdown(false);
    const { type = "" } = item || {};
    checked && !loading && (await checkTeamSubscriptionActive()) && cbk(type);
  };

  const handleEnrichHoverAction = (mouseEnter = false) => {
    if (selectedEntityCount > 0) {
      setShowEnrichTypeDropdown(mouseEnter);
    }
    setShowDescriptionTp(mouseEnter);
  };

  const pageTypeObj = {
    calls: "callsExport",
    contacts: "contactsExport",
    sequences: "sequenceExport"
  };

  const dropdownOptions = [
    {
      value: "Enrich emails",
      key: "email",
      method: enrichAction,
      type: "EMAIL",
      icon: {
        ele: <EnrichEmails />
      }
    },
    {
      value: "Enrich emails and phone numbers",
      key: "email_phone",
      method: enrichAction,
      type: "EMAIL_PHONE",
      icon: {
        ele: <EnrichEmailsPhone />
      }
    }
  ];

  return (
    <div
      className={`exportBtnWrapper enrichBtnwrapper ${
        selectedEntityCount <= 0 ? "exportSelectedDisabled" : ""
      } ${selectedEntityCount ? "enabled" : ""}`}
      onMouseEnter={() => handleEnrichHoverAction(true)}
      onMouseLeave={() => handleEnrichHoverAction(false)}
    >
      <span className="enrichText">
        <EnrichIcon />
      </span>
      {!isEnrichEnabled && (
        <span className="wrapLockIcon">
          <UpgradeIconTooltip
            plan={PaywallUtils.getPlanName(planName, inFreePlan)}
            type={pageTypeObj[pageType]}
            enableTooltip={true}
            alignTooltip="topRight"
          />
        </span>
      )}
      {showDescriptionTp && (
        <CustomTooltip
          text={
            selectedEntityCount > 0
              ? "Enrich selected"
              : "Select one or more contacts to enrich."
          }
          className={
            selectedEntityCount > 0
              ? "enrichContactsTooltip"
              : "bulkAddtoSequence"
          }
        />
      )}
      {showEnrichTypeDropdown && <DropdownMenu options={dropdownOptions} />}
    </div>
  );
};

export { EnrichBtn };
export default observer(EnrichBtn);
