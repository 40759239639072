import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { pullContactState } from "./store";
import Button from "../../Components/common/Button";
import { ReactComponent as PullContactsIcon } from "./svg/pullContacts.svg";
import { ReactComponent as ContactsFoundIcon } from "./svg/contactsFound.svg";
import PasteInput from "./PasteInput";
import ListSequence from "./ListSequence";
import userDetail from "Stores/userDetail";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Utils from "Utils/utils";

const PullContacts = observer(() => {
  const history = useHistory();
  const handleSearch = () => {
    pullContactState.setSearchLoading(true);
    pullContactState.lookupContacts();
  };

  const handleExtract = () => {
    pullContactState.setExtractBtnLoading(true);
    pullContactState.extractContacts(history);
  };

  const handleShowAddToSeq = () => {
    pullContactState.setShowListSeq(true);
  };

  const closeCbk = () => {
    pullContactState.setShowPopup(false);
    pullContactState.resetFunc();
  };

  const getSequenceList = async (pageNo = 1) => {
    const payload = {
      pageNo: pageNo || 1,
      limit: 500,
      filters: {
        memberIds: [userDetail?.userInfo?.memberId]
      }
    };
    let seqObjResponse = await pullContactState.getSequenceData(payload);
    const { totalPages = 0, currentPage = 0 } = seqObjResponse || {};
    if (totalPages > 0 && currentPage < totalPages) {
      getSequenceList(currentPage + 1);
    }
    return true;
  };

  useEffect(() => {
    pullContactState.getContactList();
    getSequenceList();
  }, []);

  return (
    pullContactState.showPopup && (
      <>
        <div className="pullContactsMask" onClick={closeCbk} />
        <div className="pullContactsWrapper">
          <div className="card">
            <i className="material-icons closeIcon" onClick={closeCbk}>
              close
            </i>
            <PullContactsIcon />
            <p className="pullContactsText">
              Pull contacts from LinkedIn sales navigator
            </p>
            <PasteInput />
            {pullContactState.extractBtn ? (
              <>
                <div
                  className={`extractContactsWrapper ${
                    pullContactState.showListSeq ? "showListSeq" : ""
                  }`}
                >
                  <div className="extractTextWithNotes">
                    <ContactsFoundIcon />
                    <div className="flexWrapperWithIcon">
                      <p className="extractContactsText">
                        <strong>{`${Utils.formatNumberToString(
                          pullContactState.pullContactsCount
                        )} ${
                          pullContactState.pullContactsCount > 1
                            ? "contacts"
                            : "contact"
                        } `}</strong>{" "}
                        <span>{` found`}</span>
                      </p>
                      {!pullContactState.showListSeq && (
                        <div className="flexWrapper">
                          <span className="smallNotesText">
                            {pullContactState.pullContactsCount >= 2500
                              ? `The first 2,500 contacts from this search will be
                            extracted to Salesgear. We recommend that you make
                            this search more targeted to keep the count around
                            2,500.`
                              : "All the contacts from this search will be extracted to Salesgear. You can extract up to 2,500 contacts per search criteria."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {pullContactState.showListSeq && (
                    <div className="extractBtnWrapper">
                      <div className="listSequenceContainer">
                        <ListSequence />
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  btnText={"Extract"}
                  onClick={
                    !pullContactState.showListSeq
                      ? handleShowAddToSeq
                      : handleExtract
                  }
                  className={`extractBtn ${
                    pullContactState.disableExtractBtn
                      ? "disableExtractBtn"
                      : ""
                  } ${pullContactState?.extractBtnLoading ? "loading" : ""}`}
                  loader={pullContactState.extractBtnLoading}
                />
              </>
            ) : (
              <Button
                btnText={"Search"}
                onClick={handleSearch}
                className={`searchWrapper ${
                  pullContactState?.searchLoading ? "loading" : ""
                }`}
                loader={pullContactState.searchLoading}
              />
            )}
          </div>
        </div>
      </>
    )
  );
});

export { PullContacts };
export default PullContacts;
