import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import ProgressBar from "Utils/LottieFiles/ProgressBar.json";
import ConactProcessImg from "Assets/png/ContactProcess.png";
import BgDotWave from "Assets/png/BgDotWave.png";
import { observer } from "mobx-react";
const { action, observable } = require("mobx");

const defaultSpeed = 1;
class EnrichProcessingState {
  @observable showPopup = false;
  @observable doComplete = false;

  @action
  completeAnimation = () => {
    this.doComplete = true;
  };

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };
}

const enrichProcessingState = new EnrichProcessingState();

const EnrichProcessing = observer(() => {
  const [speed, setSpeed] = useState(defaultSpeed);
  window.timeout = null;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: ProgressBar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const setProgressSpeed = () => {
    window.timeout = setTimeout(() => {
      if (
        !enrichProcessingState.doComplete &&
        enrichProcessingState.showPopup
      ) {
        setSpeed(0.1);
      }
    }, 2500);
  };

  useEffect(() => {
    if (enrichProcessingState.showPopup) {
      setProgressSpeed();
    } else {
      setSpeed(defaultSpeed);
    }
  }, [enrichProcessingState.showPopup]);

  useEffect(() => {
    clearTimeout(window.timeout);
    setSpeed(10);
  }, [enrichProcessingState.doComplete]);

  return enrichProcessingState.showPopup ? (
    <div className="popup">
      <div className="popupOverlay"></div>
      <div className="popupCard expProcessWt animated customzoomIn">
        <div className="popupTitle">
          <h6 className="titleSm">
            We are enriching your contacts. Hold tight.
          </h6>
        </div>
        <div className="popupBody">
          <div className="progressBar">
            <Lottie options={defaultOptions} width={240} speed={speed} />
          </div>
        </div>
        <div className="imgWrap">
          <img src={ConactProcessImg} className="contactImg" alt="" />
          <img className="dotWaveBg" src={BgDotWave} alt="" />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
});

export { EnrichProcessing, enrichProcessingState };
export default EnrichProcessing;
