/* eslint-disable array-callback-return */
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import AddContactDropDown from "Components/common/AddContactDropDown";
import { toasterState } from "Components/common/toaster";
import contactStoreData from "Stores/contactData";
import { tagsStoreData } from "Stores/TagsData";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import {
  MP_EVENT,
  SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS
} from "Static/constant";
import { addSingleContactPopupState } from "Components/common/AddSingleContactPopup";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import { ContactUtils } from "Utils/ContactUtils";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const AddContactBtn = observer(props => {
  const {
    planName = "",
    inFreePlan = false,
    noOfContactsEligible = 0
  } = userDetail?.userFeatureFlag || {};

  const {
    validContactUpload = false,
    tableLoading = false,
    activeTab = "",
    id = "",
    contactLimit = 10,
    pageType = "contacts",
    isFromScheduleSeq = true,
    setContactUploading = () => {},
    setTableLoading = () => {},
    setFilterLoading = () => {},
    setActiveTab = () => {}
  } = props;

  let isContactsPage = pageType === "contacts";

  const addBulkContactToDirectory = async () => {
    if (await checkTeamSubscriptionActive()) {
      if (validContactUpload) {
        addBulkContactPopupState.setPageType("contacts");
        addBulkContactPopupState.setSubmitCallback(bulkContactUpload);
        addBulkContactPopupState.setShowPopup(true);
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "contactsAdded",
          false,
          planName,
          inFreePlan,
          { contactCount: noOfContactsEligible }
        );
      }
    }
  };

  const bulkContactUpload = payload => {
    const bulkContactPayload = getBulkContactPayload(payload);
    const tempObj = { contacts: bulkContactPayload };
    const addTagPopupCbk = tagsList => {
      const extractedTagsArray = Utils.extractTagNameFromList(tagsList);
      uploadContact(tempObj, null, null, extractedTagsArray);
    };
    Utils.showAddTagsPopup(addTagPopupCbk, bulkContactPayload.length);
  };

  const convertPhAsArray = phNumber => {
    return phNumber?.split(",") || null;
  };

  const getBulkContactPayload = payload => {
    if (payload?.length) {
      const contactArr = [];
      payload.map(obj => {
        const contact = {};
        contact.firstName = obj.firstname || null;
        contact.lastName = obj.lastname || null;
        contact.email = obj.email || null;
        contact.linkedinId = Utils.removeHttpFromString(
          obj.linkedinId || obj.linkedinid || null
        );
        contact.mobilePhone = convertPhAsArray(
          obj.mobilePhone ||
            obj.mobilephone ||
            obj.mobilenumber ||
            obj.mobileNumber ||
            obj.mobile ||
            null
        );
        contact.otherPhone = convertPhAsArray(
          obj.otherPhone ||
            obj.otherphone ||
            obj.otherNumber ||
            obj.othernumber ||
            null
        );
        contact.phone = convertPhAsArray(
          obj.phone || obj.phonenumber || obj.phoneNumber || null
        );
        contact.company = obj.company || obj.companyname || null;
        contact.jobTitle = obj.jobTitle || obj.jobtitle || null;
        contact.industry = obj.industry || null;
        contact.street = obj.street || null;
        contact.city = obj.city || null;
        contact.state = obj.state || null;
        contact.country = obj.country || null;
        contact.zipCode = obj.zipCode || obj.zipcode || obj.zip || null;
        contact.website = obj.website || null;
        contact.facebook = obj.facebook || null;
        contact.twitter = obj.twitter || null;
        contact.contactNotes =
          obj.contactNotes || obj.contactnotes || obj.notes || null;
        contact.customFields =
          Utils.getMappedCustomFieldsFromPayload(obj, true) || {};
        contactArr.push(contact);
      });
      return contactArr;
    }
  };

  const addSingleContact = async event => {
    if (await checkTeamSubscriptionActive()) {
      if (!tableLoading) {
        if (validContactUpload) {
          Utils.preventDefaultFn(event);
          showAddSingleContactPopup();
        } else {
          PaywallUtils.upgradeUtilsFunction(
            "contactsAdded",
            false,
            planName,
            inFreePlan,
            { contactCount: noOfContactsEligible }
          );
        }
      }
    }
  };

  const uploadContact = async (
    data,
    emptyParam1,
    emptyParam2,
    tagsArray = []
  ) => {
    setContactUploading(true);
    try {
      const payload = getContactUploadPayload(data, tagsArray);
      const response = await saveContact(payload);
      if (response?.status) {
        if (response?.status === 200) {
          if (tagsArray?.length) {
            Utils.mixpanelTrack(MP_EVENT.TAG_ADDED, {
              pageType: window.location.pathname
            });
          }
          contactStoreData.setFilteredTags([]);
          handleUploadContactSuccess(response);
        } else {
          handleUploadcontactFailure();
        }
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (e) {
      handleUploadcontactFailure();
    }
    setContactUploading(false);
  };

  const getContactUploadPayload = (data, tagsArray) => {
    if (data?.contacts?.length) {
      return {
        contacts: data.contacts,
        contactTags: data.contactTags || tagsArray || []
      };
    }
  };

  const saveContact = payload => {
    const config = {
      url: URLS.addContactsToDirectory,
      method: "POST",
      data: payload
    };
    return makeApi(config);
  };

  const handleUploadContactSuccess = response => {
    tagsStoreData.getAllTags();
    const { invalidContacts, validContacts } = response.data;
    let message = "";
    let toasterType = "failure";
    if (validContacts) {
      setTableLoading(true);
      setFilterLoading(true);
      contactStoreData.setTotalContactValue(null); // setting the totalContact value to null. Check store condition
      const payload = {
        filter: {
          stage: null
        },
        stageId: null,
        searchTerm: "",
        pageNo: 1,
        limit: contactLimit || 10
      };
      ContactUtils.contactFilterByPayload(
        payload,
        setTableLoading,
        setFilterLoading,
        true
      );
      setActiveTab("total");
      if (validContacts) {
        message += `${validContacts} ${
          validContacts > 1 ? "contacts" : "contact"
        } added`;
      }
      toasterType = "success";
    }
    if (invalidContacts) {
      message += ` ${invalidContacts} duplicate contact${
        invalidContacts > 1 ? "s found" : " found"
      }`;
    }
    toasterState.setToastMsg(message, toasterType);
    Utils.mixpanelTrack(MP_EVENT.CT_ADDED, {
      contactCount: validContacts,
      pageType: "Contacts"
    });
  };

  const handleUploadcontactFailure = () => {
    toasterState.setToastMsg("Contacts upload Failed", "failure");
  };

  const showAddSingleContactPopup = () => {
    addSingleContactPopupState.setTokenList(
      getMandatoryFieldsForSingleContactUploading()
    );
    addSingleContactPopupState.setSubmitCallback(uploadContact);
    addSingleContactPopupState.setSuccessCallback(handleUploadContactSuccess);
    addSingleContactPopupState.setShowPopup(true);
  };

  const getMandatoryFieldsForSingleContactUploading = () => {
    return SINGLE_CONTACT_UPLOADING_MANDATORY_FIELDS;
  };

  useEffect(() => {
    return () => {
      addSingleContactPopupState.setShowPopup(false);
    };
  }, []);

  return (
    <AddContactDropDown
      addSingleContact={addSingleContact}
      addBulkContact={addBulkContactToDirectory}
      showButton={true}
      btnId={id}
      pageType={pageType}
      allowContactsUpload={true}
      isFromScheduleSeq={isFromScheduleSeq}
      allowSequence={true}
    />
  );
});

export default AddContactBtn;
export { AddContactBtn };
