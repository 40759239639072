/* eslint-disable */
import { MP_KEY } from "Static/constant";

/*start Mixpanel*/

typeof mixpanel != undefined &&
  mixpanel &&
  mixpanel.init(MP_KEY, {
    cross_subdomain_cookie: false,
    secure_cookie: true,
    cookie_domain: ".salesgear.io"
  });

/*end Mixpanel*/
